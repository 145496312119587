import { CdpEmailAddress } from './cdp-email-address';
import { CdpName } from './cdp-name';
import { CdpPhoneNumber } from './cdp-phone-number';
import { CdpPermission } from './permissions/cdp-permission.service';

export enum CdpUserRole {
  UNKNOWN = 'Unknown',
  ADMIN = 'Admin',
}

export class CdpUserProfile {
  public name: CdpName = new CdpName();
  public email: CdpEmailAddress = new CdpEmailAddress();
  public phoneNumber: CdpPhoneNumber = new CdpPhoneNumber();
  public role: CdpUserRole = CdpUserRole.UNKNOWN;

  constructor() {
  }

  public areAllRequiredFieldsCompleteAndValid(): boolean {
    /*
    console.log(
      `User name:${JSON.stringify(this.name)} isValid:${this.name.isValid()}`
    );
    console.log(
      `User email:${JSON.stringify(this.email)} isValid:${this.email.isValid()}`
    );
*/

    return this.name.isValid() && this.email.isValid();
  }

  public assign(rhs: CdpUserProfile) {
    this.setFromDict(rhs);
  }

  public setFromDict(dict: any) {
    // Note: This assignment assumes that the dictionary's entries
    //       match the expected properties.
    //console.log('User before update:', this);
    //console.log('User update dict:', dict);
    //Object.assign(this, dict);

    if (dict.name) {
      if (dict.name.familyName) {
        this.name.familyName = dict.name.familyName;
      }

      if (dict.name.givenName) {
        this.name.givenName = dict.name.givenName;
      }
    }

    if (dict.email && dict.email.email) {
      this.email.email = dict.email.email;
    }

    if (dict.phoneNumber && dict.phoneNumber.phoneNumber) {
      this.phoneNumber.phoneNumber = dict.phoneNumber.phoneNumber;
    }

    if (dict.role) {
      this.role = dict.role;
    }

    //console.log('User after update:', this);
  }
}

export class CdpUser {
  private permissions_: CdpPermission[] = [];
  private defaultPermissionIsAllowed_: boolean = false;

  profile: CdpUserProfile = new CdpUserProfile();

  constructor() {
  }

  public get permissions(): CdpPermission[] {
    return this.permissions_;
  }

  public get defaultPermissionIsAllowed(): boolean {
    return this.defaultPermissionIsAllowed_;
  }

  // For internal use only.
  setPermissions(permissions: CdpPermission[]) {
    this.permissions_ = permissions;
  }

  public set defaultPermissionIsAllowed(isAllowed: boolean) {
    this.defaultPermissionIsAllowed_ = isAllowed;
  }
  
}
