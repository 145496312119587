import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { CdpContainsErrors } from '../cdp-backend-core.service';
import { CdpError } from '../../core/cdp-error';
import { CdpJwt } from '../../core/cdp-auth/auth.service';

import { CdpEmailData, CdpSendEmailResponse } from '../../email/cdp-email';
import { CdpLoggerService } from 'src/app/core/log/cdp-logger.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CdpBackendService {
  constructor(private http: HttpClient, private logger: CdpLoggerService) {}

  // The return value is returned as a JSON string because there's no
  // real benefit to parsing it here.  We will have to store it in the
  // browser's session storage, and that requires a string representation
  // anyway.
  async sendCommandStartOrConnectCdpSession_(jwt: CdpJwt): Promise<string> {
    try {
      const jwtAccessToken: string = jwt.accessToken;
      const jwtIdToken: string = jwt.idToken;

      const queryStringParams: string = `jwtacc=${jwtAccessToken}&jwtid=${jwtIdToken}`;

      const serverUri = environment.server.urlBase;
      const uri: string = `${serverUri}/start_session?${queryStringParams}`;

      var responseBegin = await fetch(uri);

      var text = responseBegin.text();
      //console.log(`Server response to "${queryStringParams}": ${text}`);

      return text;
    } catch (error) {
      if (error instanceof CdpError) {
        throw error;
      } else {
        var message: string = `Server command error 1: ${JSON.stringify(
          error
        )}`;
        throw new CdpError(message);
      }
    }
  }

  // Because the browser has to save the session information as a string
  // anyway, we simply have it save and pass in the query string
  // parameters needed to end the session.
  async sendCommandEndCdpSession_(queryStringParams: string): Promise<void> {
    try {
      //console.log('sendCommandEndCdpSession 1');

      if (queryStringParams.length == 0) {
        // There's no valid way to end a session without the
        // session information.
        //console.log('  ->sendCommandEndCdpSession: no ID');
        return;
      }

      const serverUri = environment.server.urlBase;
      const uri: string = `${serverUri}/end_session?${queryStringParams}`;

      //console.log('URL for fetch 5: ', uri);
      var responseBegin = null;
      try {
        responseBegin = await fetch(uri);
      } catch (error) {
        //console.log('Await fetch threw exception:', error);
        throw error;
      }

      var data = await responseBegin.json();
      //console.log('Fetch response: ', data);

      /*console.log(
        `Server response to "${queryStringParams}": ${JSON.stringify(data)}`
      );*/

      return;
    } catch (error) {
      if (error instanceof CdpError) {
        throw error;
      } else {
        var message: string = `Server command error 2: ${JSON.stringify(
          error
        )}`;
        throw new CdpError(message);
      }
    }
  }

  async startOrConnectCdpSession(jwt: CdpJwt): Promise<string> {
    //console.log('Backend startOrConnect');

    try {
      return this.sendCommandStartOrConnectCdpSession_(jwt);
    } catch (error) {
      if (error instanceof CdpError) {
        //console.log('Session connect cdp error:', error);
        throw error;
      } else {
        throw new CdpError(`Session connect error: ${JSON.stringify(error)}`);
      }
    }
  }

  async endCdpSession(queryStringParams: string): Promise<void> {
    //console.log('Backend endCdpSession 1');

    try {
      return this.sendCommandEndCdpSession_(queryStringParams);
    } catch (error) {
      //console.log('Caught error in endCdpSession:', error);
      if (error instanceof CdpError) {
        throw error;
      } else {
        throw new CdpError(`End session error: ${JSON.stringify(error)}`);
      }
    }
  }

  async sendCommandSquareSessionSetupStart(jwt: CdpJwt): Promise<string> {
    try {
      const jwtAccessToken: string = jwt.accessToken;
      const jwtIdToken: string = jwt.idToken;

      const queryStringParams: string = `jwtacc=${jwtAccessToken}&jwtid=${jwtIdToken}`;

      const serverUri = environment.server.urlBase;
      const uri: string = `${serverUri}/square_session_setup_start?${queryStringParams}`;

      //console.log(`Send server command: ${uri}`);
      var responseBegin = await fetch(uri);
      var data = await responseBegin.json();
      //console.log(`Server response to "${uri}": ${JSON.stringify(data)}`);

      return data.squareOAuthURI;
    } catch (error) {
      var message: string = `Server command error 3: ${JSON.stringify(error)}`;
      throw new CdpError(message);
    }
  }

  async sendCommandSquareSessionCompleteStart(
    queryStringParams: string
  ): Promise<any> {
    try {
      // Note: <queryStringParams> already includes the initial "?"
      const serverUri = environment.server.urlBase;
      const uri: string = `${serverUri}/square_session_complete_start${queryStringParams}`;

      //console.log(`Send server command: ${uri}`);
      var responseBegin = await fetch(uri);
      //console.log("Response begin:", responseBegin);
      var data = await responseBegin.json();
      //console.log(`Server response to "${uri}": ${JSON.stringify(data)}`);

      return data;
    } catch (error) {
      var message: string = `Server command error 4: ${JSON.stringify(error)}`;
      throw new CdpError(message);
    }
  }

  async sendEmail(emailData: CdpEmailData): Promise<CdpSendEmailResponse> {
    var sendEmailResponse: CdpSendEmailResponse = new CdpSendEmailResponse();

    try {
      const serverUri = environment.server.urlBase;
      const uri: string = `${serverUri}/send_email`;

      //console.log('Backend send email:', emailData);
      let observable = this.http
        .post<CdpSendEmailResponse>(uri, emailData)
        .pipe(
          tap((response: CdpSendEmailResponse) => {
            this.logger.log(`Send email got response`);
            return response;
          }),
          catchError(this.handleError<CdpSendEmailResponse>('Send email'))
        );

      observable.subscribe((response) => {
        return response;
      });
    } catch (error) {
      var message: string = `Error: Server command sendEmail: ${JSON.stringify(
        error
      )}`;
      sendEmailResponse.appendError(error);
    }

    return sendEmailResponse;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logger.log(`${operation} failed: ${error.message}`);

      if (result && result instanceof CdpContainsErrors) {
        result.appendError(error);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
