import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-cdp-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cdp-footer.component.html',
  styleUrl: './cdp-footer.component.css'
})
export class CdpFooterComponent {

}
