import { v4 as uuidv4 } from 'uuid';

import { CdpCustomer, CdpCustomerInfo, CdpCustomerSet } from './cdp-customer';
import { CdpEmailAddress } from '../core/cdp-email-address';
import { CdpName } from '../core/cdp-name';

export enum CdpCustomerDatabaseOperationType {
  None = 'None',

  ModifyCustomers = 'ModifyCustomers',

  CreateDatabase = 'CreateDatabase',
  CreateList = 'CreateList',
  DeleteDatabaseAndLists = 'DeleteDatabaseAndLists',
  DeleteList = 'DeleteList',
  LoadDatabaseAndLists = 'LoadDatabaseAndLists',
  SaveDatabaseAndLists = 'SaveDatabaseAndLists',
  UnloadDatabase = 'UnloadDatabase',
  UpdateDatabase = 'UpdateDatabase',
  UpdateList = 'UpdateList',
}

export enum CdpCustomerDatabaseFileType {
  CustomerDatabase = 'CustomerDatabase',
  CustomerDatabaseList = 'CustomerDatabaseList',
}

export class CdpCustomerDatabaseRecord {
  shortId: number = -1;
  id: string = '';
  email: string = '';
  givenName: string = '';
  familyName: string = '';
  commPref: string = '';

  clear() {
    this.shortId = -1;
    this.id = '';
    this.email = '';
    this.givenName = '';
    this.familyName = '';
    this.commPref = '';
  }

  makeFromDict(recordDict: any) {
    this.shortId = recordDict.shortId;
    this.id = recordDict.id;
    this.email = recordDict.email;
    this.givenName = recordDict.givenName;
    this.familyName = recordDict.familyName;
    this.commPref = recordDict.commPref;
  }
  replaceUsingInfo(info: CdpCustomerInfo) {
    // Note: We leave id, shortId, and commPref unchanged.

    this.email = info.email.email;
    this.familyName = info.name.familyName;
    this.givenName = info.name.givenName;
  }

  isEquivalentToInfo(info: CdpCustomerInfo): boolean {
    return (
      this.email == info.email.email &&
      this.givenName == info.name.givenName &&
      this.familyName == info.name.familyName
    );
  }

  merge(other: CdpCustomerDatabaseRecord): boolean {
    let canUpdateEmail: boolean = false;
    let canUpdateGivenName: boolean = false;
    let canUpdateFamilyName: boolean = false;

    // Update the email if the current email is unspecified.
    // If both the current and new email are specified but
    // different, we cannot merge.

    // Update the name if either part of the current name
    // is unspecified.  For a given part, if both the
    // current and new value are specified but different,
    // we cannot merge.
    canUpdateEmail = this.canMergeStrings_(this.email, other.email);
    canUpdateGivenName = this.canMergeStrings_(this.givenName, other.givenName);
    canUpdateFamilyName = this.canMergeStrings_(
      this.familyName,
      other.familyName
    );

    if (!canUpdateEmail || !canUpdateGivenName || !canUpdateFamilyName) {
      return false;
    }

    if (this.email.length == 0) {
      this.email = other.email;
    }

    if (this.givenName.length == 0) {
      this.givenName = other.givenName;
    }

    if (this.familyName.length == 0) {
      this.familyName = other.familyName;
    }

    return true;
  }

  private canMergeStrings_(value1: string, value2: string) {
    return value2.length == 0 || value1.length == 0 || value1 == value2;
  }

  mergeUsingInfo(otherInfo: CdpCustomerInfo): boolean {
    const other: CdpCustomerDatabaseRecord = new CdpCustomerDatabaseRecord();
    other.replaceUsingInfo(otherInfo);

    return this.merge(other);
  }

  // The following function is static just to make it simpler to
  // handle dictionaries that are equivalent to a CdpCustomerDatabaseRecord,
  // since those lack member functions.
  static makeCustomerFromRecord(
    record: CdpCustomerDatabaseRecord
  ): CdpCustomer {
    const customer: CdpCustomer = new CdpCustomer();

    customer.id.id = record.id;
    customer.id.shortId = record.shortId;

    customer.info.email = new CdpEmailAddress(record.email);
    customer.info.name = new CdpName(record.givenName, record.familyName);

    return customer;
  }
}

export class CdpCustomerDatabaseList {
  fileType: CdpCustomerDatabaseFileType =
    CdpCustomerDatabaseFileType.CustomerDatabaseList;
  formatVersion: string = '1.0';
  name: string = '';
  listId: string = '';
  databaseId: string = '';
  customerShortIds: number[] = [];

  doesNameMatch(name: string): boolean {
    return name.toLowerCase() == this.name.toLowerCase();
  }

  makeFromDict(listDict: any) {
    // TODO Check fileType and formatVersion
    this.name = listDict.name;
    this.listId = listDict.listId;
    this.databaseId = listDict.databaseId;
    this.customerShortIds = listDict.customerShortIds;
  }
}

export class CdpCustomerDatabaseInternal {
  fileType: CdpCustomerDatabaseFileType =
    CdpCustomerDatabaseFileType.CustomerDatabase;
  formatVersion: string = '1.0';
  id: string = '';
  nextCustomerShortId: number = 0;
  customerRecords: CdpCustomerDatabaseRecord[] = [];

  makeFromDict(databaseDict: any) {
    // TODO Check fileType and formatVersion
    this.id = databaseDict.id;
    this.nextCustomerShortId = databaseDict.nextCustomerShortId;


    const numRecords: number = databaseDict.customerRecords.length;
    this.customerRecords.length = 0;
    for (let i = 0 ; i < numRecords ; i++) {
      const customerRecord: CdpCustomerDatabaseRecord = new CdpCustomerDatabaseRecord();
      customerRecord.makeFromDict(databaseDict.customerRecords[i]);
      this.customerRecords.push(customerRecord);
    }
  }
}

export enum CdpCustomerRecipientType {
  BlankIgnored,
  EmailKnown,
  EmailUnknown,
  EmailInvalid,
  ListKnown,
  ListUnknownOrInvalid,
}

export class CdpCustomerRecipientSet {
  origRecipientNames: string[] = [];
  recipientTypes: CdpCustomerRecipientType[] = [];
  customerRecords: CdpCustomerDatabaseRecord[] = [];

  getNumRecipients(): number {
    return this.customerRecords.length;
  }
}

export enum CdpCustomerDatabaseUpdateMode {
  RemoveAllExisting = 'RemoveAllExisting',
  Replace = 'Replace',
  MergeIfNoConflict = 'MergeIfNoConflict',
}

export enum CdpCustomerDatabaseUpdateResultType {
  AddedNew = 'AddedNew',
  ReplacedExisting = 'ReplacedExisting',
  Merged = 'Merged',
  Rejected = 'Rejected',
  Identical = 'Identical',
}

export class CdpCustomerDatabaseUpdateResult {
  resultType: CdpCustomerDatabaseUpdateResultType =
    CdpCustomerDatabaseUpdateResultType.Rejected;
  customerRecord: CdpCustomerDatabaseRecord | null = null;

  constructor(
    resultType: CdpCustomerDatabaseUpdateResultType = CdpCustomerDatabaseUpdateResultType.Rejected,
    customerRecord: CdpCustomerDatabaseRecord | null = null
  ) {
    this.resultType = resultType;
    this.customerRecord = customerRecord;
  }
}

export class CdpCustomerDatabaseUpdateResultSet {
  results: CdpCustomerDatabaseUpdateResult[] = [];

  hasAnyChanges(): boolean {
    for (const result of this.results) {
      if (
        result.resultType != CdpCustomerDatabaseUpdateResultType.Identical &&
        result.resultType != CdpCustomerDatabaseUpdateResultType.Rejected
      ) {
        // There was a change.
        return true;
      }
    }

    return false;
  }
}

export class CdpCustomerDatabase {
  private databaseInternal_: CdpCustomerDatabaseInternal | null = null;
  private databaseLists_: CdpCustomerDatabaseList[] = [];

  private recordIndexFromShortIdMap_: Map<number, number> = new Map<
    number,
    number
  >();
  private recordIndexFromEmailMap_: Map<string, number> = new Map<
    string,
    number
  >();
  private dirtyFlags_: number = 0;

  constructor(
    databaseInternal: CdpCustomerDatabaseInternal | null,
    databaseLists: CdpCustomerDatabaseList[]
  ) {
    this.databaseInternal_ = databaseInternal;
    this.databaseLists_ = databaseLists;

    this.rebuildMaps_();
  }

  private rebuildMaps_() {
    this.recordIndexFromShortIdMap_.clear();
    this.recordIndexFromEmailMap_.clear();

    //console.log('Rebuilding DB maps:', JSON.stringify(this));

    if (this.databaseInternal_) {
      const customerRecords: CdpCustomerDatabaseRecord[] =
        this.databaseInternal_.customerRecords;
      const numRecords: number = customerRecords.length;
      for (let recordIndex = 0; recordIndex < numRecords; recordIndex++) {
        const customerRecord: CdpCustomerDatabaseRecord =
          customerRecords[recordIndex];
        const shortId: number = customerRecord.shortId;
        const email: string = customerRecord.email;
        //console.log('  Adding record:', JSON.stringify(customerRecord));

        // TODO Should we allow a negative short ID ever, e.g., to indicate a deleted
        //      but undeletable customer?
        // Note: There should never be a customer without an email.
        //       TODO Might we ever allow a different unique identifier for a customer?
        if (shortId >= 0 && email.length > 0) {
          this.recordIndexFromShortIdMap_.set(shortId, recordIndex);
          this.recordIndexFromEmailMap_.set(email, recordIndex);
        }
      }
    }
  }

  get databaseInternal(): CdpCustomerDatabaseInternal | null {
    return this.databaseInternal_;
  }
  get databaseLists(): CdpCustomerDatabaseList[] {
    return this.databaseLists_;
  }

  getListNames(): string[] {
    const listNames: string[] = [];

    for (const databaseList of this.databaseLists_) {
      listNames.push(databaseList.name);
    }

    return listNames;
  }

  getListUsingName(listName: string): CdpCustomerDatabaseList | null {
    //console.log('getListUsingName listName=', listName);

    for (const databaseList of this.databaseLists_) {
      //console.log('getListUsingName: dl=', databaseList);

      if (databaseList.doesNameMatch(listName)) {
        return databaseList;
      }
    }

    return null;
  }

  getCustomerRecordsInList(
    listName: string
  ): CdpCustomerDatabaseRecord[] | null {
    let dl: CdpCustomerDatabaseList | null = null;

    for (const databaseList of this.databaseLists_) {
      if (databaseList.doesNameMatch(listName)) {
        dl = databaseList;
        break;
      }
    }

    /*
    console.log(
      `Getting customers for list: name=${listName}: dl=${JSON.stringify(dl)}`
    );
*/
    if (!dl) {
      return null;
    }

    const customerRecords: CdpCustomerDatabaseRecord[] = [];
    //console.log('DB map:', JSON.stringify(this.recordIndexFromShortIdMap_));

    for (const shortId of dl.customerShortIds) {
      const recordIndex: number = this.getRecordIndexUsingShortId_(shortId);

      if (recordIndex >= 0) {
        const record: CdpCustomerDatabaseRecord | null =
          this.getCustomerRecordUsingIndex_(recordIndex);
        if (record) {
          customerRecords.push(record);
        } else {
          // This should never happen.
          console.log(
            `Inconsistent record index ${recordIndex} for short ID ${shortId}`
          );
        }
      } else {
        // TODO The list contains a customer that doesn't exist.
        //      Clearly something is out of sync, e.g. a customer was deleted
        //      from the database. Just ignore this situation for now.
      }
    }

    return customerRecords;
  }

  isDirty(): boolean {
    return this.dirtyFlags_ != 0;
  }

  markClean() {
    this.dirtyFlags_ = 0;
  }

  private getCustomerRecordUsingIndex_(
    recordIndex: number
  ): CdpCustomerDatabaseRecord | null {
    //console.log(`getCustomerRecordUsingIndex_ index=${recordIndex} nr=${this.databaseInternal_?.customerRecords.length}`);
    if (this.databaseInternal_) {
      const numRecords: number = this.databaseInternal_.customerRecords.length;

      if (recordIndex >= 0 && recordIndex < numRecords) {
        return this.databaseInternal_.customerRecords[recordIndex];
      }
    }

    return null;
  }

  getShortIdUsingCustomerInfo(info: CdpCustomerInfo): number {
    return this.getShortIdUsingEmail(info.email.email);
  }

  private getRecordIndexUsingEmail_(email: string): number {
    const index: number | undefined = this.recordIndexFromEmailMap_.get(email); 
    return (index == undefined ? -1 : index);
  }

  private getRecordIndexUsingShortId_(shortId: number): number {
    const index: number | undefined = this.recordIndexFromShortIdMap_.get(shortId); 
    return (index == undefined ? -1 : index);
  }

  getShortIdUsingEmail(email: string): number {
    const recordIndex: number = this.getRecordIndexUsingEmail_(email);

    //console.log(`getShortId for customer: ${email} -> index= ${recordIndex}`);

    if (recordIndex >= 0) {
      const record: CdpCustomerDatabaseRecord | null =
        this.getCustomerRecordUsingIndex_(recordIndex);
      if (record) {
        return record.shortId;
      }
    }

    return -1;
  }

  getCustomerRecordUsingEmail(email: string): CdpCustomerDatabaseRecord | null {
    const recordIndex: number = this.getRecordIndexUsingEmail_(email);
    if (recordIndex >= 0) {
      return this.getCustomerRecordUsingIndex_(recordIndex);
    }

    return null;
  }

  getCustomerUsingEmail(email: string): CdpCustomer | null {
    const record: CdpCustomerDatabaseRecord | null =
      this.getCustomerRecordUsingEmail(email);
    if (record) {
      return CdpCustomerDatabaseRecord.makeCustomerFromRecord(record);
    } else {
      return null;
    }
  }

  addNewListUsingShortIds(
    listName: string,
    shortIds: number[]
  ): [string, boolean] {
    if (!this.databaseInternal_) {
      // TODO Throw exception?
      return ['', false];
    }

    for (const databaseList of this.databaseLists_) {
      if (databaseList.doesNameMatch(listName)) {
        // Duplicates are not allowed.
        console.log('Cannot add list with same name as existing list.');
        return ['', false];
      }
    }

    // Normalize the name by removing leading and trailing whitespace
    // and replacing any internal sequences of whitespace with a
    // single space.
    const name: string = listName
      .trim()
      .split(/[\s,\t,\n]+/)
      .join(' ');

    // TODO Check for any restricted characters?

    const databaseList: CdpCustomerDatabaseList = new CdpCustomerDatabaseList();
    databaseList.name = name;
    databaseList.databaseId = this.databaseInternal_.id;
    databaseList.listId = uuidv4();

    const uniqueShortIds: number[] = [...new Set(shortIds)].sort();

    databaseList.customerShortIds = uniqueShortIds;

    //console.log('Adding new list:', databaseList);

    this.databaseLists_.push(databaseList);

    this.dirtyFlags_ = 1;

    return [name, true];
  }

  addOrReplaceListUsingShortIds(
    listName: string,
    shortIds: number[],
    allowReplace: boolean
  ): [string, boolean] {
    let didChange: boolean = false;

    //console.log('Add or replace list:', listName);

    if (!this.databaseInternal_) {
      // TODO Throw an exception or just do nothing?
      return ['', didChange];
    }

    // Remove duplicates by converting to a Set.
    const uniqueShortIds = [...new Set(shortIds)].sort();

    // Check that all short IDs are valid.
    for (const shortId of uniqueShortIds) {
      if (this.getRecordIndexUsingShortId_(shortId) < 0) {
        throw Error('Cannot add unknown customer to list');
      }
    }

    let foundList: boolean = false;

    for (const databaseList of this.databaseLists_) {
      if (databaseList.doesNameMatch(listName)) {
        foundList = true;

        if (!allowReplace) {
          throw Error('Cannot add list with duplicate name');
        }

        // Replace the contents of the list.
        // If the old and new sets of IDs aren't equal, then
        // mark the database as being dirty.
        const uniqueListShortIds: number[] = [
          ...new Set(databaseList.customerShortIds),
        ].sort();

        let areEqual = uniqueShortIds.length == uniqueListShortIds.length;
        if (areEqual) {
          // Just compare element by element, since the arrays are sorted.
          const n: number = uniqueShortIds.length;
          for (let i = 0; i < n; i++) {
            if (uniqueShortIds[i] != uniqueListShortIds[i]) {
              areEqual = false;
              break;
            }
          }
        }

        databaseList.customerShortIds = uniqueShortIds;

        if (!areEqual) {
          didChange = true;
          this.dirtyFlags_ = 1;
        }

        return [databaseList.name, didChange];
      }
    }

    if (!foundList) {
      // We need to create a new list and fill it.
      return this.addNewListUsingShortIds(listName, uniqueShortIds);
    }

    return ['', false]; // happy compiler
  }

  maybeRemoveList(listName: string, databaseId: string, listId: string, markDirtyIfRemoved: boolean) {
    // If <databaseId> is the same as the ID for the current database, remove the
    // matching list, if any.
    // TODO Should we match on listName or only listId?
    if (!this.databaseInternal_ || (this.databaseInternal_.id != databaseId)) {
      console.log("Ignoring list from a different database");

      return;
    }

    const numLists: number = this.databaseLists_.length;

    const remainingLists: CdpCustomerDatabaseList[] = this.databaseLists_.filter((dl) => dl.listId != listId && (dl.name != listName));
    
    if (markDirtyIfRemoved) {
       const didRemoveAny: boolean = (remainingLists.length != numLists);
       if (didRemoveAny) {
        this.dirtyFlags_ = 1;
       }
    }

    this.databaseLists_ = remainingLists;
  }

  addOrUpdateCustomerInfo(
    info: CdpCustomerInfo,
    updateMode: CdpCustomerDatabaseUpdateMode
  ): CdpCustomerDatabaseUpdateResult {
    const email: string = info.email.email;

    //console.log(`addOrUpdateCustomerInfo mode=${updateMode} info=${info}`);

    const existingCustomerRecordIndex: number =
      this.getRecordIndexUsingEmail_(email);

    const existingCustomerRecord: CdpCustomerDatabaseRecord | null =
      this.getCustomerRecordUsingIndex_(existingCustomerRecordIndex);

    //console.log(`  existing customer record match: ${existingCustomerRecord}`);

    if (!existingCustomerRecord) {
      // We're adding a new customer.
      const newCustomerRecord: CdpCustomerDatabaseRecord =
        this.addNewCustomerUsingInfo_(info);
      return new CdpCustomerDatabaseUpdateResult(
        CdpCustomerDatabaseUpdateResultType.AddedNew,
        newCustomerRecord
      );
    } else if (existingCustomerRecord.isEquivalentToInfo(info)) {
      // The new info is equivalent to the existing info, so no change is needed.
      return new CdpCustomerDatabaseUpdateResult(
        CdpCustomerDatabaseUpdateResultType.Identical,
        existingCustomerRecord
      );
    } else if (
      updateMode == CdpCustomerDatabaseUpdateMode.Replace ||
      updateMode == CdpCustomerDatabaseUpdateMode.RemoveAllExisting
    ) {
      // Completely replace the existing customer with the new data.
      const updatedCustomerRecord: CdpCustomerDatabaseRecord =
        this.replaceCustomerUsingInfo_(existingCustomerRecord, info);

      return new CdpCustomerDatabaseUpdateResult(
        CdpCustomerDatabaseUpdateResultType.ReplacedExisting,
        updatedCustomerRecord
      );
    } else {
      // There is an existing customer, and we are only allowed to merge the data if there is no
      // conflict.
      const updatedCustomerRecord: CdpCustomerDatabaseRecord | null =
        this.mergeCustomerUsingInfo_(existingCustomerRecord, info);
      const updateResultType: CdpCustomerDatabaseUpdateResultType =
        updatedCustomerRecord
          ? CdpCustomerDatabaseUpdateResultType.Merged
          : CdpCustomerDatabaseUpdateResultType.Rejected;
      return new CdpCustomerDatabaseUpdateResult(
        updateResultType,
        updatedCustomerRecord || existingCustomerRecord
      );
    }
  }

  private addNewCustomerRecordUsingInfo_(
    info: CdpCustomerInfo
  ): CdpCustomerDatabaseRecord {
    if (!this.databaseInternal_) {
      // This could happen only for a database that wasn't created on the
      // backend, and we probably shouldn't allow interacting with such
      // an object.

      throw Error('Database is invalid');
    }

    const shortId: number = this.databaseInternal_.nextCustomerShortId++;

    const record: CdpCustomerDatabaseRecord = new CdpCustomerDatabaseRecord();
    record.shortId = shortId;

    // TODO Really, creating the full ID should probably be done by the backend.
    record.id = uuidv4();

    record.email = info.email.email;
    record.familyName = info.name.familyName;
    record.givenName = info.name.givenName;

    this.databaseInternal_.customerRecords.push(record);

    const recordIndex: number = this.databaseInternal_.customerRecords.length - 1;

    this.recordIndexFromShortIdMap_.set(shortId, recordIndex);
    this.recordIndexFromEmailMap_.set(record.email, recordIndex);

    this.dirtyFlags_ = 1;

    //console.log('Added customer record:', record);

    return record;
  }

  private addNewCustomerUsingInfo_(
    info: CdpCustomerInfo
  ): CdpCustomerDatabaseRecord {
    const email: string = info.email.email;

    if (this.getCustomerRecordUsingEmail(email) != null) {
      throw Error('Duplicate customer email');
    }

    const record: CdpCustomerDatabaseRecord =
      this.addNewCustomerRecordUsingInfo_(info);
    return record;
  }

  private replaceCustomerUsingInfo_(
    existingCustomerRecord: CdpCustomerDatabaseRecord,
    info: CdpCustomerInfo
  ): CdpCustomerDatabaseRecord {
    const email: string = info.email.email;

    if (existingCustomerRecord.email != info.email.email) {
      throw Error('Cannot change customer email');
    }

    if (!existingCustomerRecord.isEquivalentToInfo(info)) {
      existingCustomerRecord.replaceUsingInfo(info);

      // TODO Mark <existingCustomerRecord> as dirty.
      this.dirtyFlags_ = 1;
    }

    return existingCustomerRecord;
  }

  private mergeCustomerUsingInfo_(
    existingCustomerRecord: CdpCustomerDatabaseRecord,
    info: CdpCustomerInfo
  ): CdpCustomerDatabaseRecord | null {
    const email: string = info.email.email;

    if (existingCustomerRecord.email != info.email.email) {
      throw Error('Cannot change customer email');
    }

    if (!existingCustomerRecord.isEquivalentToInfo(info)) {
      // Note: merge() does not change any values unless the full merge is valid.
      const mergeSucceeded: boolean =
        existingCustomerRecord.mergeUsingInfo(info);
      if (mergeSucceeded) {
        // TODO Mark <existingCustomerRecord> as dirty.

        this.dirtyFlags_ = 1;
      }
    }

    return existingCustomerRecord;
  }

  getCustomerRecipientsFromRecipientNames(
    recipientNames: string[]
  ): CdpCustomerRecipientSet {
    return CdpCustomerDatabase.getCustomerRecipientsFromRecipientNames_(
      this,
      recipientNames
    );
  }

  static getCustomerRecipientsFromRecipientNamesWithoutDatabase(
    recipientNames: string[]
  ): CdpCustomerRecipientSet {
    return CdpCustomerDatabase.getCustomerRecipientsFromRecipientNames_(
      null,
      recipientNames
    );
  }

  static getCustomerRecipientsFromRecipientNames_(
    database: CdpCustomerDatabase | null,
    recipientNames: string[]
  ): CdpCustomerRecipientSet {
    let customerRecipients: CdpCustomerDatabaseRecord[] = [];
    const recipientTypes: CdpCustomerRecipientType[] = [];

    // Each recipient can be an individual email address or the name of a
    // contact list.
    for (const recipientName of recipientNames) {
      const name: string = recipientName.trim();
      let recipientType: CdpCustomerRecipientType =
        CdpCustomerRecipientType.BlankIgnored;

      if (name.length > 0) {
        if (name.indexOf('@') >= 0) {
          // This is an email address.
          const email: CdpEmailAddress = new CdpEmailAddress(name);
          if (!email.isValid()) {
            recipientType = CdpCustomerRecipientType.EmailInvalid;
          } else {
            let customerRecord: CdpCustomerDatabaseRecord | null = null;

            if (database) {
              customerRecord = database.getCustomerRecordUsingEmail(name);
            }

            recipientType = customerRecord
              ? CdpCustomerRecipientType.EmailKnown
              : CdpCustomerRecipientType.EmailUnknown;

            if (!customerRecord) {
              customerRecord = new CdpCustomerDatabaseRecord();
              customerRecord.email = email.email;
            }

            customerRecipients.push(customerRecord);
          }
        } else {
          // This is (or at least should be) the name of a list.
          let databaseList: CdpCustomerDatabaseList | null = null;

          if (database) {
            databaseList = database.getListUsingName(name);
          }

          recipientType = databaseList
            ? CdpCustomerRecipientType.ListKnown
            : CdpCustomerRecipientType.ListUnknownOrInvalid;

          // The "&& database" below is just to make the compiler happy.
          if (databaseList && database) {
            const listCustomerRecords: CdpCustomerDatabaseRecord[] | null =
              database.getCustomerRecordsInList(name);
            if (listCustomerRecords) {
              customerRecipients =
                customerRecipients.concat(listCustomerRecords);
            }
          }
        }
      }

      recipientTypes.push(recipientType);
    }

    // Remove duplicates by converting to a Set.
    customerRecipients = [...new Set(customerRecipients)];

    const recipientSet: CdpCustomerRecipientSet = new CdpCustomerRecipientSet();
    recipientSet.origRecipientNames = recipientNames;
    recipientSet.recipientTypes = recipientTypes;
    recipientSet.customerRecords = customerRecipients;

    return recipientSet;
  }
}
