import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdpBusinessProfile } from '../cdp-business';
import { CdpCountryComponent } from '../../address/cdp-country/cdp-country';
import { CdpUsaStateComponent } from '../../address/cdp-usa-state/cdp-usa-state.component';
import { CdpUsaZipCodeComponent } from '../../address/cdp-usa-zip-code/cdp-usa-zip-code.component';
import { CdpBusinessProfileBasicInfoComponent } from '../cdp-business-profile-basic-info/cdp-business-profile-basic-info.component';
import { CdpBusinessTypeComponent } from '../cdp-business-type/cdp-business-type.component';
import { CdpBusinessProfileDetailsComponent } from '../cdp-business-profile-details/cdp-business-profile-details.component';
import { CdpBusinessProfileDescriptionComponent } from '../cdp-business-profile-description/cdp-business-profile-description.component';

@Component({
  selector: 'app-cdp-business-profile',
  standalone: true,
  imports: [
    CommonModule,
    CdpCountryComponent,
    CdpUsaStateComponent,
    CdpUsaZipCodeComponent,
    CdpBusinessProfileBasicInfoComponent,
    CdpBusinessProfileDescriptionComponent,
    CdpBusinessProfileDetailsComponent,
    CdpBusinessTypeComponent,
  ],
  templateUrl: './cdp-business-profile.component.html',
  styleUrl: './cdp-business-profile.component.scss',
})
export class CdpBusinessProfileComponent {
  @Input() profile: CdpBusinessProfile = new CdpBusinessProfile();

  constructor() {}
}
