import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdpSideMenuComponent } from 'src/app/ui/cdp-side-menu/cdp-side-menu.component';
import { SquareConnectComponent } from 'src/app/connectors/square/square-connect/square-connect.component';

@Component({
  selector: 'app-square-connect-page',
  standalone: true,
  imports: [CommonModule, CdpSideMenuComponent, SquareConnectComponent],
  templateUrl: './square-connect-page.component.html',
  styleUrl: './square-connect-page.component.sass',
})
export class SquareConnectPageComponent {}
