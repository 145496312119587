import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CdpRoutes } from 'src/app/core/router/cdp-routes';

@Component({
  selector: 'app-cdp-integrations',
  standalone: true,
  imports: [],
  templateUrl: './cdp-integrations.component.html',
  styleUrl: './cdp-integrations.component.sass',
})
export class CdpIntegrationsComponent {
  constructor(private router: Router) {}

  onClickConnectSquare() {
    this.router.navigate([CdpRoutes.SquareConnect]);
  }
}
