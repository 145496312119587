import { Component, EventEmitter, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DndDirective } from '../directives/dnd.directive';
import { CdpFileType } from 'src/app/core/files/cdp-file';

@Component({
  selector: 'app-cdp-drop-zone',
  standalone: true,
  imports: [CommonModule, DndDirective],
  templateUrl: './cdp-drop-zone.component.html',
  styleUrl: './cdp-drop-zone.component.scss',
})
export class CdpDropzoneComponent {
  @Input() text: string = "Drag image or click";
  fileDropEventEmitter = new EventEmitter<any>();

  @Input() allowedFileTypes: CdpFileType[] = [CdpFileType.Image];

  get allowedFileTypeIcons(): string {
    let iconString: string = '';
    if (this.allowedFileTypes.includes(CdpFileType.Image)) {
      iconString += '<i class="fs-1 bi-image"></i>';
    }
    if (this.allowedFileTypes.includes(CdpFileType.Csv)) {
      iconString += '<i class="fs-1 bi-filetype-csv"></i>';
    }
    if (this.allowedFileTypes.includes(CdpFileType.Txt)) {
      iconString += '<i class="fs-1 bi-filetype-txt"></i>';
    }
    if (this.allowedFileTypes.includes(CdpFileType.Any)) {
      iconString += '<i class="fs-1 bi-file"></i>';
    }

    return iconString;
  }

  get multiple(): string {
    return (this.allowMultipleFiles ? "multiple" : "false");   
  }

  @Input() isOperationInProgress: boolean = false;

  // TODO Show a blocked indicator if the user tries to drop multiple
  //      files onto a single-file dropzone.
  @Input() allowMultipleFiles: boolean = true;

  processFile(file: any)
  {
    //console.log("Drop zone emitting event:", file);
    this.fileDropEventEmitter.emit(file);
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    console.log("File dropped:", $event);

    // The event is a list of files.
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(filesEvent: any) {
    console.log("File browse handler:", filesEvent);

    // The event target has a list of files.
    this.prepareFilesList(filesEvent.target.files);
  }


  
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    if (this.allowMultipleFiles) {
    for (const item of files) {
      //console.log("Dropped object type:", typeof(item));
      this.processFile(item);
    }
    } else if (files.length > 0) {
      this.processFile(files[0]);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
