import { Component, OnInit } from '@angular/core';

import {
  CdpEmailTemplateManagerData,
  CdpEmailTemplateManagerEvent,
  CdpEmailTemplateManagerFile,
  CdpEmailTemplateManagerFileSet,
  CdpEmailTemplateManagerOwnerCategory,
  CdpEmailTemplateManagerService,
} from '../cdp-email-template-manager.service';
import {
  CdpEmailTemplateGallerySectionEvent,
  CdpEmailTemplateGallerySectionComponent,
  CdpEmailTemplateGalleryFileSet,
  CdpEmailTemplateGalleryOperationType,
} from '../cdp-email-template-gallery-section/email-template-gallery-section.component';
import { NavigationEnd, Router } from '@angular/router';
import { Mutex } from 'async-mutex';
import { CdpEmailTemplateFile } from '../cdp-email-template-file';
import { CdpProgressOperationSet, CdpProgressOperationTag } from 'src/app/ui/progress/cdp-progress';

@Component({
  selector: 'app-cdp-email-template-gallery-user',
  standalone: true,
  imports: [CdpEmailTemplateGallerySectionComponent],
  templateUrl: './cdp-email-template-gallery-user.component.html',
  styleUrl: './cdp-email-template-gallery-user.component.sass',
})
export class CdpEmailTemplateGalleryUserComponent implements OnInit {
  private thisUrl_: string = '';
  private mutex_: Mutex = new Mutex();
  galleryFileSet: CdpEmailTemplateGalleryFileSet = new CdpEmailTemplateGalleryFileSet();

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();

  didLoadAtLeastOnce: boolean = false;

  sectionTitle: string = 'My Templates';
  ownerCategory: CdpEmailTemplateManagerOwnerCategory = CdpEmailTemplateManagerOwnerCategory.BusinessOrUser;
  allowVariants: boolean = false;
  showFilters: boolean = false;
  emptyFilterMatchesAll: boolean = true;
  showTemplateIds: boolean = false; 
  showTemplateNames: boolean = true;

  constructor(
    private router: Router,
    private templateManager: CdpEmailTemplateManagerService
  ) {
  }

  async onReload() {
    await this.resetFromTemplateManagerData(
      await this.templateManager.getData()
    );
  }

  async ngOnInit(): Promise<void> {
    this.thisUrl_ = this.router.url;

    //console.log('Template gallery init');
    await this.onReload();

    //console.log('Template gallery subscribing to template manager');
    this.templateManager.eventEmitter.subscribe(
      (event: CdpEmailTemplateManagerEvent) =>
        this.onTemplateManagerEvent(event)
    );

    const onReload = this.onReload.bind(this);
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        //console.log('Navend event:', JSON.stringify(event));
        if (event.url == this.thisUrl_) {
          // route is restored
          //console.log('Navend event match:', this.thisUrl_);

          await onReload();
        }
      }
    });
  }

  async resetFromTemplateManagerData(data: CdpEmailTemplateManagerData | null) {
    const release = await this.mutex_.acquire();

    const operationTag: CdpProgressOperationTag = this.operationSet.addOperation(CdpEmailTemplateGalleryOperationType.LoadTemplates, "Loading templates..."
    );
    let succeeded: boolean = false;
    try {
      await this.resetFromTemplateManagerData_(data);
      succeeded = true;
    } finally {
      // Display the message only very briefly.
      const message: string = (succeeded ? "Loaded templates." : "Failed to load templates.");
      this.operationSet.updateOperationFinal(operationTag, succeeded, message, 100);
      this.didLoadAtLeastOnce = true;
      release();
    }
  }

  private async resetFromTemplateManagerData_(
    data: CdpEmailTemplateManagerData | null
  ) {
    //console.log('resetFromTemplateManagerData start', this.sectionTitle);

    this.galleryFileSet = new CdpEmailTemplateGalleryFileSet();

    const newGalleryFileSet: CdpEmailTemplateGalleryFileSet =
      new CdpEmailTemplateGalleryFileSet();

    if (data) {

      //console.log('Load data:', data.templateFileMap);
      //console.log(`${this.sectionTitle}: cat=${this.ownerCategory}`);

      const templateManagerFileSet: CdpEmailTemplateManagerFileSet | undefined =
        data.templateFileMap.get(this.ownerCategory);
      if (templateManagerFileSet) {
        /*
        console.log(
          `${this.sectionTitle}: cat=${this.ownerCategory} n=${templateManagerFileSet.files.length}`
        );
        */
       
        const n: number = templateManagerFileSet.files.length;

        //const templateFiles: CdpEmailTemplateFile[] = [];

        for (let i = 0; i < n; i++) {
          //console.log(`${i}: ${JSON.stringify(templateManagerFileSet.files[i].templateInfo)}`)

          const templateManagerFile: CdpEmailTemplateManagerFile =
            templateManagerFileSet.files[i];

          /*
        console.log(
          `${this.ownerCategory} templateManagerFile:${JSON.stringify(
            templateManagerFile
          )}`
        );
        */

          // Note: templateManagerFile.templateFile may be null at this point, because
          //       the actual file data has not been loaded yet.  We load the file and
          //       wait for it to complete.
          //console.log("Loading template for:", JSON.stringify(templateManagerFile));

          const templateFile: CdpEmailTemplateFile | null =
            await this.templateManager.loadTemplateFileFromTemplateManagerFile(
              templateManagerFile
            );
          templateManagerFile.templateFile = templateFile;

          if (templateFile) {
            templateManagerFile.templateInfo = templateFile.info;

            //templateFiles.push(templateFile);
          }

          //console.log(`${i}: ${JSON.stringify(templateManagerFile.templateInfo)}`)

          //console.log("Loaded template:", templateFile?.info.name);

          //console.log("Before add file. n=", this.galleryFileSet.files.length);
          newGalleryFileSet.addTemplateManagerFile(
            templateManagerFile,
            null,
            null,
            this.emptyFilterMatchesAll
          );
          //console.log("Added file. n=", this.galleryFileSet.files.length);
        }

        // And here are all the templates as JSON.
        //console.log(`Templates for cat=${this.ownerCategory}:`);
        //console.log(JSON.stringify(templateFiles));
      }
    }

    //console.log('resetFromTemplateManagerData end', this.sectionTitle);

    this.galleryFileSet = newGalleryFileSet;
  }

  async onTemplateManagerEvent(event: CdpEmailTemplateManagerEvent) {
    /*
    console.log(
      `${this.ownerCategory} Template gallery received event: ${JSON.stringify(
        event
      )}`
    );

    console.log(
      `${
        this.ownerCategory
      } Template gallery event didTemplatesChange=${event.didTemplatesChange()}`
    );
*/

    if (event.didTemplatesChange()) {
      // Many things may have changed, so reset from the data.
      // TODO Depending on the event type, there might be just one file to update.
      await this.resetFromTemplateManagerData(event.data);

      return;
    }

    this.didLoadAtLeastOnce = true;
  }
}
