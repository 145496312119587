import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

export class CdpUsaZipCode {
  zipCode: string = '';
}

@Component({
  selector: 'app-cdp-usa-zip-code',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cdp-usa-zip-code.component.html',
  styleUrl: './cdp-usa-zip-code.component.scss',
})
export class CdpUsaZipCodeComponent {
  @Input() zipCode: CdpUsaZipCode = new CdpUsaZipCode();
}
