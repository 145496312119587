import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdpUserProfile } from '../../cdp-user';
import { FormsModule } from '@angular/forms';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpSession } from '../../cdp-session';
import { CdpJwt } from '../../cdp-auth/auth.service';
import { CognitoIdToken } from 'amazon-cognito-identity-js';

@Component({
  selector: 'app-cdp-user-profile',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cdp-user-profile.component.html',
  styleUrl: './cdp-user-profile.component.scss'
})
export class CdpUserProfileComponent {
  @Input() profile: CdpUserProfile = new CdpUserProfile();
}
