import { Component, OnInit } from '@angular/core';
import { CdpCustomer, CdpCustomerInfo } from '../cdp-customer';
import { FormsModule } from '@angular/forms';
import {
  CdpCustomerDatabase,
  CdpCustomerDatabaseList,
  CdpCustomerDatabaseOperationType,
  CdpCustomerDatabaseRecord,
  CdpCustomerDatabaseUpdateMode,
  CdpCustomerDatabaseUpdateResult,
  CdpCustomerDatabaseUpdateResultSet,
  CdpCustomerDatabaseUpdateResultType,
} from '../cdp-customer-database';
import {
  CdpCustomerDatabaseService,
  CdpCustomerDatabaseServiceEvent,
} from '../cdp-customer-database.service';
import {
  CdpProgressOperationStatus,
} from 'src/app/ui/progress/cdp-progress';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpUser } from 'src/app/core/cdp-user';
import { CdpEmailAddress } from 'src/app/core/cdp-email-address';

@Component({
  selector: 'app-cdp-customer-database-list-picker',
  standalone: true,
  imports: [
    FormsModule,
  ],
  templateUrl: './cdp-customer-database-list-picker.component.html',
  styleUrl: './cdp-customer-database-list-picker.component.sass'
})
export class CdpCustomerDatabaseListPickerComponent implements OnInit {
  databaseUpdateMode: CdpCustomerDatabaseUpdateMode =
    CdpCustomerDatabaseUpdateMode.MergeIfNoConflict;

  private currentDatabase_: CdpCustomerDatabase | null = null;
  listNames: string[] = [];

  currentListName: string = '';
  numCustomersInCurrentList: number = 0;

  constructor(
    private databaseService: CdpCustomerDatabaseService,
    private sessionManager: CdpSessionManagerService
  ) {
    this.databaseService.eventEmitter.subscribe((event) =>
      this.processDatabaseServiceEvent_(event)
    );
  }

  getCurrentDatabase(): CdpCustomerDatabase | null {
    return this.currentDatabase_;
  }

  private async createTestList_(testListName: string) {
    if (!this.currentDatabase_) {
      return;
    }

    // The test list consists of just the user's own email.
    // First, we need to create a record in the database for the user if one
    // isn't already present.
    const user: CdpUser | null = await this.sessionManager.getCurrentUser();

    if (!user) {
      return;
    }

    const email: CdpEmailAddress = user.profile.email;
    if (!email.isValid()) {
      return;
    }

    let customer: CdpCustomer | null = this.currentDatabase_.getCustomerUsingEmail(email.email);

    if (!customer) {
      // We need to add the user to the database.
      const info: CdpCustomerInfo = new CdpCustomerInfo();
      info.email = email;
      info.name = user.profile.name;

      const result: CdpCustomerDatabaseUpdateResult =
        this.currentDatabase_.addOrUpdateCustomerInfo(info, CdpCustomerDatabaseUpdateMode.Replace);

      console.log("Added user for test list:", result);

      if (result.resultType == CdpCustomerDatabaseUpdateResultType.Rejected) {
        // This really shouldn't happen.
        return;
      }
    }

    // Now that the user is definitely in the database, we add the test list
    // comprising just the user.
    const shortId: number = this.currentDatabase_.getShortIdUsingEmail(email.email);
    if (shortId < 0) {
      // This shouldn't happen.
      return;
    }

    this.currentDatabase_.addNewListUsingShortIds(testListName, [shortId]);
  }

  private async maybeCreateTestList_() {
    if (this.currentDatabase_) {
      const databaseLists: CdpCustomerDatabaseList[] = this.currentDatabase_.databaseLists;

      // TODO At some point, the test list should probably be editable, but
      //      possibly not renamable.
      const testListName: string = 'Test list';
      let hasTestList: boolean = false;
      for (const databaseList of databaseLists) {
        if (databaseList.doesNameMatch(testListName)) {
          hasTestList = true;
          break;
        }
      }

      if (!hasTestList) {
        await this.createTestList_(testListName);
      }
    }
  }

  private async refreshDatabaseAndLists_() {
    this.currentDatabase_ = this.databaseService.getCurrentDatabase();

    await this.maybeCreateTestList_();

    let updatedListName: string = '';
    let updatedCustomerRecords: CdpCustomerDatabaseRecord[] = [];
    let updatedListNames: string[] = [];

    if (this.currentDatabase_) {
      updatedListNames = this.currentDatabase_.getListNames();

      const databaseList: CdpCustomerDatabaseList | null =
        this.currentDatabase_.getListUsingName(this.currentListName);

      /*
       console.log(
          `DB list: name=${this.currentListName}: ${JSON.stringify(databaseList)}`
        );
        */

      if (!databaseList) {
        // There is no longer a list with the current list name, so use the name of
        // the first list, if there are any lists.
        if (updatedListNames.length > 0) {
          updatedListName = updatedListNames[0];
          //console.log('Setting list name to:', updatedListName);
        }
      } else {
        // There is still a list with the current list name.
        //console.log('Keeping list name:', this.currentListName);

        updatedListName = this.currentListName;
      }

      const customerRecordsInList: CdpCustomerDatabaseRecord[] | null =
        this.currentDatabase_.getCustomerRecordsInList(updatedListName);
      if (customerRecordsInList) {
        updatedCustomerRecords = customerRecordsInList;
      }
    }

    //console.log('After DB refresh:');
    //console.log('  List names:', updatedListNames);
    //console.log('  Customers:', updatedCustomerRecords);
    //console.log('  Current list:', updatedListName);

    this.listNames = updatedListNames;
    this.numCustomersInCurrentList = updatedCustomerRecords.length;
    this.currentListName = updatedListName;
  }

  async ngOnInit() {
    await this.refreshDatabaseAndLists_();
  }
  
  private async processDatabaseServiceEvent_(event: CdpCustomerDatabaseServiceEvent) {
    const operationType: CdpCustomerDatabaseOperationType = event.operationType;
    const operationStatus: CdpProgressOperationStatus = event.operationStatus;

    /*
    console.log(
      'processDatabaseServiceEvent_ Received event:',
      JSON.stringify(event)
    );
    */

    // An operation that is starting gets added to the set of operations in progress,
    // primarily so that we can prevent the user from starting any other operations.
    switch (operationStatus) {
      case CdpProgressOperationStatus.NotStarted:
        // This would be strange, but just ignore it.
        break;

      case CdpProgressOperationStatus.InProgress:
        break;

      case CdpProgressOperationStatus.CompletedFailed:
      case CdpProgressOperationStatus.CompletedSucceeded:
        // If the operation type is one that may have changed anything, refresh the
        // database, and then remove the indicator that the operation is in progress.
        // The only operations that don't change anything are saving operations.

        if (
          operationType != CdpCustomerDatabaseOperationType.None &&
          operationType != CdpCustomerDatabaseOperationType.SaveDatabaseAndLists
        ) {
          await this.refreshDatabaseAndLists_();
        }

        break;

      case CdpProgressOperationStatus.StartAndComplete:
        // We don't need to remove the operation, because it never would have been
        // added.  We do need to refresh the data.
        await this.refreshDatabaseAndLists_();
        break;

      default:
        console.log('Unknown event: ', event);
        throw Error(`Unknown event: ${event}`);
    }
  }

  selectListUsingName(name: string) {
    if (name != this.currentListName) {
      this.currentListName = name;
      this.onCurrentListNameChanged();
    }
  }

  async onCurrentListNameChanged() {
    await this.refreshDatabaseAndLists_();
  }

  get hasCurrentCustomerList(): boolean {
    return this.currentListName.length > 0;
  }
}
