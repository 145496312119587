export class CdpEmailTemplateId {
  id: string = '';
  version: string = '1.0';
  isDraft: boolean = false;

  isEmpty(): boolean {
    return this.id.length == 0;
  }

  // The following function is static to allow use with dictionary-like
  // ID objects.
  static idWithVersion(id: CdpEmailTemplateId): string {
    return `${id.id}_v${id.version}`;
  }
}

export class CdpEmailTemplateMetadataField {
  name: string = '';
  values: string[] = [];
}

export enum CdpEmailTemplateOwnerCategory {
  Builtin = 'Builtin',
  User = 'User',
  Business = 'Business',
}

export class CdpEmailTemplateInfo {
  id: CdpEmailTemplateId = new CdpEmailTemplateId();
  name: string = '';
  metadata: any = {};
  baseId: CdpEmailTemplateId = new CdpEmailTemplateId();
  doExclude: boolean = false;

  clear() {
    this.id = new CdpEmailTemplateId();
    this.name = '';
    this.metadata = {};
    this.baseId = new CdpEmailTemplateId();
    this.doExclude = false;
  }

  loadFromText(text: string) {
    this.clear();

    const infoDict: any = JSON.parse(text);

    Object.assign(this, infoDict);

    //console.log("Loading from text:", text);
    // console.log("Metadata exclude:", this.metadata["exclude"]);
    if ('exclude' in this.metadata) {
      // Most of the metadata consists of string arrays.  "exclude" is just a
      // single "true" or "false" value.
      this.doExclude = this.metadata['exclude'] == 'true';

      delete this.metadata.exclude;
    }
  }
}

export class CdpEmailTemplateMetadataTag {
  name: string = '';
  count: number = 0;
}

export class CdpEmailTemplateInfoSet {
  infos: CdpEmailTemplateInfo[] = [];
  metadataKeys: Map<string, CdpEmailTemplateMetadataTag[]> = new Map<
    string,
    CdpEmailTemplateMetadataTag[]
  >();

  addInfo(info: CdpEmailTemplateInfo) {
    this.infos.push(info);
    this.accumMetadata_(info.metadata);
  }

  private accumMetadata_(metadata: any) {
    if (metadata) {
      for (const entry of Object.entries(metadata)) {
        const key: string = entry[0];
        const values: string[] = entry[1] as string[];

        if (!this.metadataKeys.has(key)) {
          this.metadataKeys.set(key, []);
        }

        //console.log(`Accum entry: key=${key} values=${values}`);
        //console.log("Metadata keys:", this.metadataKeys);

        if (values && values.length > 0) {
          const allTags: CdpEmailTemplateMetadataTag[] | undefined =
            this.metadataKeys.get(key);
          //console.log("All tags=", allTags);

          if (allTags != undefined) {
            for (const value of values) {
              let foundTag: boolean = false;
              for (const tag of allTags) {
                //console.log(`Compare value:${value} tagName:${tag.name}`);

                // TODO Should this be case-sensitive
                if (value.toLowerCase() == tag.name.toLowerCase()) {
                  foundTag = true;
                  tag.count++;
                }
              }

              if (!foundTag) {
                const tag: CdpEmailTemplateMetadataTag =
                  new CdpEmailTemplateMetadataTag();
                tag.name = value;
                tag.count = 1;

                allTags.push(tag);
              }
            }
          }
        }
      }
    }

    //console.log('After accum:', this.metadataKeys);
  }
}

export class CdpEmailTemplateInfoFilter {
  filter: any = {};

  static doesIncludeAny(filterValues: string[], values: string[]) {
    if (filterValues.length == 0) {
      return true;
    }

    for (const filterValue in filterValues) {
      for (const value in values) {
        if (filterValue.toLowerCase() == value.toLowerCase()) {
          return true;
        }
      }
    }

    return false;
  }

  private flatten_(data: any) {
    const allValues: string[] = [];

    for (const entry of Object.entries(data)) {
      const filterKey: string = entry[0];
      const filterValues: string[] = entry[1] as string[];

      for (const filterValue of filterValues) {
        const combined: string = `${filterKey}:${filterValue}`;
        allValues.push(combined);
      }
    }

    return allValues;
  }

  doesMatchMatadata(metadata: any, emptyFilterMatchesAll: boolean): boolean {
    //console.log('Doing filter match:');
    //console.log('  Filter:', this.filter);
    //console.log('  Metadata:', metadata);

    if (this.filter.size == 0) {
      return emptyFilterMatchesAll;
    }

    // The key field is a category, and the values in the array of the entry are
    // individual tags. The filter is a match if there is any match to any entry in
    // any category.

    //console.log("filter:", JSON.stringify(this.filter));
    const allFilterValues: string[] = this.flatten_(this.filter);

    //console.log("All allFilterValues:", JSON.stringify(allFilterValues));

    if (allFilterValues.length == 0) {
      return emptyFilterMatchesAll;
    }

    let filterHasAnyValues: boolean = false;

    for (const entry of Object.entries(this.filter)) {
      const filterKey: string = entry[0];
      const filterValues: string[] = entry[1] as string[];

      if (filterValues.length == 0) {
        // There are no filter values in this category, so we don't
        // exclude anything based on this category.
      } else {
        filterHasAnyValues = true;

        let foundMatchWithinCategory: boolean = false;

        let metadataValuesInCategoryAny: any = metadata[filterKey];
        if (metadataValuesInCategoryAny) {
          const metadataValuesInCategory: string[] =
            metadataValuesInCategoryAny as string[];

          for (const filterValue of filterValues) {
            for (const metadataValue of metadataValuesInCategory) {
              if (filterValue.toLowerCase() == metadataValue.toLowerCase()) {
                //console.log(`Match category:${filterKey} value=${filterValue}`);
                foundMatchWithinCategory = true;
                break;
              }
            }

            if (foundMatchWithinCategory) {
              break;
            }
          }
        }

        if (!foundMatchWithinCategory) {
          // We didn't find a match within a non-empty category, so overall this
          // is not a match.
          return false;
        }
      }
    }

    if (!filterHasAnyValues) {
      return emptyFilterMatchesAll;
    } else {
      return true;
    }
  }

  doesMatchTemplateInfo(
    info: CdpEmailTemplateInfo,
    emptyFilterMatchesAll: boolean
  ): boolean {
    return this.doesMatchMatadata(info.metadata, emptyFilterMatchesAll);
  }
}
