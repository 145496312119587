import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { CdpDropzoneComponent } from 'src/app/ui/cdp-drop-zone/cdp-drop-zone.component';
import { CdpImageTileComponent } from '../cdp-image-tile/cdp-image-tile.component';
import {
  CdpFile,
  CdpFileCategory,
  CdpFileInfo,
  CdpFileLocationType,
} from 'src/app/core/files/cdp-file';
import {
  CdpImageGallerySectionComponent,
  CdpImageGallerySectionInfo,
} from '../cdp-image-gallery-section/cdp-image-gallery-section.component';
import { CdpImage } from 'src/app/core/images/cdp-image';
import { CdpResourceService } from 'src/app/resource/cdp-resource.service';
import { CdpProgressSpinnerComponent } from 'src/app/ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';
import { CdpProgressOperationSet } from 'src/app/ui/progress/cdp-progress';

export class CdpImageGalleryFileInfo {
  sectionName: string = '';
  fileInfo: CdpFileInfo = new CdpFileInfo();

  url(): string {
    return this.fileInfo.url;
  }

  filename(): string {
    return this.fileInfo.filename;
  }
}

@Component({
  selector: 'app-cdp-image-gallery-user',
  standalone: true,
  imports: [
    CdpDropzoneComponent,
    CdpImageTileComponent,
    CdpImageGallerySectionComponent,
    CdpProgressSpinnerComponent
  ],
  templateUrl: './cdp-image-gallery-user.component.html',
  styleUrl: './cdp-image-gallery-user.component.sass',
})
export class CdpImageGalleryUserComponent implements AfterViewInit, OnInit {
  sectionInfos: CdpImageGallerySectionInfo[] = [];
  sectionMap: Map<string, CdpImageGalleryFileInfo[]> = new Map<
    string,
    CdpImageGalleryFileInfo[]
  >();

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();

  @ViewChildren(CdpImageGallerySectionComponent)
  viewChildrenSections: QueryList<CdpImageGallerySectionComponent> | undefined;

  constructor(private resourceService: CdpResourceService) {}

  async ngOnInit(): Promise<void> {
    /*
    this.sectionInfos.push(new CdpImageGallerySectionInfo('Logo', false));
    this.sectionInfos.push(new CdpImageGallerySectionInfo('Products'));
    this.sectionInfos.push(new CdpImageGallerySectionInfo('Other'));
    */
  }

  async ngAfterViewInit(): Promise<void> {
    // Subscribe to the set of sections so that we get notified when a new
    // section is created, e.g., by loading images.
    this.viewChildrenSections?.changes.subscribe((value) => {
      this.sectionsChanged_(value);
    });

    const onResourceChanged = this.onResourcesChanged_.bind(this);

    this.resourceService.eventEmitter.subscribe((categoryLocationMap) => {
      onResourceChanged();
    })

    this.onResourcesChanged_();
  }

  async onResourcesChanged_() {
    this.sectionInfos.length = 0;

    // Get a list of the user's saved images.
    const files: CdpFile[] =
      await this.resourceService.getFilesInCategory(CdpFileCategory.Image);

    // Load the user's saved images.  This will indirectly create sections
    // for the images.
    this.fileListComplete_(files);
  }

  sectionsChanged_(event: any): void {
    //console.log('Sections changed event: ', event);
  }

  getSectionImages(sectionName: string): CdpImage[] {
    const fileInfos: CdpImageGalleryFileInfo[] | undefined =
      this.sectionMap.get(sectionName);
    if (!fileInfos) {
      return [];
    } else {
      const images: CdpImage[] = [];

      for (const fileInfo of fileInfos) {
        const image: CdpImage = new CdpImage();

        image.url = fileInfo.url();

        images.push(image);
      }

      //console.log(`getSectionImages: section:${sectionName} images:${images}`);

      return images;
    }
  }

  addSectionInfo(sectionInfo: CdpImageGallerySectionInfo): void {
    this.sectionInfos.push(sectionInfo);
  }

  private createSectionsAndLoadImages_(
    sections: Map<string, CdpImageGalleryFileInfo[]>
  ): void {
    // There are 3 sections that should always exist: Logo, Products, and Other,
    // in that order.  And the "Logo" section should always be created to allow
    // only a single image, at least for now.

    const sectionInfos: CdpImageGallerySectionInfo[] = [
      new CdpImageGallerySectionInfo('Logo', false),
      new CdpImageGallerySectionInfo('Products'),
      new CdpImageGallerySectionInfo('Other'),
    ];

    for (const entry of sections.entries()) {
      const sectionName: string = entry[0];
      const sectionFileInfos: CdpImageGalleryFileInfo[] = entry[1];

      // If we haven't already created a section with this name, do so now.
      let sectionExists: boolean = false;
      for (const sectionInfo of sectionInfos) {
        if (sectionInfo.name.toLowerCase() == sectionName.toLowerCase()) {
          sectionExists = true;
          sectionInfo.fileInfos =
            sectionInfo.fileInfos.concat(sectionFileInfos);

          break;
        }
      }

      if (!sectionExists) {
        const sectionInfo: CdpImageGallerySectionInfo =
          new CdpImageGallerySectionInfo(sectionName);
        sectionInfo.fileInfos = sectionFileInfos;

        sectionInfos.push(sectionInfo);
      }
    }

    // Now add the section infos to the gallery, which will also cause any images
    // to get loaded.
    for (const sectionInfo of sectionInfos) {
      this.addSectionInfo(sectionInfo);
    }
  }

  private loadImages_(infos: CdpImageGalleryFileInfo[]) {
    const sections: Map<string, CdpImageGalleryFileInfo[]> = new Map<
      string,
      CdpImageGalleryFileInfo[]
    >();

    for (const info of infos) {
      const sectionName: string = info.sectionName;
      let sectionInfos: CdpImageGalleryFileInfo[] | undefined =
        sections.get(sectionName);

      if (!sectionInfos) {
        // Add a new section.
        sectionInfos = [];
        sections.set(sectionName, sectionInfos);
      }

      sectionInfos.push(info);
    }

    //console.log('Sections after load:', sections);
    this.createSectionsAndLoadImages_(sections);
  }

  private fileListComplete_(files: CdpFile[]) {
    const infos: CdpImageGalleryFileInfo[] = [];

    for (const file of files) {
      const fileInfo: CdpFileInfo = file.fileInfo;

      if (
        fileInfo.locationType == CdpFileLocationType.BusinessPublic &&
        fileInfo.category == CdpFileCategory.Image &&
        !fileInfo.isMetadata &&
        !fileInfo.isDeleted
      ) {
        const fullImageName: string = fileInfo.filename;
        // The section name is the first component of the image name.
        // TODO AWS imposes limits on object key names, so we might need to
        //      store a special file indicating a mapping from the
        //      object key name prefix to the original section name.
        let sectionName: string = '';
        let imageName: string = '';

        const delimIndex = fullImageName.indexOf('/');
        if (delimIndex >= 0) {
          sectionName = fullImageName.substring(0, delimIndex);
          imageName = fullImageName.substring(delimIndex + 1);
        } else {
          sectionName = 'Other';
          imageName = fullImageName;
        }

        const info: CdpImageGalleryFileInfo = new CdpImageGalleryFileInfo();
        info.sectionName = sectionName;
        info.fileInfo = fileInfo;

        infos.push(info);
      }
    }

    //console.log("User gallery files list complete infos:", infos);
    
    this.loadImages_(infos);
  }
}
