export class CdpFileUtilties {

  static getFilenameExtension(path: string): string {
    if (!path || (path.length == 0)) {
      return '';
    }

    let fragments: string[] = path.split(/[\\/]/);

    if (fragments.length == 0) {
      return '';
    }

    // Extract filename from full path.
    let filename: string | undefined = fragments.pop();

    if (filename && filename.length > 0) {
      // Get the last period.
      let pos = filename.lastIndexOf('.');

      // If the dot is the first character, there is no
      // extension.
      if (pos < 1) return '';

      // Extract the extension, skipping the "."
      return filename.slice(pos + 1);
    } else {
      return '';
    }
  }

  static isExtensionImageFile(ext: string): boolean {
    // TODO Make this configurable
    const imageFileExtensions: string[] = [
        'ico',
        'jpg',
        'jpeg',
        'png',
        'bmp'
    ];

    const extLower: string = ext.toLowerCase();
    const index = imageFileExtensions.findIndex(item => item == extLower);

    return (index >= 0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  static formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
