import { Component } from '@angular/core';
import { CdpProfileCombinedComponent } from '../profile/cdp-profile-combined/cdp-profile-combined.component';

@Component({
  selector: 'app-cdp-initial-registration',
  standalone: true,
  imports: [CdpProfileCombinedComponent],
  templateUrl: './cdp-initial-registration.component.html',
  styleUrl: './cdp-initial-registration.component.sass'
})
export class CdpInitialRegistrationComponent {

}
