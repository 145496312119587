import { CdpBusiness } from './business/cdp-business';
import { CdpJwt } from './cdp-auth/auth.service';
import { CdpUser } from './cdp-user';

export enum CdpSessionStatus {
  NONE = 'None',
  SIGNED_IN = 'Signed in',
  SIGNED_OUT = 'Signed out',
  EXPIRED = 'Expired',
}

export class CdpSessionInfo {
  sessionStatus: CdpSessionStatus = CdpSessionStatus.NONE;
  sessionId: string = '';
  userId: string = '';
  businessId: string = '';  
  sessionStartTimestampUtc: string = '';
  cognitoSub: string = '';
  cognitoIss: string = '';
  sat: string = '';


  public reset() {
    this.sessionStatus = CdpSessionStatus.NONE;
    this.sessionId = '';
    this.userId = '';
    this.businessId = '';
    this.sessionStartTimestampUtc = '';
    this.cognitoSub = '';
    this.cognitoIss = '';
    this.sat = '';
  }

  public isValid(): boolean {
    return (this.sessionStatus != CdpSessionStatus.NONE) && (this.sessionId.length > 0);
  }
}

export class CdpSession {
  sessionInfo: CdpSessionInfo = new CdpSessionInfo();
  user: CdpUser | null = null;
  business: CdpBusiness | null = null;

  isValid(): boolean {
    return this.sessionInfo.isValid();
  }

  setFromDict(dict: any) {
    this.sessionInfo.reset();
    this.user = null;
    this.business = null;

    // TODO TODO
  }
}
