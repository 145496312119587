/// <reference types="@angular/localize" />

import { enableProdMode, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';


import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { testFileParser } from './app/core/files/test/test-cdp-file-parse';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const giantTestingHack: boolean = false;

if (giantTestingHack) {
testFileParser();
} else {


if (isDevMode()) {
  console.log("Starting main");
  console.log('About to configure Amplify');
}

Amplify.configure(awsExports);

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));


}