import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Mutex } from 'async-mutex';

import {
  CdpBackendFileService,
  CdpFileOperationRequest,
  CdpFileOperationResult,
  CdpFileOperationResultSet,
  CdpFileOperationType,
} from '../backend/files/cdp-backend-file.service';

import {
  CdpFile,
  CdpFileCategory,
  CdpFileInfo,
  CdpFileLocationType,
  CdpFileObjectSet,
  allCategories,
  allLocationTypes,
} from '../core/files/cdp-file';
import {
  CdpSessionManagerEventType,
  CdpSessionManagerService,
} from '../cdp-session-manager.service';
import { CdpEvent } from '../core/cdp-event';

export type CdpFileLocationMap = Map<CdpFileLocationType, CdpFile[]>;
export type CdpFileCategoryLocationMap = Map<
  CdpFileCategory,
  CdpFileLocationMap
>;

@Injectable({
  providedIn: 'root',
})
export class CdpResourceService {
  private categoryLocationMap_: CdpFileCategoryLocationMap = new Map<
    CdpFileCategory,
    CdpFileLocationMap
  >();
  private mutex_: Mutex = new Mutex();

  public eventEmitter: EventEmitter<CdpFileCategoryLocationMap> =
    new EventEmitter<CdpFileCategoryLocationMap>();

  constructor(
    private fileService: CdpBackendFileService,
    private sessionManager: CdpSessionManagerService
  ) {
    this.refreshResources();

    this.sessionManager.eventEmitter.subscribe((event) =>
      this.processSessionManagerEvent_(event)
    );
    this.fileService.eventEmitter.subscribe((resultSet) =>
      this.processFileServiceResultSet_(resultSet)
    );
  }

  private processSessionManagerEvent_(event: CdpEvent) {
    const eventType = event.getCategoryEventType();

    /*
    console.log(
      'Resource service processing session manager event:',
      eventType
    );
*/

    if (
      eventType == CdpSessionManagerEventType.SignIn ||
      eventType == CdpSessionManagerEventType.SessionStarted ||
      // TODO SessionChanged also seems to be needed for now
      eventType == CdpSessionManagerEventType.SessionChanged
    ) {
      this.refreshResources();
    } else if (eventType == CdpSessionManagerEventType.SignOut) {
      this.clearBusinessAndUserResources();
    }
  }

  private async maybeUpdateCategoryLocationMapFromResults_(successfulResults: CdpFileOperationResult[]) {
    // Note: The caller is required to have already locked the mutex.

    // All of the input results are guaranteed to be successful and are not
    // just a request to list files.

    if (successfulResults.length == 0) {
      // We don't need to do anything.
      return;
    }

    // TODO TODO We should use a more limited operation here!
    // Also, we have to avoid infinite recursions! So, for now,
    // ignore download results, even though they could indicate
    // content changes.
    let doRefresh: boolean = false;
    for (const result of successfulResults) {
      const request: CdpFileOperationRequest = result.request;
      const operationType: CdpFileOperationType = request.operationType;
      if ((operationType != CdpFileOperationType.ListFiles) && (operationType != CdpFileOperationType.Download)) {
        doRefresh = true;
        break;
      }
    }

    if (doRefresh) {
      this.refreshResources();
    }
  }

  private async maybeUpdateCategoryLocationMapFromResultSet_(
    resultSet: CdpFileOperationResultSet
  ) {
    // Note: The caller is required to have already locked the mutex.

    // Check for successful operations only.
    const successfulResults: CdpFileOperationResult[] = [];
    for (const result of resultSet.results) {
      if (result.succeeded) {
        const request: CdpFileOperationRequest = result.request;
        const operationType: CdpFileOperationType = request.operationType;
        // We can ignore requests to list the files.  If we issued the request,
        // we've already processed it.
        if (operationType != CdpFileOperationType.ListFiles) {
          successfulResults.push(result);
        }
      }
    }

    if (successfulResults.length == 0) {
      // We don't need to do anything.
      return;
    }

    await this.maybeUpdateCategoryLocationMapFromResults_(successfulResults);
  }

  private async processFileServiceResultSet_(
    resultSet: CdpFileOperationResultSet
  ) {
    // TODO TODO Update the data based on the changes in the result set.
    //console.log('Resource service processFileServiceResultSet_:', resultSet);
    const release = await this.mutex_.acquire();
    try {
      await this.maybeUpdateCategoryLocationMapFromResultSet_(resultSet);
    } finally {
      release();
    }
  }

  public refreshResources() {
    //console.log('Refreshing resources');

    this.startListFiles_();
  }

  public async clearBusinessAndUserResources() {
    //console.log('Clearing resources');

    const release = await this.mutex_.acquire();
    try {
      this.clearBusinessAndUserResources_();
    } finally {
      release();
    }
  }

  public async getCategoryLocationMap(): Promise<CdpFileCategoryLocationMap> {
    const release = await this.mutex_.acquire();
    try {
      return this.categoryLocationMap_;
    } finally {
      release();
    }
  }

  public async getFilesInCategory(
    category: CdpFileCategory
  ): Promise<CdpFile[]> {
    let files: CdpFile[] = [];

    //console.log("Resource manager get files in category:", category);

    const release = await this.mutex_.acquire();
    try {
      const fileLocationMap: CdpFileLocationMap | undefined =
        this.categoryLocationMap_.get(category);

      //console.log("Resource file location map: ", fileLocationMap);

      if (fileLocationMap) {
        for (const values of fileLocationMap.values()) {
          files = files.concat(values);
        }
      }
    } finally {
      release();
    }

    return files;
  }

  private clearBusinessAndUserResources_() {
    // This function requires that the mutex already be locked.
    const emptyCategoryLocationMap: Map<CdpFileCategory, CdpFileLocationMap> =
      this.createCategoryLocationMap_([]);
    this.setCategoryLocationMap_(emptyCategoryLocationMap);
  }

  private setCategoryLocationMap_(
    categoryLocationMap: Map<CdpFileCategory, CdpFileLocationMap>
  ) {
    /*
    console.log(
      'Resources changed. numResources=',
      this.categoryLocationMap_.size
    );
    */

    this.categoryLocationMap_ = categoryLocationMap;
    this.eventEmitter.emit(this.categoryLocationMap_);
  }

  startListFiles_() {
    //console.log('Start list files');

    const observable: Observable<CdpFileOperationResultSet> =
      this.fileService.listFiles();

    // Note: There is no need to pipe through catchError() here, since the file service
    // observable already does that.
    observable.subscribe((resultSet: CdpFileOperationResultSet) =>
      this.fileListComplete_(resultSet)
    );
  }

  private fileListCompleteWithObjectSets_(objectSets: CdpFileObjectSet[]) {
    //console.log('Object sets:', objectSets);

    let fileInfos: CdpFileInfo[] = [];

    // We determine the file category by the presence of particular subdirectories
    // being present.
    //
    // Note: Any files not in one of the specified directories will be completely
    //       ignored.  It is currently intended that there should never be any
    //       files outside of those directories.
    //
    // Note: Metadata files may occur within any of the specified directories, and they are
    //       identified by their suffix.
    const categoryDirs: string[] = ['/img/', '/emt/', '/oth/'];
    const categories: CdpFileCategory[] = [
      CdpFileCategory.Image,
      CdpFileCategory.EmailTemplate,
      CdpFileCategory.Other,
    ];
    const numCategories: number = categories.length;

    for (const objectSet of objectSets) {
      const keyNames: string[] = objectSet.object_key_names;
      const locationType: CdpFileLocationType = objectSet.location_type;

      keyNames.forEach((keyName) => {
        let category: CdpFileCategory | null = null;
        let filename: string = '';

        for (let i = 0; i < numCategories; i++) {
          const curCategory: CdpFileCategory = categories[i];
          const curCategoryDir: string = categoryDirs[i];

          const pos: number = keyName.indexOf(curCategoryDir);
          if (pos >= 0) {
            category = curCategory;

            filename = keyName.substring(pos + curCategoryDir.length);

            break;
          }
        }

        if (category) {
          const fileInfo: CdpFileInfo = new CdpFileInfo();

          // Check for the suffix that indicates that the file has been deleted
          // and should thus be in the recycle bin.
          const index = filename.lastIndexOf('._del');
          if (index >= 0) {
            fileInfo.isDeleted = true;

            // There may be a deletion version after ".del".
            // E.g., "myfile._del.2".
            const splitFilename: string[] = filename.split('._del');

            filename = filename.substring(0, index);

            if (splitFilename.length > 1) {
              fileInfo.deletedVersion = splitFilename[1];
            }
          }

          fileInfo.locationType = locationType;
          fileInfo.category = category;

          fileInfo.filename = filename;
          fileInfo.isMetadata = CdpFileInfo.isFilenameMetadata(filename);

          fileInfo.metadata = null;
          fileInfo.metadataFileInfo = null;

          if (
            objectSet.root_url.length > 0 &&
            !fileInfo.isMetadata &&
            !fileInfo.isDeleted
          ) {
            // The files in this object set are public and thus have
            // URLs.

            // Note: <objectSet> has the expected data fields, but not the full
            //       prototype of CdpFileObjectSet.  Thus, we call a static
            //       helper function rather than a member function to make the
            //       URL.
            fileInfo.url = CdpFileObjectSet.makeObjectKeyNameUrl(
              keyName,
              locationType,
              objectSet.root_url
            );
          }

          fileInfos.push(fileInfo);
        }
      });
    }

    // We now have all of the file infos. We organize them by location
    // type and category, and we associate files with their metadata
    // file, if any.
    this.organizeFileInfos_(fileInfos);
  }

  private fileListComplete_(resultSet: CdpFileOperationResultSet) {
    if (resultSet.errors && resultSet.errors.length > 0) {
      console.log('Error listing files:', resultSet.errors);
    } else {
      // The result set should contain one result, which should contain the object sets.
      for (const result of resultSet.results) {
        if (
          result.request.operationType == CdpFileOperationType.ListFiles &&
          result.succeeded
        ) {
          this.fileListCompleteWithObjectSets_(result.objectSets);
          return;
        }
        console.log('Error listing files: no matching result');
      }
    }
  }

  private createCategoryLocationMap_(
    files: CdpFile[]
  ): Map<CdpFileCategory, CdpFileLocationMap> {
    const categoryLocationMap: Map<CdpFileCategory, CdpFileLocationMap> =
      new Map<CdpFileCategory, CdpFileLocationMap>();
    for (const category of allCategories) {
      const fileLocationMap: Map<CdpFileLocationType, CdpFile[]> = new Map<
        CdpFileLocationType,
        CdpFile[]
      >();
      categoryLocationMap.set(category, fileLocationMap);

      for (const locationType of allLocationTypes) {
        fileLocationMap.set(locationType, []);
      }
    }

    // Go through all the file infos and put them in the appropriate list.
    for (const file of files) {
      const fileInfo: CdpFileInfo = file.fileInfo;
      const category = fileInfo.category;
      const locationType = fileInfo.locationType;

      const curFiles: CdpFile[] | undefined = categoryLocationMap
        .get(category)
        ?.get(locationType);

      if (curFiles) {
        // will always be defined
        curFiles.push(file);
      }
    }

    return categoryLocationMap;
  }

  private associateMetadata_(files: CdpFile[]) {
    for (const file of files) {
      const fileInfo: CdpFileInfo = file.fileInfo;
      if (!fileInfo.isMetadata) {
        const filename: string = fileInfo.filename;

        for (const otherFile of files) {
          const otherFileInfo: CdpFileInfo = otherFile.fileInfo;
          if (otherFileInfo.isMetadata) {
            // Determine if the filenames match.
            if (
              filename + CdpFileInfo.metadataFileSuffix ==
              otherFileInfo.filename
            ) {
              // We've found the matching metadata file, if the deletion status
              // matches. If there are multiple deleted versions in the recycle
              // bin, we need to match deleted versions.
              if (fileInfo.deletedVersion == otherFileInfo.deletedVersion) {
                // This is the full match.
                fileInfo.metadataFileInfo = otherFileInfo;

                break;
              }
            }
          }
        }
      }
    }
  }

  private associateAllMetadata_(
    categoryLocationMap: Map<CdpFileCategory, CdpFileLocationMap>
  ) {
    for (const category of allCategories) {
      const fileLocationMap: CdpFileLocationMap | undefined =
        categoryLocationMap.get(category);

      if (fileLocationMap) {
        for (const locationType of allLocationTypes) {
          const curFiles: CdpFile[] | undefined =
            fileLocationMap.get(locationType);

          if (curFiles) {
            this.associateMetadata_(curFiles);
          }
        }
      }
    }
  }

  private organizeFileInfos_(fileInfos: CdpFileInfo[]) {
    const files: CdpFile[] = [];

    for (const fileInfo of fileInfos) {
      const file: CdpFile = new CdpFile();
      file.fileInfo = fileInfo;

      files.push(file);
    }

    this.organizeFiles_(files);
  }

  private organizeFiles_(files: CdpFile[]) {
    // Files are organized by category and then by location type.

    // For simplicity of use, we will always create a file info map for each category and
    // location type combination, even if there are no files in that combination.

    // Create the initial empty maps for all combinations of category and location type.
    const categoryLocationMap: Map<CdpFileCategory, CdpFileLocationMap> =
      this.createCategoryLocationMap_(files);

    // For metadata files, find the corresponding file and create the
    // association.
    this.associateAllMetadata_(categoryLocationMap);

    this.setCategoryLocationMap_(categoryLocationMap);

    //console.log('Organized file infos:', categoryLocationMap);
  }
}
