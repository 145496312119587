import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { SquareService } from '../square.service';

@Component({
  selector: 'app-square-connect-start',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './square-connect-start.component.html',
  styleUrl: './square-connect-start.component.sass'
})
export class SquareConnectStartComponent implements OnInit, AfterViewInit {
  @ViewChild('connecting_placeholder') connecting_placeholder!: ElementRef<HTMLInputElement>;
  @Input() stillLoading: boolean = true;

  constructor(private router: Router, private squareService: SquareService) {}

  async ngOnInit() {
    const url: string = await this.squareService.squareSessionSetupStart();
    console.log("URL: ", url);

    // Hide the "Loading" spinner.
    this.stillLoading = false;

    // Navigate to the URL.
    // TODO See https://adrianfaciu.dev/posts/angular-router-external-links/
    // TODO Handle errors.
    window.location.href = url;
  }

  ngAfterViewInit() {}
}
