import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CdpLoggerService {

  constructor() {
    window.InboxEase = window.InboxEase || {};
    console = console || this;
   }

  log(message?: any, ...optionalParams: any[]) {
    console.log(message, optionalParams);
  }

  /*
  log(...data: any[]) {
    console.log("Hello?");
    console.log(data);
  }
  */
}
