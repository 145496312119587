<div class="d-flex justify-content-center m-2">
  <h3 class="text-color-primary">{{ sectionTitle }}</h3>
</div>

<div class="vertical-break"></div>
<div class="container">
  <div class="row justify-content-center">
    @if (allowVariants) {
    <div class="col col-auto">
      <div class="btn-group" role="group" aria-label="Select template variant">
        <input
          type="radio"
          class="btn-check col-2"
          name="btnTemplateVariant"
          autocomplete="off"
          id="btnVariantOriginal"
          checked
          (click)="onClickOriginal()"
        />
        <label class="btn btn-outline-primary" for="btnVariantOriginal"
          >Original</label
        >

        <input
          type="radio"
          class="btn-check col-2"
          name="btnTemplateVariant"
          id="btnVariantRebranded"
          autocomplete="off"
          (click)="onClickRebranded()"
        />
        <label class="btn btn-outline-primary" for="btnVariantRebranded"
          >Rebranded</label
        >
      </div>
    </div>
    }

    <div class="col col-auto">
      <div
        class="btn-group"
        role="group"
        aria-label="Select mobile or desktop template view"
      >
        <input
          type="radio"
          class="btn-check col-2"
          name="btnradio"
          id="btnMobile"
          autocomplete="off"
          checked
          (click)="onClickMobile()"
          checked
        />
        <label class="btn btn-outline-primary" for="btnMobile">Mobile</label>

        <input
          type="radio"
          class="btn-check col-2"
          name="btnradio"
          id="btnDesktop"
          autocomplete="off"
          (click)="onClickDesktop()"
        />
        <label class="btn btn-outline-primary" for="btnDesktop">Desktop</label>
      </div>
    </div>
  </div>
</div>
<div class="vertical-break"></div>

<app-cdp-email-template-filter
  #template_filter
  [categoryFilters]="categoryFilters"
  [visible]="showFilters"
  [initialSelectedIndustry]="initialSelectedIndustry"
  (filterChanged)="onFilterChanged($event)"
></app-cdp-email-template-filter>
<div class="vertical-break"></div>

<app-cdp-progress-spinner
  [operationSet]="this.operationSet"
></app-cdp-progress-spinner>
<div class="vertical-break"></div>
@if (!isOperationInProgress) { @if (showFilters) {
<h5>
  Matching templates: {{ filterNumMatch }} of
  {{ galleryFileSet.files.length }}
</h5>
} @else {
<h5>Number of templates: {{ galleryFileSet.files.length }}</h5>
}

<div class="vertical-break"></div>
<div
  #show_no_templates
  [hidden]="didLoadAtLeastOnce && !showNoTemplatesMessage"
>
  <h5>
    You haven't created any templates yet. Go to the Template Gallery, click the
    edit (pencil) icon on a template, and then click "Save" in the editor.
  </h5>
</div>
<div>
  @for (galleryFile of galleryFileSet.files; track $index) { @if
  (galleryFile.isVisible) {
  <app-cdp-email-template-tile
    [templateManagerFile]="galleryFile.templateManagerFile"
    [viewParams]="getViewParams()"
    [visible]="galleryFile.isVisible"
    [showTemplateId]="showTemplateIds"
    [showTemplateName]="showTemplateNames"
    (beingDeletedEvent)="galleryFileSet.removeId(galleryFile.id)"
  ></app-cdp-email-template-tile>
  } }
</div>
}
