<div>
  <div style="max-width: 600px">
    <app-cdp-user-profile
      [profile]="tempUserProfile"
      #user_profile
    ></app-cdp-user-profile>
  </div>
  <br />
  <div style="max-width: 800px">
    <app-cdp-business-profile
      [profile]="tempBusinessProfile"
      #business_profile
    ></app-cdp-business-profile>
  </div>
  <div class="mt-2 text-center">
    @if (!isFormValid()) {
    <p style="color: red">One or more fields are missing or invalid.</p>
    }
    <div class="row">
      <div class="col">
        <button
          class="btn btn-primary profile-button"
          type="submit"
          (click)="onClickSaveProfile()"
          [disabled]="!isFormValid() || isOperationInProgress"
        >
          Save Profile
        </button>
      </div>
      <div class="col">
        <span>
          <app-cdp-progress-spinner
            [operationSet]="operationSet"
          ></app-cdp-progress-spinner>
        </span>
      </div>
    </div>
  </div>
  <div class="vertical-break"></div>
</div>
