import { EmitterShapeBase } from "@tsparticles/plugin-emitters";
import { getRandom, halfRandom } from "@tsparticles/engine";
const half = 0.5,
  sides = 4,
  double = 2;
function randomSquareCoordinate(position, offset) {
  return position + offset * (getRandom() - halfRandom);
}
export class EmittersSquareShape extends EmitterShapeBase {
  constructor(position, size, fill, options) {
    super(position, size, fill, options);
  }
  async init() {}
  async randomPosition() {
    return await new Promise(success => {
      const fill = this.fill,
        position = this.position,
        size = this.size;
      if (fill) {
        return success({
          position: {
            x: randomSquareCoordinate(position.x, size.width),
            y: randomSquareCoordinate(position.y, size.height)
          }
        });
      } else {
        const halfW = size.width * half,
          halfH = size.height * half,
          side = Math.floor(getRandom() * sides),
          v = (getRandom() - halfRandom) * double;
        switch (side) {
          case 0:
            return success({
              position: {
                x: position.x + v * halfW,
                y: position.y - halfH
              }
            });
          case 1:
            return success({
              position: {
                x: position.x - halfW,
                y: position.y + v * halfH
              }
            });
          case 2:
            return success({
              position: {
                x: position.x + v * halfW,
                y: position.y + halfH
              }
            });
          case 3:
          default:
            return success({
              position: {
                x: position.x + halfW,
                y: position.y + v * halfH
              }
            });
        }
      }
    });
  }
}