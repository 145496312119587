@if (visible) {
<span class="card m-2" #template_tile style="display: inline-block">
  <div class="card-header p-0">
    @if (showTemplateName) {
    <div class="row">
      <div class="col">
        <a style="font-size: smaller; color: black; margin: 0px 1rem">{{
          templateName()
        }}</a>
      </div>
    </div>
    }
    <div class="row">
      <div class="col" role="group" aria-label="Preview or edit this template">
        @if (showEditButton) {
        <a class="mx-2" (click)="onClickEdit()">
          <i class="fs-4 bi-pencil-fill"></i>
        </a>
        } @if (showSendButton) {
        <a class="mx-2" (click)="onClickSend()">
          <i class="fs-4 bi-send-fill"></i>
        </a>
        }
      </div>
      @if (showTemplateId) {
      <div class="col col-auto">
        <a style="color: darkgrey; margin: 0px 1rem">{{ templateId() }}</a>
      </div>
      } @if (showDeleteButton) {
      <div class="col col-auto">
        <a class="mx-2" (click)="onClickDeleteTemplate()"
          ><i class="fs-4 bi-trash3"></i
        ></a>
      </div>
      }
    </div>
  </div>
  <div #iframe_container (click)="onClickEdit()"></div>
</span>
}
