import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { assign } from 'lodash';

import { CdpBusiness, CdpBusinessProfile } from '../../business/cdp-business';
import { CdpBusinessProfileComponent } from '../../business/cdp-business-profile/cdp-business-profile.component';
import { CdpUser, CdpUserProfile } from '../../cdp-user';
import { CdpUserProfileComponent } from '../cdp-user-profile/cdp-user-profile.component';
import {
  CdpBackendProfileService,
  CdpUpdateProfileResponse,
} from 'src/app/backend/cdp-backend-business-profile.service';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpSession } from '../../cdp-session';
import { FormsModule } from '@angular/forms';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { CdpJwt } from '../../cdp-auth/auth.service';
import { CdpProgressSpinnerComponent } from 'src/app/ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';
import {
  CdpProgressOperationSet,
  CdpProgressOperationTag,
} from 'src/app/ui/progress/cdp-progress';
import { NavigationEnd, Router } from '@angular/router';
import { CdpCloneable } from 'src/app/email/template/email-template-editor/email-template-editor.component';

export class CdpReloader {
  private thisUrl_: string = '';
  private onReloadFunc_: any;

  constructor(public router: Router, onReloadFunc: any) {
    this.onReloadFunc_ = onReloadFunc;

  }

  public url(): string {
    return this.thisUrl_;
  }

  async onInit(): Promise<void> {
    this.thisUrl_ = this.router.url;
    await this.onReload();

    const onReload = this.onReload.bind(this);
    const thisUrl: string = this.thisUrl_;

    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        if (event.url == thisUrl) {
          // Route is restored
          await onReload();
        }
      }
    });
  }

  async onReload(): Promise<void> {
    if (this.onReloadFunc_) {
      await this.onReloadFunc_();
    }
  }
}

@Component({
  selector: 'app-cdp-profile-combined',
  standalone: true,
  imports: [
    CdpBusinessProfileComponent,
    CdpProgressSpinnerComponent,
    CdpUserProfileComponent,
    FormsModule,
  ],
  templateUrl: './cdp-profile-combined.component.html',
  styleUrl: './cdp-profile-combined.component.sass',
})
export class CdpProfileCombinedComponent implements AfterViewInit, OnInit {
  @ViewChild('user_profile') userProfileComponent!: CdpUserProfileComponent;
  @ViewChild('business_profile')
  buinessProfileComponent!: CdpBusinessProfileComponent;

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();

  get isOperationInProgress() {
    return this.operationSet.isOperationInProgress;
  }

  tempUserProfile: CdpUserProfile = new CdpUserProfile();
  tempBusinessProfile: CdpBusinessProfile = new CdpBusinessProfile();

  currentUserProfile: CdpUserProfile | null = null;
  currentBusinessProfile: CdpBusinessProfile | null = null;

  private reloader_: CdpReloader;

  constructor(
    private backend: CdpBackendProfileService,
    private router: Router,
    private sessionManager: CdpSessionManagerService
  ) {
    this.reloader_ = new CdpReloader(router, this.onReload.bind(this));
  }

  async ngOnInit(): Promise<void> {

  }

  async ngAfterViewInit(): Promise<void> {
    await this.reloader_.onInit();
  }

  async onReload(): Promise<void> {
    const userProfile: CdpUserProfile | null =
      await this.sessionManager.getCurrentUserProfile();

    const businessProfile: CdpBusinessProfile | null =
      await this.sessionManager.getCurrentBusinessProfile();

    //console.log("User profile:", userProfile);
    //console.log("Business profile:", businessProfile);

    if (userProfile) {
      if (userProfile.email.email.length == 0) {
        const idToken: CognitoIdToken | null =
          await CdpJwt.getCurrentSessionCognitoIdToken();
        if (idToken) {
          const payload = idToken.payload;
          const email: string = payload['email'];
          const verified: boolean = payload['email_verified'];

          if (verified) {
            userProfile.email.email = email;
          }
        }
      }
    }

    this.currentUserProfile = userProfile;
    this.currentBusinessProfile = businessProfile;

    if (userProfile) {
      this.tempUserProfile = CdpCloneable.deepCopy(userProfile);
    } else {
      this.tempUserProfile = new CdpUserProfile();
    }

    if (businessProfile) {
      this.tempBusinessProfile = CdpCloneable.deepCopy(businessProfile);
    } else {
      this.tempBusinessProfile = new CdpBusinessProfile();
    }
  }

  onClickSaveProfile() {
    if (this.isFormValid()) {

      if (this.tempUserProfile && this.tempBusinessProfile) {
        const operationTag: CdpProgressOperationTag =
          this.operationSet.addOperation('SaveProfile', 'Saving profile...');

        let observable: Observable<CdpUpdateProfileResponse> =
          this.backend.updateUserAndBusinessProfiles(
            this.tempUserProfile,
            this.tempBusinessProfile
          );

        observable.subscribe((response) =>
          this.onSaveProfileResponseReceived(response, operationTag)
        );
      }
    }
  }

  onSaveProfileResponseReceived(
    response: CdpUpdateProfileResponse,
    operationTag: CdpProgressOperationTag
  ) {
    //console.log('Received response to save business profile: ', response);
    const succeeded: boolean = !response.errors || response.errors.length == 0;

    try {
      if (succeeded) {
        // The profiles were successfully updated on the backend, so update them locally.
        // TODO TODO Read the updated profiles from the response, in case the backend altered or added
        //           anything.
        if (this.currentUserProfile) {
          assign(this.currentUserProfile, this.tempUserProfile);
        }

        if (this.currentBusinessProfile) {
          assign(this.currentBusinessProfile, this.tempBusinessProfile);
        }
      }
    } finally {
      const message: string = succeeded ? 'Save succeeded.' : 'Save failed.';
      this.operationSet.updateOperationFinal(
        operationTag,
        succeeded,
        message,
        2000
      );
    }
  }

  isFormValid(): boolean {
    /* 
    console.log('Checking form validity');
    console.log(
      'User profile:',
      this.tempUserProfile!.areAllRequiredFieldsCompleteAndValid()
    );
    console.log(
      'Business profile:',
      this.tempBusinessProfile!.areAllRequiredFieldsCompleteAndValid()
    );
*/

    return (
      this.tempUserProfile!.areAllRequiredFieldsCompleteAndValid() &&
      this.tempBusinessProfile!.areAllRequiredFieldsCompleteAndValid()
    );
  }
}
