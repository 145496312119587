import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Observable, from, firstValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import {
  CdpEmailTemplateFile,
  CdpEmailTemplateFileTransformer,
  CdpEmailTemplateFileTransformerOperation,
} from './cdp-email-template-file';
import { CdpRoutes } from '../../core/router/cdp-routes';
import {
  CdpBackendFileService,
  CdpFileOperationResult,
  CdpFileOperationResultSet,
} from '../../backend/files/cdp-backend-file.service';
import {
  CdpFile,
  CdpFileCategory,
  CdpFileHelper,
  CdpFileInfo,
  CdpFileLocationType,
} from '../../core/files/cdp-file';
import {
  CdpImageGallerySectionComponent,
  CdpImageGallerySectionEvent,
} from '../../images/cdp-image-gallery-section/cdp-image-gallery-section.component';
import { CdpEmailTemplateBrandingSet } from './cdp-email-template-branding-set';
import {
  CdpFileCategoryLocationMap,
  CdpFileLocationMap,
  CdpResourceService,
} from '../../resource/cdp-resource.service';
import { Mutex } from 'async-mutex';
import {
  CdpEmailTemplateInfo,
  CdpEmailTemplateInfoSet,
} from './cdp-email-template-info';
import { CdpCloneable } from './email-template-editor/email-template-editor.component';
import { CdpSessionManagerService } from '../../cdp-session-manager.service';
import { CdpBusinessProfile } from '../../core/business/cdp-business';
import { CdpUrl } from '../../core/cdp-url';
import { AppConfigModule } from '../../config/app-config/app-config.module';

export class CdpEmailTemplateManagerFile {
  templateFile: CdpEmailTemplateFile | null = null;
  file: CdpFile | null = null;
  templateInfo: CdpEmailTemplateInfo | null = null;

  // <existsOnBackend> indicates that this file was either loaded from
  // the backend or has been saved at least once to the backend, i.e.,
  // that a file representing this object exists on the backend.
  // It is not guaranteed that the current contents of this object
  // are currently in sync with the file on the backend.
  existsOnBackend: boolean = false;

  // <operationInProgress> is used to indicate if there's a backend
  // operation like save or delete in progress on this file.
  operationInProgress: string = '';

  get isOperationInProgress(): boolean {
    return this.operationInProgress.length > 0;
  }

  couldBeDeleted(): boolean {
    return this.file != null && this.file.couldBeDeleted();
  }

  isEditable(): boolean {
    const editable: boolean =
      this.templateFile != null && this.file != null && !this.file.isInternal();

    //console.log(`isEditable? ${this} : ${editable}`);

    return editable;
  }

  // Returns a template manager file that can be edited.
  static makeEditableTemplateManagerFile(
    templateManagerFile: CdpEmailTemplateManagerFile | null,
    transformer: CdpEmailTemplateFileTransformer | null = null
  ): CdpEmailTemplateManagerFile | null {
    //console.log('Make editable:', JSON.stringify(templateManagerFile));
    //console.log('Is editable?', templateManagerFile?.isEditable());
    if (templateManagerFile == null) {
      return null;
    } else if (templateManagerFile.isEditable()) {
      // The template manager file is already editable, so use it directly.
      // Note: We don't use <transformer> in this case, since it's typically
      //       for customizing a builtin template for the user.
      return templateManagerFile;
    } else {
      // The editable version has the same contents but a unique name and new
      // file info.
      const editableTemplateManagerFile: CdpEmailTemplateManagerFile =
        CdpCloneable.deepCopy(templateManagerFile);
      editableTemplateManagerFile.existsOnBackend = false;
      editableTemplateManagerFile.file = null;

      if (!editableTemplateManagerFile.templateFile) {
        return null;
      }

      if (transformer) {
        editableTemplateManagerFile.templateFile = transformer.transform(
          editableTemplateManagerFile.templateFile
        );
      }

      const newTemplateFile: CdpEmailTemplateFile | null =
        editableTemplateManagerFile.templateFile;

      const newTemplateInfo: CdpEmailTemplateInfo = newTemplateFile.info;

      // TODO TODO Really, the backend should assign a new ID.
      newTemplateInfo.id.id = uuidv4();
      newTemplateInfo.id.version = '1.0';
      newTemplateInfo.baseId = CdpCloneable.deepCopy(newTemplateFile.info.id);

      //console.log('Creating new fileInfo for template:', newTemplateFile.info);

      const filename: string =
        newTemplateInfo.name + `__${newTemplateInfo.id.id}.emt`;

      const newFileInfo: CdpFileInfo = new CdpFileInfo();
      newFileInfo.category = CdpFileCategory.EmailTemplate;
      newFileInfo.locationType = CdpFileLocationType.BusinessPrivate;
      newFileInfo.filename = filename;

      editableTemplateManagerFile.templateInfo = newTemplateInfo;
      editableTemplateManagerFile.file = new CdpFile();
      editableTemplateManagerFile.file.fileInfo = newFileInfo;

      //console.log('Created new file info for template:', newFileInfo);
      return editableTemplateManagerFile;
    }
  }

  async makeTransformed(
    transformer: CdpEmailTemplateFileTransformer
  ): Promise<CdpEmailTemplateManagerFile> {
    let clone: CdpEmailTemplateManagerFile = CdpCloneable.deepCopy(this);
    if (this.templateFile) {
      clone.templateFile = transformer.transform(this.templateFile);
    }

    return clone;
  }
}

export class CdpEmailTemplateManagerFileSet {
  files: CdpEmailTemplateManagerFile[] = [];
  infoSet: CdpEmailTemplateInfoSet = new CdpEmailTemplateInfoSet();
  isInfoSetValid: boolean = true;

  addCdpFiles(cdpFiles: CdpFile[]): number[] {
    // Note: First we need to go through any existing templates to see if we are
    //       re-adding (and/or intentionally updating) an existing template.
    //       If there is a match, then it is assumed that the contents of the file
    //       may have changed.
    const newCdpFiles: CdpFile[] = [];
    const newTemplateIndices: number[] = [];
    const origNumTemplateFiles: number = this.files.length;
    const numCdpFiles: number = cdpFiles.length;

    for (let i = 0; i < numCdpFiles; i++) {
      const cdpFile: CdpFile = cdpFiles[i];
      const cdpFileInfo: CdpFileInfo = cdpFile.fileInfo;

      // Ignore metadata files and deleted files.
      if (cdpFileInfo.isMetadata || cdpFileInfo.isDeleted) {
        continue;
      }

      let foundMatch: boolean = false;
      for (let it = 0; it < origNumTemplateFiles; it++) {
        const origTemplateFile: CdpEmailTemplateManagerFile = this.files[it];

        // If the file info of the template file matches the file info of the
        // new file, then we're replacing the template file.
        if (origTemplateFile.file) {
          const origTemplateFileInfo: CdpFileInfo =
            origTemplateFile.file.fileInfo;
          if (cdpFileInfo.isSameFile(origTemplateFileInfo)) {
            // These are the same file, so we replace the original.
            const newTemplateFile: CdpEmailTemplateManagerFile =
              new CdpEmailTemplateManagerFile();
            newTemplateFile.file = cdpFile;
            this.files[it] = newTemplateFile;

            newTemplateIndices.push(it);

            foundMatch = true;

            // We never allow two files to have the same file info, so we can stop
            // now.
            break;
          }
        }
      }

      if (!foundMatch) {
        newCdpFiles.push(cdpFile);
      }
    }

    // For any files that weren't already in the set, add them to the set.
    let index = this.files.length;
    for (const cdpFile of newCdpFiles) {
      const newTemplateFile: CdpEmailTemplateManagerFile =
        new CdpEmailTemplateManagerFile();
      newTemplateFile.file = cdpFile;
      this.files.push(newTemplateFile);

      newTemplateIndices.push(index);
      index++;
    }

    if (newTemplateIndices.length > 0) {
      // The info set is no longer valid.  Making it valid will require actually
      // loading all the template files and extracting the metadata.
      this.isInfoSetValid = false;
    }

    return newTemplateIndices;
  }

  setFiles(files: CdpEmailTemplateManagerFile[]) {
    // Replace the existing data.
    this.files = files;
    this.infoSet = new CdpEmailTemplateInfoSet();
    this.isInfoSetValid = false;
  }
}

export enum CdpEmailTemplateManagerOwnerCategory {
  // Simplify to just two categories.
  Builtin = 'Builtin',
  BusinessOrUser = 'BusinessOrUser',
}

export class CdpEmailTemplateManagerData {
  templateFileMap: Map<
    CdpEmailTemplateManagerOwnerCategory,
    CdpEmailTemplateManagerFileSet
  > = new Map<
    CdpEmailTemplateManagerOwnerCategory,
    CdpEmailTemplateManagerFileSet
  >();

  brandingSet: CdpEmailTemplateBrandingSet = new CdpEmailTemplateBrandingSet();

  static getOwnerCategoryFromLocationType(
    locationType: CdpFileLocationType
  ): CdpEmailTemplateManagerOwnerCategory {
    switch (locationType) {
      case CdpFileLocationType.Internal:
        return CdpEmailTemplateManagerOwnerCategory.Builtin;

      default:
        return CdpEmailTemplateManagerOwnerCategory.BusinessOrUser;
    }
  }

  addCdpFilesToOwnerCategory(
    ownerCategory: CdpEmailTemplateManagerOwnerCategory,
    cdpFiles: CdpFile[]
  ): number[] {
    let templateFileSet: CdpEmailTemplateManagerFileSet | undefined =
      this.templateFileMap.get(ownerCategory);
    if (!templateFileSet) {
      templateFileSet = new CdpEmailTemplateManagerFileSet();
      this.templateFileMap.set(ownerCategory, templateFileSet);
    }

    return templateFileSet.addCdpFiles(cdpFiles);
  }

  // Note: addTemplatesFromFileLocationMap() adds the files to the map, but it does not
  //       load files.  Files that are already loaded will have their contents read
  //       and converted to a template file.
  addTemplatesFromFileLocationMap(fileLocationMap: CdpFileLocationMap) {
    let builtinFiles: CdpFile[] = [];
    let businessOrUserFiles: CdpFile[] = [];

    let files: CdpFile[] | undefined = fileLocationMap.get(
      CdpFileLocationType.Internal
    );
    if (files) {
      builtinFiles = builtinFiles.concat(files);
    }

    const businesOrUserLocationTypes: CdpFileLocationType[] = [
      CdpFileLocationType.BusinessPublic,
      CdpFileLocationType.BusinessPrivate,
      CdpFileLocationType.UserPrivate,
    ];

    for (const locationType of businesOrUserLocationTypes) {
      files = fileLocationMap.get(locationType);
      if (files) {
        businessOrUserFiles = businessOrUserFiles.concat(files);
      }
    }

    // Add the files to the template file map.
    const ownerCategories: CdpEmailTemplateManagerOwnerCategory[] = [
      CdpEmailTemplateManagerOwnerCategory.Builtin,
      CdpEmailTemplateManagerOwnerCategory.BusinessOrUser,
    ];
    const allCdpFiles: CdpFile[][] = [builtinFiles, businessOrUserFiles];

    for (let i = 0; i < 2; i++) {
      const ownerCategory: CdpEmailTemplateManagerOwnerCategory =
        ownerCategories[i];
      const cdpFiles: CdpFile[] = allCdpFiles[i];

      this.addCdpFilesToOwnerCategory(ownerCategory, cdpFiles);
    }
  }
}

export enum CdpEmailTemplateManagerEventType {
  DataChanged = 'DataChanged',
  TemplateFileMapChanged = 'TemplateFileMapChanged',
  BrandingChanged = 'BrandingChanged',
  TemplateAdded = 'TemplateAdded',
  TemplateRemoved = 'TemplateRemoved',
}

export class CdpEmailTemplateManagerEvent {
  eventType: CdpEmailTemplateManagerEventType =
    CdpEmailTemplateManagerEventType.DataChanged;
  data: CdpEmailTemplateManagerData | null = null;
  file: CdpFile | null = null;

  didBrandingChange(): boolean {
    return (
      this.eventType == CdpEmailTemplateManagerEventType.BrandingChanged ||
      this.eventType == CdpEmailTemplateManagerEventType.DataChanged
    );
  }

  didTemplatesChange(): boolean {
    return (
      this.eventType == CdpEmailTemplateManagerEventType.DataChanged ||
      this.eventType == CdpEmailTemplateManagerEventType.TemplateAdded ||
      this.eventType ==
        CdpEmailTemplateManagerEventType.TemplateFileMapChanged ||
      this.eventType == CdpEmailTemplateManagerEventType.TemplateRemoved
    );
  }
}

class CdpEmailTemplateFileTransformerOperationReplaceText
  implements CdpEmailTemplateFileTransformerOperation
{
  from: string;
  to: string;
  whichFields: string[];

  constructor(from: string, to: string, whichFields: string[]) {
    this.from = from;
    this.to = to;
    this.whichFields = whichFields;
  }

  transform(templateFile: CdpEmailTemplateFile): CdpEmailTemplateFile {
    const transformedTemplateFile: CdpEmailTemplateFile =
      CdpCloneable.deepCopy(templateFile);

    if (this.to != this.from) {
      for (const field of this.whichFields) {
        let src: string | null = null;
        switch (field) {
          case 'html':
            src = templateFile.html;
            break;
          case 'css':
            src = templateFile.css;
            break;
          case 'compiledHtml':
            src = templateFile.getCompiledHtml();
            break;
          case 'ampHtml':
            src = templateFile.getAmpHtml();
            break;

          default:
            const message: string = `Unknown template file field: ${field}`;
            console.log(message);
            throw message;
        }

        if (src != null) {
          const dest: string = src.replaceAll(this.from, this.to);
          switch (field) {
            case 'html':
              transformedTemplateFile.html = dest;
              break;
            case 'css':
              transformedTemplateFile.css = dest;
              break;
            case 'compiledHtml':
              transformedTemplateFile.setCompiledHtml(dest);
              break;
            case 'ampHtml':
              transformedTemplateFile.setAmpHtml(dest);
              break;

            default:
              const message: string = `Unknown template file field: ${field}`;
              console.log(message);
              throw message;
              break;
          }
        }
      }
    }

    return transformedTemplateFile;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CdpEmailTemplateManagerService {
  private currentTemplateManagerFile_: CdpEmailTemplateManagerFile =
    new CdpEmailTemplateManagerFile();

  private mutex_: Mutex = new Mutex();

  private data_: CdpEmailTemplateManagerData =
    new CdpEmailTemplateManagerData();

  eventEmitter = new EventEmitter<CdpEmailTemplateManagerEvent>();

  constructor(
    private fileService: CdpBackendFileService,
    private router: Router,
    private resourceService: CdpResourceService,
    private sessionManager: CdpSessionManagerService
  ) {
    //console.log('Template manager service: constructor');
    this.onInit_();
  }

  private async onInit_() {
    //console.log('Template manager service: OnInit');
    const categoryLocationMap: CdpFileCategoryLocationMap =
      await this.resourceService.getCategoryLocationMap();
    /*console.log(
      'Template manager service: OnInit got map: ',
      JSON.stringify(categoryLocationMap)
    );
*/
    const onResourcesChanged = this.onResourcesChanged_.bind(this);

    onResourcesChanged(categoryLocationMap);

    this.resourceService.eventEmitter.subscribe((categoryLocationMap) => {
      onResourcesChanged(categoryLocationMap);
    });
  }

  public async getData(): Promise<CdpEmailTemplateManagerData> {
    const release = await this.mutex_.acquire();
    try {
      return this.data_;
    } finally {
      release();
    }
  }

  public async getTemplateManagerFilesInOwnerCategory(
    ownerCategory: CdpEmailTemplateManagerOwnerCategory
  ): Promise<CdpEmailTemplateManagerFile[]> {
    const release = await this.mutex_.acquire();
    try {
      if (this.data_) {
        const templateManagerFileSet:
          | CdpEmailTemplateManagerFileSet
          | undefined = this.data_.templateFileMap.get(ownerCategory);
        if (templateManagerFileSet) {
          for (const templateManagerFile of templateManagerFileSet.files) {
            const templateFile: CdpEmailTemplateFile | null =
              await this.loadTemplateFileFromTemplateManagerFile(
                templateManagerFile
              );
            templateManagerFile.templateFile = templateFile;

            if (templateFile) {
              templateManagerFile.templateInfo = templateFile.info;
            }
          }
          return [...templateManagerFileSet.files];
        } else {
          return [];
        }
      }

      return [];
    } finally {
      release();
    }
  }

  deleteTemplateManagerFile(templateManagerFile: CdpEmailTemplateManagerFile) {
    if (!templateManagerFile.couldBeDeleted()) {
      // The file cannot be deleted, e.g. if it's a builtin template
      // or it's a file where we don't have the filename.

      return;
    }

    if (!templateManagerFile.existsOnBackend) {
      // There isn't a file on the backend that corresponds to this template
      // manager file, e.g., it was never saved in the first place.
      console.log('File does not exist on backend:', templateManagerFile);
      return;
    }

    // Now we'll try to delete the file in the backend.  If the
    // deletion is successful, then we'll remove it from the current
    // data (if it's present in the "current" data at the time when the
    // deletion completes).
    if (templateManagerFile.file) {
      // should be guaranteed from check above
      const fileInfo: CdpFileInfo = templateManagerFile.file.fileInfo;
      const deletePermanent: boolean = true; // TODO Allow specifying?
      const observable: Observable<CdpFileOperationResultSet> =
        this.fileService.deleteFiles([fileInfo], deletePermanent);
      observable.subscribe((resultSet) =>
        this.processFileOperationResultSet_(resultSet)
      );
    }
  }

  processFileOperationResultSet_(resultSet: CdpFileOperationResultSet) {
    // TODO TODO
    //console.log('Template manager got file operation result set:', resultSet);
  }

  private extractBrandingSet_(
    imageFileMap: CdpFileLocationMap
  ): CdpEmailTemplateBrandingSet {
    const brandingSet: CdpEmailTemplateBrandingSet =
      new CdpEmailTemplateBrandingSet();

    const files: CdpFile[] | undefined = imageFileMap.get(
      CdpFileLocationType.BusinessPublic
    );
    if (files) {
      for (const file of files) {
        const fileInfo: CdpFileInfo = file.fileInfo;
        if (
          !fileInfo.isDeleted &&
          !fileInfo.isMetadata &&
          fileInfo.url.length > 0
        ) {
          if (fileInfo.filename.startsWith('Logo/logo.')) {
            //console.log('Found logo:', fileInfo);

            brandingSet.logoImageUrl = fileInfo.url;

            break;
          }
        }
      }
    }
    return brandingSet;
  }

  // We may want to transform builtin templates, such as replacing links to
  // our websites to the user's website.
  public async makeTransformerFromBuiltinForUser(): Promise<CdpEmailTemplateFileTransformer | null> {
    const businessProfile: CdpBusinessProfile | null =
      await this.sessionManager.getCurrentBusinessProfile();

    const businessUrl: CdpUrl | null = businessProfile
      ? businessProfile.basicInfo.businessUrl
      : null;

    if (
      !businessUrl ||
      !businessUrl.isValid() ||
      businessUrl.hostname.length == 0
    ) {
      return null;
    }

    const transformer: CdpEmailTemplateFileTransformer =
      new CdpEmailTemplateFileTransformer();

    // Replace our website name with the user's, but only for a complete href
    // that is just our website name without prefixes or suffixes, since, e.g.,
    // image files may contain our website name.
    const srcHostname: string = AppConfigModule.websiteHostname;
    const destHostname: string = businessUrl.hostname;

    // Replace the website name in all HTML fields. Note that we clearly
    // don't need to replace anything in the CSS.
    const whichFields: string[] = ['html', 'compiledHtml', 'ampHtml'];

    // The replacements we want to make target strings like
    //    href="https://inboxease.com"
    // where there are no specific pages etc. after the ".com".  However,
    // there might be a trailing slash, and there might also be tracking
    // tags that start with something like "inboxease.com/?utm_".
    // Also note that we don't want to replace "images.inboxease.com".
    //
    // Hopefully, it will suffice to look for and replace just the patterns below.
    //
    // TODO TODO The links with utm* aren't going to really do the right thing unless
    //           we're hooked into the user's website.

    const allFrom: string[] = [
      `//${srcHostname}/"`, // e.g. "https://inboxease.com/"
      `//${srcHostname}"`, // e.g. "https://inboxease.com"
      `//${srcHostname}/?`, // e.g. "https://inboxease.com/?utm...
    ];

    const allTo: string[] = [
      `//${destHostname}/"`,
      `//${destHostname}"`,
      `//${destHostname}/?`,
    ];

    const numReplacements: number = allFrom.length;

    for (let i = 0; i < numReplacements; i++) {
      const from: string = allFrom[i];
      const to: string = allTo[i];

      const transformerOperation: CdpEmailTemplateFileTransformerOperation =
        new CdpEmailTemplateFileTransformerOperationReplaceText(
          from,
          to,
          whichFields
        );

      transformer.addOperation(transformerOperation);
    }

    return transformer;
  }

  private async setData_(data: CdpEmailTemplateManagerData) {
    const release = await this.mutex_.acquire();
    try {
      this.data_ = data;
    } finally {
      release();
    }

    const event: CdpEmailTemplateManagerEvent =
      new CdpEmailTemplateManagerEvent();
    event.eventType = CdpEmailTemplateManagerEventType.DataChanged;
    event.data = data;

    this.eventEmitter.emit(event);
  }

  async loadTemplateFileFromTemplateManagerFile(
    templateManagerFile: CdpEmailTemplateManagerFile
  ): Promise<CdpEmailTemplateFile | null> {
    if (templateManagerFile.templateFile) {
      // The file data is already available.
      return templateManagerFile.templateFile;
    } else if (
      !templateManagerFile.templateFile &&
      templateManagerFile.file != null
    ) {
      // The file data has not been loaded to create the template, but we do either
      // have the file data or can load the data now.
      const cdpFile: CdpFile = templateManagerFile.file;
      const fileInfo: CdpFileInfo = cdpFile.fileInfo;

      let promise: Promise<CdpEmailTemplateFile>;
      let isBackendFile: boolean = false;

      if (cdpFile.file) {
        // The file contents are already available.
        promise = this.loadTemplateFileEmtFromFile_(cdpFile.file);
      } else if (fileInfo.url.length > 0) {
        // The file is available via public URL.
        //console.log("Load template from URL:", fileInfo.url);
        promise = this.loadTemplateFileEmtFromUrl_(fileInfo.url);
      } else {
        // We need to download the file from the backend.
        promise = this.loadTemplateFileEmtFromFileService_(fileInfo);
        isBackendFile = true;
      }

      const templateFile: CdpEmailTemplateFile = await promise;
      templateManagerFile.templateFile = templateFile;
      templateManagerFile.existsOnBackend = isBackendFile;

      return templateManagerFile.templateFile;
    } else {
      // We don't have the data and there's nothing we can do to get it.
      return null;
    }
  }

  private async loadTemplateFileEmtFromUrl_(
    url: string
  ): Promise<CdpEmailTemplateFile> {
    try {
      const emtString: string = await firstValueFrom(
        this.fileService.loadUrlFileAsText(url)
      );
      return this.loadTemplateFileEmtFromString(emtString);
    } catch (error) {
      console.log('loadTemplateFileEmtFromUrl_ caught error:', error);
      return new CdpEmailTemplateFile();
    }
  }

  private async loadTemplateFileEmtFromFile_(
    file: File
  ): Promise<CdpEmailTemplateFile> {
    // The template data is already available.
    const emtString: string = await CdpFileHelper.readFileAsText(file);

    return this.loadTemplateFileEmtFromString(emtString);
  }

  private async loadTemplateFileEmtFromFileService_(
    fileInfo: CdpFileInfo
  ): Promise<CdpEmailTemplateFile> {
    //console.log('Downloading:', JSON.stringify(fileInfo));

    const resultSetObservable: Observable<CdpFileOperationResultSet> =
      this.fileService.downloadFiles([fileInfo]);
    const resultSet: CdpFileOperationResultSet = await lastValueFrom(
      resultSetObservable
    );

    //console.log('Download result set:', resultSet);

    // TODO TODO Handle errors properly.
    if (resultSet.results.length == 1) {
      const result: CdpFileOperationResult = resultSet.results[0];
      if (result.succeeded) {
        //console.log("Typeof result.data:", typeof(result.data));
        const emtString: string = result.data;
        const fileInfo: CdpFileInfo | null = result.fileInfo;
        //console.log("Downloaded emt string:", emtString);

        return this.loadTemplateFileEmtFromString(emtString);
      }
    }

    return new CdpEmailTemplateFile(); // TODO TODO error
  }

  loadTemplateFileEmtFromString(emtString: string): CdpEmailTemplateFile {
    const templateFile: CdpEmailTemplateFile = new CdpEmailTemplateFile();
    //console.log("l.325");
    templateFile.loadFromEmtFormat(emtString);

    return templateFile;
  }

  private updateDataUsingMap_(
    categoryLocationMap: CdpFileCategoryLocationMap | undefined
  ) {
    //console.log('Updating data using map:', categoryLocationMap);

    const newData: CdpEmailTemplateManagerData =
      new CdpEmailTemplateManagerData();
    if (categoryLocationMap) {
      const templateFileLocationMap: CdpFileLocationMap | undefined =
        categoryLocationMap.get(CdpFileCategory.EmailTemplate);

      if (templateFileLocationMap) {
        newData.addTemplatesFromFileLocationMap(templateFileLocationMap);
      }

      // Extract any branding elements from the images.
      const imageFileLocationMap: CdpFileLocationMap | undefined =
        categoryLocationMap.get(CdpFileCategory.Image);
      if (imageFileLocationMap) {
        newData.brandingSet = this.extractBrandingSet_(imageFileLocationMap);
      }
    }

    this.setData_(newData);
  }

  private onResourcesChanged_(categoryLocationMap: CdpFileCategoryLocationMap) {
    this.updateDataUsingMap_(categoryLocationMap);
  }

  getBrandingSet(): CdpEmailTemplateBrandingSet {
    return this.data_.brandingSet;
  }

  setBrandingLogoImageUrl(logoImageUrl: string): void {
    // //console.log("Setting logo image URL: ", logoImageUrl);

    this.data_.brandingSet.logoImageUrl = logoImageUrl;

    this.onBrandingChanged_();
  }

  showBogusAllImagesDoc(): void {
    // TODO    //console.log(this.bogusAllImagesDoc.documentElement.outerHTML);
  }

  onBrandingChanged_() {
    //console.log(
    //  'Template manager emitting branding changed event:',
    //  this.data_
    //);

    const event: CdpEmailTemplateManagerEvent =
      new CdpEmailTemplateManagerEvent();
    event.eventType = CdpEmailTemplateManagerEventType.BrandingChanged;
    event.data = this.data_;

    this.eventEmitter.emit(event);
  }

  private processImageGallerySectionEvent_(event: CdpImageGallerySectionEvent) {
    // Look for a logo or other branding element.
    //console.log("Template manager processing section event:", event);

    if (event.sectionInfo.name == 'Logo') {
      // TODO For now, there should be only one logo image, so whatever image
      // we get is set as the logo image.  If there were multiple images in
      // the logo section, then whichever one gets loaded last will be the
      // logo.
      const logoImageUrl: string = event.image.url;

      this.setBrandingLogoImageUrl(logoImageUrl);
    }
  }

  notifyImageGallerySectionCreated(section: CdpImageGallerySectionComponent) {
    //console.log("Template manager subscribing to section:", section);
    const processImageGallerySectionEvent =
      this.processImageGallerySectionEvent_.bind(this);
    section.addedImageEvent.subscribe((event) => {
      processImageGallerySectionEvent(event);
    });
  }

  getCurrentTemplateManagerFile(): CdpEmailTemplateManagerFile {
    return this.currentTemplateManagerFile_;
  }

  setCurrentTemplateManagerFile(f: CdpEmailTemplateManagerFile) {
    //console.log('setCurrentTemplateManagerFile: name=', f.info.name);
    this.currentTemplateManagerFile_ = f;
  }

  openEditor(templateManagerFile: CdpEmailTemplateManagerFile) {
    // TODO pass the file directly to the editor.
    this.setCurrentTemplateManagerFile(templateManagerFile);
    this.router.navigate([CdpRoutes.EmailTemplateEditor]);
  }

  openEmailSend(templateManagerFile: CdpEmailTemplateManagerFile) {
    // TODO pass the file directly to the editor.
    this.setCurrentTemplateManagerFile(templateManagerFile);
    this.router.navigate([CdpRoutes.SendEmail]);
  }

  addOrUpdateUserTemplateFile(cdpFile: CdpFile) {
    const locationType: CdpFileLocationType = cdpFile.fileInfo.locationType;

    const ownerCategory: CdpEmailTemplateManagerOwnerCategory =
      CdpEmailTemplateManagerData.getOwnerCategoryFromLocationType(
        locationType
      );

    //console.log('addOrUpdateUserTemplateFile:', cdpFile);

    this.data_.addCdpFilesToOwnerCategory(ownerCategory, [cdpFile]);

    const event: CdpEmailTemplateManagerEvent =
      new CdpEmailTemplateManagerEvent();
    event.eventType = CdpEmailTemplateManagerEventType.TemplateAdded;
    event.data = this.data_;
    event.file = cdpFile;

    //console.log('Template manager emitting event:', JSON.stringify(event));
    this.eventEmitter.emit(event);
  }

  /*
  async loadTemplateFileEmt(
    templateName: string
  ): Promise<CdpEmailTemplateFile> {
    //console.log("htmlUrl:", htmlUrl);
    //console.log("cssUrl:", cssUrl);

    const emtString: string = await this.fileService.loadUrlFileAsText(
      templateName
    );

    //console.log("l.602");
    const templateFile: CdpEmailTemplateFile = new CdpEmailTemplateFile();
    templateFile.loadFromEmtFormat(emtString);

    // Strip out other branding.
    const transformer: CdpEmailTemplateFileTransformerStripOrigProvider =
      new CdpEmailTemplateFileTransformerStripOrigProvider();

    const updatedTemplateFile: CdpEmailTemplateFile | null =
      await transformer.transform(templateFile);

    //console.log(`loadTemplateFileEmt l.608: ${templateFile.info}`);

    //console.log(`loadTemplateFileEmt transformed: ${updatedTemplateFile?.info}`);
    return updatedTemplateFile ? updatedTemplateFile : templateFile;
  }
  */
}
