import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { CdpAuthService } from './core/cdp-auth/auth.service';
import { CdpSessionManagerService } from './cdp-session-manager.service';
import { CdpBackendService } from './backend/cdp-backend/cdp-backend.service';
import { CdpEvent } from './core/cdp-event';
import { SquareService } from './connectors/square/square.service';
import { CustomerUiService } from './ui/customer-ui.service';
import { CdpHeaderComponent } from './core/cdp-header/cdp-header.component';
import { FormsModule } from '@angular/forms';
import { CdpFooterComponent } from './core/cdp-footer/cdp-footer.component';
import { CdpSideMenuComponent } from './ui/cdp-side-menu/cdp-side-menu.component';
import { CdpEmailTemplateDirectoryService } from './email/template/cdp-email-template-directory.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    CdpFooterComponent,
    CdpHeaderComponent,
    CdpSideMenuComponent,
    FormsModule,
    NgMultiSelectDropDownModule
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  title = 'InboxEase';

  @Output() eventEmitter_ = new EventEmitter<CdpEvent>();

  constructor(
    private authenticator: CdpAuthService,
    private backend: CdpBackendService,
    private rootElementRef: ElementRef,
    private sessionManager: CdpSessionManagerService,
    private ui: CustomerUiService,
    private square: SquareService,
    // TODO? private emailTemplateDirectory: CdpEmailTemplateDirectoryService
  ) {}

  ngOnInit() {
    window.InboxEase = window.InboxEase || {};
    window.InboxEase.Auth = this.authenticator;
    window.InboxEase.Backend = this.backend;
    window.InboxEase.EventEmitter = this.eventEmitter_;
    window.InboxEase.SessionManager = this.sessionManager;
    window.InboxEase.Square = this.square;
    window.InboxEase.UI = this.ui;

    // Loading the email templates from the server can take a while, so start the
    // loading process.
    // TODO? this.emailTemplateDirectory.startLoadBuiltinTemplateInfos();
  }

  ngOnDestroy() {
    window.InboxEase.Auth = null;
    window.InboxEase.Backend = null;
    window.InboxEase.EventEmitter = null;
    window.InboxEase.SessionManager = null;
    window.InboxEase.Square = null;
    window.InboxEase.UI = null;
  }

  public getRootElementRef(): ElementRef {
    return this.rootElementRef;
  }

  public eventEmitter(): EventEmitter<CdpEvent> {
    return this.eventEmitter_;
  }

  public emit(e: CdpEvent) {
    //console.log('Root component emit: ', e);
    this.eventEmitter_.emit(e);
  }
}
