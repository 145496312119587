import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

export class CdpCountry {
  // Country code in ISO-3166-2 format.
  isoCode: string = 'ISO 3166-2:US';

  public isUsa(): boolean {
    return this.isoCode == 'ISO 3166-2:US';
  }
}

@Component({
  selector: 'app-cdp-country',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cdp-country.component.html',
  styleUrl: './cdp-country.component.scss',
})
export class CdpCountryComponent {
  @Input() country: CdpCountry = new CdpCountry();
}
