import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CdpBusinessProfileDescription } from '../cdp-business';

@Component({
  selector: 'app-cdp-business-profile-description',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cdp-business-profile-description.component.html',
  styleUrl: './cdp-business-profile-description.component.sass',
})
export class CdpBusinessProfileDescriptionComponent {
  @Input() description: CdpBusinessProfileDescription =
    new CdpBusinessProfileDescription();
}
