import { CdpAddress } from '../address/cdp-address';
import { CdpPhoneNumber } from '../cdp-phone-number';
import { CdpUrl } from '../cdp-url';
import { merge } from 'lodash';

export class CdpBusinessType {
  type: string = '';
  typeOther: string = '';

  public isValid(): boolean {
    // TODO We might want to restrict "type" to being a known value.
    if (this.type == 'Other') {
      return this.typeOther.length > 0;
    } else {
      return this.type.length > 0;
    }
  }

  public static knownTypes: string[] = [
    'Art & Design',
    'Bakery',
    'Bar',
    'Bookstore',
    'Clothing',
    'Education/Nonprofit',
    'Florist',
    'Hair/Beauty Salon',
    'Hobbies/Leisure',
    'Home/Furniture',
    'Hotel',
    'Jewelry',
    'Pets',
    'Professional services',
    'Restaurant',
    'Retail',
    'Spa',
    'Sports bar',
    'Winery/Distillery'
  ];
}

export class CdpBusinessProfileBasicInfo {
  businessName: string = '';
  businessUrl: CdpUrl = new CdpUrl();
  phoneNumber: CdpPhoneNumber = new CdpPhoneNumber();
  address: CdpAddress = new CdpAddress();

  public areAllRequiredFieldsCompleteAndValid(): boolean {
    // Note: Phone number and address are not required.
    return this.businessName.length > 0 && this.businessUrl.isValid();
  }
}

export class CdpBusinessProfileDetails {
  businessType: CdpBusinessType = new CdpBusinessType();

  public areAllRequiredFieldsCompleteAndValid(): boolean {
    // Note: Phone number and address are not required.
    return this.businessType.isValid();
  }
}

export class CdpBusinessProfileDescription {
  description: string = '';

  public areAllRequiredFieldsCompleteAndValid(): boolean {
    // The description is not required.
    return true;
  }
}

export class CdpBusinessProfile {
  basicInfo: CdpBusinessProfileBasicInfo = new CdpBusinessProfileBasicInfo();
  details: CdpBusinessProfileDetails = new CdpBusinessProfileDetails();
  description: CdpBusinessProfileDescription =
    new CdpBusinessProfileDescription();

  public areAllRequiredFieldsCompleteAndValid(): boolean {
    return (
      this.basicInfo.areAllRequiredFieldsCompleteAndValid() &&
      this.details.areAllRequiredFieldsCompleteAndValid() &&
      this.description.areAllRequiredFieldsCompleteAndValid()
    );
  }

  public assign(rhs: CdpBusinessProfile) {
    merge(this, rhs);
  }

  public setFromDict(dict: any) {
    // Note: This assignment assumes that the dictionary's entries
    //       match the expected properties.
    merge(this, dict);

    // The URL property in the dict is just a string basicInfo.businessUrl.url_, but
    // we now cache additional fields, so we reset from the stored value.

    // We need to convert that to use the correct internal form. Otherwise,
    // we'll end up with a string member rather than a "URL" object.
    // You'd expect "typeof(rawUrl)" below to be "URL | null", but it's actually
    // "string" at this point.
    
    //console.log("before: businessUrl=", this.basicInfo.businessUrl);

    const rawUrl: any = this.basicInfo.businessUrl.href;
    this.basicInfo.businessUrl.trySetUrl(rawUrl);

    //console.log("Type of rawUrl:", typeof(rawUrl));
    //console.log("after: businessUrl=", this.basicInfo.businessUrl)
  }
}

export class CdpBusiness {
  profile: CdpBusinessProfile = new CdpBusinessProfile();
}
