import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdpSession } from '../core/cdp-session';
import { CdpRoutes } from '../core/router/cdp-routes';
import { CdpSessionManagerService } from '../cdp-session-manager.service';
import { Router, RouterModule } from '@angular/router';
import { CdpEvent } from '../core/cdp-event';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './main.component.html',
  styleUrl: './main.component.sass',
})
export class MainComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private sessionManager: CdpSessionManagerService
  ) {
    this.sessionManager.eventEmitter.subscribe((e) =>
      this.processSessionManagerEvent(e)
    );
  }

  async ngAfterViewInit(): Promise<void> {
    await this.refreshIdentity_();
  }

  async processSessionManagerEvent(event: CdpEvent) {
    await this.refreshIdentity_();
  }

  private async refreshIdentity_() {
    const session: CdpSession | null =
      await this.sessionManager.getCurrentCdpSession();

    const isUserSignedIn: boolean = await this.sessionManager.isUserSignedIn();
    //console.log('Session:', session);
    //console.log('Is user signed in? ', isUserSignedIn);

    if (!session || !session.user || !isUserSignedIn) {
      // We're on the correct page for a user who hasn't signed in.
    } else {
      //console.log("Session user profile:", session?.user?.profile);
      //console.log("Session business profile:", session?.business?.profile);

      // If the user's profile is missing required items, those need to be filled out
      // now.
      //
      // Note: Typically, this should happen only the first time the user logs in.
      const doesUserProfileHaveAllRequiredItems: boolean | undefined =
        session?.user?.profile.areAllRequiredFieldsCompleteAndValid();

      const doesBusinessProfileHaveAllRequiredItems: boolean | undefined =
        session?.business?.profile.areAllRequiredFieldsCompleteAndValid();
      if (
        !doesUserProfileHaveAllRequiredItems ||
        !doesBusinessProfileHaveAllRequiredItems
      ) {
        this.router.navigate([CdpRoutes.InitialRegistration]);
      } else {
        // The user has finished registration, so go to the dashboard.
        // TODO Should the user's info include a preference about which page to start on?
        this.router.navigate([CdpRoutes.Dashboard]);
      }
    }
  }

  linkSignIn: string = CdpRoutes.SignIn;
  linkRegister: string = CdpRoutes.Register;
}
