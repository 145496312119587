
export enum CdpEmailSenderVerificationType {
  Domain = "Domain",
  Email = "Email"
}

export enum CdpEmailSenderVerificationStatus {
  Failed = "Failed",
  NotStarted = "NotStarted",
  Pending = "Pending",
  Success = "Success",
  TemporaryFailure = "TemporaryFailure"
}

export class CdpEmailSenderSender {
  type: CdpEmailSenderVerificationType = CdpEmailSenderVerificationType.Email;
  name: string = "";
  status: CdpEmailSenderVerificationStatus = CdpEmailSenderVerificationStatus.Failed;
  token: string = "";
}

export class CdpEmailSenderSenderSet {
  senders: CdpEmailSenderSender[] = [];

  has(type: CdpEmailSenderVerificationType, status: CdpEmailSenderVerificationStatus): boolean {
    for (const sender of this.senders) {
      if ((sender.type == type) && (sender.status == status)) {
        return true;
      }
    }

    return false;
  }

  hasVerified(type: CdpEmailSenderVerificationType): boolean {
    return this.has(type, CdpEmailSenderVerificationStatus.Success);
  }

  hasPending(type: CdpEmailSenderVerificationType): boolean {
    return this.has(type, CdpEmailSenderVerificationStatus.Pending);
  }

  hasFailed(type: CdpEmailSenderVerificationType): boolean {
    return this.has(type, CdpEmailSenderVerificationStatus.Failed);
  }

  hasVerifiedDomain(): boolean {
    return this.hasVerified(CdpEmailSenderVerificationType.Domain);
  }

  hasVerifiedEmail(): boolean {
    return this.hasVerified(CdpEmailSenderVerificationType.Email);
  }

  hasPendingDomain(): boolean {
    return this.hasPending(CdpEmailSenderVerificationType.Domain);
  }

  hasPendingEmail(): boolean {
    return this.hasPending(CdpEmailSenderVerificationType.Email);
  }

  hasFailedDomain(): boolean {
    return this.hasFailed(CdpEmailSenderVerificationType.Domain);
  }

  getDomain(): string {
    for (const sender of this.senders) {
      if (sender.type == CdpEmailSenderVerificationType.Domain) {
        return sender.name;
      }
    }

    return "";   
  }

  getDomainToken(): string {
    for (const sender of this.senders) {
      if (sender.type == CdpEmailSenderVerificationType.Domain) {
        return sender.token;
      }
    }

    return "";   
  }

  getDomainWithStatus(status: CdpEmailSenderVerificationStatus): string {
    for (const sender of this.senders) {
      if ((sender.type == CdpEmailSenderVerificationType.Domain) && (sender.status == status)) {
        return sender.name;
      }
    }

    return "";   
  }

  getVerifiedDomain(): string {
    return this.getDomainWithStatus(CdpEmailSenderVerificationStatus.Success);
  }

  getPendingDomain(): string {
    return this.getDomainWithStatus(CdpEmailSenderVerificationStatus.Pending);
  }

  getFailedDomain(): string {
    return this.getDomainWithStatus(CdpEmailSenderVerificationStatus.Failed);
  }

  getVerifiedEmailAddresses(): string[] {
    const verifiedEmailAddresses: string[] = [];

    for (const sender of this.senders) {
      if ((sender.type == CdpEmailSenderVerificationType.Email) && (sender.status == CdpEmailSenderVerificationStatus.Success)) {
        verifiedEmailAddresses.push(sender.name);
      }
    }

    return verifiedEmailAddresses;
  }
}