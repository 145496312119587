import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, from, concatMap } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import {
  CdpBackendCoreService,
  CdpContainsErrors,
} from '../cdp-backend-core.service';
import { CdpJwt } from 'src/app/core/cdp-auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { CdpLoggerService } from 'src/app/core/log/cdp-logger.service';
import {
  CdpFile,
  CdpFileInfo,
  CdpFileLocationType,
  CdpFileObjectSet,
} from 'src/app/core/files/cdp-file';
import { CdpProgressOperation, CdpProgressOperationStatus } from 'src/app/ui/progress/cdp-progress';

export enum CdpFileOperationType {
  ListFiles = 'ListFiles',
  Upload = 'Upload',
  Download = 'Download',
  Delete = 'Delete',
  Undelete = 'Undelete',
}

export class CdpFileOperation extends CdpProgressOperation {
  file: CdpFile = new CdpFile();
  fileOperationType: CdpFileOperationType;
  result: CdpFileOperationResult | null = null;

  get fileInfo(): CdpFileInfo {
    return this.file.fileInfo;
  }

  constructor(file: CdpFile, fileOperationType: CdpFileOperationType,
    message: string, status: CdpProgressOperationStatus = CdpProgressOperationStatus.InProgress) {
      super(fileOperationType, message);

    this.file = file;
    this.status = status;
    this.fileOperationType = fileOperationType;
  }
}

export class CdpFileOperationRequest {
  operationType: CdpFileOperationType = CdpFileOperationType.ListFiles;
  uploadAllowOverwrite: boolean = false;
  deletePermanent: boolean = false;
  fileInfo: CdpFileInfo | null = null;
  file: CdpFile | null = null;
  //formData: FormData | null = null;
}

export class CdpFileOperationRequestSet {
  requests: CdpFileOperationRequest[] = [];
}

export class CdpFileOperationResult {
  request: CdpFileOperationRequest = new CdpFileOperationRequest();
  succeeded: boolean = false;
  operationAttempted: boolean = false;
  failedFileExists: boolean = false;
  failureMessage: string = '';
  fileInfo: CdpFileInfo | null = null;

  isDataBinaryBase64: boolean = false;
  data: string = '';

  // The following is used only with listFiles().
  objectSets: CdpFileObjectSet[] = [];
}

export class CdpFileOperationResultSet extends CdpContainsErrors {
  results: CdpFileOperationResult[] = [];

  fileInfos: CdpFileInfo[] = [];

  static makeErrorResponse(e: any): CdpFileOperationResultSet {
    let response: CdpFileOperationResultSet = new CdpFileOperationResultSet();
    response.appendError(e);

    return response;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CdpBackendFileService {
  eventEmitter: EventEmitter<CdpFileOperationResultSet> =
    new EventEmitter<CdpFileOperationResultSet>();

  constructor(
    private backendCore: CdpBackendCoreService,
    private http: HttpClient,
    private logger: CdpLoggerService
  ) {}

  loadUrlFileAsText(url: string): Observable<string> {
    const observable: Observable<string> = this.http.get(url, {
      responseType: 'text',
    });

    return observable;
  }

  private executeOp_(
    opName: string,
    makeRequestSetFunc: any
  ): Observable<CdpFileOperationResultSet> {
    //console.log(`Start backend call: ${opName}`);

    const requestSet: CdpFileOperationRequestSet = makeRequestSetFunc();
    //console.log("Request set:", JSON.stringify(requestSet));
    const observable: Observable<CdpFileOperationResultSet> =
      this.executeFileFunc_(requestSet, opName);
    return observable;
  }

  private emit_(resultSet: CdpFileOperationResultSet) {
    //console.log("File service emit event:", resultSet);
    this.eventEmitter.emit(resultSet);
  }

  listFiles(): Observable<CdpFileOperationResultSet> {
    const makeRequestSetListFiles = this.makeRequestSetListFiles_.bind(this);
    const observable: Observable<CdpFileOperationResultSet> = this.executeOp_(
      'List files',
      makeRequestSetListFiles
    );
    observable.subscribe((resultSet) => this.emit_(resultSet));
    return observable;
  }

  downloadFiles(
    fileInfos: CdpFileInfo[]
  ): Observable<CdpFileOperationResultSet> {
    const observable: Observable<CdpFileOperationResultSet> = this.executeOp_(
      'Download file(s)',
      this.makeRequestSetDownload_.bind(this, fileInfos)
    );
    observable.subscribe((resultSet) => this.emit_(resultSet));
    return observable;
  }

  // Note: The caller must subscribe to the returned observable.
  uploadFiles(
    files: CdpFile[],
    allowOverwrite: boolean
  ): Observable<CdpFileOperationResultSet> {
    // TODO TODO Fix this!
    return this.uploadFile(files[0], allowOverwrite);
    /*
    const observable: Observable<CdpFileOperationResultSet> = this.executeOp_(
      'Upload file(s)',
      this.makeRequestSetUpload_.bind(this, files, allowOverwrite)
    );
    observable.subscribe((resultSet) => this.emit_(resultSet));
    return observable;
    */
  }

  uploadFile(
    file: CdpFile,
    allowOverwrite: boolean
  ): Observable<CdpFileOperationResultSet> {
    const formData: FormData = this.makeFormDataUpload_(file, allowOverwrite);
    const observable: Observable<CdpFileOperationResultSet> = this.uploadFileFunc_(
      formData,
      'Upload file(s)'
    );
    observable.subscribe((resultSet) => this.emit_(resultSet));
    return observable;
  }

  deleteFiles(
    fileInfos: CdpFileInfo[],
    deletePermanent: boolean
  ): Observable<CdpFileOperationResultSet> {
    const observable: Observable<CdpFileOperationResultSet> = this.executeOp_(
      'Delete file(s)',
      this.makeRequestSetDelete_.bind(this, fileInfos, deletePermanent)
    );
    observable.subscribe((resultSet) => this.emit_(resultSet));
    return observable;
  }

  undeleteFiles(
    fileInfos: CdpFileInfo[]
  ): Observable<CdpFileOperationResultSet> {
    const observable: Observable<CdpFileOperationResultSet> = this.executeOp_(
      'Undelete file(s)',
      this.makeRequestSetUndelete_.bind(this, fileInfos)
    );
    observable.subscribe((resultSet) => this.emit_(resultSet));
    return observable;
  }

  private makeRequestUpload_(
    file: CdpFile,
    allowOverwrite: boolean
  ): CdpFileOperationRequest {
    const request: CdpFileOperationRequest = new CdpFileOperationRequest();

    //console.log("Making upload request for file:", file);

    request.operationType = CdpFileOperationType.Upload;
    request.fileInfo = file.fileInfo;
    request.uploadAllowOverwrite = allowOverwrite;

    /*
    const formData: FormData = new FormData();

    if (!file.file) {
      console.log("No file data provided for upload");
      throw Error('No file data provided for upload');
    }

    if (file.fileInfo.filename.length == 0) {
      throw Error('No filename specified for upload');
    }

    if (file.fileInfo.locationType == CdpFileLocationType.Unspecified) {
      throw Error('No file location specified');
    }

    formData.append('file', file.file);

    console.log("Form data:", formData);

    request.formData = formData;
*/

    return request;
  }

  private makeRequestSetUpload_(
    files: CdpFile[],
    allowOverwrite: boolean
  ): CdpFileOperationRequestSet {
    const requestSet: CdpFileOperationRequestSet =
      new CdpFileOperationRequestSet();

    for (const file of files) {
      const request: CdpFileOperationRequest = this.makeRequestUpload_(
        file,
        allowOverwrite
      );
      requestSet.requests.push(request);
    }

    return requestSet;
  }

  private makeRequestDownload_(fileInfo: CdpFileInfo): CdpFileOperationRequest {
    const request: CdpFileOperationRequest = new CdpFileOperationRequest();

    request.operationType = CdpFileOperationType.Download;
    request.fileInfo = fileInfo;

    return request;
  }

  private makeRequestSetDownload_(
    fileInfos: CdpFileInfo[]
  ): CdpFileOperationRequestSet {
    const requestSet: CdpFileOperationRequestSet =
      new CdpFileOperationRequestSet();

    for (const fileInfo of fileInfos) {
      const request: CdpFileOperationRequest =
        this.makeRequestDownload_(fileInfo);
      requestSet.requests.push(request);
    }

    return requestSet;
  }

  private makeRequestDelete_(
    fileInfo: CdpFileInfo,
    deletePermanent: boolean
  ): CdpFileOperationRequest {
    const request: CdpFileOperationRequest = new CdpFileOperationRequest();

    request.operationType = CdpFileOperationType.Delete;
    request.fileInfo = fileInfo;
    request.deletePermanent = deletePermanent;

    return request;
  }

  private makeRequestSetDelete_(
    fileInfos: CdpFileInfo[],
    deletePermanent: boolean
  ): CdpFileOperationRequestSet {
    const requestSet: CdpFileOperationRequestSet =
      new CdpFileOperationRequestSet();

    for (const fileInfo of fileInfos) {
      const request: CdpFileOperationRequest = this.makeRequestDelete_(
        fileInfo,
        deletePermanent
      );
      requestSet.requests.push(request);
    }

    return requestSet;
  }

  private makeRequestUndelete_(fileInfo: CdpFileInfo): CdpFileOperationRequest {
    const request: CdpFileOperationRequest = new CdpFileOperationRequest();

    request.operationType = CdpFileOperationType.Undelete;
    request.fileInfo = fileInfo;

    return request;
  }

  private makeRequestSetUndelete_(
    fileInfos: CdpFileInfo[]
  ): CdpFileOperationRequestSet {
    const requestSet: CdpFileOperationRequestSet =
      new CdpFileOperationRequestSet();

    for (const fileInfo of fileInfos) {
      const request: CdpFileOperationRequest =
        this.makeRequestUndelete_(fileInfo);
      requestSet.requests.push(request);
    }

    return requestSet;
  }

  private makeRequestListFiles_(): CdpFileOperationRequest {
    const request: CdpFileOperationRequest = new CdpFileOperationRequest();

    request.operationType = CdpFileOperationType.ListFiles;

    return request;
  }

  private makeRequestSetListFiles_(): CdpFileOperationRequestSet {
    const requestSet: CdpFileOperationRequestSet =
      new CdpFileOperationRequestSet();

      //console.log("In makeRequestSetListFiles_");

    const request: CdpFileOperationRequest = this.makeRequestListFiles_();
    requestSet.requests.push(request);

    return requestSet;
  }

  private makeFormDataUpload_(file: CdpFile, allowOverwrite: boolean): FormData {
    const formData: FormData = new FormData();

    if (!file.file) {
      throw Error('No file data provided for upload');
    }

    if (file.fileInfo.filename.length == 0) {
      throw Error('No filename specified for upload');
    }

    if (file.fileInfo.locationType == CdpFileLocationType.Unspecified) {
      throw Error('No file location specified');
    }

    // The form will contain one field for the request and then the
    // file.

    const request: CdpFileOperationRequest = this.makeRequestUpload_(
      file,
      allowOverwrite
    );

    formData.append('request', JSON.stringify(request));
    formData.append('file', file.file);

    return formData;
  }

  
  private executeFileFuncWithJwt_(
    endpoint: string,
    requestSet: CdpFileOperationRequestSet,
    funcDescription: string,
    makeServerUrlFunc: any,
    httpClient: HttpClient,
    handleErrorFunc: any,
    jwt: CdpJwt
  ): Observable<CdpFileOperationResultSet> {
    try {
      const url = makeServerUrlFunc(endpoint, jwt);
      //console.log("Invoke URL:", url);

      let observable = httpClient
        .post<CdpFileOperationRequestSet>(url, requestSet)
        .pipe(catchError(handleErrorFunc(funcDescription)));

      return observable;
    } catch (error) {
      //console.log(`Server error executing ${funcDescription}: ${error}`);

      return of(CdpFileOperationResultSet.makeErrorResponse(error));
    }
  }

  private uploadFileFuncWithJwt_(
    endpoint: string,
    formData: FormData,
    funcDescription: string,
    makeServerUrlFunc: any,
    httpClient: HttpClient,
    handleErrorFunc: any,
    jwt: CdpJwt
  ): Observable<CdpFileOperationResultSet> {
    try {
      const url = makeServerUrlFunc(endpoint, jwt);
      //console.log("Invoke URL:", url);

      let observable = httpClient
        .post<FormData>(url, formData)
        .pipe(catchError(handleErrorFunc(funcDescription)));

      return observable;
    } catch (error) {
      console.log(`Server error executing ${funcDescription}: ${error}`);

      return of(CdpFileOperationResultSet.makeErrorResponse(error));
    }
  }

  private executeFileFunc_(
    requestSet: CdpFileOperationRequestSet,
    funcDescription: string
  ): Observable<CdpFileOperationResultSet> {
    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    //console.log('executeFileFunc_:', requestSet);

    // We need to store references to member functions and pass them explicitly because
    // "this" will be something different in a callback context.
    const endpoint: string = 'file/exec_ops';
    const handleError = this.handleError<CdpFileOperationResultSet>;

    const func = this.executeFileFuncWithJwt_.bind(
      this,
      endpoint,
      requestSet,
      funcDescription,
      this.backendCore.makeServerUrlWithJwt,
      this.http,
      this.handleError<CdpFileOperationResultSet>
    );

    try {
    return jwtObservable.pipe(concatMap((jwt) => func(jwt)),
    catchError(handleError(funcDescription)));
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  }

  private uploadFileFunc_(
    formData: FormData,
    funcDescription: string
  ): Observable<CdpFileOperationResultSet> {
    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    //console.log('uploadFileFunc_:');

    // We need to store references to member functions and pass them explicitly because
    // "this" will be something different in a callback context.
    const endpoint: string = 'file/exec_ops';
    const handleError = this.handleError<CdpFileOperationResultSet>;

    const func = this.uploadFileFuncWithJwt_.bind(
      this,
      endpoint,
      formData,
      funcDescription,
      this.backendCore.makeServerUrlWithJwt,
      this.http,
      this.handleError<CdpFileOperationResultSet>
    );

    try {
    return jwtObservable.pipe(concatMap((jwt) => func(jwt)),
    catchError(handleError(funcDescription)));
    } catch (error) {
      console.log("Error:", error);
      throw error;
    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      console.log('Result:', JSON.stringify(result));

      // TODO: better job of transforming error for user consumption
      this.logger.log(`${operation} failed: ${error.message}`);

      if (result && result instanceof CdpContainsErrors) {
        result.appendError(error);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
