import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  CdpEmailSenderVerificationRequest,
  CdpEmailSenderVerificationRequestType,
  CdpEmailSenderVerificationResponse,
} from 'src/app/backend/cdp-email-send-backend.service';
import { CdpEmailAddress } from 'src/app/core/cdp-email-address';
import { CdpEmailSenderSenderSet } from 'src/app/core/email/cdp-email-sender';
import {
  CdpProgressOperationSet,
  CdpProgressOperationTag,
} from 'src/app/ui/progress/cdp-progress';

import { CdpProgressSpinnerComponent } from 'src/app/ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';
import {
  CdpSendEmailService,
  CdpSendEmailServiceEvent,
} from '../../send/cdp-send-email.service';
import { CdpDomain } from 'src/app/core/cdp-domain';
import { NavigationEnd, Router } from '@angular/router';

export enum CdpEmailVerificationOperationType {
  CheckVerificationStatus = 'CheckVerificationStatus',
  SendVerificationEmail = 'SendVerificationEmail',
  StartDomainVerification = 'StartDomainVerification',
}

@Component({
  selector: 'app-cdp-email-domain-verification',
  standalone: true,
  imports: [FormsModule, CdpProgressSpinnerComponent],
  templateUrl: './cdp-email-domain-verification.component.html',
  styleUrl: './cdp-email-domain-verification.component.sass',
})
export class CdpEmailDomainVerificationComponent implements OnInit {
  @ViewChild('domain_verification_button')
  domainVerificationButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('email_verification_button')
  emailVerificationButton!: ElementRef<HTMLButtonElement>;
  @Output('cancel') cancel: EventEmitter<string> = new EventEmitter<string>();

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();

  operationResultMessage: string = '';

  email: CdpEmailAddress = new CdpEmailAddress();
  domain: CdpDomain = new CdpDomain();

  private thisUrl_ = '';

  constructor(private router: Router, private verifier: CdpSendEmailService) {}

  ngOnInit(): void {
    this.thisUrl_ = this.router.url;

    const onReload = this.onReload.bind(this);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //console.log('Navend event:', JSON.stringify(event));
        if (event.url == this.thisUrl_) {
          // route is restored
          //console.log('Navend event match:', this.thisUrl_);
          onReload();
        }
      }
    });

    this.verifier.eventEmitter.subscribe((e: CdpSendEmailServiceEvent) =>
      this.processEmailSenderVerificationEvent_(e)
    );

    this.onReload();
  }

  onReload() {
    this.verifier.checkVerificationStatus();
  }

  get senderSet(): CdpEmailSenderSenderSet {
    return this.verifier.getCurrentSenderSet();
  }

  onClickCancel() {
    //console.log('Clicked cancel on domain verification');
    this.cancel.emit('cancel');
  }

  onEmailChanged() {
    this.emailVerificationButton.nativeElement.disabled = !this.email.isValid();
  }

  onDomainChanged() {
    this.domainVerificationButton.nativeElement.disabled =
      !this.domain.isValid();

      /*
    console.log(
      `Domain: ${this.domain.domain} Valid: ${this.domain.isValid()}`
    );
    */
  }

  private processEmailSenderVerificationEvent_(e: CdpSendEmailServiceEvent) {
    if (this.domain.domain == '') {
      this.domain.domain = this.senderSet.getDomain();
      this.onDomainChanged();
    }
  }

  startedVerifyDomain_(
    response: CdpEmailSenderVerificationResponse,
    operationTag: CdpProgressOperationTag
  ) {
    const succeeded: boolean = response ? response.succeeded : false;
    const message: string = succeeded
      ? 'Started domain verification'
      : 'Could not start the domain verification process.';

    try {
      //console.log('Domain verification response:', JSON.stringify(response));

      if (succeeded) {
        this.senderSet.senders = response.senders;
        this.operationResultMessage =
          'In order to complete domain verification, please see the instructions below.';
      } else {
        this.senderSet.senders = [];
        this.operationResultMessage =
          'Something went wrong and we were not able to start the domain verification process. Please email us at support@inboxease.com so we can help you.';
      }
    } finally {
      // Note: Wait a short time before turning off the progress message so that
      // the user has a chance to read it.
      this.operationSet.updateOperationFinal(
        operationTag,
        succeeded,
        message,
        500
      );
    }
  }

  sentVerificationEmail_(
    response: CdpEmailSenderVerificationResponse,
    operationTag: CdpProgressOperationTag
  ) {
    const succeeded: boolean = response ? response.succeeded : false;

    const message: string = succeeded
      ? 'Sent verification email'
      : 'Could not start the verification process.';

    try {
      //console.log('Send verification response:', JSON.stringify(response));

      if (succeeded) {
        this.senderSet.senders = response.senders;
        this.operationResultMessage =
          'We have sent you a verification email.  Please click the link in that email to verify your email address.';
      } else {
        this.senderSet.senders = [];
        this.operationResultMessage =
          'Something went wrong. Please email us at support@inboxease.com so we can help you.';
      }
    } finally {
      // Note: Wait a short time before turning off the progress message so that
      // the user has a chance to read it.
      this.operationSet.updateOperationFinal(
        operationTag,
        succeeded,
        message,
        500
      );
    }
  }

  onClickSendVerificationEmail() {
    const emailParts: string[] = this.email.email.split('@');
    if (emailParts.length != 2) {
      console.log('Invalid email address:', this.email.email);
      return;
    }

    const operationTag: CdpProgressOperationTag =
      this.operationSet.addOperation(
        CdpEmailVerificationOperationType.SendVerificationEmail,
        'Starting verification...'
      );

    const observable: Observable<CdpEmailSenderVerificationResponse> =
      this.verifier.verifyEmailStart([this.email.email]);

    observable.subscribe((response) =>
      this.sentVerificationEmail_(response, operationTag)
    );
  }

  onClickVerifyDomain() {
    if (!this.domain.isValid()) {
      console.log('Invalid domain:', this.domain.domain);
      return;
    }

    const operationTag: CdpProgressOperationTag =
      this.operationSet.addOperation(
        CdpEmailVerificationOperationType.StartDomainVerification,
        'Starting domain verification...'
      );

    const observable: Observable<CdpEmailSenderVerificationResponse> =
      this.verifier.verifyDomainStart(this.domain.domain);

    observable.subscribe((response) =>
      this.startedVerifyDomain_(response, operationTag)
    );
  }

  refreshedSenders_(
    response: CdpEmailSenderVerificationResponse,
    operationTag: CdpProgressOperationTag
  ) {
    const succeeded: boolean = response ? response.succeeded : false;
    const message: string = succeeded
      ? 'Finished checking the verification status.'
      : 'Could not check the verification status.';

    try {
      //console.log('Send verification response:', JSON.stringify(response));

      if (succeeded) {
        this.senderSet.senders = response.senders;
      } else {
        this.senderSet.senders = [];
      }
    } finally {
      // Note: Wait a short time before turning off the progress message so that
      // the user has a chance to read it.
      this.operationSet.updateOperationFinal(
        operationTag,
        succeeded,
        message,
        500
      );
    }
  }

  onClickRefreshSenders() {
    this.refreshSenders();
  }

  refreshSenders() {
    const request: CdpEmailSenderVerificationRequest =
      new CdpEmailSenderVerificationRequest();
    request.requestType = CdpEmailSenderVerificationRequestType.CheckStatus;

    const operationTag: CdpProgressOperationTag =
      this.operationSet.addOperation(
        CdpEmailVerificationOperationType.CheckVerificationStatus,
        'Checking verification status...'
      );

    const observable: Observable<CdpEmailSenderVerificationResponse> =
      this.verifier.checkVerificationStatus();

    observable.subscribe((response) =>
      this.refreshedSenders_(response, operationTag)
    );
  }
}
