import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdpImage } from 'src/app/core/images/cdp-image';

@Component({
  selector: 'app-cdp-image-tile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cdp-image-tile.component.html',
  styleUrl: './cdp-image-tile.component.sass',
})
export class CdpImageTileComponent implements OnInit, AfterViewInit {
  @ViewChild('tile_image') tileImage!: ElementRef<HTMLImageElement>;
  @Input() isImageVisible: boolean = true;

  @Input() isOperationInProgress: boolean = false;
  
  @Input() showDeleteButton = true;

  @Output() beingDeletedEvent: EventEmitter<CdpImage> =
    new EventEmitter<CdpImage>();

  maxImageDisplayWidth: number = 300;

  private image_: CdpImage = new CdpImage();

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const tileImageNative: HTMLImageElement = this.tileImage.nativeElement;
    tileImageNative.style.width = '100px';
    tileImageNative.style.borderStyle = 'solid';
    tileImageNative.style.margin = '10px';

    this.imageChanged_();
  }

  private imageChanged_() {
    if (this.tileImage) {
      const tileImageNative: HTMLImageElement = this.tileImage.nativeElement;

      if (tileImageNative) {
        // In order to prevent the browser from caching an old image if we're
        // e.g. reusing a URL for new contents, we add a bogus timestamp
        // to the URL.
        const url: string = this.image_.url;
        tileImageNative.src = CdpImage.makeUncachedUrl(url);
        tileImageNative.title = url;
      }
    }
  }

  @Input() set image(image: CdpImage) {
    this.image_ = image;

    //console.log('Tile setting image: URL=', image.url);
    this.imageChanged_();
  }

  setImageFromUrl(url: string) {
    const image: CdpImage = new CdpImage();
    image.url = url;

    //console.log('Tile setting image from URL:', image);

    this.image = image; // call property setter
  }

  onClickDeleteImage() {
    const image: CdpImage = this.image_;

    if (image.fileInfo && image.fileInfo.couldBeDeleted()) {
      this.beingDeletedEvent.emit(image);
    } else {
      console.log('Image undeletable:', image);
    }
  }
}
