<div class="container rounded bg-white mt-3">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h4 class="text-right">User Profile</h4>
  </div>

  <div class="card">
    <div class="card-body">

      <form class="form-inline">
        <div class="form-group row">
          <div class="col col-3">
            <label class="col-form-label">First name</label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder=""
              [(ngModel)]="profile.name.givenName"
              name="givenName"
              required
            />
          </div>
        </div>

        <div class="vertical-break"></div>

        <div class="form-group row">
          <div class="col col-3">
            <label class="col-form-label">Last name</label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder=""
              [(ngModel)]="profile.name.familyName"
              name="familyName"
              required
            />
          </div>
        </div>

        <div class="vertical-break"></div>

        
        <div class="form-group row">
          <div class="col col-3">
            <label class="col-form-label">Email</label>
          </div>
          <div class="col">
            <input
              type="email"
              class="form-control"
              placeholder="me&#64;mycompany.com"
              [(ngModel)]="profile.email.email"
              name="email"
              readonly
            />
          </div>
        </div>

        <div class="vertical-break"></div>

        <div class="form-group row">
          <div class="col col-3">
            <label class="col-form-label">Phone Number</label>
          </div>
          <div class="col">
            <input
              type="tel"
              class="form-control"
              placeholder=""
              [(ngModel)]="profile.phoneNumber.phoneNumber"
              name="phoneNumber"
            />
          </div>
        </div>

      </form>
    </div>
  </div>
</div>
