import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
  IDropdownSettings,
  NgMultiSelectDropDownModule,
} from 'ng-multiselect-dropdown';

import { CdpSelect, CdpSelectOption } from './cdp-select';

@Component({
  selector: 'app-cdp-select',
  standalone: true,
  imports: [CommonModule, FormsModule, NgMultiSelectDropDownModule],
  templateUrl: './cdp-select.component.html',
  styleUrl: './cdp-select.component.css',
})
export class CdpSelectComponent implements AfterViewInit {

  options: CdpSelectOption[] = [];

  dropdownPlaceholder: string = 'Select';
  dropdownList: any[] = [];
  private selectedItems_: any[] = [];
  dropdownSettings: IDropdownSettings = {};

  private select_: CdpSelect = new CdpSelect();

  @Output() selectedItemsEvent = new EventEmitter<any>();


  ngAfterViewInit() {
    this.dropdownSettings = {
      singleSelection: false,
      showSelectedItemsAtTop: false,
      selectAllText: 'Select all',
      unSelectAllText: 'Unselect all',
      itemsShowLimit: 1,
      //idField: 'item_id',
      //textField: 'item_text',
      //allowSearchFilter: true,
    };
  }

  @Input()
  getSelectedItems(): any[] {
    return this.selectedItems_;
  }

  setSelectedItems(event: any): void {
    //console.log("setSelectedItems this=", this);

     //console.log("setSelectedItems received event:", event);

    const emitEvent = {
      label: this.select_.rawLabel,
      selectedItems: event
    };

    //console.log("setSelectedItems emitting event:", emitEvent);
     //console.log("Emit event:", emitEvent);
    this.selectedItemsEvent.emit(emitEvent);

    this.selectedItems_ = event;
  }

  onItemSelect(item: any) {

     //console.log("Select:", item);
  }
  onSelectAll(items: any) {
     //console.log("Select all:", items);
  }

  @Input()
  set select(select: CdpSelect) {
     //console.log("Calling property set select:", select);

    const dl: any[] = []

    for (const option of select.options) {
      dl.push(option.value);
    }

    dl.sort();
    
    if (select.initialSelectedValues.length > 0) {
      this.selectedItems_ = select.initialSelectedValues;
    }

    this.dropdownPlaceholder = select.label;
    this.dropdownList = dl;

    this.select_ = select;
  }
}
