import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CdpCustomer, CdpCustomerInfo } from '../cdp-customer';
import { CdpDropzoneComponent } from 'src/app/ui/cdp-drop-zone/cdp-drop-zone.component';
import { CdpFileUtilties } from 'src/app/core/files/cdp-file-utilities';
import { CdpFileHelper, CdpFileType } from 'src/app/core/files/cdp-file';
import { TabulatorTableComponent } from 'src/app/ui/tabulator-table/tabulator-table.component';
import { CdpCustomerListSectionComponent } from '../cdp-customer-list-section/cdp-customer-list-section.component';
import { FormsModule } from '@angular/forms';
import { CdpProgressSpinnerComponent } from 'src/app/ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';
import {
  CdpCustomerDatabase,
  CdpCustomerDatabaseList,
  CdpCustomerDatabaseOperationType,
  CdpCustomerDatabaseRecord,
  CdpCustomerDatabaseUpdateMode,
  CdpCustomerDatabaseUpdateResult,
  CdpCustomerDatabaseUpdateResultSet,
  CdpCustomerDatabaseUpdateResultType,
} from '../cdp-customer-database';
import {
  CdpCustomerDatabaseService,
  CdpCustomerDatabaseServiceEvent,
} from '../cdp-customer-database.service';
import {
  CdpProgressOperationSet,
  CdpProgressOperationStatus,
  CdpProgressOperationTag,
} from 'src/app/ui/progress/cdp-progress';
import {
  CdpPermissionService,
  CdpPermissionServiceEvent,
  CdpPermissionSet,
} from 'src/app/core/permissions/cdp-permission.service';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpUser } from 'src/app/core/cdp-user';
import { CdpEmailAddress } from 'src/app/core/cdp-email-address';

export enum CdpCustomerDatabasePermission {
  ListCreate = 'cd:list:create',
  ListDelete = 'cd:list:delete',
  ListModify = 'cd:list:modify',
  ListRename = 'cd:list:rename',
}

@Component({
  selector: 'app-cdp-customer-database',
  standalone: true,
  imports: [
    CdpCustomerListSectionComponent,
    CdpDropzoneComponent,
    CdpProgressSpinnerComponent,
    TabulatorTableComponent,
    FormsModule,
  ],
  templateUrl: './cdp-customer-database.component.html',
  styleUrl: './cdp-customer-database.component.sass',
})
export class CdpCustomerDatabaseComponent implements AfterViewInit, OnInit {
  @ViewChild('dropzone') dropzone!: CdpDropzoneComponent;
  dropzoneText: string = 'Drag file or click';
  dropzoneAllowMultipleFiles: boolean = false;

  dropzoneAllowedFileTypes: CdpFileType[] = [CdpFileType.Csv, CdpFileType.Txt];

  databaseUpdateMode: CdpCustomerDatabaseUpdateMode =
    CdpCustomerDatabaseUpdateMode.MergeIfNoConflict;

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();
  private operationFromEventIdMap_: Map<string, CdpProgressOperationTag> =
    new Map<string, CdpProgressOperationTag>();
  get isOperationInProgress(): boolean {
    return this.operationSet.isOperationInProgress;
  }

  private addOperation_(
    operationType: CdpCustomerDatabaseOperationType,
    message: string,
    eventId: string
  ) {
    const operationTag: CdpProgressOperationTag =
      this.operationSet.addOperation(operationType, message);
    if (eventId.length > 0) {
      this.operationFromEventIdMap_.set(eventId, operationTag);
    }
  }

  private removeOperationUsingEventId_(eventId: string) {
    const operationTag: CdpProgressOperationTag | undefined =
      this.operationFromEventIdMap_.get(eventId);

    if (operationTag) {
      this.operationSet.removeOperation(operationTag);
    }
  }

  tableData: any[] = [];
  columnNames: any[] = [];
  tableHeight: string = '311px';

  specifyingNewListName: boolean = false;
  newListName: string = '';

  elemCustomersTable = document.createElement('div');

  private currentDatabase_: CdpCustomerDatabase | null = null;
  listNames: string[] = [];

  currentListName: string = '';
  customerRecordsInCurrentList: CdpCustomerDatabaseRecord[] = [];

  constructor(
    private databaseService: CdpCustomerDatabaseService,
    private permissionService: CdpPermissionService,
    private sessionManager: CdpSessionManagerService
  ) {
    this.refreshPermissions_();

    this.databaseService.eventEmitter.subscribe((event) =>
      this.processDatabaseServiceEvent_(event)
    );

    this.permissionService.eventEmitter.subscribe((event) =>
      this.processPermissionServiceEvent_(event)
    );
  }

  getCurrentDatabase(): CdpCustomerDatabase | null {
    return this.currentDatabase_;
  }

  private async createTestList_(testListName: string) {
    if (!this.currentDatabase_) {
      return;
    }

    // The test list consists of just the user's own email.
    // First, we need to create a record in the database for the user if one
    // isn't already present.
    const user: CdpUser | null = await this.sessionManager.getCurrentUser();

    if (!user) {
      return;
    }

    const email: CdpEmailAddress = user.profile.email;
    if (!email.isValid()) {
      return;
    }

    let customer: CdpCustomer | null = this.currentDatabase_.getCustomerUsingEmail(email.email);

    if (!customer) {
      // We need to add the user to the database.
      const info: CdpCustomerInfo = new CdpCustomerInfo();
      info.email = email;
      info.name = user.profile.name;

      const result: CdpCustomerDatabaseUpdateResult =
        this.currentDatabase_.addOrUpdateCustomerInfo(info, CdpCustomerDatabaseUpdateMode.Replace);

      console.log("Added user for test list:", result);

      if (result.resultType == CdpCustomerDatabaseUpdateResultType.Rejected) {
        // This really shouldn't happen.
        return;
      }
    }

    // Now that the user is definitely in the database, we add the test list
    // comprising just the user.
    const shortId: number = this.currentDatabase_.getShortIdUsingEmail(email.email);
    if (shortId < 0) {
      // This shouldn't happen.
      return;
    }

    this.currentDatabase_.addNewListUsingShortIds(testListName, [shortId]);
  }

  private async maybeCreateTestList_() {
    if (this.currentDatabase_) {
      const databaseLists: CdpCustomerDatabaseList[] = this.currentDatabase_.databaseLists;

      // TODO At some point, the test list should probably be editable, but
      //      possibly not renamable.
      const testListName: string = 'Test list';
      let hasTestList: boolean = false;
      for (const databaseList of databaseLists) {
        if (databaseList.doesNameMatch(testListName)) {
          hasTestList = true;
          break;
        }
      }

      if (!hasTestList) {
        await this.createTestList_(testListName);
      }
    }
  }

  private async refreshDatabaseAndLists_() {
    this.currentDatabase_ = this.databaseService.getCurrentDatabase();

    await this.maybeCreateTestList_();

    let updatedListName: string = '';
    let updatedCustomerRecords: CdpCustomerDatabaseRecord[] = [];
    let updatedListNames: string[] = [];

    if (this.currentDatabase_) {
      updatedListNames = this.currentDatabase_.getListNames();

      const databaseList: CdpCustomerDatabaseList | null =
        this.currentDatabase_.getListUsingName(this.currentListName);

      /*
       console.log(
          `DB list: name=${this.currentListName}: ${JSON.stringify(databaseList)}`
        );
        */

      if (!databaseList) {
        // There is no longer a list with the current list name, so use the name of
        // the first list, if there are any lists.
        if (updatedListNames.length > 0) {
          updatedListName = updatedListNames[0];
          //console.log('Setting list name to:', updatedListName);
        }
      } else {
        // There is still a list with the current list name.
        //console.log('Keeping list name:', this.currentListName);

        updatedListName = this.currentListName;
      }

      const customerRecordsInList: CdpCustomerDatabaseRecord[] | null =
        this.currentDatabase_.getCustomerRecordsInList(updatedListName);
      if (customerRecordsInList) {
        updatedCustomerRecords = customerRecordsInList;
      }
    }

    //console.log('After DB refresh:');
    //console.log('  List names:', updatedListNames);
    //console.log('  Customers:', updatedCustomerRecords);
    //console.log('  Current list:', updatedListName);

    this.listNames = updatedListNames;
    this.customerRecordsInCurrentList = updatedCustomerRecords;
    this.currentListName = updatedListName;

    this.currentDatabaseListChanged_();
  }

  async ngOnInit() {
    await this.refreshDatabaseAndLists_();
  }

  ngAfterViewInit(): void {
    if (this.dropzone) {
      this.dropzone.fileDropEventEmitter.subscribe((file) => {
        this.processFileDrop(file);
      });
    } else {
      //console.log('No drop zone!');
    }
  }

  listCreateIsAllowed: boolean = true;
  listDeleteIsAllowed: boolean = true;
  listModifyIsAllowed: boolean = true;
  listRenameIsAllowed: boolean = true;

  private setPermissions_(permissionSet: CdpPermissionSet) {
    this.listCreateIsAllowed = permissionSet.isAllowed(
      CdpCustomerDatabasePermission.ListCreate
    );

    this.listDeleteIsAllowed = permissionSet.isAllowed(
      CdpCustomerDatabasePermission.ListDelete
    );

    this.listModifyIsAllowed = permissionSet.isAllowed(
      CdpCustomerDatabasePermission.ListModify
    );

    this.listRenameIsAllowed = permissionSet.isAllowed(
      CdpCustomerDatabasePermission.ListRename
    );
  }

  private refreshPermissions_() {
    const permissionSet: CdpPermissionSet = this.permissionService.getCurrentUserPermissionSet();
  
    this.setPermissions_(permissionSet);
  }

  private processPermissionServiceEvent_(event: CdpPermissionServiceEvent) {
      this.setPermissions_(event.currentUserPermissionSet);
  }

  private getOperationTypeStartingMessage_(
    operationType: CdpCustomerDatabaseOperationType
  ): string {
    switch (operationType) {
      case CdpCustomerDatabaseOperationType.CreateDatabase:
        return 'Creating new database';

      case CdpCustomerDatabaseOperationType.CreateList:
        return 'Adding new list';
      case CdpCustomerDatabaseOperationType.DeleteDatabaseAndLists:
        return 'Deleting database';

      case CdpCustomerDatabaseOperationType.DeleteList:
        return 'Deleting list';

      case CdpCustomerDatabaseOperationType.LoadDatabaseAndLists:
        return 'Loading database and lists';

      case CdpCustomerDatabaseOperationType.SaveDatabaseAndLists:
        return 'Saving database and lists';

      default:
        return '';
    }
  }

  private async processDatabaseServiceEvent_(event: CdpCustomerDatabaseServiceEvent) {
    const operationType: CdpCustomerDatabaseOperationType = event.operationType;
    const operationStatus: CdpProgressOperationStatus = event.operationStatus;

    /*
    console.log(
      'processDatabaseServiceEvent_ Received event:',
      JSON.stringify(event)
    );
    */

    // An operation that is starting gets added to the set of operations in progress,
    // primarily so that we can prevent the user from starting any other operations.
    switch (operationStatus) {
      case CdpProgressOperationStatus.NotStarted:
        // This would be strange, but just ignore it.
        break;

      case CdpProgressOperationStatus.InProgress:
        this.addOperation_(
          operationType,
          this.getOperationTypeStartingMessage_(operationType),
          event.eventId
        );
        break;

      case CdpProgressOperationStatus.CompletedFailed:
      case CdpProgressOperationStatus.CompletedSucceeded:
        // If the operation type is one that may have changed anything, refresh the
        // database, and then remove the indicator that the operation is in progress.
        // The only operations that don't change anything are saving operations.

        if (
          operationType != CdpCustomerDatabaseOperationType.None &&
          operationType != CdpCustomerDatabaseOperationType.SaveDatabaseAndLists
        ) {
          await this.refreshDatabaseAndLists_();
        }

        this.removeOperationUsingEventId_(event.eventId);

        break;

      case CdpProgressOperationStatus.StartAndComplete:
        // We don't need to remove the operation, because it never would have been
        // added.  We do need to refresh the data.
        await this.refreshDatabaseAndLists_();
        break;

      default:
        console.log('Unknown event: ', event);
        throw Error(`Unknown event: ${event}`);

        break;
    }
  }

  private currentDatabaseListChanged_(): any {
    // In case the current list changed or if its contents might have changed,
    // refresh the display.
    this.fillTableFromCurrentCustomerList_();
  }

  onClickNewList() {
    if (this.isOperationInProgress || this.specifyingNewListName || !this.listCreateIsAllowed) {
      // TODO This shouldn't happen if all the event are behaving properly...
      return;
    }

    // Setting this value here will make the UI
    // for adding a new list visible. Note that we don't use the
    // "operation in progress" mechanism here, since that mechanism
    // is primarily for use for cases where we need a service to do
    // the work.
    this.specifyingNewListName = true;
  }

  endAddNewList() {
    this.specifyingNewListName = false;
    this.newListName = '';
  }

  onClickCancelNewList() {
    this.endAddNewList();
  }

  private deleteList_(name: string, operationTag: CdpProgressOperationTag) {
    // TODO Decide on event vs observable approach.
    try {
      this.databaseService.deleteListFromCurrentDatabase(name);
    } finally {
      // TODO TODO We don't keep track of an observable here, so we'll just
      // hope the delete succeeded.
      this.operationSet.updateOperationFinal(
        operationTag,
        true,
        'Delete list completed.'
      );
    }
  }

  private deleteListFailed_(
    name: string,
    operationTag: CdpProgressOperationTag
  ) {
    this.operationSet.updateOperationFinal(
      operationTag,
      false,
      `Failed to delete list "${this.currentListName}"`
    );
  }

  onClickDeleteList() {
    if (this.isOperationInProgress || !this.listDeleteIsAllowed) {
      // TODO This shouldn't happen if the UI is behaving properly...
      return;
    }

    const operationTag: CdpProgressOperationTag =
      this.operationSet.addOperation(
        CdpCustomerDatabaseOperationType.DeleteList,
        `Deleting list "${this.currentListName}"...`
      );

    const name: string = this.currentListName;
    try {
      this.deleteList_(name, operationTag);
    } catch {
      this.deleteListFailed_(name, operationTag);
    }
  }

  selectListUsingName(name: string) {
    if (name != this.currentListName) {
      this.currentListName = name;
      this.onCurrentListNameChanged();
    }
  }

  onNewListChanged() {
    const newListName: string = this.newListName;

    // TODO Should probably use progress here.
    this.endAddNewList();

    //console.log('In onNewListChanged: ', newListName);

    const result: [string, boolean] = this.databaseService.addNewList(
      newListName,
      []
    );

    //console.log('In onNewListChanged result= ', result);

    const finalListName: string = result[0];
    this.selectListUsingName(finalListName);
  }

  async onCurrentListNameChanged() {
    await this.refreshDatabaseAndLists_();
    this.fillTableFromCurrentCustomerList_();
  }

  private fillTableFromCurrentCustomerList_() {
    this.makeTable(this.customerRecordsInCurrentList, this.listModifyIsAllowed);
  }

  private async loadCsvFile_(file: File): Promise<CdpCustomerInfo[]> {
    const customerInfos: CdpCustomerInfo[] = [];

    const text: string = await CdpFileHelper.readFileAsText(file);

    const lines: string[] = text.split('\n');

    for (const line of lines) {
      const trimmedLine = line.trim();
      if (trimmedLine.length > 0) {
        let fields: string[] = line.split(',');

        const customerInfo: CdpCustomerInfo = new CdpCustomerInfo();

        fields = fields.map((f) => f.trim());

        if (fields.length >= 1) {
          if (fields[0].length == 0) {
            // The email address is required.
            console.log('Ignoring CSV line:', trimmedLine);
            continue;
          }
          customerInfo.email.email = fields[0];
        }

        if (fields.length >= 2) {
          customerInfo.name.givenName = fields[1];
        }

        if (fields.length >= 3) {
          customerInfo.name.familyName = fields[2];
        }

        customerInfos.push(customerInfo);
      }
    }

    return customerInfos;
  }

  get hasCurrentCustomerList(): boolean {
    return this.currentListName.length > 0;
  }

  async processFileDrop(file: File) {
    //console.log('Process file drop:', file);

    if (!this.hasCurrentCustomerList || !this.listModifyIsAllowed) {
      // The drop zone should have been hidden, but just ignore anyway.
      return;
    }

    let ext: string = CdpFileUtilties.getFilenameExtension(file.name);
    let isCsvFile: boolean =
      ext.toLowerCase() == 'csv' || ext.toLowerCase() == 'txt';

    if (isCsvFile) {
      const loadedCustomerInfos: CdpCustomerInfo[] = await this.loadCsvFile_(
        file
      );

      // Update the current database using the customer info in the CSV file.
      // If there are any actual changes, then an event will be emitted by the database
      // service and our table display may update.
      const updateResultSet: CdpCustomerDatabaseUpdateResultSet =
        this.databaseService.updateCurrentDatabase(
          loadedCustomerInfos,
          this.databaseUpdateMode
        );

      // TODO Examine the result set and report conflicts.

      // Now, change the current list to contain the customers that were just loaded.
      this.databaseService.addOrReplaceList(
        this.currentListName,
        loadedCustomerInfos
      );
    }
  }

  /*
  makeTable3() {
    this.tableData = [
      { id: 1, name: "Oli Bob", age: "12", col: "red", dob: "" },
      { id: 2, name: "Mary May", age: "1", col: "blue", dob: "14/05/1982" },
      {
        id: 3,
        name: "Christine Lobowski",
        age: "42",
        col: "green",
        dob: "22/05/1982",
      },
      {
        id: 4,
        name: "Brendon Philips",
        age: "125",
        col: "orange",
        dob: "01/08/1980",
      },
      {
        id: 5,
        name: "Margret Marmajuke",
        age: "16",
        col: "yellow",
        dob: "31/01/1999",
      },
    ];
    this.columnNames = [
        //Define Table Columns
        { title: "Name", field: "name", width: 150, editor:"input" },
        {
          title: "Age",
          field: "age",
          hozAlign: "left",
          formatter: "progress",
        },
        { title: "Favourite Color", field: "col" },
        {
          title: "Date Of Birth",
          field: "dob",
          sorter: "date",
          hozAlign: "center",
        },
      ];

  }
  */

  private makeTableDataFromCustomers_(
    customerRecords: CdpCustomerDatabaseRecord[]
  ): any[] {
    var tableData: any[] = [];

    for (const customerRecord of customerRecords) {
      let id: number = 1;

      const curCustomerData: any = {
        id: id,
        email: customerRecord.email,
        given_name: customerRecord.givenName,
        family_name: customerRecord.familyName,
      };

      tableData.push(curCustomerData);
      id++;
    }

    return tableData;
  }

  private makeColumnsFromCustomers_(
    customerRecords: CdpCustomerDatabaseRecord[],
    allowModify: boolean
  ): any {
    // See https://tabulator.info/docs/5.5/columns#definition

    function editCheck(cell: any): boolean { return allowModify; }

    var columns = [
      { title: 'Email', field: 'email', editor: 'input', editable: editCheck },
      { title: 'First name', field: 'given_name', editor: 'input', editable: editCheck },
      { title: 'Last name', field: 'family_name', editor: 'input', editable: editCheck },
    ];

    return columns;
  }

  makeTable(customerRecords: CdpCustomerDatabaseRecord[], allowModify: boolean) {
    this.tableData = this.makeTableDataFromCustomers_(customerRecords);
    this.columnNames = this.makeColumnsFromCustomers_(customerRecords, allowModify);
  }
}
