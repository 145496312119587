import { Component } from '@angular/core';

@Component({
  selector: 'app-cdp-cookie-policy',
  standalone: true,
  imports: [],
  templateUrl: './cdp-cookie-policy.component.html',
  styleUrl: './cdp-cookie-policy.component.sass'
})
export class CdpCookiePolicyComponent {

}
