import { Component } from '@angular/core';

@Component({
  selector: 'app-cdp-privacy-policy',
  standalone: true,
  imports: [],
  templateUrl: './cdp-privacy-policy.component.html',
  styleUrl: './cdp-privacy-policy.component.sass'
})
export class CdpPrivacyPolicyComponent {

}
