<div class="m-o p-0" style="min-height:100vh">

<app-cdp-header></app-cdp-header>

<div class="container-fluid d-flex m-0 p-0">
  <app-cdp-side-menu></app-cdp-side-menu>
  <span class="container-fluid m-0 p-0">
  <router-outlet></router-outlet>
  </span>
</div>

<app-cdp-footer></app-cdp-footer>

</div>

