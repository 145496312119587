import { Component } from '@angular/core';

@Component({
  selector: 'app-cdp-feedback-complete',
  standalone: true,
  imports: [],
  templateUrl: './cdp-feedback-complete.component.html',
  styleUrl: './cdp-feedback-complete.component.sass'
})
export class CdpFeedbackCompleteComponent {

}
