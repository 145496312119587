import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CdpSelect, CdpSelectOption } from 'src/app/ui/cdp-select/cdp-select';
import { CdpSelectComponent } from 'src/app/ui/cdp-select/cdp-select.component';
import {
  CdpEmailTemplateInfoFilter,
  CdpEmailTemplateMetadataTag,
} from '../cdp-email-template-info';

export class CdpEmailTemplateFilterSingle {
  label: string = '';
  rawLabel: string = '';
  metadataTags: CdpEmailTemplateMetadataTag[] = [];
  intro: string = '';
}

@Component({
  selector: 'app-cdp-email-template-filter',
  standalone: true,
  imports: [CommonModule, FormsModule, CdpSelectComponent],
  templateUrl: './cdp-email-template-filter.component.html',
  styleUrl: './cdp-email-template-filter.component.sass',
})
export class CdpEmailTemplateFilterComponent implements AfterViewInit {
  @Input() visible = true;
  selects: CdpSelect[] = [];
  selectIntros: string[] = [];

  filter: CdpEmailTemplateInfoFilter = new CdpEmailTemplateInfoFilter();
  categoryFilters_: CdpEmailTemplateFilterSingle[] = [];

  @Output() filterChanged: EventEmitter<CdpEmailTemplateInfoFilter> =
    new EventEmitter<CdpEmailTemplateInfoFilter>();

  @Input() set categoryFilters(
    categoryFilters: CdpEmailTemplateFilterSingle[]
  ) {
    //console.log("set category filters: ", JSON.stringify(categoryFilters));

    this.selects.length = 0;

    for (const categoryFilter of categoryFilters) {
      const label: string = categoryFilter.label;
      const intro: string = categoryFilter.intro;
      const metadataTags: CdpEmailTemplateMetadataTag[] =
        categoryFilter.metadataTags;

      const options: CdpSelectOption[] = [];
      for (const tag of metadataTags) {
        const option: CdpSelectOption = new CdpSelectOption();
        option.text = tag.name;
        option.value = tag.name;

        options.push(option);
      }

      //console.log("Adding ", label);
      if (options.length > 0) {
        this.addFilterSelect(label, categoryFilter.rawLabel, options, intro);
      }
    }
  }

  @Input() initialSelectedIndustry: string = '';

  ngAfterViewInit(): void {}

  selectedItemsEvent(event: any) {
    //console.log('selectedItemsEvent Received event:', event);
    
    const category: string = event.label;
    const selectedOptions: string[] = event.selectedItems;

    //console.log(`Filter update: category:${category} selected:${selectedOptions}`);
    this.filter.filter[category] = selectedOptions;

    //console.log("New filter:", this.filter);
    this.filterChanged.emit(this.filter);
  }

  addFilterSelect(
    label: string,
    rawLabel: string,
    options: CdpSelectOption[],
    intro: string
  ) {
    const select: CdpSelect = new CdpSelect();
    select.label = label;
    select.rawLabel = rawLabel;
    select.options = options;
    
    //console.log("Initial: ", this.initialSelectedIndustry);

    if (label == 'Industry') {
      for (const option of options) {
        if (this.initialSelectedIndustry == option.value) {
          select.initialSelectedValues = [this.initialSelectedIndustry];
          //console.log("Matched option:", option);
          break;
        }
      }
    }

    this.selects.push(select);
    this.selectIntros.push(intro);
  }
}
