import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-cdp-calendar',
  standalone: true,
  imports: [],
  templateUrl: './cdp-calendar.component.html',
  styleUrl: './cdp-calendar.component.sass'
})
export class CdpCalendarComponent implements AfterViewInit{
  @ViewChild('iframe_container') iframeContainer!: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
      this.addOrReplaceIframe("assets/calendars/2024 Calendar.html");
  }

  addOrReplaceIframe(src: string): HTMLIFrameElement {
    const iframeContainerNative: HTMLDivElement =
      this.iframeContainer.nativeElement;

    // Remove the existing iframe, if any.
    while (iframeContainerNative.firstChild) {
      const lastChild = iframeContainerNative.lastChild;
      if (lastChild) {
        iframeContainerNative.removeChild(lastChild);
      }
    }


    const iframeNative: HTMLIFrameElement = document.createElement('iframe');

    iframeNative.onload = (e) => {
      const scrollPad: number = 2;

      let fullHeight: number =
        iframeNative.contentWindow!.document.body.scrollHeight + scrollPad;

      // This is a hack to deal with a large vertical blank area at the
      // bottom of the calendar.
      fullHeight *= 0.78;

      iframeNative.width = "100%";  
      iframeNative.height = fullHeight.toString();

      this.iframeContainer.nativeElement.style.height = `${
        fullHeight
      }px`;
    };

    iframeNative.src = src;

    iframeContainerNative.appendChild(iframeNative);

    return iframeNative;
  }
}
