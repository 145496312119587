import {
  OnDestroy,
  OnInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
  AfterViewInit,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdpBackendEmailService } from 'src/app/backend/email/cdp-backend-email.service';
import { Observable } from 'rxjs';

import { ScriptService } from 'src/app/util/scripts/script.service';
import { CdpEmailTemplateFile, CdpEmailTemplateFileTransformer } from 'src/app/email/template/cdp-email-template-file';
import {
  CdpEmailTemplateManagerFile,
  CdpEmailTemplateManagerService,
} from 'src/app/email/template/cdp-email-template-manager.service';

import { FormsModule } from '@angular/forms';
import { CdpRoutes } from 'src/app/core/router/cdp-routes';
import { NavigationEnd, Router } from '@angular/router';
import { CdpFile, CdpFileInfo } from 'src/app/core/files/cdp-file';
import {
  CdpBackendFileService,
  CdpFileOperationResultSet,
} from 'src/app/backend/files/cdp-backend-file.service';
import { CdpProgressSpinnerComponent } from 'src/app/ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';
import { CdpCommonFile, CdpCommonMultiFile } from 'src/app/core/files/cdp-file-parse';
import { CdpProgressOperationEvent, CdpProgressOperationSet, CdpProgressOperationTag } from 'src/app/ui/progress/cdp-progress';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpEmailTemplateId } from '../cdp-email-template-info';

class EmailTemplateData {
  name: string = '';
  html: string = '';
  css: string = '';
}

// See https://javascript.plainenglish.io/deep-clone-an-object-and-preserve-its-type-with-typescript-d488c35e5574.
export class CdpCloneable {
  public static deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map((item) => this.deepCopy(item))
      : source instanceof Date
      ? new Date(source.getTime())
      : source && typeof source === 'object'
      ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
          Object.defineProperty(
            o,
            prop,
            Object.getOwnPropertyDescriptor(source, prop)!
          );
          o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
          return o;
        }, Object.create(Object.getPrototypeOf(source)))
      : (source as T);
  }
}

enum CdpEmailTemplateEditorOperationType {
  CompileTemplate = 'CompileTemplate',
  Save = 'Save'
}

enum CdpEmailTemplateEditorInternalSaveEventType {
  GotTemplate = 'GotTemplate',
  CompiledEmail = 'CompiledEmail',
  CompiledTemplateFile = 'CompiledTemplateFile',
  PluginInitialized = 'PluginInitialized',
}

class CdpEmailTemplateEditorInternalSaveEvent {
  type: CdpEmailTemplateEditorInternalSaveEventType =
    CdpEmailTemplateEditorInternalSaveEventType.GotTemplate;
  html: string = '';
  css: string = '';
  compiledHtml: string = '';
  ampHtml: string = '';
  compilationError: any = null;
  ampErrors: any = null;
  templateFile: CdpEmailTemplateFile | null = null;
  operationTag: CdpProgressOperationTag = new CdpProgressOperationTag("unknown");

  static makeEventGotTemplate(
    operationTag: CdpProgressOperationTag,
    html: string,
    css: string
  ): CdpEmailTemplateEditorInternalSaveEvent {
    const e: CdpEmailTemplateEditorInternalSaveEvent =
      new CdpEmailTemplateEditorInternalSaveEvent();
    e.type = CdpEmailTemplateEditorInternalSaveEventType.GotTemplate;
    e.html = html;
    e.css = css;
    e.operationTag = operationTag;

    return e;
  }

  static makeEventCompiledEmail(
    operationTag: CdpProgressOperationTag,
    html: string,
    css: string,
    compiledHtml: string,
    ampHtml: string,
    compilationError: any,
    ampErrors: any
  ): CdpEmailTemplateEditorInternalSaveEvent {
    const e: CdpEmailTemplateEditorInternalSaveEvent =
      new CdpEmailTemplateEditorInternalSaveEvent();
    e.type = CdpEmailTemplateEditorInternalSaveEventType.CompiledEmail;
    e.html = html;
    e.css = css;
    e.compiledHtml = compiledHtml;
    e.ampHtml = ampHtml;
    e.compilationError = compilationError;
    e.ampErrors = ampErrors;
    e.operationTag = operationTag;

    return e;
  }

  static makeEventCompiledTemplateFile(
    operationTag: CdpProgressOperationTag,
    templateFile: CdpEmailTemplateFile,
    compilationError: any,
    ampErrors: any
  ): CdpEmailTemplateEditorInternalSaveEvent {
    const e: CdpEmailTemplateEditorInternalSaveEvent =
      new CdpEmailTemplateEditorInternalSaveEvent();
    e.type = CdpEmailTemplateEditorInternalSaveEventType.CompiledTemplateFile;
    e.templateFile = templateFile;
    e.compilationError = compilationError;
    e.ampErrors = ampErrors;
    e.operationTag = operationTag;

    return e;
  }

  static makeEventPluginInitialized(
    operationTag: CdpProgressOperationTag,
    templateFile: CdpEmailTemplateFile | null
  ): CdpEmailTemplateEditorInternalSaveEvent {
    const e: CdpEmailTemplateEditorInternalSaveEvent =
      new CdpEmailTemplateEditorInternalSaveEvent();
    e.type = CdpEmailTemplateEditorInternalSaveEventType.PluginInitialized;
    e.templateFile = templateFile;
    e.operationTag = operationTag;

    return e;
  }
}

@Component({
  selector: 'app-email-template-editor',
  standalone: true,
  imports: [CommonModule, FormsModule, CdpProgressSpinnerComponent],
  providers: [ScriptService],
  templateUrl: './email-template-editor.component.html',
  styleUrl: './email-template-editor.component.scss',
})
export class CdpEmailTemplateEditorComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  @ViewChild('editor_container') editorContainer!: ElementRef<HTMLSpanElement>;
  @ViewChild('iframe') iframe!: ElementRef<HTMLIFrameElement>;
  @ViewChild('save_button') saveButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('discard_edits_button')
  discardEditsButton!: ElementRef<HTMLButtonElement>;
  @Input() currentTemplateName: string = '';

  @Input() templateManagerFile = new CdpEmailTemplateManagerFile();

  emailTemplateData: EmailTemplateData = new EmailTemplateData();

  templateFileAtStart: CdpEmailTemplateFile = new CdpEmailTemplateFile();
  templateFileCurrent: CdpEmailTemplateFile = new CdpEmailTemplateFile();

  private thisUrl_: string = '';

  private editorSaveEventEmitter_: EventEmitter<CdpEmailTemplateEditorInternalSaveEvent> =
    new EventEmitter<CdpEmailTemplateEditorInternalSaveEvent>();

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();
  get isOperationInProgress() { return this.operationSet.isOperationInProgress; }

  isEditorLoading: boolean = false;

  logger = console.log;

  // The template files here are a hack to allow pre-compiling the builtin templates.
  private doCompileBuiltinTemplates_: boolean = false;
  private templatesToCompile_: CdpEmailTemplateFile[] = [];
  private compileTemplateCurrentIndex_ = -1;
  private compiledTemplates_: CdpEmailTemplateFile[] = [];

  constructor(
    private backend: CdpBackendEmailService,
    private fileService: CdpBackendFileService,
    private sessionManager: CdpSessionManagerService,
    private templateManager: CdpEmailTemplateManagerService,
    private router: Router
  ) {
    // We use internal events to deal with the editor.
    this.editorSaveEventEmitter_.subscribe((e) =>
      this.processEditorSaveEvent_(e)
    );

    this.operationSet.eventEmitter.subscribe((e) => this.processOperationEvent_(e));
  }

  ngOnInit(): void {
    window.InboxEase = window.InboxEase || {};
    window.InboxEase.EmailEditor =
      window.InboxEase.EmailEditor || {};

    window.InboxEase.EmailEditor.getAuthToken =
      window.InboxEase.EmailEditor.getAuthToken ||
      this.backend.getEmailTemplateEditorAuth.bind(this.backend);

    window.InboxEase.EmailEditor.pluginIntialized =
      window.InboxEase.EmailEditor.pluginIntialized ||
      this.emitInternalSaveEventPluginInitialized_.bind(this);
 

    this.thisUrl_ = this.router.url;

    const onReload = this.onReload.bind(this);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //console.log('Navend event:', JSON.stringify(event));
        if (event.url == this.thisUrl_) {
          // route is restored
          //console.log('Navend event match:', this.thisUrl_);
          onReload();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.doCompileBuiltinTemplates_) {
      // This is a hack to allow compiling all the builtin templates.
      // Uncomment the following line to re-compile all the builtin templates if needed.
      this.compileBuiltinTemplates_();
    }

    this.onReload();
  }

  ngOnDestroy() {
    this.closeEditor();
  }

  onIframeLoad() {
    //console.log('iframe loaded');
  }

  createDynamicDocument(transformer: CdpEmailTemplateFileTransformer | null) {
    if (this.doCompileBuiltinTemplates_) {
      return;
    }

    if (!this.iframe || !this.iframe.nativeElement) {
      return;
    }

    //console.log("In createDynamicDocument");

    const origTemplateManagerFile: CdpEmailTemplateManagerFile =
      this.templateManager.getCurrentTemplateManagerFile();

    // Make sure that we're using an editable version of the file, e.g.,
    // a copy if the original is a builtin template.
    const templateManagerFile: CdpEmailTemplateManagerFile | null =
      CdpEmailTemplateManagerFile.makeEditableTemplateManagerFile(
        origTemplateManagerFile, transformer
      );

      //console.log("Got templateManagerFile: ", templateManagerFile);

    if (!templateManagerFile) {
      // This shouldn't happen.
     // console.log('Could not make editable template manager file');
      // TODO Prompt the user to choose a template?
      this.navigateIfEmptyTemplateFile();

      return;
    }

    this.isEditorLoading = true;
    const operationTag: CdpProgressOperationTag = this.operationSet.addOperation("LoadEditor", "Editor loading...");
    //console.log("Loading editor. op=", operationTag.id);

    try {
    const onIframeLoad = this.onIframeLoad.bind(this);
    const loadTemplateManagerFile = this.loadTemplateManagerFile.bind(this);

    var iframeNative = this.iframe.nativeElement;
    iframeNative.onload = (e) => {
      onIframeLoad();

      //console.log("Loaded iframe");
      loadTemplateManagerFile(templateManagerFile, operationTag);
    };
    iframeNative.onerror = (e) => {
      //console.log('Iframe load error');
    };
    iframeNative.width = '1200px'; // TODO TODO '100%';
    iframeNative.height = '800px';

    iframeNative.src = 'assets/email-template-editor/editor_frame.html';
  } catch {
    this.operationSet.removeOperation(operationTag);
    this.isEditorLoading = false;
  }
  }

  navigateIfEmptyTemplateFile() {
    // Briefly show a message that the user needs to pick a template and is being
    // redirected.
    // Navigate to the template gallery? To the user's saved templates?
    this.router.navigate([CdpRoutes.EmailTemplateGallery]);
  }

  private compileTemplateFileAfterPluginInitialized_(
    operationTag: CdpProgressOperationTag,
    templateFile: CdpEmailTemplateFile
  ) {
    const emitInternalSaveEventCompiledTemplateFile =
      this.emitInternalSaveEventCompiledTemplateFile_.bind(this, operationTag);

    if (
      !this.iframe ||
      !this.iframe.nativeElement ||
      !this.iframe.nativeElement.contentWindow
    ) {
      emitInternalSaveEventCompiledTemplateFile(
        templateFile,
        'No iframe for template compilation',
        null
      );
    }

    // TODO TODO Deal with operationInProgress

    var contentWindow = this.iframe.nativeElement.contentWindow;

    if (contentWindow) {
      // guaranteed
      contentWindow.compileEmail(function (
        compilationError: any,
        compiledHtmlIn: string | undefined,
        ampHtmlIn: string | undefined,
        ampErrors: any
      ) {
        const compiledHtml: string = compiledHtmlIn ? compiledHtmlIn : '';
        const ampHtml: string = ampHtmlIn ? ampHtmlIn : '';

        if (!compilationError) {
          templateFile.setCompiledHtml(compiledHtml);
        }

        if (!ampErrors) {
          templateFile.setAmpHtml(ampHtml);
        }

        emitInternalSaveEventCompiledTemplateFile(
          templateFile,
          compilationError,
          ampErrors
        );
      });
    }
  }

  compileTemplateFile(operationTag: CdpProgressOperationTag, templateFile: CdpEmailTemplateFile) {
    const onIframeLoad = this.onIframeLoad.bind(this);
    const compileTemplateComplete = this.compileTemplateComplete_.bind(this, operationTag);
    const emitInternalSaveEventCompiledTemplateFile =
      this.emitInternalSaveEventCompiledTemplateFile_.bind(this, operationTag);

    if (!this.iframe || !this.iframe.nativeElement) {
      emitInternalSaveEventCompiledTemplateFile(
        templateFile,
        'No iframe for template compilation',
        null
      );
    }

    // TODO TODO Deal with operationInProgress

    var iframeNative = this.iframe.nativeElement;
    if (this.doCompileBuiltinTemplates_) {
      //iframeNative.style.display = "none";
    }

    const idForEditor: string = CdpEmailTemplateId.idWithVersion(templateFile.info.id);

    iframeNative.onload = (e) => {
      onIframeLoad();

      if (!iframeNative.contentWindow) {
        console.log('Editor iframe did not initialize');
        emitInternalSaveEventCompiledTemplateFile(
          templateFile,
          'No iframe for template compilation',
          null
        );
        return;
      }

      // The compilation will be triggered by the event emitted when the plugin
      // initialization is complete.
      iframeNative.contentWindow.initPluginAndCompile(
        templateFile, idForEditor,
        compileTemplateComplete
      );
    };

    iframeNative.onerror = (e) => {
      //console.log('Iframe load error');
    };
    iframeNative.width = '1200px'; // TODO TODO '100%';
    iframeNative.height = '800px';

    iframeNative.src = 'assets/email-template-editor/editor_frame.html';
  }

  private compileAllTemplatesComplete_(operationTag: CdpProgressOperationTag, templateFiles: CdpEmailTemplateFile[]) {
    this.operationSet.updateOperationFinal(operationTag, true, "Finished compiling templates.");

    const file: CdpCommonMultiFile = CdpEmailTemplateFile.makeEmtMultiFile(templateFiles);

    const lines: string[] = file.getAsLines();

    console.log(lines.join('\n'));
  }

  private compileNextTemplateFile_(operationTag: CdpProgressOperationTag) {
    const index: number = this.compileTemplateCurrentIndex_;
    if (index >= 0 && index < this.templatesToCompile_.length) {
      console.log(
        `Compiling template ${index} of ${this.templatesToCompile_.length}`
      );

      ++this.compileTemplateCurrentIndex_;

      const templateFile: CdpEmailTemplateFile =
        this.templatesToCompile_[index];
      this.compileTemplateFile(operationTag, templateFile);
    } else {
      console.log(`Done compiling templates`);

      // We've finished compiling all the templates.
      this.templatesToCompile_ = [];

      this.compileAllTemplatesComplete_(operationTag, this.compiledTemplates_);
      this.compiledTemplates_ = [];
    }
  }

  private compileBuiltinTemplatesFinish_(text: string) {
    const templateFilesDicts: any[] = JSON.parse(text);
    const n: number = templateFilesDicts.length;

    // Add all the templates to the static array of templates to compile.
    this.templatesToCompile_.length = 0;

    for (let i = 0; i < n; i++) {
      const templateFileDict: any = templateFilesDicts[i];

      const templateFile: CdpEmailTemplateFile = new CdpEmailTemplateFile();
      templateFile.info = templateFileDict.info;
      templateFile.css = templateFileDict.css;
      templateFile.html = templateFileDict.html;
      //Object.assign(templateFile, templateFileDict);

      this.templatesToCompile_.push(templateFile);
    }

    if (this.templatesToCompile_.length > 0) {
      const operationTag: CdpProgressOperationTag = this.operationSet.addOperation(CdpEmailTemplateEditorOperationType.CompileTemplate, "Compiling templates...");
      
      // Kick off the event-driven sequence to compile each template in turn.
      this.compileTemplateCurrentIndex_ = 0;
      this.compileNextTemplateFile_(operationTag);
    }
  }

  private compileBuiltinTemplates_() {
    const url: string = 'assets/email-templates/all_builtin_templates.json';

    const compileBuiltinTemplatesFinish =
      this.compileBuiltinTemplatesFinish_.bind(this);

    const operationTag: CdpProgressOperationTag =
    this.operationSet.addOperation(CdpEmailTemplateEditorOperationType.CompileTemplate,
      'Compiling templates...');

    try {
      const observable: Observable<string> =
        this.fileService.loadUrlFileAsText(url);

      observable.subscribe((text) => compileBuiltinTemplatesFinish(text));
    } catch (error) {
      const message: string = 'Compile builtin templates caught error: ' + error;
      this.operationSet.updateOperationFinal(operationTag, false, message);
    }
  }

  loadTemplateManagerFile(templateManagerFile: CdpEmailTemplateManagerFile,
    operationTag: CdpProgressOperationTag) {

    /*
    const emitInternalSaveEventPluginInitialized =
      this.emitInternalSaveEventPluginInitialized_.bind(this, operationTag, null);
    */

    //console.log("In loadTemplateManagerFile");

    const templateFile: CdpEmailTemplateFile | null =
      templateManagerFile.templateFile;
    if (!templateFile || templateFile.isEmpty()) {
      // TODO Prompt the user to choose a template?
      this.operationSet.removeOperation(operationTag);
      this.navigateIfEmptyTemplateFile();
    } else {
      this.templateManagerFile = templateManagerFile;
      this.templateFileAtStart = templateFile;
      this.currentTemplateName = templateFile.info.name;

      // Create clone.
      this.templateFileCurrent = CdpCloneable.deepCopy(templateFile);

      var iframeNative = this.iframe.nativeElement;
      //console.log("Calling initPlugin");

      const idForEditor: string = CdpEmailTemplateId.idWithVersion(templateFile.info.id);

      //console.log(`Calling initPlugin: ID=${idForEditor} tag=${operationTag.id}`);

      iframeNative.contentWindow!.initPlugin(
        templateFile, idForEditor, operationTag);
      
      /*
      iframeNative.contentWindow!.initPlugin(
        templateFile, idForEditor,
        emitInternalSaveEventPluginInitialized
      );
      */
    }
  }

  static convertTemplateFileToCdpFile(
    templateFile: CdpEmailTemplateFile,
    fileInfo: CdpFileInfo
  ): CdpFile {
    const cdpFile: CdpFile = new CdpFile();

    const filename: string = templateFile.info.name + '.emt';

    cdpFile.fileInfo = fileInfo;

    const emtFile: CdpCommonFile = templateFile.makeEmtFile();
    const emtString: string = emtFile.getAsLines().join('\n');

    cdpFile.file = new File([emtString], filename, {
      type: 'text/plain',
    });

    return cdpFile;
  }

  private operationInProgressChanged_() {
    if (this.saveButton && this.saveButton.nativeElement) {
     this.saveButton.nativeElement.disabled = this.isOperationInProgress;
    }

    if (this.discardEditsButton && this.discardEditsButton.nativeElement) {
    this.discardEditsButton.nativeElement.disabled = this.isOperationInProgress;
 }
}

  private processOperationEvent_(e: CdpProgressOperationEvent) {
    this.operationInProgressChanged_();
  }

  private processEditorSaveEvent_(e: CdpEmailTemplateEditorInternalSaveEvent) {
    //console.log("Processing event:", e);

    switch (e.type) {
      case CdpEmailTemplateEditorInternalSaveEventType.GotTemplate:
        this.saveCurrentTemplateDataFromEditorAfterGotTemplate_(e.html, e.css, e.operationTag);
        break;

      case CdpEmailTemplateEditorInternalSaveEventType.CompiledEmail:
        this.saveCurrentTemplateDataFromEditorAfterCompiledEmail_(
          e.html,
          e.css,
          e.compiledHtml,
          e.ampHtml,
          e.compilationError,
          e.ampErrors,
          e.operationTag
        );
        break;

      case CdpEmailTemplateEditorInternalSaveEventType.CompiledTemplateFile:
        if (e.templateFile) {
          /*
          console.log(
            'Got compiled template. Length=',
            e.templateFile.getCompiledHtml().length
          );
          */

          this.compiledTemplates_.push(e.templateFile);

          if ((this.compiledTemplates_.length % 50) == 0) {
            const file: CdpCommonMultiFile = CdpEmailTemplateFile.makeEmtMultiFile(this.compiledTemplates_);

            const lines: string[] = file.getAsRegion().getAsLines();
        
            console.log(lines.join('\n'));

            this.compiledTemplates_ = [];
          }
  
        }
        this.compileNextTemplateFile_(e.operationTag);

        break;

      case CdpEmailTemplateEditorInternalSaveEventType.PluginInitialized:
        if (e.templateFile) {
          this.compileTemplateFileAfterPluginInitialized_(e.operationTag, e.templateFile);
        }

        break;
    }
  }

  private emitInternalSaveEvent_(e: CdpEmailTemplateEditorInternalSaveEvent) {
    //console.log("Emitting editor internal save event:", e);

    this.editorSaveEventEmitter_.emit(e);
  }

  private emitInternalSaveEventGotTemplate_(operationTag: CdpProgressOperationTag, html: string, css: string) {
    const e: CdpEmailTemplateEditorInternalSaveEvent =
      CdpEmailTemplateEditorInternalSaveEvent.makeEventGotTemplate(operationTag, html, css);
    this.emitInternalSaveEvent_(e);
  }

  private emitInternalSaveEventCompiledEmail_(
    operationTag: CdpProgressOperationTag,
    html: string,
    css: string,
    compiledHtml: string,
    ampHtml: string,
    compilationError: any,
    ampErrors: any
  ) {
    const e: CdpEmailTemplateEditorInternalSaveEvent =
      CdpEmailTemplateEditorInternalSaveEvent.makeEventCompiledEmail(
        operationTag,
        html,
        css,
        compiledHtml,
        ampHtml,
        compilationError,
        ampErrors
      );
    this.emitInternalSaveEvent_(e);
  }

  private emitInternalSaveEventCompiledTemplateFile_(
    operationTag: CdpProgressOperationTag,
    templateFile: CdpEmailTemplateFile,
    compilationError: any,
    ampErrors: any
  ) {
    const e: CdpEmailTemplateEditorInternalSaveEvent =
      CdpEmailTemplateEditorInternalSaveEvent.makeEventCompiledTemplateFile(
        operationTag,
        templateFile,
        compilationError,
        ampErrors
      );
    this.emitInternalSaveEvent_(e);
  }

  private emitInternalSaveEventPluginInitialized_(
    operationTag: CdpProgressOperationTag,
    templateFile: CdpEmailTemplateFile | null
  ) {
    //console.log("Plugin init complete");
    //console.log("Removing operation:", operationTag.id);

    this.operationSet.removeOperation(operationTag);
    this.isEditorLoading = false;
    //console.log("Operation in progress?", this.operationSet.isOperationInProgress);

    const e: CdpEmailTemplateEditorInternalSaveEvent =
      CdpEmailTemplateEditorInternalSaveEvent.makeEventPluginInitialized(
        operationTag,
        templateFile
      );
    this.emitInternalSaveEvent_(e);
  }

  private compileTemplateComplete_(
    operationTag: CdpProgressOperationTag,
    templateFile: CdpEmailTemplateFile,
    compiledHtmlIn: string | undefined,
    ampHtmlIn: string | undefined,
    compilationError: any,
    ampErrors: any
  ) {
    try {
      const compiledHtml: string = compiledHtmlIn ? compiledHtmlIn : '';
      const ampHtml: string = ampHtmlIn ? ampHtmlIn : '';

      /*
      console.log("Template file:", templateFile);
      console.log('Editor after compile.');
      console.log('  Compiled HTML:', compiledHtml);
      if (compilationError) {
        console.log('  Compilation error: ', compilationError);
      }

      if (ampErrors) {
        console.log('  AMP errors: ', ampErrors);
      }
      */

      templateFile.setCompiledHtml(compiledHtml);
      templateFile.setAmpHtml(ampHtml);

      /*
      const file: CdpCommonFile = CdpEmailTemplateFile.makeEmtFile(templateFile);
      console.log('@@!BeginFile');
      console.log(file.getAsLines().join("\n"));
      console.log('@@!EndFile');
      */
    } finally {
      this.emitInternalSaveEventCompiledTemplateFile_(
        operationTag, 
        templateFile,
        compilationError,
        ampErrors
      );
    }
  }

  private setOperationInProgressCompletedSucceeded_(message: string, operationTag: CdpProgressOperationTag | null) {
    //console.log('Operation succeeded: ', message);
    if (operationTag) {
      this.operationSet.updateOperationFinal(operationTag, true, message);
    }
  }

  private setOperationInProgressCompletedFailed_(message: string, operationTag: CdpProgressOperationTag | null) {
    //console.log('Operation failed: ', message);
    if (operationTag) {
      this.operationSet.updateOperationFinal(operationTag, false, message);
    }
  }

  private saveCurrentTemplateDataFromEditorAfterCompiledEmail_(
    html: string,
    css: string,
    compiledHtmlIn: string | undefined,
    ampHtmlIn: string | undefined,
    compilationError: any,
    ampErrors: any,
    operationTag: CdpProgressOperationTag | null
  ) {
    try {
      // TODO Check errors!

      const templateFile = this.templateFileCurrent;

      const compiledHtml: string = compiledHtmlIn ? compiledHtmlIn : '';
      const ampHtml: string = ampHtmlIn ? ampHtmlIn : '';

      /*
      console.log('Editor after compile.');
      console.log('  Compiled HTML length:', compiledHtml.length);
      if (compilationError) {
        console.log('  Compilation error: ', compilationError);
      }

      if (ampErrors) {
        console.log('  AMP errors: ', ampErrors);
      }
      //console.log('getTemplate. templateFile=', JSON.stringify(templateFile));
*/

      // TODO Check if there are any changes to the info, html, or CSS and
      //      skip saving if no change.
      templateFile.info.name = this.currentTemplateName;
      templateFile.html = html;
      templateFile.css = css;
      templateFile.setCompiledHtml(compiledHtml);
      templateFile.setAmpHtml(ampHtml);

      // TODO Handle versioning and drafts
      // Make a clone with the modified contents.  If the save succeeds,
      // update the current file.
      const templateManagerFile: CdpEmailTemplateManagerFile | null =
        CdpCloneable.deepCopy(this.templateManagerFile);

      if (!templateManagerFile || !templateManagerFile.file) {
        // This shouldn't happen.
        this.setOperationInProgressCompletedFailed_("Couldn't save file. No file info available.", operationTag);
        return;
      }

      const fileInfo: CdpFileInfo = templateManagerFile.file.fileInfo;

      templateManagerFile.templateFile = CdpCloneable.deepCopy(templateFile);
      const cdpFile: CdpFile =
        CdpEmailTemplateEditorComponent.convertTemplateFileToCdpFile(
          templateFile,
          fileInfo
        );

      //console.log('About to save template:', templateFile.info);
      const allowOverwrite: boolean = true; // TODO Allow specifying?
      const observableResultSet: Observable<CdpFileOperationResultSet> =
        this.fileService.uploadFiles([cdpFile], allowOverwrite);
      observableResultSet.subscribe((resultSet) => {
        // TODO Before clearing the operation in progress, do we need to check for
        //      any kind of race conditions?
        // Also, should show a succeeded or failed message, with a delay to
        // allow reading.

        //console.log('Save complete. result set=', JSON.stringify(resultSet));

        if (resultSet.errors.length == 0) {
          // The save succeeded.
          // TODO Doublecheck the actual results.
          // TODO Allow result to indicate e.g. an overwrite attempt, which might be
          //      allowable or not.

          // TODO Allow for the possibility that the filename changed when saved,
          // e.g. if we implement some "rename if overwrite attempted" mode.
          this.templateManagerFile.templateFile = templateFile;
          this.templateManagerFile.existsOnBackend = true;
        }

        // Now emit an event that we saved the file.  The importance of the
        // event isn't so much about the save as about the fact that the
        // contents may have changed.
        // TODO TODO
        //    Or, we can just rely on the resource manager to propagate the
        //    event from the file service.

        
        this.setOperationInProgressCompletedSucceeded_("Save succeeded.", operationTag);
      });

    } catch (error) {
      console.log('Editor save caught error: ', error);

      this.setOperationInProgressCompletedFailed_("Couldn't save file. No file info available.", operationTag);
    }
  }

  private saveCurrentTemplateDataFromEditorAfterGotTemplate_(
    html: string,
    css: string,
    operationTag: CdpProgressOperationTag
  ) {
    if (
      !this.iframe ||
      !this.iframe.nativeElement ||
      !this.iframe.nativeElement.contentWindow
    ) {
      // TODO This shouldn't happen.

      this.setOperationInProgressCompletedFailed_('Could not get template from editor', operationTag);
      return;
    }

    const emitInternalSaveEventCompiledEmail =
      this.emitInternalSaveEventCompiledEmail_.bind(this);

    //console.log('In saveCurrentTemplateDataFromEditorAfterGotTemplate_');

    this.iframe.nativeElement.contentWindow.compileEmail(function (
      compilationError: any,
      compiledHtml: string,
      ampHtml: string,
      ampErrors: any
    ) {
      emitInternalSaveEventCompiledEmail(
        operationTag,
        html,
        css,
        compiledHtml,
        ampHtml,
        compilationError,
        ampErrors
      );
    });
  }

  async saveCurrentTemplateDataFromEditor() {
    if (
      !this.iframe ||
      !this.iframe.nativeElement ||
      !this.iframe.nativeElement.contentWindow
    ) {
      // TODO This shouldn't happen.
      console.log('Could not get template from editor');
      return;
    }

    // TODO Block other editor operations until everything completes.

    const operationTag: CdpProgressOperationTag = this.operationSet.addOperation(CdpEmailTemplateEditorOperationType.Save, 
      'Saving template...');

    const emitInternalSaveEventGotTemplate =
      this.emitInternalSaveEventGotTemplate_.bind(this, operationTag);

    this.iframe.nativeElement.contentWindow.getTemplate(function (
      html: string,
      css: string
    ) {
      emitInternalSaveEventGotTemplate(html, css);
    });
  }

  async onReload(): Promise<void> {
    // We may want to transform builtin templates, such as replacing links to
    // our websites to the user's website.
    const transformer: CdpEmailTemplateFileTransformer | null = await this.templateManager.makeTransformerFromBuiltinForUser();

    this.createDynamicDocument(transformer);
  }

  getAuthTokenCallback() {
    //console.log('In getAuthTokenCallback: this=', this);
    return this.backend.getEmailTemplateEditorAuth();
  }

  onClickSave() {
    this.saveCurrentTemplateDataFromEditor();
  }

  onClickDiscardEdits() {}

  closeEditor() {
    window.StripoApi.stop();
  }
}
