import { head } from 'lodash';
import { CdpFile } from './cdp-file';

export enum CdpCommonFileType {
  CustomerDatabase = 'CustomerDatabase',
  CustomerDatabaseList = 'CustomerDatabaseList',
  EmailList = 'EmailList',
  EmailTemplate = 'EmailTemplate',
  MultiFile = 'MultiFile',
  Unknown = 'Unknown',
}

export class CdpCommonFileHeader {
  isValid(): boolean {
    return this.fileType != CdpCommonFileType.Unknown;
  }

  fileType: CdpCommonFileType = CdpCommonFileType.Unknown;
  formatVersion: string = '1.0';

  private valueMap_: any = {};

  public static regionType = 'Header';

  public static prefixBegin = '@@!BeginHeader';
  public static prefixEnd = '@@!EndHeader';
  public static prefixFileType = '@@!FileType';
  public static prefixFormatVersion = '@@!Version';

  clear() {
    this.fileType = CdpCommonFileType.Unknown;
    this.formatVersion = '1.0';
  }

  getValue(name: string): string | undefined {
    return this.valueMap_[name];
  }

  setValue(name: string, value: string) {
    this.valueMap_[name] = value;
  }

  getAsRegion(): CdpFileParserRegion {
    const region: CdpFileParserRegion = new CdpFileParserRegion(CdpCommonFileHeader.regionType);

    region.bodyLines = [
      `fileType = ${this.fileType}`,
      `version = ${this.formatVersion}`,
    ];

    for (const [key, value] of Object.entries(this.valueMap_)) {
      region.bodyLines.push(`${key} = ${value}`);
    }

    region.isValid_ = true;

    return region;
  }

  private static convertTextToFileType_(text: string): CdpCommonFileType {
    const allFileTypes: CdpCommonFileType[] = [
      CdpCommonFileType.CustomerDatabase,
      CdpCommonFileType.CustomerDatabaseList,
      CdpCommonFileType.EmailList,
      CdpCommonFileType.EmailTemplate,
      CdpCommonFileType.MultiFile,
    ];

    for (const fileType of allFileTypes) {
      if (text == fileType) {
        return fileType;
      }
    }

    return CdpCommonFileType.Unknown;
  }

  loadFromRegion(region: CdpFileParserRegion) {
    this.clear();

    if (
      !region.isValid ||
      region.type != CdpCommonFileHeader.regionType ||
      region.subregions.length > 0
    ) {
      // This is not a valid header.
      return;
    }

    // The body lines should all be in the format "name = value". There must be at
    // least a fileType and a version specified. <value> may be blank.
    for (const line of region.bodyLines) {
      if (line.indexOf('=') < 0) {
        // This is not a valid line.
        return;
      }
      const fields: string[] = line.split('=', 2).map((f) => f.trim());
      const value: string = fields.length == 2 ? fields[1] : '';
      this.valueMap_[fields[0]] = value;
    }

    if (!('fileType' in this.valueMap_) || !('version' in this.valueMap_)) {
      // File type and version are required.
      return;
    }

    this.fileType = CdpCommonFileHeader.convertTextToFileType_(
      this.valueMap_['fileType']
    );
    if (this.fileType == CdpCommonFileType.Unknown) {
       console.log('Unknown file type:', this.valueMap_['fileType']);
      return;
    }

    this.formatVersion = this.valueMap_['version'];

    // The header is valid.
  }
}

export class CdpCommonFileSection {
  private isValid_: boolean = true;

  sectionName: string = '';
  lines: string[] = [];

  public static regionType = 'Section';

  isValid(): boolean {
    return this.isValid_;
  }

  getAsRegion(): CdpFileParserRegion {
    const region: CdpFileParserRegion = new CdpFileParserRegion(CdpCommonFileSection.regionType, this.sectionName);
    region.bodyLines = [...this.lines]; // clone

    region.isValid_ = true;

    return region;
  }

  loadFromRegion(region: CdpFileParserRegion) {
    if (region.type != CdpCommonFileSection.regionType) {
       console.log('Region is not a section. type=', region.type);
    }

    this.sectionName = region.beginLine.tag;

    this.lines = [...region.bodyLines]; // clone
  }
}

export class CdpCommonFileSectionSet {
  private isValid_: boolean = true;

  sections: CdpCommonFileSection[] = [];

  public static regionType: string = 'SectionSet';

  isValid(): boolean {
    return this.isValid_;
  }

  clear() {
    this.isValid_ = true;
    this.sections = [];
  }

  getAsRegion(): CdpFileParserRegion {
    const region: CdpFileParserRegion = new CdpFileParserRegion(CdpCommonFileSectionSet.regionType);

    for (const section of this.sections) {
      region.subregions.push(section.getAsRegion());
    }

    region.isValid_ = true;

    return region;
  }

  loadFromRegion(region: CdpFileParserRegion) {
    this.clear();

    this.isValid_ = false;

    if (region.type != CdpCommonFileSectionSet.regionType) {
       console.log('Invalid region type for section set: ', region.type);

      return;
    }

    // The region may have zero or more subregions, all of which must be sections.
    for (const subregion of region.subregions) {
      const section: CdpCommonFileSection = new CdpCommonFileSection();
      section.loadFromRegion(subregion);
      if (!section.isValid()) {
        return;
      }

      this.sections.push(section);
    }

    this.isValid_ = true;
  }
}

export class CdpCommonFile {
  header: CdpCommonFileHeader = new CdpCommonFileHeader();
  sectionSet: CdpCommonFileSectionSet = new CdpCommonFileSectionSet();

  public static regionType: string = 'File';

  isValid(): boolean {
    return this.header.isValid();
  }

  clear() {
    this.header = new CdpCommonFileHeader();
    this.sectionSet = new CdpCommonFileSectionSet();
  }

  clearAndMakeInvalid() {
    // A cleared file has a header with unknown file type and is thus
    // already invalid.
    this.clear();
  }

  getFilename(): string {
    const filename: string | undefined = this.header.getValue('filename');
    return filename == undefined ? '' : filename;
  }

  setFilename(filename: string) {
    this.header.setValue('filename', filename);
  }

  getAsLines(): string[] {
    return this.getAsRegion().getAsLines();
  }

  getAsRegion(): CdpFileParserRegion {
    const region: CdpFileParserRegion = new CdpFileParserRegion(CdpCommonFile.regionType);

    region.subregions.push(this.header.getAsRegion());
    region.subregions.push(this.sectionSet.getAsRegion());
    region.isValid_ = true;
    
    return region;
  }

  public loadFromLines(lines: string[]) {
    this.clearAndMakeInvalid();

    const region: CdpFileParserRegion | null =
      CdpFileParser.parseNextRegion(lines);

    if (region) {
     this.loadFromRegion(region);
    }
  }

  public loadFromText(text: string) {
    this.clearAndMakeInvalid();

    const lines: string[] = text.split('\n');
    this.loadFromLines(lines);
  }

  public loadFromRegion(region: CdpFileParserRegion) {
    this.clear();

    // The region should contain two subregions: the header and the section set.
    // However, it is legal to have an empty file with just a header.
    if ((region.subregions.length == 0) || (region.subregions.length > 2)) {
       console.log("Error loading file: invalid number of subregions: ", region);
      return;
    }

    const headerRegion: CdpFileParserRegion = region.subregions[0];
    if (headerRegion.type != CdpCommonFileHeader.regionType ) {
      // The file is invalid.
       console.log("Error loading file: first region is not a header. region=", headerRegion);
      return;
    }

    const header: CdpCommonFileHeader = new CdpCommonFileHeader();
    header.loadFromRegion(headerRegion);
    if (!header.isValid()) {
      console.log("Invalid header: ", header);

      return;
    }

    // Note: The header is not required to specify a filename.


    const sectionSet: CdpCommonFileSectionSet = new CdpCommonFileSectionSet();

    if (region.subregions.length > 1) {
      const sectionSetRegion: CdpFileParserRegion = region.subregions[1];

      if (sectionSetRegion.type != CdpCommonFileSectionSet.regionType) {
        // The file is invalid.
         console.log("Error loading file: second region is not a section set. region=", sectionSetRegion);
        return;
      }
      
      sectionSet.loadFromRegion(sectionSetRegion);

      if (!sectionSet.isValid()) {
         console.log("Error loading file: invalid section set. region=", sectionSetRegion);
        return;
      }
    }

    this.header = header;
    this.sectionSet = sectionSet;
  }
}

export class CdpCommonMultiFile {
  header: CdpCommonFileHeader = new CdpCommonFileHeader();
  files: CdpCommonFile[] = [];

  public static regionType: string = 'MultiFile';

  public static prefixFileBegin: string = '@@!BeginFile';
  public static prefixFileEnd: string = '@@!EndFile';

  constructor() {
    this.makeHeader_();
  }

  private makeHeader_() {
    const header: CdpCommonFileHeader = new CdpCommonFileHeader();
    header.fileType = CdpCommonFileType.MultiFile;
    header.formatVersion = '1.0';

    this.header = header;
  }

  isValid(): boolean {
    return this.header.isValid();
  }

  clear() {
    this.makeHeader_();
    this.files = [];
  }

  clearAndMakeInvalid() {
    this.header = new CdpCommonFileHeader();
    this.files = [];
  }

  loadFromText(text: string) {
    this.clearAndMakeInvalid();

    const lines: string[] = text.split('\n');
    const region: CdpFileParserRegion | null =
      CdpFileParser.parseNextRegion(lines);

    if (region) {
     this.loadFromRegion(region);
    }
  }

  loadFromRegion(region: CdpFileParserRegion) {
    this.clearAndMakeInvalid();

    if (region.type != CdpCommonMultiFile.regionType) {
      // TODO Allow a single file?

       console.log('Wrong region type for multifile: ', region.type);
      return;
    }

    // There must be a header region.
    if (region.subregions.length == 0) {
      console.log('No header in multifile');
      return;
    }

    // First, read the header.
    const header: CdpCommonFileHeader = new CdpCommonFileHeader();
    header.loadFromRegion(region.subregions[0]);

    //console.log("MultiFile header:", header);

    if (header.fileType != CdpCommonFileType.MultiFile) {
      // This is not a valid multifile.
      // TODO Allow reading a single file as a multifile?

      return;
    }

    // Now we should have a set of zero or more individual files.
    const files: CdpCommonFile[] = [];

    let subregionIndex: number = 1;
    const numSubregions: number = region.subregions.length;
    for (; subregionIndex < numSubregions; subregionIndex++) {
      const file: CdpCommonFile = new CdpCommonFile();
      file.loadFromRegion(region.subregions[subregionIndex]);

      //console.log(`Loaded file ${subregionIndex}: ${file}`);

      if (!file.isValid()) {
        console.log(`Loaded invalid file ${subregionIndex}: ${JSON.stringify(file)}`);
      
        return;
      }

      files.push(file);
    }

    // The multifile is valid.
    this.header = header;
    this.files = files;
  }

  getAsLines(): string[] {
    return this.getAsRegion().getAsLines();
  }

  getAsRegion(): CdpFileParserRegion {
    const region: CdpFileParserRegion = new CdpFileParserRegion(CdpCommonMultiFile.regionType);

    region.subregions.push(this.header.getAsRegion());

    for (const file of this.files) {
      region.subregions.push(file.getAsRegion());
    }

    region.isValid_ = true;

    return region;
  }
}

export class CdpFileParserDelimiterLine {
  isBegin: boolean = false;
  isEnd: boolean = false;
  regionType: string = '';
  tag: string = ''; // optional
  args: string[] = []; // optional

  isValid(): boolean {
    return (this.isBegin || this.isEnd) && (this.regionType.length > 0);
  }
  
  isMatchingOpposite(other: CdpFileParserDelimiterLine): boolean {
    if (!this.isValid() || !other.isValid() || (this.isBegin == other.isBegin) || (this.regionType != other.regionType)) {
      return false;
    }

    const doesMatch = (this.tag == other.tag);

     //console.log("Is matching opposite:", doesMatch);
     //console.log("   this:", this);
     //console.log("   other:", other);

    return doesMatch;
  }

  static makeBeginLine(regionType: string, tag: string = '', args: string[] = []): CdpFileParserDelimiterLine {
    const delimiterLine: CdpFileParserDelimiterLine = new CdpFileParserDelimiterLine();
    delimiterLine.isBegin = true;
    delimiterLine.regionType = regionType.trim();
    delimiterLine.tag = tag.trim();
    delimiterLine.args = [...args].map((f) => f.trim());

    return delimiterLine;
  }

  static makeEndLine(regionType: string, tag: string = ''): CdpFileParserDelimiterLine {
    const delimiterLine: CdpFileParserDelimiterLine = new CdpFileParserDelimiterLine();
    delimiterLine.isEnd = true;
    delimiterLine.regionType = regionType.trim();
    delimiterLine.tag = tag.trim();
    
    return delimiterLine;
  }

  getAsText(): string {
    if (!this.isValid()) {
      return '';
    }
    
    const beginPrefix: string = '@@!Begin';
    const endPrefix: string = '@@!End';
    const prefix: string = (this.isBegin ? beginPrefix : endPrefix);
 
    const argString: string = (this.args.length > 0 ? ' ' + this.args.join(' '): '');
    return `${prefix}${this.regionType} ${this.tag}${argString}`;
  }

  static parseLine(line: string): CdpFileParserDelimiterLine {
    const delimiterLine: CdpFileParserDelimiterLine = new CdpFileParserDelimiterLine();

    const tokens: string[] = CdpFileParser.splitLineFirstTokenRest(line);
    if (tokens.length == 0) {
      return delimiterLine;
    }
    
    const beginPrefix: string = '@@!Begin';
    const endPrefix: string = '@@!End';

    const isBegin: boolean = tokens[0].startsWith(beginPrefix);
    const isEnd: boolean = tokens[0].startsWith(endPrefix);
    
    if (!isBegin && !isEnd) {
      return delimiterLine;
    }

    const prefix: string = (isBegin ? beginPrefix : endPrefix);

    const regionType: string = tokens[0].substring(prefix.length);

    if (regionType.length == 0) {
      // We expect to see at least '@@!BeginXXX' or '!@@EndXXX, not just '@@!Begin' or '@@!End,
      // so the set of lines is invalid.

      return delimiterLine;
    }

    delimiterLine.isBegin = isBegin;
    delimiterLine.isEnd = isEnd;
    delimiterLine.regionType = regionType;

    if (tokens.length > 1) {
      delimiterLine.tag = tokens[1]
    }

    if (tokens.length > 2) {
      delimiterLine.args = tokens.slice(2);
    }
    
    return delimiterLine;
  }
}

export class CdpFileParserRegion {
  isValid_: boolean = false; // for use by CdpFileParser
  // A region begins with '@@!BeginXXX' and ends with '@@!EndXXX'. The 'XXX' is the type.
  type: string = '';

  beginLineIndex: number = -1;
  endLineIndex: number = -1; // note: inclusive
  beginLine: CdpFileParserDelimiterLine = new CdpFileParserDelimiterLine();
  endLine: CdpFileParserDelimiterLine = new CdpFileParserDelimiterLine();
  bodyLines: string[] = [];

  subregions: CdpFileParserRegion[] = [];
  
  isValid(): boolean {
    return this.isValid_;
  }

  constructor(regionType: string, tag: string = '', args: string[] = []) {
    this.type = regionType;

    this.beginLine = CdpFileParserDelimiterLine.makeBeginLine(regionType, tag, args);
    this.endLine = CdpFileParserDelimiterLine.makeEndLine(regionType, tag);
  }

  getAsLines(): string[] {
    if (!this.isValid()) {
      return [];
    }

    let lines: string[] = [];

    lines.push(this.beginLine.getAsText());

    // TODO We don't currently really allow a region to contain both subregions and its own body text.
    lines = lines.concat(this.bodyLines);

    for (const subregion of this.subregions) {
      const subregionLines: string[] = subregion.getAsLines();
      lines = lines.concat(subregionLines);
    }

    lines.push(this.endLine.getAsText());

    return lines;
  }
}

export class CdpFileParserRegionSet {
  private isValid_: boolean = false;
  regions: CdpFileParserRegion[] = [];

  isValid(): boolean {
    return this.isValid_;
  }
}

export class CdpFileParser {
  // The line meta prefix must be the first character of a line
  // for the line to be considered a command.  Leading whitespace
  // is not ignored.
  private static lineMetaPrefix = '@@!';

  private static commentPrefix = '@@!#';
  private static sectionSeparator = '@@!----****----';
  private static fileTypePrefix = '@@!FileType';
  private static endSectionName = '__end__';

  private static prefixBeginHeader_ = '@@!BeginHeader';
  private static prefixEndHeader_ = '@@!EndHeader';

  private static isBlankOrCommentLine(line: string): boolean {
    // Do the quick checks first.
    if (line.length == 0 || line.startsWith(CdpFileParser.commentPrefix)) {
      return true;
    }

    const trimmedLine: string = line.trim();
    return (
      trimmedLine.length == 0 ||
      trimmedLine.startsWith(CdpFileParser.commentPrefix)
    );
  }

  private static skipBlankOrCommentLines_(
    lines: string[],
    lineBeginIndex: number,
    lineEndIndex: number
  ): number {
    const numLines: number = lines.length;
    const endIndex: number =
      lineEndIndex < 0 ? numLines : Math.min(lineEndIndex, numLines);

    let lineIndex: number = lineBeginIndex;
    for (; lineIndex < endIndex; lineIndex++) {
      if (!CdpFileParser.isBlankOrCommentLine(lines[lineIndex])) {
        break;
      }
    }

    return lineIndex;
  }

  static extractFields(text: string, delim: string = ' '): string[] {
    return text.split(delim).map((f) => f.trim());
  }

  static extractFieldsAfterPrefix(
    text: string,
    prefix: string
  ): [boolean, string[]] {
    // The text needs to start with the prefix followed by at least one space.
    if (!text.startsWith(prefix + ' ')) {
      return [false, []];
    }

    const fieldText: string = text.substring(prefix.length + 1);
    const fields: string[] = CdpFileParser.extractFields(fieldText);

    return [true, fields];
  }

  // Split a line into the first non-whitespace token and the rest of the line, if any.
  // Whitespace is trimmed from both the token and the rest of the line.
  public static splitLineFirstTokenRest(text: string): string[] {
    const trimmedText: string = text.trim();
    const fields: string[] = trimmedText.split(' ').map((f) => f.trim());
    return fields;
  }

  // <lineBeginIndex> is required to be at a line beginning with '@@!Begin'.
  public static getDelimitedRegion(
    lines: string[],
    lineBeginIndex: number,
    lineEndIndex: number
  ): CdpFileParserRegion | null{
    const numLines: number = lines.length;
    const endIndex: number =
      lineEndIndex < 0 ? numLines : Math.min(lineEndIndex, numLines);

    if (lineBeginIndex >= endIndex) {
      // There are no lines, so the region is invalid.
      return null;
    }


    const beginLine: string = lines[lineBeginIndex];

     //console.log("In getDelimitedRegion. Expecting begin. line=", beginLine)

    // The first line needs to be a region begin line.
    let beginDelimiterLine: CdpFileParserDelimiterLine = CdpFileParserDelimiterLine.parseLine(beginLine);
    if (!beginDelimiterLine.isValid() || !beginDelimiterLine.isBegin) {
      // This is not the start of a region, so the set of lines is invalid.
      console.log("In getDelimitedRegion. Invalid begin line=", beginLine);

      return null;
    }

    const regionBeginLineIndex = lineBeginIndex;

    let lineIndex = lineBeginIndex + 1;
    let endDelimiterLine: CdpFileParserDelimiterLine | null = null;
    let regionEndLineIndex: number = -1;
    const regionBodyLines: string[] = [];

    // Now keep reading util we find the expected matching end line.
    // TODO Note: This approach does not allow arbitrary nesting. However,
    //      our current usage doesn't require such nesting.
    for (; lineIndex < endIndex; lineIndex++) {
      const line: string = lines[lineIndex];

      if (CdpFileParser.isBlankOrCommentLine(line)) {
        continue;
      }

      const delimiterLine: CdpFileParserDelimiterLine = CdpFileParserDelimiterLine.parseLine(line);
      if (delimiterLine.isValid() && delimiterLine.isMatchingOpposite(beginDelimiterLine)) {
         //console.log("Found matching delimiter line:", delimiterLine);

        // We've found the end of the region.
        endDelimiterLine = delimiterLine;
        regionEndLineIndex = lineIndex;

        break;
      }

      // This is a body line of the region.
       //console.log("Region body line:", line);

      regionBodyLines.push(line);
    }

    if (!endDelimiterLine) {
      console.log("Did not find end delimiter for region");
      return null;
    } 


    const region: CdpFileParserRegion = new CdpFileParserRegion(beginDelimiterLine.regionType);
    region.beginLineIndex = regionBeginLineIndex;
    region.beginLine = beginDelimiterLine;
    region.endLineIndex = regionEndLineIndex;
    region.endLine = endDelimiterLine;

     //console.log("Intial region body lines:", regionBodyLines);

    // The body lines we have now are either the final body lines of the region or else one or
    // more subregions.
    // Note: We do not currently allow both non-subregion body lines and subregions together.
    const subregions: CdpFileParserRegion[] = [];

    if (regionBodyLines.length > 0) {
      const delimiterLine: CdpFileParserDelimiterLine = CdpFileParserDelimiterLine.parseLine(regionBodyLines[0]);
      if (!delimiterLine.isValid()) {
        // The first line of the body lines is not a region begin or end line, so we
        // consider all the body lines to be raw text rather than nested subregions.

        // We don't need to do anything here.
        region.bodyLines = regionBodyLines;
      } else if (delimiterLine.isEnd) {
        // This is an error.  We have an end without a matching begin.
        console.log("Parse error: End without matching begin. line=", regionBodyLines[0]);
        return null;
      } else {
        // We have a valid region begin, so the body lines should all be a set of subregions.
        let subregionLineIndex: number = 0;
        const numBodyLines: number = regionBodyLines.length;

         //console.log("Parsing subregions from lines.", numBodyLines);

        for ( ; subregionLineIndex < numBodyLines ; ) {
          const subregion: CdpFileParserRegion | null = CdpFileParser.parseNextRegion(regionBodyLines, subregionLineIndex, numBodyLines);
          if (!subregion) {
            // This is an error, since we don't allow mixing subregions and ordinary body lines.
            console.log("Parse error: Expected region. line=", regionBodyLines[subregionLineIndex]);

            return null;
          }

          // The subregion is valid.
          subregions.push(subregion);

           //console.log("Got subregion:", subregion);
           //console.log("Next line: ", subregion.endLineIndex + 1)
          subregionLineIndex = subregion.endLineIndex + 1;
        }
      }
    }

    region.subregions = subregions;
    region.isValid_ = true;

     //console.log("Parsed region: ", JSON.stringify(region));

    return region;
  }

  public static parseNextRegion(lines: string[], beginLineIndex: number = 0, endLineIndex: number = -1): CdpFileParserRegion | null {

    // A file consists of nested delimited regions. Lines that are completely
    // blank (except for whitespace) or comments are stripped.
    // All other lines must occur within delimited regions, which start with
    // '@@!BeginXXX [tag]' and end with '@@!EndXXX [tag]'.
    //
    // TODO Arbitrary nesting isn't fully supported, because of the possibility of
    //      matching an inner end tag to an outer begin tag.  However, that
    //      isn't an issue with current usage.

    const numLines: number = lines.length;
    const endIndex: number =
    endLineIndex < 0 ? numLines : Math.min(endLineIndex, numLines);

    if (beginLineIndex >= endIndex) {
      // There are no lines, so the region is invalid.
       console.log("No lines");

      return null;
    }

    for (let lineIndex = beginLineIndex; lineIndex < numLines; lineIndex++) {
      const line: string = lines[lineIndex];
       //console.log(`Line ${lineIndex}: ${line}`);

      if (CdpFileParser.isBlankOrCommentLine(line)) {
        continue;
      }

       //console.log("Parsing line. Expecting region begin. line=", line);

      // The next non-blank line needs to be a region begin line.
      const fields: string[] = CdpFileParser.splitLineFirstTokenRest(line);
      if (fields.length == 0 || !fields[0].startsWith('@@!Begin')) {
        // This is not the start of a region, so the set of lines is invalid.
        return null;
      }

      // Now we can read the region, which may contain subregions.
      const region: CdpFileParserRegion | null = CdpFileParser.getDelimitedRegion(
        lines, lineIndex, endLineIndex);

      return (region && region.isValid() ? region : null);
    } 

    // We never found a begin line.
    return null;
  }
}
