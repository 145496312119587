<div class="container justify-content-center my-2" style="max-width: 600px">
  <div class="d-flex justify-content-center m-2">
    <h3 class="text-color-primary">Feedback</h3>
  </div>

  <div class="justify-content-center text-center m-2">
    <p>We'd love to hear any feedback or questions that you have.</p>
  </div>
  <div class="card">
    <div>
      <label class="col-form-label mx-2">From</label>
    </div>
    <a class="form-control mx-1 px-1" style="width: 95%">{{ from }}</a>
    <br />
    <div class="row mx-2">
      <div class="col col-auto px-0">
        <label class="col-form-label mx-0 px-0">Topic</label>
      </div>
      <div class="col col-auto">
        <select class="form-select mx-1" [(ngModel)]="topic" name="topic">
          @for (topic of allTopics; track $index) {
          <option>{{ topic }}</option>
          }
        </select>
      </div>
    </div>
    <div class="vertical-break"></div>

    <div class="m-2">
      <textarea
        class="form-control"
        rows="8"
        [(ngModel)]="message"
        placeholder="Type your message here."
        name="message"
      ></textarea>
    </div>
  </div>

  <div class="mt-2 text-center">
    @if (!isFormValid()) {
    <p style="color: red">One or more fields are missing or invalid.</p>
    }
    <div class="row">
      <div class="col">
        <button
          class="btn btn-primary profile-button"
          type="submit"
          (click)="onClickSubmit()"
          [disabled]="!isFormValid() || isOperationInProgress"
        >
          Submit
        </button>
      </div>
      <div class="col col-auto">
        <span>
          <app-cdp-progress-spinner
            [operationSet]="operationSet"
          ></app-cdp-progress-spinner>
        </span>
      </div>
    </div>
  </div>
</div>
