<div class="container rounded bg-white mt-3">
  <div class="d-flex justify-content-between align-items-center">
    <h4 class="text-right">Business Profile</h4>
  </div>
  <div class="row">
    <div class="col col-6">
      <app-cdp-business-profile-basic-info
        [info]="profile.basicInfo"
        [details]="profile.details"
      ></app-cdp-business-profile-basic-info>
    </div>
    <!--
  <app-cdp-business-profile-details [details]="profile.details"></app-cdp-business-profile-details>
  <br />
  -->
    <div class="col" style="height:100%;">
      <app-cdp-business-profile-description
        [description]="profile.description"
      ></app-cdp-business-profile-description>
    </div>
  </div>
</div>
