import { ApplicationRef, Injectable } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { AuthenticatorService } from '@aws-amplify/ui-angular';

import awsExports from '../../../aws-exports';
import { AppComponent } from 'src/app/app.component';
import { CdpEvent, CdpEventCategory } from '../cdp-event';

export class CdpAuthUser {
  aud: string = '';
  auth_time: Number = 0;
  cognito_username: string = '';
  email: string = '';
  email_verified: boolean = false;
  event_id: string = '';
  exp: Number = 0;
  iat: Number = 0;
  iss: string = '';
  jti: string = '';
  origin_jti: string = '';
  sub: string = '';

  CdpAuthUser() {}

  static makeUserFromPayload(payload: { [key: string]: any }): CdpAuthUser {
    var user: CdpAuthUser = new CdpAuthUser();

    user.aud = payload['aud'];
    user.email = payload['email'];
    user.email_verified = payload['email_verified'];

    for (const [key, value] of Object.entries(payload)) {
      //console.log(`Payload ${key}: ${value}`);

      switch (key) {
        case 'aud':
          user.aud = value;
          break;
        case 'auth_time':
          user.auth_time = value;
          break;
        case 'cognito:username':
          user.cognito_username = value;
          break;
        case 'email':
          user.email = value;
          break;
        case 'email_verified':
          user.email_verified = value;
          break;
        case 'event_id':
          user.event_id = value;
          break;
        case 'exp':
          user.exp = value;
          break;
        case 'iat':
          user.iat = value;
          break;
        case 'iss':
          user.iss = value;
          break;
        case 'jti':
          user.jti = value;
          break;
        case 'origin_jti':
          user.origin_jti = value;
          break;
        case 'sub':
          user.sub = value;
          break;

        default:
          break;
      }
    }

    return user;
  }

  static async getCurrentAuthUser(): Promise<CdpAuthUser> {
    try {
      const session: CognitoUserSession = await Auth.currentSession();
      const idToken: CognitoIdToken = session.getIdToken();
      const payload = idToken.payload;

      var user: CdpAuthUser = CdpAuthUser.makeUserFromPayload(payload);

      return user;
    } catch (error) {
      return new CdpAuthUser();
    }
  }
}

export class CdpJwt {
  constructor(public accessToken: string, public idToken: string) {}

  public isEmpty(): boolean {
    return (this.accessToken == "") && (this.idToken == "");
  }

  public static async getCurrentSessionTokens(): Promise<CdpJwt> {
    try {
      const session: CognitoUserSession = await Auth.currentSession();

      const accessToken: CognitoAccessToken = session.getAccessToken();
      const idToken: CognitoIdToken = session.getIdToken();
      const accessJwt: string = accessToken.getJwtToken();
      const idJwt: string = idToken.getJwtToken();

      return new CdpJwt(accessJwt, idJwt);
    } catch {
      return new CdpJwt('', '');
    }
  }

  public static async getCurrentSessionCognitoIdToken(): Promise<CognitoIdToken | null> {
    try {
      const session: CognitoUserSession = await Auth.currentSession();
      const idToken: CognitoIdToken = session.getIdToken();

      return idToken;
    } catch {
      return null;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class CdpAuthService {

  constructor(
    private authenticator: AuthenticatorService,
    private appRef: ApplicationRef
  ) {
    Auth.configure(awsExports);

    this.startListen();
  }

  startListen() {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.processAuthEvent_(payload);
    });
  }
  async getCurrentSessionJwtTokens(): Promise<CdpJwt> {
    return CdpJwt.getCurrentSessionTokens();
  }

  async getCurrentSessionCognitoIdToken(): Promise<CognitoIdToken | null> {
    return CdpJwt.getCurrentSessionCognitoIdToken();
  }

  async getCurrentAuthUser(): Promise<CdpAuthUser> {
    return CdpAuthUser.getCurrentAuthUser();
  }

  async isUserSignedIn(): Promise<boolean> {
    const session = await this.maybeGetCurrentSession();

    //console.log("isUserSignedIn auth session:", session);
    
    return session ? true : false;
  }

  async isAuthorizedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
      });
      //console.log('Authenticated user: ', user);
      return true;
    } catch (err) {
      //console.log('No authenticated user: ', err);
      return false;
    }
  }

  async signOut(): Promise<any> {
    // Note that this function only signs out from Cognito locally.
    // This should be accompanied by a call to disconnect the current
    // session via SessionManager.disconnectCdpSession().
    try {
      return Auth.signOut();
    } catch (error) {
      //console.log('error signing out: ', error);
    }
  }

  async maybeGetCurrentAuthenticatedUser(): Promise<CognitoUser | null> {
    try {
      const user = await Auth.currentAuthenticatedUser({
        // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        bypassCache: false,
      });

      return user;
    } catch (error) {
      return null;
    }
  }

  async maybeGetCurrentSession(): Promise<CognitoUserSession | null> {
    //console.log("Maybe get current Cognito session:");

    try {
      const session = await Auth.currentSession();

      //console.log("Cognito session: ", session);

      return session;
    } catch (error) {
      //console.log("Error getting Cognito session: ", error);

      return null;
    }
  }

  private static convertHubAuthEvent_(hubEvent: string): CdpEvent {
    var e: CdpEvent = new CdpEvent(hubEvent, CdpEventCategory.Auth, hubEvent, null);

    //console.log("Hub event: ", hubEvent);
    //console.log("Converted to CdpEvent: ", e.getType());

    return e;
  }

  private async processAuthEvent_(
    payload: any /* HubPayload */
  ): Promise<void> {
    //console.log('CdpAuthService Processing auth event: ', JSON.stringify(payload));

    // Convert from Amplify Hub auth event to our own event type.
    const e: CdpEvent = CdpAuthService.convertHubAuthEvent_(payload.event);

    const rootAppComponent: AppComponent = this.appRef.components[0].instance as AppComponent;
    rootAppComponent.emit(e);
  }
}
