export class SoundsNote {
  constructor() {
    this.duration = 500;
    this.value = [];
  }
  load(data) {
    if (!data) {
      return;
    }
    if (data.duration !== undefined) {
      this.duration = data.duration;
    }
    if (data.value !== undefined) {
      this.value = data.value;
    }
  }
}