"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = assertString;
function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }
  return _typeof(obj);
}
function assertString(input) {
  var isString = typeof input === 'string' || input instanceof String;
  if (!isString) {
    var invalidType = _typeof(input);
    if (input === null) invalidType = 'null';else if (invalidType === 'object') invalidType = input.constructor.name;
    throw new TypeError("Expected a string but received a ".concat(invalidType));
  }
}
module.exports = exports.default;
module.exports.default = exports.default;