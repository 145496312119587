<div class="card">
  <div class="mt-2 text-center">
    <h5 class="card-title mx-3 my-1">Domain Verification Status</h5>
  </div>
  <div class="vertical-break"></div>
  <div class="mt-2 text-center">
    <button
      #verification_status_button
      class="btn btn-primary profile-button mx-2"
      type="submit"
      (click)="onClickRefreshSenders()"
    >
      Recheck verification status
    </button>
  </div>
  <div class="card-body">
    @if (senderSet.hasVerifiedDomain()) {
    <p>
      Congratulations! You have already verified your domain
      <em>{{ senderSet.getVerifiedDomain() }}</em
      >.
    </p>
    } @else if (senderSet.hasPendingDomain()) {
    <p>
      The verification of your domain
      <em>{{ senderSet.getPendingDomain() }}</em> is still pending. Domain
      verification often completes within a few hours, but may sometimes take as
      long as 48 hours. If more than 48 hours have passed since you requested
      domain verification, please email us at
      <a href="mailto:support@inboxease.com"
        >support&#64;inboxease.com</a
      >
      so that we can help fix the problem.
    </p>
    } @else if (senderSet.hasFailedDomain()) {
    <p>
      The verification of your domain
      <em>{{ senderSet.getFailedDomain() }}</em> failed. This will occur if you
      have not added the appropriate TXT record to your domain's DNS records.
      Please start the domain verification process again. If you need help,
      please email us at
      <a href="mailto:support@inboxease.com"
        >support&#64;inboxease.com</a
      >
      so that we can help fix the problem.
    </p>
    } @else {
    <p>
      To start verifying your domain, we need to confirm that you control at
      least one email address at that domain. We'll send a verification email to
      the address you provide.
    </p>
    }
  </div>
</div>

@if (senderSet.hasPendingDomain()) {
  <div class="vertical-break"></div>
  <div class="vertical-break"></div>
<div class="card">
  <div class="mt-2 text-center">
    <h5 class="card-title mx-3 my-1">Domain Verification Instructions</h5>
  </div>
  <div class="vertical-break"></div>
  <div class="mt-2 text-center">
  <p>Your domain verification token is:</p><p> <strong>{{senderSet.getDomainToken()}}</strong></p>
  </div>
  <div class="mt-2 text-left px-4">
  <p>In order to complete the verification of your domain, you must add a TXT record to your domain's DNS records with the token as the value.</p>
  <p>The particular way to modify your DNS records will vary depending on your hosting provider.  As an example, these are 
  the instructions for Bluehost. <a href="https://www.bluehost.com/help/article/dns-management-add-edit-or-delete-dns-entries"></a></p>
  <p>Contact your hosting provider if you need further instructions.</p>  
</div>
</div>
<div class="vertical-break"></div>
<div class="vertical-break"></div>
}

@if (senderSet.hasVerifiedEmail() && !senderSet.hasVerifiedDomain()) {
<!-- The user has verified at least one email but has not started domain verification.
    Note: We should also check for the case where domain verification was attempted but failed.
    We allow trying to verify a domain again even if there is a pending domain verification,
    in case the user specified the wrong domain on a previous attempt.
  -->
<div class="vertical-break"></div>
<div class="container">
  <div class="form-group row">
    <div class="col col-auto">
      <label class="col-form-label">Domain</label>
    </div>
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="mycompany.com"
        required
        [(ngModel)]="domain.domain"
        (change)="onDomainChanged()"
      />
    </div>
  </div>
</div>
<div class="vertical-break"></div>
<div class="mt-2 text-center">
  <button
    #domain_verification_button
    class="btn btn-primary profile-button mx-2"
    type="submit"
    (click)="onClickVerifyDomain()"
    disabled
  >
    Start domain verification
  </button>
  <button
    class="btn btn-outline-secondary profile-button mx-2"
    type="submit"
    (click)="onClickCancel()"
  >
    Cancel
  </button>
</div>
}

<div class="container">
  <div class="form-group row">
    <div class="col col-auto">
      <label class="col-form-label">Email</label>
    </div>
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="sales@mycompany.com"
        name="givenName"
        required
        [(ngModel)]="email.email"
        (change)="onEmailChanged()"
      />
    </div>
  </div>
</div>
<div class="vertical-break"></div>
<div class="mt-2 text-center">
  <button
    #email_verification_button
    class="btn btn-primary profile-button mx-2"
    type="submit"
    (click)="onClickSendVerificationEmail()"
    disabled
  >
    Send verification email
  </button>
  <button
    class="btn btn-outline-secondary profile-button mx-2"
    type="submit"
    (click)="onClickCancel()"
  >
    Cancel
  </button>
</div>

<div class="vertical-break"></div>
<app-cdp-progress-spinner
  [operationSet]="this.operationSet"
></app-cdp-progress-spinner>
<div class="vertical-break"></div>
<p>{{ operationResultMessage }}</p>
@for (sender of senderSet.senders; track $index) {
<p>{{ sender.name }}: {{ sender.status }}</p>
}
