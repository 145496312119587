import { CdpFileInfo } from "../files/cdp-file";

class CdpImageInfo {
    name: string = "";
    fileSize: number = 0;
    progress: number = 0;
}

export class CdpImage {
  imageFile: any = null;
  url: string = '';
  imageInfo: CdpImageInfo = new CdpImageInfo();
  fileInfo: CdpFileInfo | null = null;

  label: string = "";
  imageHtml: string = "";

  isEmpty(): boolean {
    return this.url.length == 0;
  }

  static makeUncachedUrl(url: string): string {
    // Create a unique URL in case the browser might have cached an outdated
    // version of the image.
    const timestamp = new Date().getTime();
    const uniqueUrl = url + '?_=' + timestamp;

    return uniqueUrl;
  }
}
