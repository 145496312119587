import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, concatMap, from, of, map } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CdpJwt } from '../core/cdp-auth/auth.service';
import { CdpBusinessProfile } from '../core/business/cdp-business';
import {
  CdpBackendCoreService,
  CdpBackendResponse,
} from './cdp-backend-core.service';
import { CdpUserProfile } from '../core/cdp-user';

export class CdpUpdateProfileResponse extends CdpBackendResponse {
  static makeErrorResponse(e: any): CdpUpdateProfileResponse {
    let response: CdpUpdateProfileResponse = new CdpUpdateProfileResponse();
    response.appendError(e);

    return response;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CdpBackendProfileService {
  constructor(
    private http: HttpClient,
    private backendCore: CdpBackendCoreService
  ) {}

  private updateProfilesWithJwt_(
    jwt: CdpJwt,
    userProfile: CdpUserProfile,
    businessProfile: CdpBusinessProfile
  ): Observable<CdpUpdateProfileResponse> {
    try {
      const url = this.backendCore.makeServerUrlWithJwt('update_profiles', jwt);

      const body = {
        user: userProfile,
        business: businessProfile,
      };

      console.log('Posting user and business profile update to:', url);
      let observable = this.http.post<CdpUpdateProfileResponse>(url, body).pipe(
        catchError(
          // TODO
          this.handleError<CdpUpdateProfileResponse>(
            'Update user and business profiles'
          )
        )
      );

      return observable;
    } catch (error) {
      console.log('Server error updating user and business profiles:', error);

      return of(CdpUpdateProfileResponse.makeErrorResponse(error));
    }
  }

  updateUserAndBusinessProfiles(
    userProfile: CdpUserProfile,
    businessProfile: CdpBusinessProfile
  ): Observable<CdpUpdateProfileResponse> {
    console.log('Start backend call: updateBusinessProfile');

    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    return jwtObservable.pipe(
      concatMap((jwt) =>
        this.updateProfilesWithJwt_(jwt, userProfile, businessProfile)
      )
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.log('Error: ', error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
