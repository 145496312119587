import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CdpSendEmailService,
  CdpSendEmailServiceEvent,
} from '../../send/cdp-send-email.service';
import {
  CdpEmailData,
  CdpSendEmailResponse,
  CdpSendEmailTemplate,
  CdpSendEmailTemplateData,
  CdpSendEmailTemplateDestination,
} from '../../cdp-email';
import { FormsModule } from '@angular/forms';

import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { CdpEmailTemplateTileComponent } from '../../../email/template/cdp-email-template-tile/cdp-email-template-tile.component';
import { CdpEmailTemplateFile } from '../../template/cdp-email-template-file';
import { CdpCloneable } from '../../template/email-template-editor/email-template-editor.component';
import { CdpSessionManagerService } from '../../../cdp-session-manager.service';
import { CdpSession } from '../../../core/cdp-session';
import { CdpBusiness } from '../../../core/business/cdp-business';
import { NavigationEnd, Router } from '@angular/router';
import {
  CdpCustomerDatabase,
  CdpCustomerDatabaseRecord,
  CdpCustomerRecipientSet,
} from '../../../customers/cdp-customer-database';
import { CdpCustomerDatabaseService } from '../../../customers/cdp-customer-database.service';
import { CdpEmailSenderSenderSet } from '../../../core/email/cdp-email-sender';
import { CdpProgressOperationSet, CdpProgressOperationTag } from '../../../ui/progress/cdp-progress';
import { CdpProgressSpinnerComponent } from '../../../ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';
import { CdpRoutes } from '../../../core/router/cdp-routes';
import { CdpEmailTemplateManagerEvent, CdpEmailTemplateManagerFile, CdpEmailTemplateManagerOwnerCategory, CdpEmailTemplateManagerService } from '../../template/cdp-email-template-manager.service';
import { CdpEmailTemplateId, CdpEmailTemplateInfo } from '../../template/cdp-email-template-info';
import { CdpCustomerDatabaseListPickerComponent } from 'src/app/customers/cdp-customer-database-list-picker/cdp-customer-database-list-picker.component';


@Component({
  selector: 'app-cdp-email-campaign-create',
  standalone: true,
  imports: [CommonModule, FormsModule, CdpProgressSpinnerComponent, CdpCustomerDatabaseListPickerComponent, CdpEmailTemplateTileComponent,
  NgbDatepicker],
  templateUrl: './cdp-email-campaign-create.component.html',
  styleUrl: './cdp-email-campaign-create.component.sass'
})
export class CdpEmailCampaignCreateComponent implements OnInit, AfterViewInit {
  @ViewChild('template_tile') templateTile!: CdpEmailTemplateTileComponent;

  private thisUrl_: string = '';
  private businessName_: string = '';
  canCreateCampaign: boolean = true;

  private currentDatabase_: CdpCustomerDatabase | null = null;
  listNames: string[] = [];
  currentListName: string = '';
  numRecipients: number = 0;

  selectedTemplateName: string = '';
  selectedTemplateId: CdpEmailTemplateId | null = null;
  templateManagerFiles: CdpEmailTemplateManagerFile[] = [];
  templateNames: string[] = [];


  senderDisplayNameRaw: string = '{{business}}';
  senderDisplayName: string = '{{business}}';
  senderEmailAddress: string = 'support@inboxease.com';
  unverifiedSenderEmailAddress: string = 'support@inboxease.com';
  verifiedSenderEmailAddresses: string[] = [];
  verifiedDomain: string = '';
  senderEmailAddressOptions: string[] = [this.unverifiedSenderEmailAddress];

  subject: string = '';

  maxNumSubjectsToDisplay = 4;

  subjectSelectOptionsRaw: string[] = [
    'Hello from {{business}}!',
    'Hello from {{business}}, {{firstname}}!',
    'Greetings from {{business}}: A Warm Welcome!',
    'Exclusive Deals Inside: Thanks, {{firstname}}!',
    "Unwrap Savings with {{business}}'s Holiday Specials 🎁",
    "{{firstname}}, Your VIP Access to {{business}}'s New Arrivals",
    "Discover {{business}}'s Latest: Your Style Update Awaits!",
    'Stay in the Loop: {{business}} News and Updates Inside',
    "Elevate Your Experience with {{business}}'s New Features",
    "Curated Just for You: Explore {{business}}'s Best Picks",
    'Insider Insights: Be the First to Know with {{business}}',
    "Explore the Unique: {{business}}'s Exclusive Collection",
    'Cheers to a festive season with {{business}}, {{firstname}}!',
    'New Year, New Beginnings – Celebrate with {{business}}, {{firstname}}!',
    "Elevate Your Everyday: {{business}}'s Latest Inspirations Await",
    'Discover Timeless Elegance with {{business}}, {{firstname}}',
    "Immerse Yourself in Luxury: {{business}}'s Finest Creations",
    'Unlock Your Potential with {{business}} – Empower Your Style',
    'Inspired Living: {{business}} Brings You a World of Beauty',
    'Step into Comfort and Style with {{business}}, {{firstname}}!',
    "Curate Your Sanctuary: Explore {{business}}'s Home Essentials",
    "Redefine Your Routine: {{business}}'s Lifestyle Innovations",
    'Indulge in Self-Care with {{business}} – Because You Deserve It',
    'Personalized Perfection: Tailor Your Look with {{business}}',
  ];

  subjectSelectOptions: string[] = [];
  subjectSelectOptionsDisplayed: string[] = [];
  private currentSubjectSelectOptionIndex_ = 0;

  replacements = new Map();

  templateManagerFile: CdpEmailTemplateManagerFile | null = null;
  emailTemplateManagerFile: CdpEmailTemplateManagerFile | null = null;

  businessNameKey: string = '{{business}}';
  givenNameKey: string = '{{firstname}}';
  familyNameKey: string = '{{lastname}}';
  emailKey: string = '{{email}}';

  defaultReplacements = new Map();

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();

  get isOperationInProgress() {
    return this.operationSet.isOperationInProgress;
  }

  constructor(
    private router: Router,
    private databaseService: CdpCustomerDatabaseService,
    private sendEmailService: CdpSendEmailService,
    private templateManager: CdpEmailTemplateManagerService,
    private sessionManager: CdpSessionManagerService
  ) {
    this.defaultReplacements.set(this.givenNameKey, 'friend');
    this.defaultReplacements.set(this.familyNameKey, '');
    this.defaultReplacements.set(this.emailKey, '');

    this.sendEmailService.eventEmitter.subscribe(
      (e: CdpSendEmailServiceEvent) =>
        this.processEmailSenderVerificationEvent_(e)
    );

    this.templateManager.eventEmitter.subscribe((e) => this.processTemplateManagerEvent_(e));
    this.onReload();
  }

  get senderSet(): CdpEmailSenderSenderSet {
    return this.sendEmailService.getCurrentSenderSet();
  }

  get templateFile(): CdpEmailTemplateFile | null {
    if (this.templateManagerFile) {
      return this.templateManagerFile.templateFile;
    } else {
      return null;
    }
  }

  private senderSetChanged_() {
    // Determine if there are verified email addresses and/or a verified domain.
    const set: CdpEmailSenderSenderSet = this.senderSet;

    const verifiedAddresses: string[] = set.getVerifiedEmailAddresses();

    let senderOptions: string[] = [];
    if (this.verifiedSenderEmailAddresses.length == 0) {
      senderOptions.push(this.unverifiedSenderEmailAddress);
    } else {
      senderOptions = verifiedAddresses;
    }

    this.verifiedSenderEmailAddresses = verifiedAddresses;
    this.verifiedDomain = set.getVerifiedDomain();
    this.senderEmailAddressOptions = senderOptions;

    if (senderOptions.indexOf(this.senderEmailAddress) < 0) {
      // The current sender address is not a valid option.
      this.senderEmailAddress = senderOptions[0];
    }
  }

  onSenderEmailAddressChanged() {}

  private processEmailSenderVerificationEvent_(e: CdpSendEmailServiceEvent) {
    this.senderSetChanged_();
  }

  private async refreshTemplates_() {
    this.templateNames = [];

    const templateManagerFiles: CdpEmailTemplateManagerFile[] =
    await this.templateManager.getTemplateManagerFilesInOwnerCategory(
      CdpEmailTemplateManagerOwnerCategory.BusinessOrUser);

    for (const templateManagerFile of templateManagerFiles) {
      const templateInfo: CdpEmailTemplateInfo | null = templateManagerFile.templateInfo;
      if (templateInfo) {
        // TODO Names need to be forced to be unique.
        this.templateNames.push(templateInfo.name);
      }
    }
  }

  private async processTemplateManagerEvent_(e: CdpEmailTemplateManagerEvent) {
    // TODO Just get data from event.
    this.refreshTemplates_();
  }

  async onReload(firstTime: boolean = false) {
    this.sendEmailService.checkVerificationStatus();
    this.refreshTemplates_();

    this.templateManagerFile =
      this.templateManager.getCurrentTemplateManagerFile();

    const session: CdpSession | null =
      await this.sessionManager.getCurrentCdpSession();

    this.businessName_ = '';

    if (session) {
      const business: CdpBusiness | null = session.business;
      if (business) {
        this.businessName_ = business.profile.basicInfo.businessName;
      }
    }

    this.businessChanged_();

    //console.log('Reload. subject=', this.subject);
    if (this.subject.length == 0) {
      this.selectSubjectOption_(0);
    }

    this.onTemplateFileChanged();
  }

  private makeSubjectOptionsFromRaw_() {
    this.subjectSelectOptions.length = 0;
    for (const option of this.subjectSelectOptionsRaw) {
      this.subjectSelectOptions.push(
        option.replace(this.businessNameKey, this.businessName_)
      );
    }
  }

  private businessChanged_() {
    // Replace "{{business}}" in subject, senderDisplayName, and subject options.
    this.subject = this.subject.replaceAll(
      this.businessNameKey,
      this.businessName_
    );
    this.senderDisplayName = this.senderDisplayNameRaw.replaceAll(
      this.businessNameKey,
      this.businessName_
    );

    this.makeSubjectOptionsFromRaw_();
  }

  private generateAndAppendMoreSubjectOptionsRaw_(numNewSubjects: number) {
    // TODO TODO Use generative AI!!!!
    // const newSubjectsRaw: string[] = []
    // ... append new subjects
    // this.subjectOptionsRawChanged_();
  }

  onClickGenerateSubjects() {
    this.generateMoreSubjectOptionsRaw_(this.maxNumSubjectsToDisplay);
  }

  private getCurrentPageStartIndex_(): number {
    const curPage: number = Math.floor(
      this.currentSubjectSelectOptionIndex_ / this.maxNumSubjectsToDisplay
    );
    const curPageStartIndex: number = curPage * this.maxNumSubjectsToDisplay;

    return curPageStartIndex;
  }

  private showCurrentSubjectPage() {
    const curPageStartIndex: number = this.getCurrentPageStartIndex_();

    const curPageEndIndex = Math.min(
      curPageStartIndex + this.maxNumSubjectsToDisplay,
      this.subjectSelectOptions.length
    );

    const numOnPage: number = curPageEndIndex - curPageStartIndex + 1;

    this.subjectSelectOptionsDisplayed.length = 0;
    for (let i = curPageStartIndex; i < curPageEndIndex; i++) {
      this.subjectSelectOptionsDisplayed.push(this.subjectSelectOptions[i]);
    }
  }

  private showNextSubjectPage() {
    const currentPageStartIndex: number = this.getCurrentPageStartIndex_();
    let nextPageStartIndex: number =
      currentPageStartIndex + this.maxNumSubjectsToDisplay;

    if (nextPageStartIndex >= this.subjectSelectOptionsRaw.length) {
      nextPageStartIndex = 0;
    }

    this.currentSubjectSelectOptionIndex_ = nextPageStartIndex;
    this.showCurrentSubjectPage();
  }

  private generateMoreSubjectOptionsRaw_(numNewSubjects: number) {
    const prevNumSubjects: number = this.subjectSelectOptionsRaw.length;
    this.generateAndAppendMoreSubjectOptionsRaw_(numNewSubjects);

    this.showNextSubjectPage();
  }

  private selectSubjectOption_(displayedIndex: number) {
    const currentPageStartIndex: number = this.getCurrentPageStartIndex_();
    let index = currentPageStartIndex + displayedIndex;

    if (index < 0) {
      index = 0;
    } else if (index >= this.subjectSelectOptions.length) {
      index = this.subjectSelectOptions.length - 1;
    }

    this.subject = this.subjectSelectOptions[index];
    this.currentSubjectSelectOptionIndex_ = index;

    this.showCurrentSubjectPage();
  }

  onClickGeneratedSubject(index: number) {
    this.selectSubjectOption_(index);
  }

  async ngOnInit(): Promise<void> {
    this.thisUrl_ = this.router.url;

    const onReload = this.onReload.bind(this);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //console.log('Navend event:', JSON.stringify(event));
        if (event.url == this.thisUrl_) {
          // route is restored
          //console.log('Navend event match:', this.thisUrl_);
          onReload();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.templateTile.allowShowDeleteButton = false;
    this.templateTile.allowShowEditButton = false;
    this.templateTile.allowShowSendButton = false;

    this.onReload(true);
  }

  showSendEmailResponse(sendEmailResponse: CdpSendEmailResponse, operationTag: CdpProgressOperationTag): void {
    //console.log('Send email response: ', sendEmailResponse);
    const succeeded: boolean = sendEmailResponse.errors.length == 0;
    const message: string = (succeeded ? 'Send email succeeded.' : 'Send email failed.');

    this.operationSet.updateOperationFinal(operationTag, succeeded, message);

    if (succeeded) {
      this.router.navigate([CdpRoutes.SendEmailComplete]);
    } else {
      // TODO Need to go to a failure page!
    }
  }

  makeSendEmailTemplate(): CdpSendEmailTemplate | null {
    const templateFile: CdpEmailTemplateFile | null = this.templateFile;
    if (templateFile) {
      if (!templateFile.hasCompiledHtml()) {
        console.log("Cannot send email. Final HTML not available.");
        return null;
      }

      const html: string = templateFile.getCompiledHtml();

      const sendTemplate: CdpSendEmailTemplate = new CdpSendEmailTemplate();

      sendTemplate.subject = this.subject;
      sendTemplate.html = html;

      // TODO sendTemplate.text

      return sendTemplate;
    } else {
      return null;
    }
  }

  makeReplacementDataForRecipient_(
    customerRecord: CdpCustomerDatabaseRecord
  ): CdpSendEmailTemplateData {
    const templateData: CdpSendEmailTemplateData =
      new CdpSendEmailTemplateData();

    const recipientEmail: string = customerRecord.email;

    /*
    console.log(
      `Email: ${recipientEmail} customer: ${JSON.stringify(customerRecord)}`
    );
  */

    let givenName: string = customerRecord.givenName;
    let familyName: string = customerRecord.familyName;

    if (givenName.length == 0) {
      givenName = this.defaultReplacements.get(this.givenNameKey) || '';
    }

    if (familyName.length == 0) {
      familyName = this.defaultReplacements.get(this.familyNameKey) || '';
    }

    const replacementData = new Map();
    replacementData.set(this.emailKey, recipientEmail);
    replacementData.set(this.givenNameKey, givenName);
    replacementData.set(this.familyNameKey, familyName);

    templateData.email = recipientEmail;
    templateData.firstname = givenName;
    templateData.lastname = familyName;

    templateData.data = replacementData;

    /*
    console.log(
      `Replacements for ${recipientEmail}: ${JSON.stringify(templateData.data)}`
    );
    templateData.data.forEach((value: any, key: any, map: any) => {
      console.log(`  key: ${key} -> value: ${value}`);
    });
    */

    return templateData;
  }

  makeSendEmailDestinationForRecipient_(
    customerRecord: CdpCustomerDatabaseRecord
  ): CdpSendEmailTemplateDestination {
    const destination: CdpSendEmailTemplateDestination =
      new CdpSendEmailTemplateDestination();

    destination.destination.toAddresses = [customerRecord.email];
    destination.replacementData =
      this.makeReplacementDataForRecipient_(customerRecord);

    return destination;
  }


  onCurrentListNameChanged() {

  }

  onSelectedTemplateNameChanged() {

  }

  onClickCreateCampaign() {

  }

  onSubjectChanged() {
  }

  onSubjectSelectChanged() {

  }

  onTemplateFileChanged() {

  }
}
