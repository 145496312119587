import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { CdpJwt } from '../core/cdp-auth/auth.service';
import { Observable, catchError, concatMap, from, map, of } from 'rxjs';
import {
  CdpBackendCoreService,
  CdpBackendResponse,
} from './cdp-backend-core.service';
import { CdpEmailSenderSender } from '../core/email/cdp-email-sender';

export enum CdpEmailSenderVerificationRequestType {
  CheckStatus = "CheckStatus",
  VerifyEmail = "VerifyEmail",
  VerifyDomain = "VerifyDomain"
}

export class CdpEmailSenderVerificationRequest {
  requestType: CdpEmailSenderVerificationRequestType = CdpEmailSenderVerificationRequestType.CheckStatus;
  emailAddresses: string[] = []
  domain: string = '';
}

export class CdpEmailSenderVerificationResponse extends CdpBackendResponse {
  succeeded: boolean = false;
  request: CdpEmailSenderVerificationRequest = new CdpEmailSenderVerificationRequest();
  senders: CdpEmailSenderSender[] = [];

  static makeErrorResponse(e: any): CdpEmailSenderVerificationResponse {
    let response: CdpEmailSenderVerificationResponse =
      new CdpEmailSenderVerificationResponse();
    response.appendError(e);

    return response;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CdpEmailSendBackendService {
  constructor(
    private backendCore: CdpBackendCoreService,
    private http: HttpClient
  ) {}

  execSenderVerifyOp(request: CdpEmailSenderVerificationRequest): Observable<CdpEmailSenderVerificationResponse> {
    return this.executeFunc_(
      'email/verify', request,
      'Email sender verify'
    );
  }

  private executeFuncWithJwt_(
    endpoint: string,
    request: CdpEmailSenderVerificationRequest,
    funcDescription: string,
    makeServerUrlFunc: any,
    httpClient: HttpClient,
    handleErrorFunc: any,
    jwt: CdpJwt
  ): Observable<CdpEmailSenderVerificationResponse> {
    try {
      const url = makeServerUrlFunc(endpoint, jwt);

      let observable = httpClient
        .post<CdpEmailSenderVerificationResponse>(url, request)
        .pipe(catchError(handleErrorFunc(funcDescription)));

      return observable;
    } catch (error) {
      console.log(`Server error executing ${funcDescription}: ${error}`);

      return of(CdpEmailSenderVerificationResponse.makeErrorResponse(error));
    }
  }

  private executeFunc_(
    endpoint: string,
    request: CdpEmailSenderVerificationRequest,
    funcDescription: string
  ): Observable<CdpEmailSenderVerificationResponse> {
    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    //console.log("sender verification executeFunc_:", request)

    // We need to store references to member functions and pass them explicitly because
    // "this" will be something different in a callback context.
    const func = this.executeFuncWithJwt_.bind(
      this,
      endpoint,
      request,
      funcDescription,
      this.backendCore.makeServerUrlWithJwt,
      this.http,
      this.handleError<CdpEmailSenderVerificationResponse>
    );

    return jwtObservable.pipe(concatMap((jwt) => func(jwt)));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.log('Error: ', error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
