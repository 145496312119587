import { AfterViewInit, Component } from '@angular/core';

import { fireworks } from '@tsparticles/fireworks';

@Component({
  selector: 'app-cdp-send-email-complete',
  standalone: true,
  imports: [],
  templateUrl: './cdp-send-email-complete.component.html',
  styleUrl: './cdp-send-email-complete.component.sass',
})
export class CdpSendEmailCompleteComponent implements AfterViewInit {
  async ngAfterViewInit() {
    const f = await fireworks('fireworks', { 'sounds': false});

    if (f) {
      setTimeout(() => {
        f.stop();
      }, 5000);
    }
  }
}
