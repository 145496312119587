import { Injectable } from '@angular/core';

import { CdpError } from '../core/cdp-error';
import { CdpJwt } from '../core/cdp-auth/auth.service';
import { environment } from 'src/environments/environment';

export class CdpContainsErrors {
  errors: any[] = [];
  appendError(error: any) {
    this.errors.push(error);
}
}

export class CdpBackendResponse extends CdpContainsErrors{
  token: string = "";
  responseMessage: string = "";
}

@Injectable({
  providedIn: 'root',
})
export class CdpBackendCoreService {
  constructor() {}

  public makeServerUrlWithJwt(endpoint: string, jwt: CdpJwt): string {
    if (jwt.isEmpty()) {
      throw CdpError.UserNotSignedIn;
    }

    const jwtAccessToken: string = jwt.accessToken;
    const jwtIdToken: string = jwt.idToken;

    const queryStringParams: string = `jwtacc=${jwtAccessToken}&jwtid=${jwtIdToken}`;

    const serverUrl = environment.server.urlBase;
    const url: string = `${serverUrl}/${endpoint}?${queryStringParams}`;

    //console.log(`Server URL+endpoint: ${serverUrl}/${endpoint}`);

    return url;
  }
}
