import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CdpEmailData, CdpSendEmailResponse } from '../cdp-email';
import { CdpBackendService } from 'src/app/backend/cdp-backend/cdp-backend.service';
import { Observable, catchError, of } from 'rxjs';
import {
  CdpEmailSendBackendService,
  CdpEmailSenderVerificationRequest,
  CdpEmailSenderVerificationRequestType,
  CdpEmailSenderVerificationResponse,
} from '../../backend/cdp-email-send-backend.service';
import { CdpEmailSenderSenderSet } from '../../core/email/cdp-email-sender';

export enum CdpSendEmailServiceEventType {
  SendEmail = 'SendEmail',
  SenderVerification = 'SenderVerification',
}
export class CdpSendEmailServiceEvent {
  type: CdpSendEmailServiceEventType = CdpSendEmailServiceEventType.SendEmail;

  constructor(type: CdpSendEmailServiceEventType) {
    this.type = type;
  }

  senderVerificationResponse: CdpEmailSenderVerificationResponse =
    new CdpEmailSenderVerificationResponse();
}

@Injectable({
  providedIn: 'root',
})
export class CdpSendEmailService {
  eventEmitter: EventEmitter<CdpSendEmailServiceEvent> =
    new EventEmitter<CdpSendEmailServiceEvent>();

  constructor(
    private backend: CdpBackendService,
    private backendVerification: CdpEmailSendBackendService
  ) {
    this.checkVerificationStatus();
  }

  private senderSet_: CdpEmailSenderSenderSet = new CdpEmailSenderSenderSet();

  getCurrentSenderSet(): CdpEmailSenderSenderSet {
    return this.senderSet_;
  }

  async sendEmail(emailData: CdpEmailData): Promise<CdpSendEmailResponse> {
    return this.backend.sendEmail(emailData);
  }

  private senderVerificationResponseFailed_(
    error: any
  ): Observable<CdpEmailSenderVerificationResponse> {
    const response: CdpEmailSenderVerificationResponse =
      new CdpEmailSenderVerificationResponse();
    response.succeeded = false;
    response.appendError(error);

    return of(response);
  }

  private emitSenderVerificationResponse_(
    response: CdpEmailSenderVerificationResponse
  ) {
    const event: CdpSendEmailServiceEvent = new CdpSendEmailServiceEvent(
      CdpSendEmailServiceEventType.SenderVerification
    );
    event.senderVerificationResponse = response;

    this.eventEmitter.emit(event);
  }

  private senderVerificationResponseComplete_(
    response: CdpEmailSenderVerificationResponse
  ) {
    if (
      response.request.requestType ==
      CdpEmailSenderVerificationRequestType.CheckStatus
    ) {
      try {
        if (response.succeeded) {
          this.senderSet_.senders = response.senders;
        } else {
          this.senderSet_.senders = [];
        }
      } catch {
        this.senderSet_.senders = [];
      }
    }

    this.emitSenderVerificationResponse_(response);
  }

  private execSenderVerifyOp_(
    request: CdpEmailSenderVerificationRequest
  ): Observable<CdpEmailSenderVerificationResponse> {
    const observable: Observable<CdpEmailSenderVerificationResponse> =
      this.backendVerification.execSenderVerifyOp(request);

    observable
      .pipe(
        catchError((error) => this.senderVerificationResponseFailed_(error))
      )
      .subscribe((response) =>
        this.senderVerificationResponseComplete_(response)
      );

    return observable;
  }

  verifyDomainStart(
    domain: string
  ): Observable<CdpEmailSenderVerificationResponse> {
    const request: CdpEmailSenderVerificationRequest =
      new CdpEmailSenderVerificationRequest();
    request.requestType = CdpEmailSenderVerificationRequestType.VerifyDomain;
    request.domain = domain;

    return this.execSenderVerifyOp_(request);
  }

  verifyEmailStart(
    emailAddresses: string[]
  ): Observable<CdpEmailSenderVerificationResponse> {
    const request: CdpEmailSenderVerificationRequest =
      new CdpEmailSenderVerificationRequest();
    request.requestType = CdpEmailSenderVerificationRequestType.VerifyEmail;
    request.emailAddresses = emailAddresses;

    return this.execSenderVerifyOp_(request);
  }

  checkVerificationStatus(): Observable<CdpEmailSenderVerificationResponse> {
    const request: CdpEmailSenderVerificationRequest =
      new CdpEmailSenderVerificationRequest();
    request.requestType = CdpEmailSenderVerificationRequestType.CheckStatus;

    return this.execSenderVerifyOp_(request);
  }
}
