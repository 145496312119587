<div style="padding: 5%">
  <h1 style="text-align: center"><b>Connect to your Square account</b></h1>
  <p>
    Connecting your Square account lets you view and analyze your customers'
    information and purchase history, and also lets you create email campaigns
    based on that information. We make limited and temporary use of the data in
    your Square account in order to let you accomplish that.
  </p>
  <p>
    You need to agree to the following Data Usage Policy before we can connect
    to your Square account, by checking the checkbox below the policy.
  </p>
  <p></p>
  <div style="overflow: auto; border: 2px solid black; padding: 10px">
    <p><strong>Data Usage Policy</strong></p>
    <ul>
      <li>
        We use read-only access to your data; we do not modify anything within
        your Square account.
      </li>
      <li>
        We will use your customers' email addresses and other information only
        to provide you with information about your business and customers and to
        email your customers on your behalf.
      </li>
      <li>
        We will not email your customers except as directed by you, for example
        as part of an email campaign you create.
      </li>
      <li>
        We will never share or sell any of your business information, including
        your customers' email addresses and purchase history.
      </li>
      <li>
        We do not use or store any financial data about your customers, such as
        their payment methods or billing address.
      </li>
      <li>
        Your customer data will not be shared with any third-party service for
        use by AI (for example, ChatGPT).
      </li>
    </ul>
  </div>
  <br />
  <input
    class="form-check-input"
    type="checkbox"
    value="consent"
    #checkbox_consent
    (change)="onCheckboxChanged($event)"
  />
  <label class="form-check-label"
    >&nbsp; I agree to the Data Usage Policy above, the
    <a href="https://inboxease.com/terms-of-service"
      >Terms of Service</a
    >, and the
    <a href="https://inboxease.com/privacy-policy"> Privacy Policy</a
    >.
  </label>
  <p>
    After you check "I agree," click the button to connect to your Square
    account. You will then be redirected to Square's website to login to your
    Square account.
  </p>
  <div style="width: 50%; margin: 0 auto">
    <button
      type="button"
      class="btn btn-light"
      [disabled]="!isCheckboxConsentChecked"
      (click)="onClickSquare()"
      style="
        height: 50px;
        width: 180px;
        border: 2px solid black;
        background-size: 70%;
        background-repeat: no-repeat;
        background-image: url('https://inboxease.com/wp-content/uploads/2023/10/Square_LogoLockup_Black_small.png');
        background-position: center;
      "
    ></button>
  </div>
</div>
