import validator from 'validator'

export class CdpEmailAddress {
    email: string = "";

    constructor(email: string = "") {
      this.email = email;
    }
    
    public isValid(): boolean {
        return validator.isEmail(this.email);
    }

    public isEqual(rhs: CdpEmailAddress): boolean {
      return this.email == rhs.email;
    }
}