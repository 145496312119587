import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { CdpRoutes } from 'src/app/core/router/cdp-routes';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';

@Component({
  selector: 'app-cdp-sign-in',
  standalone: true,
  imports: [CommonModule, AmplifyAuthenticatorModule],
  templateUrl: './cdp-sign-in.component.html',
  styleUrl: './cdp-sign-in.component.sass',
})
export class CdpSignInComponent implements OnInit {
  constructor(
    private router: Router,
    private sessionManager: CdpSessionManagerService
  ) {}

  async ngOnInit(): Promise<void> {
    const isUserSignedIn: boolean = await this.sessionManager.isUserSignedIn();

    if (isUserSignedIn) {
      // If the user is already signed in, go to the after-sign-in page.
      // Note: This location should be consistent with the navigation in the
      //       session manager for the 'signIn' event.
      this.router.navigate([CdpRoutes.AfterSignIn]);
    }
  }
}
