export class CdpRoutes {
  static AfterSignIn: string = 'after-sign-in';
  static BusinessProfile: string = 'business-profile';
  static Calendar: string = 'calendar';
  static CookiePolicy: string = 'cookie-policy';
  static CombinedProfile: string = 'combined-profile';
  static CustomerDatabase: string = 'customer-database';
  static EmailSenderVerification: string = 'sender-verification';
  static EmailCampaignCreate: string = 'email-campaign-create';
  static Feedback: string = 'feedback';
  static FeedbackComplete: string = 'feedback-complete';
  
  // Note: The following two page links are used with AWS and should not be changed
  static EmailSenderVerificationSuccess: string = 'sender-verification-success';
  static EmailSenderVerificationFailure: string = 'sender-verification-failure';
  
  static SendEmail: string = 'send-email';
  static SendEmailComplete: string = 'send-email-complete';
  static EmailTemplateEditor: string = 'email-template-editor';
  static EmailTemplateGallery: string = 'email-template-gallery';
  static EmailTemplateGalleryUser: string = 'email-template-gallery-user';
  static Home: string = '';
  static ImageGalleryUser: string = 'image-gallery-user';
  static ImagesLibrary: string = 'library-images';
  static InitialRegistration: string = 'initial-registration';
  static Integrations: string = 'integrations';
  static Dashboard: string = 'dashboard';
  static PrivacyPolicy: string = 'privacy-policy';
  static UserProfile: string = 'user-profile';
  static Register: string = 'register';
  static SignIn: string = 'sign-in';
  static SquareConnect: string = 'connect-square';
  static SquareConnectComplete: string = 'connect-square-complete';
  static SquareConnectStart: string = 'connect-square-start';
  static TermsOfService: string = 'terms-of-service';
}
