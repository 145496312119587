<select class="form-select" [(ngModel)]="businessType.type">
  <option value="">-- Please select a business type --</option>
  @for (type of allBusinessTypes; track $index) {
    <option>{{type}}</option>
  }
  <hr />
  <option value="Other">Other</option>
</select>
@if (businessType.type == "Other") {
    <br />
    <input type="text" class="form-control" placeholder="Business type" [(ngModel)]="businessType.typeOther">
}
