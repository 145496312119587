<div class="container rounded bg-white mt-5 mb-1" style="max-width: 600px">
  <div class="d-flex justify-content-center m-2">
    <h3 class="text-color-primary">Email Sender Verification</h3>
  </div>
  
  <div class="vertical-break"></div>
  @if (!startedVerification) {
  <p class="text-right">
    When you send emails, you want your customers to know that the email is
    coming from you, not from us! So, in order for us to send emails for you,
    you need to verify your business domain with us. Once you verify your
    domain, you can send email from any email address at that domain.
  </p>
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          I'd like a little more explanation
        </button>
      </h2>
      <div
        id="collapseOne"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <p class="text-right">
            For example, once you verify the domain <em>mycompany.com</em>, you
            could send emails from any email addresses that you already use in
            your domain, like <em>myname&#64;company.com</em>,
            <em>shopping&#64;mycompany.com</em>,
            <em>support&#64;mycompany.com</em>, etc.
          </p>
          <p class="text-right">
            To verify your domain, you'll need to edit the DNS records for that
            domain, using values that we provide. We'll help walk you through
            the process.
          </p>
          <p class="text-right">
            If you don't own a domain, you can just verify individual email
            addresses instead (like <em>myname&#64;company.com</em> and
            <em>shopping&#64;mycompany.com</em>). However, we do not recommend
            this approach, because emails you send that way are more likely to
            be flagged as spam and never reach your customers' inboxes.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="vertical-break"></div>
  <div class="vertical-break"></div>
  <div class="mt-2 text-center">
    <button
      class="btn btn-primary profile-button mx-2"
      type="submit"
      (click)="onClickStartDomainVerification()"
    >
      Start domain verification
    </button>
    <button
      class="btn btn-outline-secondary profile-button mx-2"
      type="submit"
      (click)="onClickNoDomain()"
    >
      I don't have a domain
    </button>
  </div>

  } 
  @else {
  <app-cdp-email-domain-verification (cancel)="cancelVerification($event)"></app-cdp-email-domain-verification>
  }
</div>
