import { ApplicationRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { AppComponent } from 'src/app/app.component';
import { CdpEvent } from '../cdp-event';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpRoutes } from '../router/cdp-routes';

@Component({
  selector: 'app-cdp-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './cdp-header.component.html',
  styleUrl: './cdp-header.component.scss',
})
export class CdpHeaderComponent {
  isUserSignedIn: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private sessionManager: CdpSessionManagerService,
    private appRef: ApplicationRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.isUserSignedIn = await this.sessionManager.isUserSignedIn();
    //console.log('Header OnInit: signedIn=', this.isUserSignedIn);
    const rootAppComponent: AppComponent = this.appRef.components[0]
      .instance as AppComponent;
    rootAppComponent.eventEmitter().subscribe((e: CdpEvent) => {
      //console.log('Heard event:', e);

      if (e.isCategoryAuth() || e.isCategorySession()) {
        // It's safer to just query the current signed-in state than
        // to try to infer that from the event.
        this.sessionManager.isUserSignedIn().then((val) => {
          this.isUserSignedIn = val;

          //console.log('Update: signedIn=', this.isUserSignedIn);
        });
      }
    });
  }

  onClickSignOut() {
    this.sessionManager.signOut();
    this.router.navigate([CdpRoutes.Home]);
  }

  linkHome: string = CdpRoutes.Home;
  linkProfile: string = CdpRoutes.CombinedProfile;
  linkRegister: string = CdpRoutes.Register;
  linkSignIn: string = CdpRoutes.SignIn;
}
