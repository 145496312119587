import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CdpEmailTemplateManagerFile,
  CdpEmailTemplateManagerService,
} from '../cdp-email-template-manager.service';
import { CdpEmailTemplateFile } from '../cdp-email-template-file';
import { CdpEmailTemplateInfo } from '../cdp-email-template-info';

export class CdpEmailTemplateTileViewParams {
  tileWidthPx: number = 256;
  tileAspect: number = 4;

  targetDisplayWidthPx = 300;
}

@Component({
  selector: 'app-cdp-email-template-tile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cdp-email-template-tile.component.html',
  styleUrl: './cdp-email-template-tile.component.scss',
})
export class CdpEmailTemplateTileComponent implements AfterViewInit, OnInit {
  @ViewChild('template_tile') tile!: ElementRef<HTMLSpanElement>;
  @ViewChild('iframe_container') iframeContainer!: ElementRef<HTMLDivElement>;

  @Output() beingDeletedEvent: EventEmitter<CdpEmailTemplateManagerFile> =
    new EventEmitter<CdpEmailTemplateManagerFile>();

  private templateManagerFile_: CdpEmailTemplateManagerFile | null = null;

  private viewParams_: CdpEmailTemplateTileViewParams =
    new CdpEmailTemplateTileViewParams();

  @Input() visible: boolean = false;

  @Input() set templateManagerFile(
    templateManagerFile: CdpEmailTemplateManagerFile | null
  ) {
    if (templateManagerFile != this.templateManagerFile_) {
      this.templateManagerFile_ = templateManagerFile;
      this.onTemplateFileChanged();
    }
  }

  @Input() set viewParams(viewParams: CdpEmailTemplateTileViewParams) {
    this.viewParams_ = viewParams;
    this.onViewParamsChanged();
  }

  currentScale: number = 1;

  @Input() allowShowEditButton: boolean = true;
  @Input() allowShowSendButton: boolean = true;
  @Input() allowShowDeleteButton: boolean = true;
  @Input() showTemplateId: boolean = true;
  @Input() showTemplateName: boolean = true;

  get showEditButton(): boolean {
    return (
      this.allowShowEditButton &&
      this.templateManagerFile_ != null &&
      !this.templateManagerFile_.isOperationInProgress
    );
  }

  get showSendButton(): boolean {
    return (
      this.allowShowSendButton &&
      this.templateManagerFile_ != null &&
      !this.templateManagerFile_.isOperationInProgress
    );
  }

  get showDeleteButton(): boolean {
    return (
      this.allowShowDeleteButton &&
      this.templateManagerFile_ != null &&
      !this.templateManagerFile_.isOperationInProgress &&
      this.templateManagerFile_.couldBeDeleted()
    );
  }

  constructor(private templateManager: CdpEmailTemplateManagerService) {}

  ngOnInit(): void {}
  
  ngAfterViewInit(): void {
    this.onTemplateFileChanged();
  }

  templateInfo(): CdpEmailTemplateInfo | null {
    if (this.templateManagerFile_) {
      const templateFile: CdpEmailTemplateFile | null = this.templateManagerFile_.templateFile;
      if (templateFile) {
        return templateFile.info;
      }
    }

    return null;
  }

  templateId(): string {
    const templateInfo: CdpEmailTemplateInfo | null = this.templateInfo();
    return (templateInfo ? templateInfo.id.id : '');
  }

  templateName(): string {
    const templateInfo: CdpEmailTemplateInfo | null = this.templateInfo();
    return (templateInfo ? templateInfo.name : '');
  }

  getIframe(): HTMLIFrameElement | null {
    const iframeContainerNative: HTMLDivElement =
      this.iframeContainer.nativeElement;

    if (iframeContainerNative.firstChild instanceof HTMLIFrameElement) {
      //console.log("Got iframe");
      return iframeContainerNative.firstChild;
    } else {
      return null;
    }
  }

  addOrReplaceIframe(): HTMLIFrameElement {
    const iframeContainerNative: HTMLDivElement =
      this.iframeContainer.nativeElement;

    // Remove the existing iframe, if any.
    while (iframeContainerNative.firstChild) {
      const lastChild = iframeContainerNative.lastChild;
      if (lastChild) {
        iframeContainerNative.removeChild(lastChild);
      }
    }

    //console.log("Creating new iframe");

    const tileThis = this;

    const iframeNative: HTMLIFrameElement = document.createElement('iframe');

    /* TODO TODO
    // Use lazy loading to improve performance.
    iframeNative.loading = "lazy";
    */

    iframeNative.style.pointerEvents = "none";
    iframeNative.onload = (e) => {
      //console.log('Iframe content window:', iframeNative.contentWindow);

      const scrollPad: number = 1 / this.currentScale + 1;

      const rect: DOMRect = iframeNative.getBoundingClientRect();
      //console.log('iframe start Rect:', rect);
      const fullWidth: number = rect.width;
      const fullHeight: number =
        iframeNative.contentWindow!.document.body.scrollHeight + scrollPad;
      const aspect: number = this.viewParams_.tileAspect;

      // Limit the displayed height to the specified aspect ratio.
      let height = Math.min(fullHeight, fullWidth * aspect);

      iframeNative.height = height.toString();

      this.iframeContainer.nativeElement.style.height = `${
        height * this.currentScale
      }px`;
      //this.tile.nativeElement.style.height = `${height * this.currentScale}px`;

      /*
      //console.log('iframe end Rect:', iframeNative.getBoundingClientRect());
      //console.log('iframe height:', iframeNative.height);
      //console.log('iframe client height:', iframeNative.clientHeight);
      //console.log('iframe scroll height:', iframeNative.scrollHeight);
      //console.log("Tile:", this.tile.nativeElement);
      */

      tileThis.visible = true;
    };

    iframeContainerNative.appendChild(iframeNative);



    //window.removeEventListener('blur', listener);    });

    return iframeNative;
  }

  onClickPreview() {}

  onClickEdit() {
    if (this.templateManagerFile_) {
      this.templateManager.openEditor(this.templateManagerFile_);
    } else {
      // This really shouldn't happen.
      console.log('No template to edit.');
    }
  }

  onClickSend() {
    if (this.templateManagerFile_) {
      this.templateManager.openEmailSend(this.templateManagerFile_);
    } else {
      // This really shouldn't happen.
      //console.log('Could not make editable template.');
    }
  }

  onClickDeleteTemplate() {
    const templateManagerFile: CdpEmailTemplateManagerFile | null =
      this.templateManagerFile_;
    this.templateManagerFile_ = null;

    // Re-check that delete is legal just in case the button wasn't properly hidden.
    if (templateManagerFile && templateManagerFile.couldBeDeleted()) {
      this.beingDeletedEvent.emit(templateManagerFile);
      //console.log("Calling deleteTemplateManagerFile");
      this.templateManager.deleteTemplateManagerFile(templateManagerFile);
    } else {
      console.log("File undeletable:", templateManagerFile);
    }
  }

  onViewParamsChanged() {
    if (!this.tile || !this.tile.nativeElement) {
      return;
    }

    const tileWidthPx: number = this.viewParams_.tileWidthPx;
    const targetDisplayWidthPx: number = this.viewParams_.targetDisplayWidthPx;
    const aspect: number = this.viewParams_.tileAspect;

    const tileHeightPx: number = tileWidthPx * aspect;

    this.tile.nativeElement.style.width = `${tileWidthPx}px`;
    //this.tile.nativeElement.style.height = `${tileHeightPx}px`;

    const scale: number = tileWidthPx / targetDisplayWidthPx;

    const iFrameWidthPx: number = targetDisplayWidthPx;
    const iFrameHeightPx: number = iFrameWidthPx * aspect;

    let iframeNative = this.getIframe();
    if (!iframeNative) {
      iframeNative = this.addOrReplaceIframe();
    }
    {
      //console.log('iframe paramsChanged before change:', this.iframe);
      const rect: DOMRect = iframeNative.getBoundingClientRect();
      //console.log('iframerect before change:', rect);
      //console.log('setting iframe contents:');

      const origX: number = rect.x;
      const origY: number = rect.y;

      iframeNative.width = `${iFrameWidthPx}px`;

      // The height of the iframe may get updated after the content loads,
      // but setting the value here makes the layout update look cleaner
      // to the user.
      iframeNative.height = `${iFrameHeightPx}px`;

      iframeNative.style.transform = `scale(${scale})`;
      iframeNative.style.transformOrigin = 'top left'; //'100 100';//`${origX} ${origY}`;

      //       iframeNative.style.transformOrigin = `${origX} ${origY}`;

      iframeNative.style.position = 'absolute';

      this.currentScale = scale;

      /*
      //console.log(
        'iframe scrollHeight:',
        iframe.contentWindow!.document.body.scrollHeight
      );
      const rect: DOMRect = iframe.getBoundingClientRect();
      //console.log('Rect height:', rect.height);
      */
    }
  }

  onTemplateFileChanged() {
    if (this.templateManagerFile_) {
      const templateFile: CdpEmailTemplateFile | null =
        this.templateManagerFile_.templateFile;
      if (templateFile && templateFile.hasCompiledHtml()) {
        const srcdoc: string = templateFile.getCompiledHtml();
        this.setSrcdocSizeWidthPxAndAspect(srcdoc);
      } else {
        //console.log("Empty template");
      }
    }
  }

  getTemplateInfo(): CdpEmailTemplateInfo | null {
    if (this.templateManagerFile_ && this.templateManagerFile_.templateFile) {
      return this.templateManagerFile_.templateFile.info;
    } else {
      return null;
    }
  }

  setSrcdocSizeWidthPxAndAspect(srcdoc: string) {
    if (
      !this.tile ||
      !this.tile.nativeElement ||
      !this.iframeContainer ||
      !this.iframeContainer.nativeElement
    ) {
      return;
    }

    const tileWidthPx: number = this.viewParams_.tileWidthPx;
    const targetDisplayWidthPx: number = this.viewParams_.targetDisplayWidthPx;
    const aspect: number = this.viewParams_.tileAspect;

    const tileHeightPx: number = tileWidthPx * aspect;

    this.tile.nativeElement.style.width = `${tileWidthPx}px`;

    this.iframeContainer.nativeElement.style.height = `${tileHeightPx}px`;
    //this.tile.nativeElement.style.height = `${tileHeightPx}px`;

    const scale: number = tileWidthPx / targetDisplayWidthPx;

    const iFrameWidthPx: number = targetDisplayWidthPx;
    const iFrameHeightPx: number = iFrameWidthPx * aspect;

    const iframeNative: HTMLIFrameElement = this.addOrReplaceIframe();
    {
      //console.log('iframe before change:', this.iframe);
      const rect: DOMRect = iframeNative.getBoundingClientRect();
      //console.log('iframerect before change:', rect);
      //console.log('setting iframe contents:');

      const origX: number = rect.x;
      const origY: number = rect.y;

      iframeNative.width = `${iFrameWidthPx}px`;

      // The height of the iframe may get updated after the content loads,
      // but setting the value here makes the layout update look cleaner
      // to the user.
      iframeNative.height = `${iFrameHeightPx}px`;

      iframeNative.style.transform = `scale(${scale})`;
      iframeNative.style.transformOrigin = 'top left'; //'100 100';//`${origX} ${origY}`;
      iframeNative.style.position = 'absolute';

      this.currentScale = scale;

      // Note: This has to be the first time the iframe is used, since setting srcdoc
      //       on an iframe that already has src or srcdoc set has no effect, for security
      //       reasons.
      //console.log("Setting iframe srcdoc.");

      iframeNative.srcdoc = srcdoc;
    }
    /*
      //console.log(
        'iframe scrollHeight:',
        iframe.contentWindow!.document.body.scrollHeight
      );
      const rect: DOMRect = iframe.getBoundingClientRect();
      //console.log('Rect height:', rect.height);
      */
  }
}
