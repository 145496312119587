<nav class="nav footer navbar navbar-expand-lg justify-content-end" role="banner">
  <div class="container-fluid justify-content-center">
    <!--
    <p>
      By using this website, you agree to our
      <a href="terms-of-service"
        >Terms of Service</a
      >, <a href="privacy-policy">Privacy Policy</a>, and <a href="cookie-policy">Cookie Policy</a>.
    </p>
  -->
    <p>© 2024 Wagel</p>
  </div>
</nav>


