import { degToRad, isObject } from "@tsparticles/engine";
const double = 2,
  doublePI = Math.PI * double,
  sides = 12,
  maxAngle = 360,
  minAngle = 0,
  origin = {
    x: 0,
    y: 0
  };
export class CircleDrawer {
  draw(data) {
    const {
      context,
      particle,
      radius
    } = data;
    if (!particle.circleRange) {
      particle.circleRange = {
        min: minAngle,
        max: doublePI
      };
    }
    const circleRange = particle.circleRange;
    context.arc(origin.x, origin.y, radius, circleRange.min, circleRange.max, false);
  }
  getSidesCount() {
    return sides;
  }
  particleInit(container, particle) {
    const shapeData = particle.shapeData,
      angle = shapeData?.angle ?? {
        max: maxAngle,
        min: minAngle
      };
    particle.circleRange = !isObject(angle) ? {
      min: minAngle,
      max: degToRad(angle)
    } : {
      min: degToRad(angle.min),
      max: degToRad(angle.max)
    };
  }
}