import { EventEmitter, Input } from "@angular/core";
import { IDropdownSettings } from "ng-multiselect-dropdown";

export class CdpSelectOption {
  text: string = ''; // the displayed text
  value: string = '';
}

export class CdpSelect {
  label: string = '';
  rawLabel: string = '';
  options: CdpSelectOption[] = [];

  initialSelectedValues: string[] = [];
}