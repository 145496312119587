import { v4 as uuidv4 } from 'uuid';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdpDropzoneComponent } from '../../ui/cdp-drop-zone/cdp-drop-zone.component';
import { CdpImageTileComponent } from '../cdp-image-tile/cdp-image-tile.component';
import {
  CdpFileOperation,
  CdpFileOperationResult,
  CdpFileOperationResultSet,
  CdpFileOperationType,
} from 'src/app/backend/files/cdp-backend-file.service';
import { CdpFileUtilties } from 'src/app/core/files/cdp-file-utilities';
import { CdpImage } from 'src/app/core/images/cdp-image';

import { CdpBackendFileService } from 'src/app/backend/files/cdp-backend-file.service';
import {
  CdpFile,
  CdpFileCategory,
  CdpFileInfo,
  CdpFileLocationType,
  CdpFileMetadata,
} from 'src/app/core/files/cdp-file';
import { CdpEmailTemplateManagerService } from 'src/app/email/template/cdp-email-template-manager.service';
import { CdpImageGalleryFileInfo } from '../cdp-image-gallery-user/cdp-image-gallery-user.component';
import {
  CdpProgressOperationSet,
  CdpProgressOperationStatus,
  CdpProgressOperationTag,
} from 'src/app/ui/progress/cdp-progress';
import { CdpProgressSpinnerComponent } from 'src/app/ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';

export enum CdpImageGalleryOperationType {
  UploadImages = 'UploadImages',
  DeleteImages = 'DeleteImages',
}

class CdpFileUploadStats {
  numInProgress: number = 0;
  numSucceeded: number = 0;
  numFailed: number = 0;

  reset(): void {
    this.numInProgress = 0;
    this.numSucceeded = 0;
    this.numFailed = 0;
  }

  setNumInProgress(numInProgress: number) {
    this.numInProgress = numInProgress >= 0 ? numInProgress : 0;
  }

  addInProgress(numToAdd: number) {
    this.setNumInProgress(this.numInProgress + numToAdd);
  }

  updateSucceeded(numNewSucceeded: number) {
    this.numSucceeded += numNewSucceeded;

    this.addInProgress(-numNewSucceeded);
  }

  updateFailed(numNewFailed: number) {
    this.numFailed += numNewFailed;

    this.addInProgress(-numNewFailed);
  }
}

export class CdpFileOperationStateEvent {
  numInProgress: number = 0;
  numSucceeded: number = 0;
  numFailed: number = 0;
  message: string = '';
}

export class CdpFileOperationState {
  private stats_: CdpFileUploadStats = new CdpFileUploadStats();
  private fileOperations_: CdpFileOperation[] = [];

  public eventEmitter: EventEmitter<CdpFileOperationStateEvent> =
    new EventEmitter<CdpFileOperationStateEvent>();

  getStateMessage(): string {
    let message: string = '';

    const numInProgress: number = this.stats_.numInProgress;
    const numSucceeded: number = this.stats_.numSucceeded;
    const numFailed: number = this.stats_.numFailed;

    if (numInProgress > 0) {
      message += `<a style="color:blue">In progress: ${numInProgress}&nbsp;</a>`;
    }

    if (numSucceeded) {
      message += `<a style="color:green">Succeeded: ${numSucceeded}&nbsp;</a>`;
    }

    if (numFailed) {
      message += `<a style="color:red">Failed: ${numFailed}&nbsp;</a>`;
    }

    return message;
  }

  isFileBeingProcessed(fileInfo: CdpFileInfo): boolean {
    for (const fileOperation of this.fileOperations_) {
      if (fileOperation.fileInfo.isSameFile(fileInfo)) {
        return true;
      }
    }

    return false;
  }

  private emitChangedEvent_() {
    const e: CdpFileOperationStateEvent = new CdpFileOperationStateEvent();
    e.numInProgress = this.stats_.numInProgress;
    e.numSucceeded = this.stats_.numSucceeded;
    e.numFailed = this.stats_.numFailed;
    e.message = this.getStateMessage();
  }

  uploadStart(file: CdpFile): CdpFileOperation | null {
    // TODO What should we do if the user tries to upload the
    //      same file twice?  Do we ignore the new version, cancel
    //      and replace the one already in process, ...?
    if (this.isFileBeingProcessed(file.fileInfo)) {
      return null;
    }

    const fileOperation: CdpFileOperation = new CdpFileOperation(
      file,
      CdpFileOperationType.Upload,
      'Uploading file...'
    );
    this.fileOperations_.push(fileOperation);
    this.stats_.addInProgress(1);
    //this.updateStatusMessage_();
    this.emitChangedEvent_();

    return fileOperation;
  }

  private recomputeStats_() {
    this.stats_.reset();

    for (const fileOperation of this.fileOperations_) {
      const status: CdpProgressOperationStatus = fileOperation.status;

      switch (status) {
        case CdpProgressOperationStatus.InProgress:
          this.stats_.numInProgress++;
          break;

        case CdpProgressOperationStatus.CompletedSucceeded:
          this.stats_.numSucceeded++;
          break;

        case CdpProgressOperationStatus.CompletedFailed:
          this.stats_.numFailed++;
          break;

        default:
          // Do nothing.
          break;
      }
    }
  }

  updateFileOperationsComplete(
    fileOperations: CdpFileOperation[],
    resultSet: CdpFileOperationResultSet,
    removeOperations: boolean
  ) {
    // In theory, each of the file operations should have a corresponding
    // result in the result set.  If for some reason there is no such result,
    // we'll assume that the file operation failed.
    // Also, all of the operations should already be in this.fileOperations_.
    let didAnyChange: boolean = false;

    for (const fileOperation of fileOperations) {
      const fileInfo: CdpFileInfo = fileOperation.fileInfo;
      let foundOperation: boolean = false;
      const prevStatus: CdpProgressOperationStatus = fileOperation.status;

      for (const result of resultSet.results) {
        // Note: result.fileInfo is actually just a dictionary. It does not
        //       have member functions like isSameFile().
        if (
          result.fileInfo &&
          fileInfo.isSameFile(result.fileInfo) &&
          result.request.operationType == fileOperation.fileOperationType
        ) {
          // This is the result corresponding to the file operation.
          fileOperation.result = result;

          const succeeded: boolean = result.succeeded;

          const status: CdpProgressOperationStatus = succeeded
            ? CdpProgressOperationStatus.CompletedSucceeded
            : CdpProgressOperationStatus.CompletedFailed;

          fileOperation.status = status;
          foundOperation = true;
          break;
        }
      }

      if (!foundOperation) {
        // We assume the operation failed if there's no matching result
        // in the result set.
        fileOperation.status = CdpProgressOperationStatus.CompletedFailed;
      }

      if (prevStatus != fileOperation.status) {
        didAnyChange = true;
      }
    }

    if (didAnyChange) {
      this.recomputeStats_();
      this.emitChangedEvent_();
    }

    if (removeOperations) {
      this.removeFileOperations_(fileOperations);
    }
  }

  private removeFileOperations_(fileOperations: CdpFileOperation[]) {
    // Note: This function doesn't fire an event.
    const keepOperations: CdpFileOperation[] = this.fileOperations_.filter(
      (op) => (fileOperations.indexOf(op) < 0));
    this.fileOperations_ = keepOperations;

    this.recomputeStats_();
  }
}

export class CdpImageGallerySectionInfo {
  name: string = '';
  allowMultipleImages: boolean = true;
  fileInfos: CdpImageGalleryFileInfo[] = [];

  constructor(name: string = '', allowMultipleImages: boolean = true) {
    this.name = name;
    this.allowMultipleImages = allowMultipleImages;
  }
}

export class CdpImageGallerySectionEvent {
  sectionInfo: CdpImageGallerySectionInfo = new CdpImageGallerySectionInfo();
  image: CdpImage;

  constructor(sectionInfo: CdpImageGallerySectionInfo, image: CdpImage) {
    this.sectionInfo = sectionInfo;
    this.image = image;
  }
}

@Component({
  selector: 'app-cdp-image-gallery-section',
  standalone: true,
  imports: [CommonModule, CdpDropzoneComponent, CdpImageTileComponent, CdpProgressSpinnerComponent],
  templateUrl: './cdp-image-gallery-section.component.html',
  styleUrl: './cdp-image-gallery-section.component.sass',
})
export class CdpImageGallerySectionComponent implements AfterViewInit, OnInit {
  @ViewChild('dropzone') dropzone!: CdpDropzoneComponent;
  @ViewChild('tile_set', { static: true, read: ViewContainerRef }) tileSet: any;

  @Input() operationSet: CdpProgressOperationSet =
    new CdpProgressOperationSet();

  get isOperationInProgress(): boolean {
    return this.operationSet.isOperationInProgress;
  }

  private sectionInfo_: CdpImageGallerySectionInfo =
    new CdpImageGallerySectionInfo();

  @Input() set sectionInfo(info: CdpImageGallerySectionInfo) {
    //console.log("Setting section info:", info);
    this.sectionInfo_ = info;

    for (const fileInfo of info.fileInfos) {
      this.addImageTileUsingFileInfo_(fileInfo.fileInfo);
    }
  }

  getSectionInfo() {
    return this.sectionInfo_;
  }

  private images_: CdpImage[] = [];

  @Input() get images(): CdpImage[] {
    return this.images_;
  }

  @Output() addedImageEvent = new EventEmitter<CdpImageGallerySectionEvent>();

  private fileOperationState_: CdpFileOperationState =
    new CdpFileOperationState();

  formatBytes = CdpFileUtilties.formatBytes;

  constructor(
    private fileService: CdpBackendFileService,
    private viewContainerRef: ViewContainerRef,
    private templateManager: CdpEmailTemplateManagerService
  ) {
    // Notify the template manager that a new image section has been
    // created, so that it can process events (like a new business logo)
    // as needed.
    //
    // Note that we need to call this in the constructor and not in ngOnInit(),
    // since that would actually be too late if this component is constructed as
    // a child component with the sectionInfo() setter function.
    this.templateManager.notifyImageGallerySectionCreated(this);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.dropzone) {
      this.dropzone.fileDropEventEmitter.subscribe((file) => {
        this.processFileDrop(file);
      });
    } else {
      //console.log('No drop zone!');
    }
  }

  processFileDrop(file: File) {
    if (this.isOperationInProgress) {
      // The dropzone is supposed to be disabled if there's already an
      // operation in progress, but we check just in case.
      return;
    }

    //console.log('Process file drop:', file);

    let ext: string = CdpFileUtilties.getFilenameExtension(file.name);
    let isImageFile: boolean = CdpFileUtilties.isExtensionImageFile(ext);

    if (isImageFile) {
      const metadata: CdpFileMetadata = new CdpFileMetadata();
      metadata.metadata = {
        imageFileSection: this.sectionInfo_.name,
        origFilename: file.name,
      };

      this.addImageFile(file, this.sectionInfo_.name, metadata);
    }
  }

  private addImageTile(image: CdpImage) {
    //console.log("In addImageTile: url=", image.url);

    // Use the uncached URL here.
    const uncachedUrl: string = CdpImage.makeUncachedUrl(image.url);
    image.imageHtml = `<img src="${uncachedUrl}">`;

    // TODO For now, we check the maximum number of images allowed, which is
    //      really just a hack for the logo case.
    //      Also, we might want to check the MD5 checksum to make sure we're not
    //      adding duplicate copies of an image within the same section.
    // TODO TODO Really, we need to make any such checks before uploading the
    //      file if we don't want duplicates, and/or to really replace the logo
    //      image even if going from, e.g., logo.png to logo.jpg.
    let didAdd: boolean = false;

    if (this.sectionInfo_.allowMultipleImages) {
      //console.log("Adding image tile:", image);
      // We need to check that the image hasn't already been added.
      // This handles the case where events may occur from the resource
      // manager and other sources that might both try to add the same
      // image.
      let foundImage: boolean = false;
      for (const img of this.images_) {
        if (img.url == image.url) {
          foundImage = true;
          break;
        }
      }
      if (!foundImage) {
        this.images_.push(image);
        didAdd = true;
      }
    } else {
      //console.log("Replacing image tile:", image);
      if ((this.images_.length == 0) || (image.url != this.images_[0].url)) {
        this.images_.length = 0; // clear the array
        this.images_.push(image);
        didAdd = true;
      }
    }

    if (didAdd) {
     const event: CdpImageGallerySectionEvent = new CdpImageGallerySectionEvent(
        this.sectionInfo_,
        image
      );
      this.addedImageEvent.emit(event);
    }
  }

  private addImageTileUsingFileInfo_(fileInfo: CdpFileInfo) {
    //console.log("In addImageTileUsingFileInfo_: url=", fileInfo.url);
    const image: CdpImage = new CdpImage();
    image.fileInfo = fileInfo;
    image.url = fileInfo.url;

    this.addImageTile(image);
  }

  private processDeleteFilesResultSet_(
    imagesBeingDeleted: CdpImage[],
    resultSet: CdpFileOperationResultSet,
    operationTag: CdpProgressOperationTag
  ) {
    // TODO If we really allow deleting multiple images, then go through the
    //      result set and check which operations succeeded.
    //      For now, just treat the operation as all or nothing.
    const succeeded: boolean = resultSet.errors.length == 0;

    if (succeeded) {
      const keepImages: CdpImage[] = this.images_.filter(
        (img) => imagesBeingDeleted.indexOf(img) < 0
      );
      this.images_ = keepImages;
    }

    //console.log('processDeleteFilesResultSet_:', resultSet);
    const message: string = succeeded ? 'Delete succeeded.' : 'Delete failed.';

    this.operationSet.updateOperationFinal(operationTag, succeeded, message);
  }

  removeImage(image: CdpImage) {
    //console.log('Section removing image:', image);

    if (image.fileInfo && image.fileInfo.couldBeDeleted()) {
      // Check whether the image is actually still in the set.
      const keepImages: CdpImage[] = this.images_.filter((img) => img != image);
      if (keepImages.length != this.images_.length) {
        // We need to delete the image file.
        // Note that we don't actually remove the image from the image array
        // until we confirm that the delete operation succeeded.

        const operationTag: CdpProgressOperationTag =
          this.operationSet.addOperation(
            CdpImageGalleryOperationType.DeleteImages,
            'Deleting image...'
          );

        const processDeleteFilesResultSet =
          this.processDeleteFilesResultSet_.bind(this);

        const deletePermanent: boolean = true; // TODO allow specifying
        this.fileService
          .deleteFiles([image.fileInfo], deletePermanent)
          .subscribe((resultSet) =>
            processDeleteFilesResultSet([image], resultSet, operationTag)
          );
      }
    }
  }

  private fileOperationsComplete_(
    fileOperations: CdpFileOperation[],
    resultSet: CdpFileOperationResultSet,
    operationTag: CdpProgressOperationTag 
  ) {
    //console.log('Got file transfer observable result set:', resultSet);

    // Update the state of the file operations based on <resultSet>, and
    // also update the overall stats and emit an event if appropriate.
    // We then remove the file operations from the set, since we don't want to
    // keep them in future statistics.
    this.fileOperationState_.updateFileOperationsComplete(
      fileOperations,
      resultSet,
      true
    );

    const succeeded: boolean = (resultSet.errors.length == 0);
    // TODO Handle reporting combinations of successes and failures.
    const message: string = (succeeded ? 'Upload succeeded.' : 'Upload failed.');
    this.operationSet.updateOperationFinal(operationTag, succeeded, message);
    
    // If there were any successful image uploads, add images for them.
    for (const fileOperation of fileOperations) {
      if (
        fileOperation.fileOperationType == CdpFileOperationType.Upload &&
        fileOperation.status == CdpProgressOperationStatus.CompletedSucceeded
      ) {
        if (fileOperation.result && fileOperation.result.succeeded) {
          this.addImageTileFromResult_(fileOperation.result);
        }
      }
    }
  }

  private addImageTileFromResult_(result: CdpFileOperationResult) {
    // Note: <result> should already be guaranteed to be a successful upload
    //       result, but we doublecheck just to be safe.
    if (
      !result.succeeded ||
      !result.fileInfo ||
      result.fileInfo.url.length == 0
    ) {
      return;
    }

    // Note: result.fileInfo is really just a dictionary.
    const resultFileInfo: CdpFileInfo =  new CdpFileInfo();
    Object.assign(resultFileInfo, result.fileInfo);

    const imageUrl: string = resultFileInfo.url;

    const image: CdpImage = new CdpImage();
    image.fileInfo = resultFileInfo;
    image.imageInfo.name = resultFileInfo.filename;

    /* TODO Is there any need for keeping the original uploaded file data
     *       rather than the post-upload URL?
     * image.imageInfo.fileSize = file.file ? file.file.size : 0;
     * image.imageInfo.progress = 100;
     * image.imageFile = file.file;
     */

    image.url = imageUrl;

    this.addImageTile(image);
  }

  async addImageFile(file: File, dir: string, metadata: CdpFileMetadata) {
    const origFilename: string = file.name;

    const origExt: string = CdpFileUtilties.getFilenameExtension(origFilename);
    const isFileImage = CdpFileUtilties.isExtensionImageFile(origExt);

    if (!isFileImage) {
      // TODO Show a wrong type message.
      //console.log('File is not an image');

      return;
    }

    // We assign a random name to the file, since the business might not
    // want the original filename visible publicly when the URL is used
    // in an email.
    //
    // However, for the "Logo" section, there should be only a single image,
    // and its name is always "logo".
    const isLogo: boolean = dir.toLowerCase() == 'logo';
    const filename: string = (isLogo ? 'logo' : uuidv4()) + '.' + origExt;

    const cdpFile: CdpFile = new CdpFile();
    cdpFile.fileInfo.locationType = CdpFileLocationType.BusinessPublic;
    cdpFile.fileInfo.filename =
      dir.length > 0 ? dir + '/' + filename : filename;
    cdpFile.fileInfo.category = CdpFileCategory.Image;
    cdpFile.fileInfo.metadata = metadata;

    cdpFile.file = file;

    //console.log('Start upload');
    const fileOperationState: CdpFileOperationState = this.fileOperationState_;
    const fileOperation: CdpFileOperation | null =
      fileOperationState.uploadStart(cdpFile);

    if (fileOperation != null) {
      const operationTag: CdpProgressOperationTag = this.operationSet.addOperation(fileOperation.fileOperationType,
        fileOperation.message);

      const allowOverwrite: boolean = true; // TODO Allow specifying?
      this.fileService
        .uploadFiles([cdpFile], allowOverwrite)
        .subscribe((resultSet) => {
          //console.log('Upload got response:', resultSet);
          this.fileOperationsComplete_([fileOperation], resultSet, operationTag);
        });
    } else {
      // TODO Show a message?
    }
  }
}
