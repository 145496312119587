import { Injectable } from '@angular/core';

import {
  CdpCustomerDatabase,
  CdpCustomerDatabaseInternal,
  CdpCustomerDatabaseList,
  CdpCustomerDatabaseOperationType,
} from '../customers/cdp-customer-database';
import { HttpClient } from '@angular/common/http';
import { CdpJwt } from '../core/cdp-auth/auth.service';
import { Observable, catchError, concatMap, from, map, of } from 'rxjs';
import {
  CdpBackendCoreService,
  CdpBackendResponse,
} from './cdp-backend-core.service';

export class CdpCustomerDatabaseRequest {
  operationType: CdpCustomerDatabaseOperationType =
    CdpCustomerDatabaseOperationType.LoadDatabaseAndLists;
  database: CdpCustomerDatabaseInternal | null = null;
  databaseLists: CdpCustomerDatabaseList[] = [];

  constructor(
    operationType: CdpCustomerDatabaseOperationType,
    database: CdpCustomerDatabaseInternal | null = null,
    databaseLists: CdpCustomerDatabaseList[] = []
  ) {
    this.operationType = operationType;
    this.database = database;
    this.databaseLists = databaseLists;
  }
}

export class CdpCustomerDatabaseResponse extends CdpBackendResponse {
  operationType = CdpCustomerDatabaseOperationType.LoadDatabaseAndLists;
  succeeded: boolean = false;
  operationAttempted: boolean = false;
  database: CdpCustomerDatabaseInternal = new CdpCustomerDatabaseInternal();
  databaseLists: CdpCustomerDatabaseList[] = [];

  static makeFailedResponse(operationType: CdpCustomerDatabaseOperationType) {
    const response: CdpCustomerDatabaseResponse =
     new CdpCustomerDatabaseResponse();

      response.operationAttempted = true;
      response.succeeded = false;
      response.operationType = operationType;

      return response;
  }

  static makeErrorResponse(e: any): CdpCustomerDatabaseResponse {
    let response: CdpCustomerDatabaseResponse =
      new CdpCustomerDatabaseResponse();
    response.appendError(e);

    return response;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CdpCustomerDatabaseBackendService {
  constructor(
    private backendCore: CdpBackendCoreService,
    private http: HttpClient
  ) {}

  loadDatabaseAndLists(): Observable<CdpCustomerDatabaseResponse> {
    return this.executeDatabaseFunc_(
      'db/exec_op',
      new CdpCustomerDatabaseRequest(
        CdpCustomerDatabaseOperationType.LoadDatabaseAndLists
      ),
      'Database load'
    );
  }

  saveDatabaseAndLists(
    databaseInternal: CdpCustomerDatabaseInternal,
    databaseLists: CdpCustomerDatabaseList[]
  ): Observable<CdpCustomerDatabaseResponse> {
    return this.executeDatabaseFunc_(
      'db/exec_op',
      new CdpCustomerDatabaseRequest(
        CdpCustomerDatabaseOperationType.SaveDatabaseAndLists,
        databaseInternal,
        databaseLists
      ),
      'Database save'
    );
  }

  saveLists(
    databaseLists: CdpCustomerDatabaseList[]
  ): Observable<CdpCustomerDatabaseResponse> {
    return this.executeDatabaseFunc_(
      'db/exec_op',
      new CdpCustomerDatabaseRequest(
        CdpCustomerDatabaseOperationType.SaveDatabaseAndLists,
        null, databaseLists
      ),
      'Database save lists'
    );
  }

  createList(listName: string, databaseId: string): Observable<CdpCustomerDatabaseResponse> {
    // To create a list, we pass in a dummy list with just the list name and database ID.
    const databaseList: CdpCustomerDatabaseList = new CdpCustomerDatabaseList();
    databaseList.name = listName;
    databaseList.databaseId = databaseId;

    return this.executeDatabaseFunc_(
      'db/exec_op',
      new CdpCustomerDatabaseRequest(
        CdpCustomerDatabaseOperationType.CreateList,
        null, [databaseList]
      ),
      'Database create list'
    );
  }

  deleteList(listName: string, databaseId: string): Observable<CdpCustomerDatabaseResponse> {
    // To delete a list, we pass in a dummy list with just the list name and database ID.
    const databaseList: CdpCustomerDatabaseList = new CdpCustomerDatabaseList();
    databaseList.name = listName;
    databaseList.databaseId = databaseId;

    return this.executeDatabaseFunc_(
      'db/exec_op',
      new CdpCustomerDatabaseRequest(
        CdpCustomerDatabaseOperationType.DeleteList,
        null, [databaseList]
      ),
      'Database delete list'
    );
  }

  private static makeFullResponse_(responseDict: any): CdpCustomerDatabaseResponse {
    const response: CdpCustomerDatabaseResponse = new CdpCustomerDatabaseResponse();

    // TODO TODO The response that gets returned is really just a dictionary.
    // Pipe the observable through this conversion function.

    //console.log("Response dict:", responseDict);

    response.operationType = responseDict.operationType;
    response.succeeded = responseDict.succeeded;
    response.operationAttempted = responseDict.operationAttempted;
    
    response.database.makeFromDict(responseDict.database);

    const numLists: number = responseDict.databaseLists.length;
    for (let i = 0 ; i < numLists ; i++) {
      const databaseList: CdpCustomerDatabaseList = new CdpCustomerDatabaseList();
      databaseList.makeFromDict(responseDict.databaseLists[i]);

      response.databaseLists.push(databaseList);
    }

    //console.log("Response:", response)

    return response;
  }

  private executeDatabaseFuncWithJwt_(
    endpoint: string,
    request: CdpCustomerDatabaseRequest,
    funcDescription: string,
    makeServerUrlFunc: any,
    httpClient: HttpClient,
    handleErrorFunc: any,
    jwt: CdpJwt
  ): Observable<CdpCustomerDatabaseResponse> {
    try {
      const url = makeServerUrlFunc(endpoint, jwt);

      let observable = httpClient
        .post<CdpCustomerDatabaseResponse>(url, request)
        .pipe(map(response => CdpCustomerDatabaseBackendService.makeFullResponse_(response)),
        catchError(handleErrorFunc(funcDescription)));

      return observable;
    } catch (error) {
      console.log(`Server error executing ${funcDescription}: ${error}`);

      return of(CdpCustomerDatabaseResponse.makeErrorResponse(error));
    }
  }

  private executeDatabaseFunc_(
    endpoint: string,
    request: CdpCustomerDatabaseRequest,
    funcDescription: string
  ): Observable<CdpCustomerDatabaseResponse> {
    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    //console.log("executeDatabaseFunc_:", request)

    // We need to store references to member functions and pass them explicitly because
    // "this" will be something different in a callback context.
    const func = this.executeDatabaseFuncWithJwt_.bind(
      this,
      endpoint,
      request,
      funcDescription,
      this.backendCore.makeServerUrlWithJwt,
      this.http,
      this.handleError<CdpCustomerDatabaseResponse>
    );

    return jwtObservable.pipe(concatMap((jwt) => func(jwt)));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.log('Error: ', error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
