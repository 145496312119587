import { Component } from '@angular/core';

@Component({
  selector: 'app-cdp-terms-of-service',
  standalone: true,
  imports: [],
  templateUrl: './cdp-terms-of-service.component.html',
  styleUrl: './cdp-terms-of-service.component.sass'
})
export class CdpTermsOfServiceComponent {

}
