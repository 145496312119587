import { EventEmitter } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

export enum CdpProgressOperationStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  CompletedFailed = 'CompletedFailed',
  CompletedSucceeded = 'CompletedSucceeded',
  StartAndComplete = 'StartAndComplete',
}

export class CdpProgressOperationTag {
  id: string = '';
  operationType: string = '';

  constructor(operationType: string) {
    this.id = uuidv4();
    this.operationType = operationType;
  }
}

export class CdpProgressOperation {
  tag: CdpProgressOperationTag;
  message: string;
  status: CdpProgressOperationStatus;

  constructor(
    operationType: string,
    message: string,
    status: CdpProgressOperationStatus = CdpProgressOperationStatus.InProgress
  ) {
    this.tag = new CdpProgressOperationTag(operationType);
    this.message = message;
    this.status = status;
  }
}

export enum CdpProgressOperationEventType {
  Remove = 'Remove',
  Add = 'Add',
  Update = 'Update',
  UpdateFinal = 'UpdateFinal',
}

export class CdpProgressOperationEvent {
  type: CdpProgressOperationEventType;
  tag: CdpProgressOperationTag;
  status: CdpProgressOperationStatus;

  constructor(
    type: CdpProgressOperationEventType,
    tag: CdpProgressOperationTag,
    status: CdpProgressOperationStatus = CdpProgressOperationStatus.NotStarted
  ) {
    this.type = type;
    this.tag = tag;
    this.status = status;
  }
}

export class CdpProgressOperationSet {
  private operations_: CdpProgressOperation[] = [];

  public eventEmitter: EventEmitter<CdpProgressOperationEvent> =
    new EventEmitter<CdpProgressOperationEvent>();

  private isOperationInProgress_: boolean = false;
  public get isOperationInProgress() {
    // Note: Although we should be able to skip having an
    //       actual Boolean data member and just use
    //       "this.operations_.length > 0" instead,
    //       it's much easier to get Angular components to
    //       notice the change by having an actual object.
    return this.isOperationInProgress_;
  }

  private updateIsOperationInProgress_() {
    this.isOperationInProgress_ = this.operations_.length > 0;
  }

  public getOperationInProgressMessage(useEarliest: boolean = true): string {
    const numOperations: number = this.operations_.length;

    if (numOperations == 0) {
      return '';
    } else {
      const index: number = useEarliest ? 0 : numOperations - 1;
      return this.operations_[index].message;
    }
  }

  private emit_(
    type: CdpProgressOperationEventType,
    tag: CdpProgressOperationTag,
    status: CdpProgressOperationStatus = CdpProgressOperationStatus.NotStarted
  ) {
    this.eventEmitter.emit(new CdpProgressOperationEvent(type, tag, status));
  }

  public addOperation(
    operationType: string,
    message: string
  ): CdpProgressOperationTag {
    const operation: CdpProgressOperation = new CdpProgressOperation(
      operationType,
      message
    );

    this.operations_.push(operation);
    this.updateIsOperationInProgress_();

    /*
    console.log(
      `Added operation: ${operation.tag.id}. num ops=${this.operations_.length}`
    );
*/

    this.emit_(CdpProgressOperationEventType.Add, operation.tag);

    return operation.tag;
  }

  public removeOperation_(
    tag: CdpProgressOperationTag,
    isFinalUpdate: boolean
  ): boolean {
    // Note that it's not necessarily an error if we try to remove an operation that isn't
    // marked as being in progress, so we just ignore any such case.
    const origNumOperations: number = this.operations_.length;

    /*
      console.log(
        `Removing operation ${tag.id}. ops start=${this.operations_.length}`
     );
     */

    for (let i = 0; i < origNumOperations; i++) {
      const operation: CdpProgressOperation = this.operations_[i];
      if (operation.tag.id == tag.id) {
        this.operations_ = this.operations_.filter((op) => op.tag.id != tag.id);
        this.updateIsOperationInProgress_();

        /* 
        console.log(
          `Removed operation ${operation.tag.id}. ops remaining=${this.operations_.length}`
        );
          */

        if (isFinalUpdate) {
          this.emit_(
            CdpProgressOperationEventType.UpdateFinal,
            tag,
            operation.status
          );
        } else {
          this.emit_(CdpProgressOperationEventType.Remove, tag);
        }

        return true;
      }
    }

    //console.log(`No match to remove. ops=${this.operations_.length}`);

    return false;
  }

  public removeOperation(tag: CdpProgressOperationTag): boolean {
    return this.removeOperation_(tag, false);
  }

  public updateOperation(
    tag: CdpProgressOperationTag,
    status: CdpProgressOperationStatus,
    message: string
  ): boolean {
    for (const operation of this.operations_) {
      if (operation.tag.id == tag.id) {
        operation.status = status;
        operation.message = message;

        this.emit_(CdpProgressOperationEventType.Update, tag);

        return true;
      }
    }

    return false;
  }

  public updateOperationFinal(
    tag: CdpProgressOperationTag,
    succeeded: boolean,
    message: string,
    removeAfterTimeMs: number = 500
  ): boolean {
    const status: CdpProgressOperationStatus = succeeded
      ? CdpProgressOperationStatus.CompletedSucceeded
      : CdpProgressOperationStatus.CompletedFailed;

    for (const operation of this.operations_) {
      if (operation.tag.id == tag.id) {
        //console.log('Removing with timeout:', removeAfterTimeMs);
        operation.status = status;
        operation.message = message;

        if (removeAfterTimeMs <= 0) {
          // Remove the operation immediately.
          this.removeOperation_(tag, true);
        } else {
          // Wait a short time before removing the operation, typically
          // so that the user has a chance to read it.
          const removeOperation = this.removeOperation_.bind(this);
          setTimeout(() => {
            removeOperation(tag, true);
          }, removeAfterTimeMs);
        }

        return true;
      }
    }

    return false;
  }

  public updateOperationMessage(
    tag: CdpProgressOperationTag,
    message: string
  ): boolean {
    for (const operation of this.operations_) {
      if (operation.tag.id == tag.id) {
        operation.message = message;

        this.emit_(CdpProgressOperationEventType.Update, tag);

        return true;
      }
    }

    return false;
  }
}
