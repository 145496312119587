import { Hub } from 'aws-amplify';
import { EventEmitter, Injectable } from '@angular/core';
import { CognitoIdToken } from 'amazon-cognito-identity-js';

import { CdpBackendService } from './backend/cdp-backend/cdp-backend.service';
import { CdpEvent, CdpEventCategory } from './core/cdp-event';
import { CdpRouterService } from './core/router/cdp-router.service';
import { CdpRoutes } from './core/router/cdp-routes';

import { CdpSession, CdpSessionInfo } from './core/cdp-session';
import { CdpUser, CdpUserProfile } from './core/cdp-user';
import { CdpBusiness, CdpBusinessProfile } from './core/business/cdp-business';
import { CdpAuthService, CdpJwt } from './core/cdp-auth/auth.service';

export enum CdpSessionManagerEventType {
  SessionChanged = 'SessionChanged',
  SessionStarted = 'SessionStarted',
  SignIn = 'SignIn',
  SignOut = 'SignOut',
  None = 'None',
}

@Injectable({
  providedIn: 'root',
})
export class CdpSessionManagerService {
  // Note: The session is also stored as JSON in the window's session storage,
  //       so that if the user navigates away from the app entirely, we don't
  //       have to immediately query the backend to refresh the session data.
  private currentSession_: CdpSession | null = null;
  private currentSessionJson_: string | null = null;

  public eventEmitter: EventEmitter<CdpEvent> = new EventEmitter<CdpEvent>();

  constructor(
    private backend: CdpBackendService,
    private authenticator: CdpAuthService,
    private router: CdpRouterService
  ) {
    this.clearCachedCdpSession_();
    const prevSessionJson: string | null = this.getCachedCdpSessionJson_();

    /*
     * console.log(
     * 'Session manager constructor. prevSessionJson=',
     * prevSessionJson
     *);
     */

    this.maybeRefreshCdpSession_(true);

    this.startListen();
  }

  startListen() {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.processAuthEvent_(payload);
    });
  }

  public async signOut() {
    // Note: Although the call to authenticator.signOut()
    //       will raise an event that will also cause
    //       endCurrentCdpSession() to be called, it
    //       seems safer to end the session first.
    try {
      // Clear out all resources associated with the current business and/or user.

      await this.endCurrentCdpSession();
    } catch {
      // Just ignore errors.
    }

    try {
      await this.authenticator.signOut();
    } catch {
      // Just ignore errors.
    }

    this.sessionChangedUserSignedOut_();
  }

  public async isUserSignedIn(): Promise<boolean> {
    return this.authenticator.isUserSignedIn();
  }

  public async getCurrentBusinessProfile(): Promise<CdpBusinessProfile | null> {
    const session: CdpSession | null = await this.getCurrentCdpSession();

    return session && session.business && session.business.profile
      ? session.business.profile
      : null;
  }

  public async getCurrentUser(): Promise<CdpUser | null> {
    const session: CdpSession | null = await this.getCurrentCdpSession();

    return session && session.user ? session.user : null;
  }

  public async getCurrentUserProfile(): Promise<CdpUserProfile | null> {
    const session: CdpSession | null = await this.getCurrentCdpSession();

    return session && session.user && session.user.profile
      ? session.user.profile
      : null;
  }

  // We need to use the browser's session storage to keep track of the
  // current session.
  //
  // Note: A class-static variable will not suffice, since the script
  //       with all its state goes away if the page is changed or
  //       reloaded.
  private async getCurrentCdpSessionEndParams_(): Promise<string | null> {
    const sessionJson: string | null = this.getCachedCdpSessionJson_();

    //console.log('About to session end.');

    return null;

    // TODO TODO
    /*
    if (!sessionInfo) return null;

    // We need the session ID, user ID, and "sat".
    try {
      const sid = sessionInfo.session.session_id;
      const sat = sessionInfo.session.sat;
      const uid = sessionInfo.session.user_id;

      const queryStringParamsEndSession: string = `uid=${uid}&sid=${sid}&sat=${sat}`;

      //console.log('End qsp:', queryStringParamsEndSession);
      return queryStringParamsEndSession;
    } catch (error) {
      //console.log('Session info error: ', error);
      return null;
    }
    */
  }

  private clearCachedCdpSession_(): void {
    this.currentSession_ = null;
    this.currentSessionJson_ = null;

    window.sessionStorage.removeItem('ci.session');
  }

  private getCachedCdpSession_(): CdpSession | null {
    if (this.currentSession_ == null) {
      // TODO?
      // Re-create the session from JSON.
    }
    return this.currentSession_;
  }

  private getCachedCdpSessionJson_(): string | null {
    if (this.currentSessionJson_ && this.currentSessionJson_.length > 0) {
      return this.currentSessionJson_;
    }

    return window.sessionStorage.getItem('ci.session');
  }

  private static makeSessionInfoFromBackendSessionInfo_(
    dict: any
  ): CdpSessionInfo {
    const sessionInfo: CdpSessionInfo = new CdpSessionInfo();

    if ('business_id' in dict) {
      sessionInfo.businessId = dict.business_id;
    }

    if ('cognito_sub' in dict) {
      sessionInfo.cognitoSub = dict.cognito_sub;
    }
    if ('cognito_iss' in dict) {
      sessionInfo.cognitoIss = dict.cognito_iss;
    }
    if ('session_id' in dict) {
      sessionInfo.sessionId = dict.session_id;
    }
    if ('session_status' in dict) {
      sessionInfo.sessionStatus = dict.session_status;
    }
    if ('session_start_timestamp_utc' in dict) {
      sessionInfo.sessionStartTimestampUtc = dict.session_start_timestamp_utc;
    }
    if ('sat' in dict) {
      sessionInfo.sat = dict.sat;
    }
    if ('user_id' in dict) {
      sessionInfo.userId = dict.user_id;
    }

    return sessionInfo;
  }

  private static makeUserFromBackendUser_(dict: any): CdpUser {
    const user: CdpUser = new CdpUser();

    //console.log("makeUserFromBackendUser_ default user start:", user)
    //console.log("makeUserFromBackendUser_:", dict);

    if ('profile' in dict) {
      user.profile.setFromDict(dict.profile);

      // As a matter of convenience on the backend (read "as a quick hack"),
      // whether or not a user is an alpha user is stored in the profile in the
      // user database.  However, permissions shouldn't really be part of the
      // profile (which should mostly be modifiable by the user).
      //console.log('Profile dict:', dict.profile);

      let isAlphaUser: boolean | undefined = true;

      if ('permissions' in dict.profile) {
        isAlphaUser = dict.profile.permissions.isAlphaUser;
        if (isAlphaUser == undefined) {
          isAlphaUser = true;
        }
      }
      //console.log('isAlphaUser:', isAlphaUser);

      user.defaultPermissionIsAllowed = !isAlphaUser;
    }

    // TODO Is there a need to keep the user_id and business_id here?
    return user;
  }

  private static makeBusinessFromBackendBusiness_(dict: any): CdpBusiness {
    const business: CdpBusiness = new CdpBusiness();

    //console.log("makeBusinessFromBackendBusiness_:", dict);

    if ('profile' in dict) {
      business.profile.setFromDict(dict.profile);
    }

    return business;
  }

  private static makeCdpSessionFromSessionJson_(
    sessionJson: string | null
  ): CdpSession | null {
    if (!sessionJson || sessionJson.length == 0) {
      return null;
    }

    const dict: any = JSON.parse(sessionJson);
    const session: CdpSession = new CdpSession();

    //console.log("Backend session:", dict);

    if (dict.sessionInfo) {
      // Convert from the backend's naming conventions.
      session.sessionInfo =
        CdpSessionManagerService.makeSessionInfoFromBackendSessionInfo_(
          dict.sessionInfo
        );
    }

    if (dict.user) {
      session.user = CdpSessionManagerService.makeUserFromBackendUser_(
        dict.user
      );
    }

    if (dict.business) {
      session.business =
        CdpSessionManagerService.makeBusinessFromBackendBusiness_(
          dict.business
        );
    }

    return session;
  }

  private setCachedCdpSessionJson_(sessionJson: string) {
    this.clearCachedCdpSession_();

    try {
      // Create the CdpSession object from the JSON.
      this.currentSession_ =
        CdpSessionManagerService.makeCdpSessionFromSessionJson_(sessionJson);

      // Store the JSON representation both as member data and in the browser's session storage.
      this.currentSessionJson_ = sessionJson;
      window.sessionStorage.setItem('ci.session', sessionJson);
    } catch {
      // TODO TODO What should be done if the JSON is invalid?
      // TODO Report an error also?
      this.clearCachedCdpSession_();
    }
  }

  public async getCurrentCdpSession(): Promise<CdpSession | null> {
    //console.log('In getCurrentCdpSession');
    await this.maybeRefreshCdpSession_(false);
    return this.currentSession_;
  }

  private async maybeRefreshCdpSession_(isFirstTime: boolean): Promise<void> {
    //console.log('In maybeRefreshCdpSession_');

    // If the session in the browser's session storage doesn't match
    // the Cognito user, refresh the session by fetching form the backend
    // if needed.
    //
    // TODO Check if JWTs are expired or nearly so and reauthenticate?
    const cognitoIdToken: CognitoIdToken | null =
      await this.authenticator.getCurrentSessionCognitoIdToken();
    var cognito_sub: string = '';
    var cognito_iss: string = '';

    if (cognitoIdToken) {
      const payload = cognitoIdToken.payload;

      if (payload) {
        cognito_sub = payload['sub'];
        cognito_iss = payload['iss'];
      }
    }
    const cachedSession: CdpSession | null = this.getCachedCdpSession_();

    //console.log('Cached session:', JSON.stringify(cachedSession));
    //console.log(`Cognito sub:${cognito_sub} iss:${cognito_iss}`);

    if (cognito_sub == '' || cognito_iss == '') {
      // There is no user logged in.
      if (cachedSession) {
        // We still have some session info cached.  Get rid of it.

        // TODO For now, don't force disconnecting the backend session
        //      here, e.g., in case the user connected from another
        //      browser.
        this.clearCachedCdpSession_();
        this.sessionChangedUserSignedOut_();
      }
    } else {
      // There is a user logged in.  If the session matches the Cognito
      // user ID, we don't need to do anything.
      // TODO That's not necessarily true. We should check, e.g., that the
      //      session hasn't expired.
      const isSameUser: boolean =
        cachedSession != null &&
        cachedSession.sessionInfo.cognitoSub == cognito_sub &&
        cachedSession.sessionInfo.cognitoIss == cognito_iss;

      //console.log('isSameUser: ', isSameUser);

      if (!isSameUser) {
        // The cached session does not match the current user.
        try {
          /*console.log(
            'In maybeRefreshCdpSession_: call startOrConnectCdpSession'
          );
*/
          await this.startOrConnectCdpSession(isFirstTime);
        } catch {
          // TODO What's the best thing to do here?
          this.clearCachedCdpSession_();
        }
      } else {
        // The user has not changed. However, if this is the first
        // time calling this function, then the application has just
        // loaded and we should emit an event.
        //console.log('Same user. isFirstTime:', isFirstTime);

        if (isFirstTime) {
          this.sessionStarted_();
        }
      }
    }
  }

  public async startOrConnectCdpSessionIfLoggedIn(): Promise<string> {
    const jwt: CdpJwt = await CdpJwt.getCurrentSessionTokens();

    if (jwt.isEmpty()) {
      // There is no user logged in.
      return '';
    }

    // On the backend service, start a new session for the user or connect to
    // an existing open session for the user.
    const sessionJson: string = await this.backend.startOrConnectCdpSession(
      jwt
    );

    return sessionJson;
  }

  public async startOrConnectCdpSession(isFirstTime: boolean): Promise<void> {
    const prevSessionJson: string | null = this.getCachedCdpSessionJson_();

    //console.log('startOrConnectCdpSession. prevSessionJson=', prevSessionJson);

    // End the old session, if any.
    try {
      await this.endCurrentCdpSession();
    } catch {
      // Just ignore any error during disconnection.
    }

    this.clearCachedCdpSession_();

    // Start a new session for the user or connect to an existing open session
    // for the user.
    const sessionJson: string = await this.startOrConnectCdpSessionIfLoggedIn();

    this.setCachedCdpSessionJson_(sessionJson);

    //console.log('Session JSON changed:', sessionJson != prevSessionJson);

    if (sessionJson != prevSessionJson) {
      this.sessionChanged_(sessionJson, prevSessionJson, isFirstTime);
    }
  }

  public async endCurrentCdpSession(): Promise<void> {
    // Clear the old session.
    const prevSessionJson: string | null = this.getCachedCdpSessionJson_();

    const queryStringParamsEndSession: string | null =
      await this.getCurrentCdpSessionEndParams_();

    // Clear the cached session information.
    this.clearCachedCdpSession_();

    if (
      queryStringParamsEndSession != null &&
      queryStringParamsEndSession != ''
    ) {
      try {
        // Tell the backend to end the session.
        await this.backend.endCdpSession(queryStringParamsEndSession);
      } catch {
        // Just ignore any errors from the backend.
      }

      // Alert listeners that the session has changed.
      this.sessionChanged_(
        this.getCachedCdpSessionJson_(),
        prevSessionJson,
        false
      );
    }
  }

  private static isSessionSignedIn_(session: any): boolean {
    if (!session) {
      return false;
    }

    //console.log('isSessionSignedIn session:', session);
    try {
      const sessionCognitoSub: string = session.sessionInfo.cognitoSub;
      const sessionCognitoIss: string = session.sessionInfo.cognitoIss;

      //console.log('Session Cognito sub:', sessionCognitoSub);

      return sessionCognitoSub.length > 0 && sessionCognitoIss.length > 0;
    } catch (error) {
      //console.log('Caught exception: ', error);

      return false;
    }
  }

  private static isEventDetailObjSignOutEvent_(detailObj: object) {
    if (!detailObj) {
      return false;
    }

    //console.log('In isEventDetailObjSignOutEvent_');

    if (!('currentSession' in detailObj) || !('prevSession' in detailObj)) {
      return false;
    }

    const currentSession: any = detailObj.currentSession;
    const prevSession: any = detailObj.prevSession;

    const isCurrentSessionSignedIn =
      CdpSessionManagerService.isSessionSignedIn_(currentSession);
    if (isCurrentSessionSignedIn) {
      return false;
    }

    const isPrevSessionSignedIn =
      CdpSessionManagerService.isSessionSignedIn_(prevSession);

    return isPrevSessionSignedIn;
  }

  private static isEventDetailObjSignInEvent_(detailObj: object) {
    if (!detailObj) {
      return false;
    }

    if (!('currentSession' in detailObj) || !('prevSession' in detailObj)) {
      //console.log('Missing currentSession or prevSession');

      return false;
    }

    const currentSession: any = detailObj.currentSession;
    const prevSession: any = detailObj.prevSession;

    //console.log('Detail obj current session: ', currentSession);
    //console.log('Detail obj prev session: ', prevSession);

    const isCurrentSessionSignedIn =
      CdpSessionManagerService.isSessionSignedIn_(currentSession);
    //console.log('isCurrentSessionSignedIn: ', isCurrentSessionSignedIn);

    if (!isCurrentSessionSignedIn) {
      return false;
    }

    const isPrevSessionSignedIn =
      CdpSessionManagerService.isSessionSignedIn_(prevSession);
    //console.log('isPrevSessionSignedIn: ', isPrevSessionSignedIn);

    return !isPrevSessionSignedIn;
  }

  public isCurrentSessionSignedIn(): boolean {
    const session: any = this.getCurrentCdpSession();

    //console.log('isCurrentSessionSignedIn: session=', session);

    return CdpSessionManagerService.isSessionSignedIn_(session);
  }

  private static getEventDetailObj_(e: any): any {
    if (!e || !('detail' in e)) {
      return null;
    }

    const detail: any = e.detail;

    if (!detail || !('detailObj' in detail)) {
      return null;
    }

    return detail.detailObj;
  }

  public isEventCurrentSessionSignedIn(e: any) {
    const detailObj: any = CdpSessionManagerService.getEventDetailObj_(e);

    if (!detailObj || !('currentSession' in detailObj)) {
      return false;
    }

    const currentSession: any = detailObj.currentSession;

    return CdpSessionManagerService.isSessionSignedIn_(currentSession);
  }

  public isEventSignOut(e: any): boolean {
    const detailObj: any = CdpSessionManagerService.getEventDetailObj_(e);

    if (!detailObj) {
      return false;
    }

    return CdpSessionManagerService.isEventDetailObjSignOutEvent_(detailObj);
  }

  public isEventSignIn(e: any): boolean {
    //console.log('isEventSignIn event: ', e);

    const detailObj: any = CdpSessionManagerService.getEventDetailObj_(e);

    //console.log('isEventSignIn detail obj:', detailObj);

    if (!detailObj) {
      return false;
    }

    return CdpSessionManagerService.isEventDetailObjSignInEvent_(detailObj);
  }

  private emitEvent_(event: CdpEvent) {
    /*
    console.log(
      'Session manager emitting event: ',
      event.getCategoryEventType()
    );
    */

    this.eventEmitter.emit(event);
  }

  private async sessionChanged_(
    currentSessionJson: string | null,
    prevSessionJson: string | null,
    isFirstTime: boolean
  ): Promise<void> {
    //console.log("Session manager checking for session change");

    const currentSession: object | null =
      CdpSessionManagerService.makeCdpSessionFromSessionJson_(
        currentSessionJson
      );
    const prevSession: object | null =
      CdpSessionManagerService.makeCdpSessionFromSessionJson_(prevSessionJson);

    // TODO Use the event details to summarize what changed.
    var detailObj: object = {
      currentSession: currentSession,
      prevSession: prevSession,
    };

    const isSignOutEvent: boolean =
      CdpSessionManagerService.isEventDetailObjSignOutEvent_(detailObj);

    const eventType: CdpSessionManagerEventType = isSignOutEvent
      ? CdpSessionManagerEventType.SignOut
      : isFirstTime
      ? CdpSessionManagerEventType.SessionStarted
      : CdpSessionManagerEventType.SessionChanged;
    const e: CdpEvent = new CdpEvent(
      eventType,
      CdpEventCategory.Session,
      eventType,
      detailObj
    );

    this.emitEvent_(e);
  }

  private async sessionStarted_(): Promise<void> {
    const e: CdpEvent = new CdpEvent(
      CdpSessionManagerEventType.SessionStarted,
      CdpEventCategory.Session,
      CdpSessionManagerEventType.SessionStarted,
      null
    );
    this.emitEvent_(e);
  }

  private async sessionChangedUserSignedOut_(): Promise<void> {
    const e: CdpEvent = new CdpEvent(
      CdpSessionManagerEventType.SignOut,
      CdpEventCategory.Session,
      CdpSessionManagerEventType.SignOut,
      null
    );
    this.emitEvent_(e);
  }

  private async processAuthEvent_(
    payload: any /* HubPayload */
  ): Promise<void> {
    /*
    console.log(
      'Session processing auth event: ',
      JSON.stringify(payload.event)
    );
    */

    switch (payload.event) {
      case 'autoSignIn':
      //console.log('auto sign in successful');
      // fall through
      case 'signIn':
        //console.log('user signed in');
        await this.startOrConnectCdpSession(false);
        this.router.navigate(CdpRoutes.AfterSignIn);
        break;

      case 'signOut':
        //console.log('user signed out');
        await this.endCurrentCdpSession();
        break;

      case 'tokenRefresh':
        //console.log('token refresh succeeded');

        // TODO TODO We want to reconnect the session to the backend
        //           without losing the current state!

        break;

      case 'configured':
        break;

      case 'signIn_failure':
        //console.log('user sign in failed');
        break;
      case 'signUp':
        //console.log('user signed up');
        break;
      case 'signUp_failure':
        //console.log('user sign up failed');
        break;
      case 'confirmSignUp':
        //console.log('user confirmation successful');
        break;
      case 'completeNewPassword_failure':
        //console.log('user did not complete new password flow');
        break;

      case 'autoSignIn_failure':
        //console.log('auto sign in failed');
        break;
      case 'forgotPassword':
        //console.log('password recovery initiated');
        break;
      case 'forgotPassword_failure':
        //console.log('password recovery failed');
        break;
      case 'forgotPasswordSubmit':
        //console.log('password confirmation successful');
        break;
      case 'forgotPasswordSubmit_failure':
        //console.log('password confirmation failed');
        break;
      case 'verify':
        //console.log('TOTP token verification successful');
        break;

      case 'tokenRefresh_failure':
        //console.log('token refresh failed');
        break;
      case 'cognitoHostedUI':
        //console.log('Cognito Hosted UI sign in successful');
        break;
      case 'cognitoHostedUI_failure':
        //console.log('Cognito Hosted UI sign in failed');
        break;
      case 'customOAuthState':
        //console.log('custom state returned from CognitoHosted UI');
        break;
      case 'customState_failure':
        //console.log('custom state failure');
        break;
      case 'parsingCallbackUrl':
        //console.log('Cognito Hosted UI OAuth url parsing initiated');
        break;
      case 'userDeleted':
        //console.log('user deletion successful');
        break;
      case 'updateUserAttributes':
        //console.log('user attributes update successful');
        break;
      case 'updateUserAttributes_failure':
        //console.log('user attributes update failed');
        break;

      default:
        //console.log('unknown event type');
        break;
    }
  }
}
