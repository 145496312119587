import { Component } from '@angular/core';
import { CdpEmailDomainVerificationComponent } from '../cdp-email-domain-verification/cdp-email-domain-verification.component';

@Component({
  selector: 'app-cdp-email-sender-verification',
  standalone: true,
  imports: [CdpEmailDomainVerificationComponent],
  templateUrl: './cdp-email-sender-verification.component.html',
  styleUrl: './cdp-email-sender-verification.component.sass'
})
export class CdpEmailSenderVerificationComponent {
  startedVerification: boolean = false;

  onClickStartDomainVerification() {
    this.startedVerification = true;
  }

  onClickNoDomain() {
    this.startedVerification = true;
  }

  cancelVerification(message: string) {    
    this.startedVerification = false;
  }
}
