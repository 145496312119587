import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CdpName } from '../../cdp-name';
import { CdpEmailAddress } from '../../cdp-email-address';
import {
  CdpProgressOperationSet,
  CdpProgressOperationTag,
} from 'src/app/ui/progress/cdp-progress';
import { CdpProgressSpinnerComponent } from 'src/app/ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';
import {
  CdpEmailData,
  CdpSendEmailResponse,
  CdpSendEmailTemplate,
  CdpSendEmailTemplateDestination,
} from 'src/app/email/cdp-email';
import { CdpSendEmailService } from 'src/app/email/send/cdp-send-email.service';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpUserProfile } from '../../cdp-user';
import { CdpCloneable } from 'src/app/email/template/email-template-editor/email-template-editor.component';
import { CdpEvent } from '../../cdp-event';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs';
import { CdpRoutes } from '../../router/cdp-routes';

@Component({
  selector: 'app-cdp-feedback',
  standalone: true,
  imports: [CdpProgressSpinnerComponent, FormsModule],
  templateUrl: './cdp-feedback.component.html',
  styleUrl: './cdp-feedback.component.sass',
})
export class CdpFeedbackComponent implements OnInit {
  private thisUrl_: string = '';

  from: string = '';
  name: CdpName = new CdpName();
  email: CdpEmailAddress = new CdpEmailAddress();
  topic: string = 'Feedback';
  message: string = '';

  resetForm() {
    this.from = '';
    this.name = new CdpName();
    this.email = new CdpEmailAddress();
  
    this.topic= 'Feedback';
    this.message = '';
  }

  //isEmailReadOnly: boolean = false;
  //isNameReadOnly: boolean = false;

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();

  get isOperationInProgress(): boolean {
    return this.operationSet.isOperationInProgress;
  }

  constructor(
    private router: Router,
    private sendEmailService: CdpSendEmailService,
    private sessionManager: CdpSessionManagerService
  ) {
    this.sessionManager.eventEmitter.subscribe((e) =>
      this.processSessionManagerEvent(e)
    );
  }

  async ngOnInit(): Promise<void> {
    await this.onReload();
    
    this.thisUrl_ = this.router.url;

    const onReload = this.onReload.bind(this);
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        //console.log('Navend event:', JSON.stringify(event));
        if (event.url == this.thisUrl_) {
          // route is restored
          //console.log('Navend event match:', this.thisUrl_);

          onReload();
        }
      }
    });
  }

  async onReload() {
    this.resetForm();
    await this.refreshIdentity_();
  }

  async processSessionManagerEvent(event: CdpEvent) {
    await this.onReload();
  }

  private async refreshIdentity_() {
    const userProfile: CdpUserProfile | null =
      await this.sessionManager.getCurrentUserProfile();
    if (userProfile) {
      const firstName: string = userProfile.name.givenName;
      const lastName: string = userProfile.name.familyName;
      const email: string = userProfile.email.email;

      this.from = `${firstName} ${lastName} <${email}>`;
      
      if (userProfile.email.isValid()) {
        this.email = CdpCloneable.deepCopy(userProfile.email);
      }

      if (userProfile.name.isValid()) {
        this.name = CdpCloneable.deepCopy(userProfile.name);
      }
      
    }
  }

  isFormValid(): boolean {
    return (
      this.name.isValid() && this.email.isValid() && this.message.length > 0
    );
  }

  private makeSendEmailDestinationForRecipient_(
    recipientEmail: string
  ): CdpSendEmailTemplateDestination {
    const destination: CdpSendEmailTemplateDestination =
      new CdpSendEmailTemplateDestination();

    destination.destination.toAddresses = [recipientEmail];

    return destination;
  }

  private makeSendEmailDestinations_(): CdpSendEmailTemplateDestination[] {
    const destinations: CdpSendEmailTemplateDestination[] = [];

    const recipientEmails: string[] = ['support@inboxease.com'];

    for (const recipientEmail of recipientEmails) {
      const destination: CdpSendEmailTemplateDestination =
        this.makeSendEmailDestinationForRecipient_(recipientEmail);

      destinations.push(destination);
    }

    return destinations;
  }

  private makeEmailData_(): CdpEmailData | null {
    let emailData: CdpEmailData = new CdpEmailData();

    // Note: The actual email address needs to be ours, since the user's
    //       email may not be verified with us.
    const senderMailbox: string = `${this.name.givenName} ${this.name.familyName} <support@inboxease.com>`;

    emailData.sender = senderMailbox;

    emailData.destinations = this.makeSendEmailDestinations_();

    const sendTemplate: CdpSendEmailTemplate = new CdpSendEmailTemplate();

    sendTemplate.subject = `Customer feedback (${this.topic}) from ${senderMailbox}`;

    const html: string = `
<p>First name: ${this.name.givenName}</p>
<p>Last Name: ${this.name.familyName}</p>
<p>Email: ${this.email.email}</p>
<p>Topic: ${this.topic}</p>
<p>Message:</p>
<p>${this.message}</p>
<p>----</p>
`;

    sendTemplate.html = html;
    emailData.template = sendTemplate;

    return emailData;
  }

  private submitFeedbackComplete_(
    sendEmailResponse: CdpSendEmailResponse,
    operationTag: CdpProgressOperationTag
  ): void {
    //console.log('Send email response: ', sendEmailResponse);
    const succeeded: boolean = sendEmailResponse.errors.length == 0;
    const message: string = succeeded
      ? 'Feedback submitted.'
      : 'Feedback submission failed.';

    this.operationSet.updateOperationFinal(operationTag, succeeded, message);

    // Display thank you message.'
    this.router.navigate([CdpRoutes.FeedbackComplete]);
  }

  async onClickSubmit() {
    if (!this.isFormValid()) {
      // This shouldn't be possible.
      console.log('Form is not valid.');
      return;
    }

    let emailData: CdpEmailData | null = this.makeEmailData_();

    if (emailData) {
      //console.log('Send feedback: emailData', emailData);

      const operationTag: CdpProgressOperationTag =
        this.operationSet.addOperation(
          'SubmitFeedback',
          'Submitting feedback...'
        );

      try {
        let response: CdpSendEmailResponse =
          await this.sendEmailService.sendEmail(emailData);

        this.submitFeedbackComplete_(response, operationTag);
      } catch {
        this.operationSet.updateOperationFinal(
          operationTag,
          false,
          'Submitting feedback failed.'
        );
      }
    } else {
      console.log('No template to send');
    }
  }

  allTopics: string[] = ['Feedback', 'Question', 'Bug report', 'Other'];
}
