import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { CdpRoutes } from 'src/app/core/router/cdp-routes';
import { CdpSideMenuComponent } from 'src/app/ui/cdp-side-menu/cdp-side-menu.component';

@Component({
  selector: 'app-square-connect',
  standalone: true,
  imports: [CommonModule,
  CdpSideMenuComponent],
  templateUrl: './square-connect.component.html',
  styleUrl: './square-connect.component.sass',
})
export class SquareConnectComponent {
  @Input() isCheckboxConsentChecked: boolean = false;

  constructor(private router: Router) {}

  onCheckboxChanged(e: any) {
    this.isCheckboxConsentChecked = e.target.checked;
  }
  onClickSquare() {
    this.router.navigate([CdpRoutes.SquareConnectStart]);
  }
}
