import validator from 'validator';

export class CdpUrl {
  // Note: The data member "url_" should really be "href_",
  //       but we leave it as is for backwards compatibility
  //       with stored profiles.
  private url_: string = '';

  private hostname_: string = '';
  private protocol_: string = '';

  constructor(url: string = '') {
    this.trySetUrl(url);
  }

  public isValid(): boolean {
    return this.url_.length > 0;
  }

  public get href(): string {
    return this.url_;
  }
  
  public get hostname(): string {
    return this.hostname_;
  }

  public get protocol(): string {
    return this.protocol_;
  }

  public trySetUrl(url: string): boolean {
    const fullUrl: URL | null = CdpUrl.validateAndMakeUrl(url);
    if (fullUrl) {
      this.url_ = fullUrl.href;
      this.hostname_ = fullUrl.hostname;
      this.protocol_ = fullUrl.protocol;
      
      return true;
    } else {
      return false;
    }
  }

  public static validateAndMakeUrl(url: string): URL | null {
    if (
      !validator.isURL(url, { require_host: true, require_protocol: false })
    ) {
      //console.log("Invalid URL:", url);
      return null;
    } else {
      try {
        const fullUrl: URL = new URL(url);
        //console.log("fullUrl:", fullUrl);
        //console.log("fullUrl hostname:", fullUrl.hostname);

        return fullUrl;
      } catch {
        // TODO Throw if invalid? Or just make an empty URL?
        //console.log("Caught URL exception");
        return null;
      }
    }
  }
}
