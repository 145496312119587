import { Injectable } from '@angular/core';
import { CdpBackendService } from 'src/app/backend/cdp-backend/cdp-backend.service';

import { CdpJwt } from 'src/app/core/cdp-auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SquareService {

  constructor(private backend: CdpBackendService) { }

  public async squareSessionSetupStart(): Promise<string> {
    const jwt: CdpJwt = await CdpJwt.getCurrentSessionTokens();

    if (jwt.isEmpty()) {
      // There is no user logged in.
      return '';
    }

    return this.backend.sendCommandSquareSessionSetupStart(jwt);
  }

  public async squareSessionCompleteStart(queryStringParams: string): Promise<any> {
    return this.backend.sendCommandSquareSessionCompleteStart(queryStringParams);
  }  
}
