import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CdpRoutes } from './cdp-routes';

@Injectable({
  providedIn: 'root'
})
export class CdpRouterService {

  constructor(private router: Router) { }

  navigate(route: CdpRoutes) {
    console.log("Navigating to:", route);
    
    this.router.navigate([route]);
  }
}
