export class CdpSendEmailDestination {
  toAddresses: string[] = [];
  ccAddresses: string[] = [];
  bccAddresses: string[] = [];
}

export class CdpSendEmailTemplateData {
  data: any = {};
  email: string = "";
  firstname: string = "";
  lastname: string = "";
}

export class CdpSendEmailTemplateDestination {
  destination: CdpSendEmailDestination = new CdpSendEmailDestination();
  replacementData: CdpSendEmailTemplateData = new CdpSendEmailTemplateData();
}

export class CdpSendEmailTemplate {
  templateName: string = '';
  subject: string = '';
  html: string = '';
  text: string = '';
}

export class CdpEmailData {
  sender: string = '';
  template: CdpSendEmailTemplate = new CdpSendEmailTemplate();
  configurationSetName: string = '';
  destinations: CdpSendEmailTemplateDestination[] = [];
  defaultTemplateData: CdpSendEmailTemplateData =
    new CdpSendEmailTemplateData();

  charSet: string = 'UTF-8';
}

export class CdpSendEmailResponse {
  responseMessage: string = '';
  errors: any[] = [];

  appendError(error: any) {
    this.errors.push(error);
  }
}
