@if (listNames.length > 0) {
<div class="form-group row">
  <div class="col col-3 mx-0 pe-0">
    <label class="col-form-label">Recipient list</label>
    <span class="badge bg-secondary mx-2">{{ numCustomersInCurrentList }}</span>
  </div>

  <div class="col col-9">
    <select
      class="form-select"
      [(ngModel)]="currentListName"
      (change)="onCurrentListNameChanged()"
    >
      @for (listName of listNames; track $index) {
      <option>{{ listName }}</option>
      }
    </select>
  </div>
</div>
}
