import { Injectable } from '@angular/core';
import { DICOMStudyTime } from 'aws-sdk/clients/medicalimaging';
import { OptionsColumns, Tabulator } from 'tabulator-tables';
import { CdpCustomerSet } from '../customers/cdp-customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerUiService {
  constructor() {}

  private makeColumns_(customerSet: any): any {
    // See https://tabulator.info/docs/5.5/columns#definition

    var columns = [
      { title: 'Email', field: 'email' },
      { title: 'Given name', field: 'given_name' },
      { title: 'Family name', field: 'family_name' },
      { title: 'Phone', field: 'phone_number' },
      { title: 'Birthday', field: 'birthday' },
      { title: '# purchases', field: 'num_purchases', hozAlign:"center"},
      { title: 'Total spent', field: 'total_spent', hozAlign:"right", headerWordWrap:true},
      { title: 'Avg purchase', field: 'avg_purchase', hozAlign:"right", headerWordWrap:true},
      { title: 'Purchase interval', field: 'interval', hozAlign:"center", headerWordWrap:true},
      { title: 'Days since last', field: 'days_since_last', hozAlign:"center", headerWordWrap:true},
      { title: 'Segments', field: 'segments' },

      // TODO Other stats?
    ];

    return columns;
  }

  private makeColumnsFromCustomerSet_(customerSet: CdpCustomerSet): any {
    // See https://tabulator.info/docs/5.5/columns#definition

    var columns = [
      { title: 'Email', field: 'email',  editor:"input" },
      { title: 'Given name', field: 'given_name', editor:"input"},
      { title: 'Family name', field: 'family_name', editor:"input" },
    ];

    return columns;
  }


  private getCustomerEmail_(customer: any): string {
    // TODO Do anything with customer.email.verified?
    return customer.email.email;
  }
  private getCustomerFamilyName_(customer: any): string {
    return customer.name.family_name;
  }

  private getCustomerGivenName_(customer: any): string {
    return customer.name.given_name;
  }

  private getCustomerPhoneNumber_(customer: any): string {
    return customer.phone_number.phone_number;
  }

  private getCustomerBirthday_(customer: any): string {
    if (customer.birthday.is_valid) {
      return customer.birthday.birthday;
    } else {
      return '';
    }
  }

  private getCustomerSegmentsStr_(customer: any): string {
    var segmentNames: string[] = [];

    const segments: [] = customer.segments;

    segments.forEach((segment: any) => {
      var name: string = segment.name;

      if (name.length > 0) {
        segmentNames.push(name);
      }
    });

    if (segmentNames.length == 0) {
      return '';
    } else {
      return segmentNames.join(',');
    }
  }

  private makeCustomerRow_(id: number, customer: any) {
    // Note: The key names must match the "field" names in the
    //       columns.
    var email: string = this.getCustomerEmail_(customer);
    var family_name: string = this.getCustomerFamilyName_(customer);
    var given_name: string = this.getCustomerGivenName_(customer);
    var phone_number: string = this.getCustomerPhoneNumber_(customer);
    var birthday: string = this.getCustomerBirthday_(customer);
    var segmentsStr: string = this.getCustomerSegmentsStr_(customer);

    var stats = customer.stats;
    var earliest_purchase_date = stats.earliest_purchase_date
    var latest_purchase_date = stats.latest_purchase_date
    var num_purchases = stats.num_purchases
    var total_purchase_amount = stats.total_purchase_amount

    const num_purchases_str: string = num_purchases.toString()
    const total_spent_str: string = total_purchase_amount.toFixed(2)

    var avg_purchase_str: string = "";
    var days_since_last_str: string = "";
    var purchase_interval_str: string = "";

    if (num_purchases > 0) {
      const average = total_purchase_amount / num_purchases;
      avg_purchase_str = average.toFixed(2);

      if (latest_purchase_date.is_valid) {
        const latestPurchaseMs = Date.parse(latest_purchase_date.date);
        const diffMs = Date.now() - latestPurchaseMs;
        const diffDays = diffMs / 86400000.0; // = (1000 * 60 * 60 * 24)

        days_since_last_str = Math.round(diffDays).toString()
      }
    }

    if (num_purchases > 1) {
      if (earliest_purchase_date.is_valid && latest_purchase_date.is_valid) {
        const latestPurchaseMs = Date.parse(latest_purchase_date.date);
        const earliestPurchaseMs = Date.parse(earliest_purchase_date.date);
        if (latestPurchaseMs >= earliestPurchaseMs) {
          const diffMs = latestPurchaseMs - earliestPurchaseMs;

          const diffDays = diffMs / 86400000.0; // = (1000 * 60 * 60 * 24)

          purchase_interval_str = Math.round(diffDays).toString()

        } else {
          // Clearly, the latest purchase should never be earlier than the earliest,
          // so ignore the invalid data.
        }

      }
    }

    return {
      id: id,
      email: email,
      given_name: given_name,
      family_name: family_name,
      phone_number: phone_number,
      birthday: birthday,
      num_purchases: num_purchases_str,
      total_spent: total_spent_str,
      avg_purchase: avg_purchase_str,
      interval: purchase_interval_str,
      days_since_last: days_since_last_str,
      segments: segmentsStr,
    };
  }

  private makeTableDataFromCustomerSet_(customerSet: CdpCustomerSet): any[] {
    var tableData: any[] = [];

    for (const customer of customerSet.customers) {
      let id: number = 1;

      const curCustomerData: any = {
        id: id,
        email: customer.email.email,
        given_name: customer.name.givenName,
        family_name: customer.name.familyName
      };

      tableData.push(curCustomerData);
      id++;
    }

    return tableData;
  }


  private makeTableData_(customerSet: any): any[] {
    var tableData: any[] = [];

    if (customerSet && 'customers' in customerSet) {
      let id: number = 1;
      for (const [customer_id, customer] of Object.entries(customerSet.customers)) {
        tableData.push(this.makeCustomerRow_(id, customer));

        id++;
      }
    /*  
      customerSet.customers.forEach((customer: any, index: number) => {
        const id = index + 1;

        tableData.push(this.makeCustomerRow_(id, customer));
      });
      */
    }

    return tableData;
  }

  public makeCustomerTableFromCustomerSet(
    jQueryElementId: string | HTMLElement,
    height: number,
    customerSet: CdpCustomerSet
  ): Tabulator {
    const layout = 'fitDataTable';

    let tableData = this.makeTableDataFromCustomerSet_(customerSet);
    let columns = this.makeColumnsFromCustomerSet_(customerSet);

    var table = new Tabulator(jQueryElementId, {
      height: height,
      layout: layout,
      columns: columns,
      data: tableData,
    });

    return table;
  }

  public makeCustomerTable(
    jQueryElementId: string | HTMLElement,
    height: number,
    customerSet: any
  ): Tabulator {
    const layout = 'fitDataTable';

    let tableData = this.makeTableData_(customerSet);
    let columns = this.makeColumns_(customerSet);

    var table = new Tabulator(jQueryElementId, {
      height: height,
      layout: layout,
      columns: columns,
      data: tableData,
    });

    return table;
  }
}
