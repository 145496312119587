export class SoundsIcon {
  constructor() {
    this.width = 24;
    this.height = 24;
    this.style = "";
  }
  load(data) {
    if (!data) {
      return;
    }
    if (data.path !== undefined) {
      this.path = data.path;
    }
    if (data.svg !== undefined) {
      this.svg = data.svg;
    }
    if (data.width !== undefined) {
      this.width = data.width;
    }
    if (data.height !== undefined) {
      this.height = data.height;
    }
  }
}