import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { CdpSideMenuComponent } from '../ui/cdp-side-menu/cdp-side-menu.component';
import { CdpSessionManagerService } from '../cdp-session-manager.service';
import { CdpEvent } from '../core/cdp-event';
import { CdpUserProfile } from '../core/cdp-user';
import { CdpCloneable } from '../email/template/email-template-editor/email-template-editor.component';
import { CdpName } from '../core/cdp-name';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [CommonModule, CdpSideMenuComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private sessionManager: CdpSessionManagerService
  ) {
    this.sessionManager.eventEmitter.subscribe((e) =>
      this.processSessionManagerEvent(e)
    );
  }

  async ngOnInit(): Promise<void> {
    await this.refreshIdentity_();
  }

  async processSessionManagerEvent(event: CdpEvent) {
    await this.refreshIdentity_();
  }

  private async refreshIdentity_() {
    const userProfile: CdpUserProfile | null =
      await this.sessionManager.getCurrentUserProfile();
    if (userProfile) {

      if (userProfile.name.isValid()) {
        this.name = CdpCloneable.deepCopy(userProfile.name);
      }
    }
  }

  name: CdpName = new CdpName();

  ngAfterViewInit(): void {
    //this.open(this.sideMenuContent);
  }
}
