import { v4 as uuidv4 } from 'uuid';
import { EventEmitter, Injectable } from '@angular/core';
import {
  CdpCustomerDatabase,
  CdpCustomerDatabaseOperationType,
  CdpCustomerDatabaseUpdateMode,
  CdpCustomerDatabaseUpdateResult,
  CdpCustomerDatabaseUpdateResultSet,
} from './cdp-customer-database';
import {
  CdpCustomerDatabaseBackendService,
  CdpCustomerDatabaseResponse,
} from '../backend/cdp-customer-database-backend.service';
import {
  CdpSessionManagerEventType,
  CdpSessionManagerService,
} from '../cdp-session-manager.service';
import { CdpEvent } from '../core/cdp-event';
import { Observable, of } from 'rxjs';
import { CdpCustomerInfo } from './cdp-customer';
import { CdpProgressOperationStatus } from '../ui/progress/cdp-progress';

export class CdpCustomerDatabaseServiceEvent {
  operationType: CdpCustomerDatabaseOperationType =
    CdpCustomerDatabaseOperationType.None;
  operationStatus: CdpProgressOperationStatus =
    CdpProgressOperationStatus.NotStarted;
  eventId: string;

  constructor(
    operationType: CdpCustomerDatabaseOperationType,
    operationStatus: CdpProgressOperationStatus
  ) {
    this.operationType = operationType;
    this.operationStatus = operationStatus;
    this.eventId = uuidv4();
  }
}

@Injectable({
  providedIn: 'root',
})
export class CdpCustomerDatabaseService {
  constructor(
    private backend: CdpCustomerDatabaseBackendService,
    private sessionManager: CdpSessionManagerService
  ) {
    //console.log('Customer database service constructor');

    this.sessionManager.eventEmitter.subscribe((event) =>
      this.processSessionManagerEvent_(event)
    );
  }

  private database_: CdpCustomerDatabase | null = null;

  eventEmitter: EventEmitter<CdpCustomerDatabaseServiceEvent> =
    new EventEmitter<CdpCustomerDatabaseServiceEvent>();

  getCurrentDatabase(): CdpCustomerDatabase | null {
    return this.database_;
  }

  doesDatabaseHaveUnsavedChanges(): boolean {
    if (!this.database_) {
      return false;
    } else {
      return this.database_.isDirty();
    }
  }

  maybeAutosave() {
    // TODO Need to use a mutex! Operation in progress.
    if (this.doesDatabaseHaveUnsavedChanges()) {
      this.saveDatabaseAndLists();
    }
  }

  updateCurrentDatabase(
    customerInfos: CdpCustomerInfo[],
    updateMode: CdpCustomerDatabaseUpdateMode
  ): CdpCustomerDatabaseUpdateResultSet {
    const updateResultSet: CdpCustomerDatabaseUpdateResultSet =
      new CdpCustomerDatabaseUpdateResultSet();

    if (!this.database_) {
      // TODO update result
    } else {
      for (const customerInfo of customerInfos) {
        // The customer needs to be added to the database, or the existing customer
        // in the database needs to be updated with the new data.
        const updateResult: CdpCustomerDatabaseUpdateResult =
          this.database_.addOrUpdateCustomerInfo(customerInfo, updateMode);
        updateResultSet.results.push(updateResult);
      }
    }

    //console.log('Update result set:', JSON.stringify(updateResultSet));
    //console.log('Has changes:', updateResultSet.hasAnyChanges());

    // Note that we need to check if the updates included any actual changes and not just
    // whether the database is dirty, since it might already have been dirty and then unchanged.
    if (updateResultSet.hasAnyChanges()) {
      this.emit_(
        CdpCustomerDatabaseOperationType.ModifyCustomers,
        CdpProgressOperationStatus.StartAndComplete
      );

      this.maybeAutosave();
    }

    return updateResultSet;
  }

  private getShortIds_(customerInfos: CdpCustomerInfo[]): number[] | null {
    if (!this.database_) {
      console.log('getShortIds: null database');

      return null;
    }

    const shortIds: number[] = [];
    for (const customerInfo of customerInfos) {
      const shortId: number =
        this.database_.getShortIdUsingCustomerInfo(customerInfo);

      /*
      console.log(
        `getShortId for customer: ${JSON.stringify(customerInfo)} -> ${shortId}`
      );
*/

      shortIds.push(shortId);
    }

    return shortIds;
  }

  addNewList(
    listName: string,
    customerInfos: CdpCustomerInfo[]
  ): [string, boolean] {
    return this.addOrReplaceList_(listName, customerInfos, false);
  }

  addOrReplaceList(
    listName: string,
    customerInfos: CdpCustomerInfo[]
  ): [string, boolean] {
    return this.addOrReplaceList_(listName, customerInfos, true);
  }

  private addOrReplaceList_(
    listName: string,
    customerInfos: CdpCustomerInfo[],
    allowReplace: boolean
  ): [string, boolean] {
    const shortIds: number[] | null = this.getShortIds_(customerInfos);
    //console.log('addOrReplaceList_: shortIds=', shortIds);

    if (!shortIds || !this.database_) {
      // There is no database.
      // TODO Throw an exception? Do nothing?
      return ['', false];
    } else {
      // If any of the short IDs are negative, then some of the customers are
      // not in the database.  Customers are required to be added to the database
      // separately before they can be added to a list.
      if (shortIds.indexOf(-1) >= 0) {
        console.log('Error: cannot add unknown customer to list');
        // TODO Throw an exception? Add at least the ones that are in the database?
        return ['', false];
      } else {
        const result: [string, boolean] =
          this.database_.addOrReplaceListUsingShortIds(
            listName,
            shortIds,
            allowReplace
          );

        const didChange: boolean = result[1];
        if (didChange) {
          // TODO Add a new event type for change to just a list and not the customer data.
          this.emit_(
            CdpCustomerDatabaseOperationType.ModifyCustomers,
            CdpProgressOperationStatus.StartAndComplete
          );

          this.maybeAutosave();
        }
        return result;
      }
    }
  }

  private loadDatabaseAndListsComplete_(
    response: CdpCustomerDatabaseResponse,
    startEvent: CdpCustomerDatabaseServiceEvent
  ) {
    //console.log('Database load complete. response=', response);

    let succeeded: boolean = response.succeeded;
    try {
      if (succeeded) {
        const database: CdpCustomerDatabase = new CdpCustomerDatabase(
          response.database,
          response.databaseLists
        );

        this.database_ = database;
      }
    } catch {
      succeeded = false;
    } finally {
      const status: CdpProgressOperationStatus = succeeded
        ? CdpProgressOperationStatus.CompletedSucceeded
        : CdpProgressOperationStatus.CompletedFailed;

      // Note that we give the complete event the same ID as the start event.
      const completeEvent: CdpCustomerDatabaseServiceEvent =
        new CdpCustomerDatabaseServiceEvent(startEvent.operationType, status);
      completeEvent.eventId = startEvent.eventId;

      //console.log('DB service emitting event: ', completeEvent);
      this.eventEmitter.emit(completeEvent);
    }
  }

  loadDatabaseAndLists(): Observable<CdpCustomerDatabaseResponse> {
    // Load the database for the business of the current user.
    const observable: Observable<CdpCustomerDatabaseResponse> =
      this.backend.loadDatabaseAndLists();

    const event: CdpCustomerDatabaseServiceEvent =
      new CdpCustomerDatabaseServiceEvent(
        CdpCustomerDatabaseOperationType.LoadDatabaseAndLists,
        CdpProgressOperationStatus.InProgress
      );

    this.eventEmitter.emit(event);

    observable.subscribe((response) => {
      this.loadDatabaseAndListsComplete_(response, event);
    });

    return observable;
  }

  private saveDatabaseAndListsComplete_(
    response: CdpCustomerDatabaseResponse,
    startEvent: CdpCustomerDatabaseServiceEvent
  ) {
    //console.log('Database save complete.');

    let succeeded: boolean = response.succeeded;
    try {
      if (succeeded) {
        if (this.database_) {
          this.database_.markClean();
        }
      }
    } catch {
      succeeded = false;
    } finally {
      const status: CdpProgressOperationStatus = succeeded
        ? CdpProgressOperationStatus.CompletedSucceeded
        : CdpProgressOperationStatus.CompletedFailed;

      // Note that we give the complete event the same ID as the start event.
      const completeEvent: CdpCustomerDatabaseServiceEvent =
        new CdpCustomerDatabaseServiceEvent(startEvent.operationType, status);
      completeEvent.eventId = startEvent.eventId;

      //console.log('DB service emitting event: ', completeEvent);
      this.eventEmitter.emit(completeEvent);
    }
  }

  private static makeSuccessfulSaveResponse_(): CdpCustomerDatabaseResponse {
    const response: CdpCustomerDatabaseResponse =
      new CdpCustomerDatabaseResponse();
    response.operationType =
      CdpCustomerDatabaseOperationType.SaveDatabaseAndLists;
    response.operationAttempted = true;
    response.succeeded = true;

    return response;
  }

  saveDatabaseAndLists(
    onlyIfDirty: boolean = false
  ): Observable<CdpCustomerDatabaseResponse> {
    if (
      !this.database_ ||
      !this.database_.databaseInternal ||
      (onlyIfDirty && !this.doesDatabaseHaveUnsavedChanges())
    ) {
      // TODO Is there any reason to emit a StartAndCompleted event here?
      return of(CdpCustomerDatabaseService.makeSuccessfulSaveResponse_());
    }

    const startEvent: CdpCustomerDatabaseServiceEvent =
      new CdpCustomerDatabaseServiceEvent(
        CdpCustomerDatabaseOperationType.SaveDatabaseAndLists,
        CdpProgressOperationStatus.InProgress
      );
    this.eventEmitter.emit(startEvent);

    // Save the database.
    const observable: Observable<CdpCustomerDatabaseResponse> =
      this.backend.saveDatabaseAndLists(
        this.database_.databaseInternal,
        this.database_.databaseLists
      );

    observable.subscribe((response) => {
      this.saveDatabaseAndListsComplete_(response, startEvent);
    });

    return observable;
  }

  private emit_(
    operationType: CdpCustomerDatabaseOperationType,
    operationStatus: CdpProgressOperationStatus,
    eventId: string = ''
  ): CdpCustomerDatabaseServiceEvent {
    const event: CdpCustomerDatabaseServiceEvent =
      new CdpCustomerDatabaseServiceEvent(operationType, operationStatus);
    if (eventId.length > 0) {
      event.eventId = eventId;
    }

    this.eventEmitter.emit(event);
    return event;
  }

  private createListCompleted_(
    response: CdpCustomerDatabaseResponse,
    startEvent: CdpCustomerDatabaseServiceEvent
  ) {
    this.emit_(
      CdpCustomerDatabaseOperationType.CreateList,
      response.succeeded
        ? CdpProgressOperationStatus.CompletedSucceeded
        : CdpProgressOperationStatus.CompletedFailed,
      startEvent.eventId
    );
  }

  private createList_(
    listName: string,
    databaseId: string
  ): Observable<CdpCustomerDatabaseResponse> {
    return this.backend.createList(listName, databaseId);
  }

  createList(listName: string, databaseId: string) {
    const startEvent: CdpCustomerDatabaseServiceEvent = this.emit_(
      CdpCustomerDatabaseOperationType.CreateList,
      CdpProgressOperationStatus.InProgress
    );

    //console.log('DB service: createList: ', listName);

    try {
      const observable: Observable<CdpCustomerDatabaseResponse> =
        this.createList_(listName, databaseId);
      observable.subscribe((response) =>
        this.createListCompleted_(response, startEvent)
      );
    } catch {
      const response: CdpCustomerDatabaseResponse =
        CdpCustomerDatabaseResponse.makeFailedResponse(
          CdpCustomerDatabaseOperationType.CreateList
        );
      this.createListCompleted_(response, startEvent);
    }
  }

  private deleteListCompleted_(
    response: CdpCustomerDatabaseResponse,
    startEvent: CdpCustomerDatabaseServiceEvent
  ) {
    // The list deletion operation actually allows for deleting multiple lists,
    // and "response.succeeded" will be true only if all deletions succeeded.
    // However, regardless of that overall value, any lists that were
    // successfully deleted are stored in the response.
    if (this.database_) {
      for (const dummyDeletedDatabaseList of response.databaseLists) {
        //console.log('Removing deleted list:', dummyDeletedDatabaseList);
        const listName: string = dummyDeletedDatabaseList.name;
        const databaseId: string = dummyDeletedDatabaseList.databaseId;
        const listId: string = dummyDeletedDatabaseList.listId;

        this.database_.maybeRemoveList(listName, databaseId, listId, false);
      }
    }
    this.emit_(
      CdpCustomerDatabaseOperationType.DeleteList,
      response.succeeded
        ? CdpProgressOperationStatus.CompletedSucceeded
        : CdpProgressOperationStatus.CompletedFailed,
      startEvent.eventId
    );
  }

  private deleteList_(
    listName: string,
    databaseId: string
  ): Observable<CdpCustomerDatabaseResponse> {
    return this.backend.deleteList(listName, databaseId);
  }

  deleteList(listName: string, databaseId: string) {
    const startEvent: CdpCustomerDatabaseServiceEvent = this.emit_(
      CdpCustomerDatabaseOperationType.DeleteList,
      CdpProgressOperationStatus.InProgress
    );

    try {
      const observable: Observable<CdpCustomerDatabaseResponse> =
        this.deleteList_(listName, databaseId);
      observable.subscribe((response) =>
        this.deleteListCompleted_(response, startEvent)
      );
    } catch {
      const response: CdpCustomerDatabaseResponse =
        CdpCustomerDatabaseResponse.makeFailedResponse(
          CdpCustomerDatabaseOperationType.DeleteList
        );
      this.deleteListCompleted_(response, startEvent);
    }
  }

  deleteListFromCurrentDatabase(listName: string) {
    if (!this.database_ || !this.database_.databaseInternal) {
      // TODO Do anything?
      return;
    } else {
      this.deleteList(listName, this.database_.databaseInternal.id);
    }
  }

  private unloadDatabaseFinish_(response: CdpCustomerDatabaseResponse) {
    // TODO If the save failed, do we still unload?

    this.database_ = null;

    this.emit_(
      CdpCustomerDatabaseOperationType.UnloadDatabase,
      CdpProgressOperationStatus.CompletedSucceeded
    );
  }

  unloadDatabase(allowUnsavedChanges: boolean) {
    // Unload the database, typically when a user signs out.
    if (!allowUnsavedChanges && this.doesDatabaseHaveUnsavedChanges()) {
      this.saveDatabaseAndLists().subscribe((response) =>
        this.unloadDatabaseFinish_(response)
      );
    } else {
      const response: CdpCustomerDatabaseResponse =
        CdpCustomerDatabaseService.makeSuccessfulSaveResponse_();

      this.unloadDatabaseFinish_(response);
    }
  }

  deleteDatabase(deletePermanently: boolean = false) {
    // TODO
  }

  private processSessionManagerEvent_(event: CdpEvent) {
    // Note: We don't need to do anything if this is just a "SessionChanged" event, e.g.,
    //       a session for the current user has new data.
    const eventType: string = event.getCategoryEventType();

    /*
    console.log(
      'Customer database service processing session manager event:',
      eventType
    );
*/

    const needUnload: boolean =
      eventType == CdpSessionManagerEventType.SignOut ||
      eventType == CdpSessionManagerEventType.SignIn ||
      eventType == CdpSessionManagerEventType.SessionStarted;

    if (needUnload) {
      // TODO TODO If we're going to try to save any unsaved changes,
      // then we need to wait for the operation to complete, regardless
      // of whether it's successful or not.
      try {
        this.saveDatabaseAndLists();
      } catch {
        // Ignore exceptions.
      }

      try {
        this.unloadDatabase(true);
      } catch {
        // Ignore exceptions.
      }
    }

    // If the user just signed in or the session just started, load the appropriate database.
    if (
      eventType == CdpSessionManagerEventType.SignIn ||
      eventType == CdpSessionManagerEventType.SessionStarted ||
      // TODO SessionChanged also seems to be needed for now
      eventType == CdpSessionManagerEventType.SessionChanged
    ) {
      try {
        //console.log('Customer database loading database');
        this.loadDatabaseAndLists();
      } catch (error) {
        // TODO Report error to user.
        console.log('Exception when loading database:', error);
      }
    }
  }
}
