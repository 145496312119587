<div class="card">
  <div class="card-body">

      <div class="form-group row">
        <div class="col col-3">
          <label class="col-form-label">Business name</label>
        </div>
        <div class="col">
          <input
            type="text"
            class="form-control"
            placeholder="My Company"
            [(ngModel)]="info.businessName"
            required
          />
        </div>
      </div>

      <div class="vertical-break"></div>

      <div class="form-group row">
        <div class="col col-3">
          <label class="col-form-label">Business type</label>
        </div>
        <div class="col">
          <app-cdp-business-type
            [businessType]="details.businessType"
          ></app-cdp-business-type>
        </div>
      </div>

      <div class="vertical-break"></div>

      <div class="form-group row">
        <div class="col col-3">
          <label class="col-form-label">Website</label>
        </div>
        <div class="col">
          <input
            type="url"
            class="form-control"
            placeholder="mycompany.com"
            [(ngModel)]="inputBusinessUrl"
            (change)="onBusinessUrlChange()"
            [style]="urlStyle"
          />
        </div>
      </div>

      <div class="vertical-break"></div>

      <div class="form-group row">
        <div class="col col-3">
          <label class="col-form-label">Phone Number</label>
        </div>
        <div class="col">
          <input
            type="tel"
            class="form-control"
            placeholder=""
            [(ngModel)]="info.phoneNumber.phoneNumber"
            required
          />
        </div>
      </div>

      <div class="vertical-break"></div>

      <div class="form-group row">
        <div class="col col-3">
          <label class="col-form-label">Country</label>
        </div>
        <div class="col">
          <app-cdp-country [country]="info.address.country"></app-cdp-country>
        </div>
      </div>
      @if (info.address.country.isUsa()) {
      <div class="vertical-break"></div>
      <div class="form-group row">
        <div class="col col-2">
          <label class="col-form-label">State</label>
        </div>
        <div class="col col-5">
          <app-cdp-usa-state
            [state]="info.address.usaState"
          ></app-cdp-usa-state>
        </div>
        <div class="col col-1">
          <label class="col-form-label">Zip</label>
        </div>
        <div class="col col-3">
          <app-cdp-usa-zip-code
            [zipCode]="info.address.usaZipCode"
          ></app-cdp-usa-zip-code>
        </div>
      </div>
      }

  </div>

  <!-- These can be helpful for debugging.

    <p>Business name: {{ info.businessName }}</p>
    <p>Business URL: {{ info.businessUrl.url }}</p>
    <p>Primary email: {{ info.primaryEmail.email }}</p>
    <p>Country: {{ info.address.country.isoCode }}</p>
    <p>State: {{ info.address.usaState.code }}</p>
    <p>Zip code: {{ info.address.usaZipCode.zipCode }}</p>
    -->
</div>
