import { CdpEmailAddress } from '../core/cdp-email-address';
import { CdpName } from '../core/cdp-name';

export class CdpCustomerId {
  id: string = '';
  shortId: number = -1;
}

export class CdpCustomerTag {
  name: string = '';
  shortId: number = -1;

  // Most tags don't have values, but some can.
  // For example, "RecentCustomer" might not have a value, but
  // "NumPurchases" would.
  value: string = '';
}

export class CdpCustomerTagSet {
  private tagNameMap_: Map<string, CdpCustomerTag> = new Map<
    string,
    CdpCustomerTag
  >();

  addTagNameAndValue(name: string, value: string = ''): CdpCustomerTag {
    const tag: CdpCustomerTag = new CdpCustomerTag();
    tag.name = name;
    tag.value = value;
    tag.shortId = this.tagNameMap_.size;

    this.tagNameMap_.set(name, tag);

    return tag;
  }

  addTagName(name: string): CdpCustomerTag {
    return this.addTagNameAndValue(name);
  }

  hasTagName(name: string): boolean {
    return this.tagNameMap_.get(name) != undefined;
  }

  getTagWithName(name: string): CdpCustomerTag | null {
    const tag: CdpCustomerTag | undefined = this.tagNameMap_.get(name);
    return tag || null;
  }

  getTags(): CdpCustomerTag[] {
    return Array.from(this.tagNameMap_.values());
  }
}

// The customer info contains the core data about a customer.
export class CdpCustomerInfo {
  email: CdpEmailAddress = new CdpEmailAddress();
  name: CdpName = new CdpName();

  isEqual(rhs: CdpCustomerInfo): boolean {
    return this.email.isEqual(rhs.email) && this.name.isEqual(rhs.name);
  }

  merge(other: CdpCustomerInfo): boolean {
    let canUpdateEmail: boolean = false;
    let canUpdateGivenName: boolean = false;
    let canUpdateFamilyName: boolean = false;

    // Update the email if the current email is unspecified.
    // If both the current and new email are specified but
    // different, we cannot merge.

    // Update the name if either part of the current name
    // is unspecified.  For a given part, if both the
    // current and new value are soecified but different,
    // we cannot merge.
    canUpdateEmail = this.canMergeStrings_(this.email.email, other.email.email);
    canUpdateGivenName = this.canMergeStrings_(
      this.name.givenName,
      other.name.givenName
    );
    canUpdateFamilyName = this.canMergeStrings_(
      this.name.familyName,
      other.name.familyName
    );

    if (!canUpdateEmail || !canUpdateGivenName || !canUpdateFamilyName) {
      return false;
    }

    if (this.email.email.length == 0) {
      this.email = other.email;
    }

    if (this.name.givenName.length == 0) {
      this.name.givenName = other.name.givenName;
    }

    if (this.name.familyName.length == 0) {
      this.name.familyName = other.name.familyName;
    }

    return true;
  }

  private canMergeStrings_(value1: string, value2: string) {
    return value2.length == 0 || value1.length == 0 || value1 == value2;
  }
}

export class CdpCustomer {
  id: CdpCustomerId = new CdpCustomerId();
  info: CdpCustomerInfo = new CdpCustomerInfo();
  tagSet: CdpCustomerTagSet = new CdpCustomerTagSet();

  get email(): CdpEmailAddress {
    return this.info.email;
  }
  get name(): CdpName {
    return this.info.name;
  }
}

export class CdpCustomerSet {
  name: string = '';
  customers: CdpCustomer[] = [];

  doesNameMatch(name: string): boolean {
    return name.toLowerCase() == this.name.toLowerCase();
  }

  getCustomerFromEmail(email: string): CdpCustomer | null {
    if (email.length == 0) {
      return null;
    }

    for (const customer of this.customers) {
      if (customer.email.email == email) {
        return customer;
      }
    }

    return null;
  }
}
