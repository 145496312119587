<span class="card m-2" #template_tile style="display: inline-block">
  <div class="card-header p-0">
    <div class="row">
      <div class="col" role="group" aria-label="Delete this image">
        @if (showDeleteButton) {
        <div class="col d-flex justify-content-end">
          <a class="mx-2" (click)="onClickDeleteImage()"
            ><i class="fs-4 bi-trash3"></i
          ></a>
        </div>
        }
      </div>
    </div>
    <img #tile_image /></div
></span>
