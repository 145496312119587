import {
  Component,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { CdpSession } from 'src/app/core/cdp-session';
import { CdpBusiness } from 'src/app/core/business/cdp-business';
import {
  CdpEmailTemplateFileTransformerOperationFromBrandingSet,
  CdpEmailTemplateFileTransformer,
} from '../cdp-email-template-file';
import {
  CdpEmailTemplateTileComponent,
  CdpEmailTemplateTileViewParams,
} from '../cdp-email-template-tile/cdp-email-template-tile.component';
import {
  CdpEmailTemplateManagerFile,
  CdpEmailTemplateManagerOwnerCategory,
  CdpEmailTemplateManagerService,
} from '../cdp-email-template-manager.service';
import {
  CdpEmailTemplateInfoFilter,
  CdpEmailTemplateInfoSet,
  CdpEmailTemplateMetadataTag,
} from '../cdp-email-template-info';
import { FormsModule } from '@angular/forms';
import {
  CdpEmailTemplateFilterComponent,
  CdpEmailTemplateFilterSingle,
} from '../cdp-email-template-filter/cdp-email-template-filter.component';
import { CdpSelectComponent } from 'src/app/ui/cdp-select/cdp-select.component';
import { CdpEmailTemplateBrandingSet } from '../cdp-email-template-branding-set';
import { Router } from '@angular/router';
import { CdpProgressSpinnerComponent } from 'src/app/ui/progress/cdp-progress-spinner/cdp-progress-spinner.component';
import { CdpProgressOperationSet } from 'src/app/ui/progress/cdp-progress';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpUser } from 'src/app/core/cdp-user';

export enum CdpEmailTemplateGalleryOperationType {
  DeleteTemplate = 'DeleteTemplate',
  LoadTemplates = 'LoadTemplates',
  RebrandTemplates = 'RebrandTemplates',
  SaveTemplate = 'SaveTemplate',
}

export class CdpEmailTemplateGallerySectionEvent {
  viewParams: CdpEmailTemplateTileViewParams | null = null;
}

export class CdpEmailTemplateGalleryFile {
  id: number = 0;

  isVisible: boolean = false;

  private selectedVariantIndex_: number = -1;
  private templateManagerFileVariants_: CdpEmailTemplateManagerFile[] = [];

  setTemplateManagerFile(templateManagerFile: CdpEmailTemplateManagerFile) {
    this.selectedVariantIndex_ = -1;
    this.templateManagerFileVariants_.length = 1;
    this.templateManagerFileVariants_[0] = templateManagerFile;
    this.selectedVariantIndex_ = 0;
  }

  get templateManagerFile(): CdpEmailTemplateManagerFile | null {
    const index: number = this.selectedVariantIndex_;
    //console.log(`Getting variant: ${index} n=${this.templateManagerFileVariants_.length}`);
    if (index >= 0 && index < this.templateManagerFileVariants_.length) {
      return this.templateManagerFileVariants_[index];
    } else {
      return null;
    }
  }

  selectVariant(variantIndex: number) {
    if (
      variantIndex == -1 ||
      (variantIndex >= 0 &&
        variantIndex < this.templateManagerFileVariants_.length)
    ) {
      this.selectedVariantIndex_ = variantIndex;
    } else {
      // TODO This shouldn't happen in correct usage.  Just ignore the change.
      console.log('Illegal variant index:', variantIndex);
    }
  }

  setOperationInProgress(operation: string) {
    for (const variant of this.templateManagerFileVariants_) {
      variant.operationInProgress = operation;
    }
  }

  applyFilter(
    filter: CdpEmailTemplateInfoFilter,
    emptyFilterMatchesAll: boolean
  ) {
    let doesMatch: boolean = false;

    if (this.templateManagerFileVariants_.length > 0) {
      const templateManagerFile: CdpEmailTemplateManagerFile =
        this.templateManagerFileVariants_[0];
      if (templateManagerFile.templateInfo != null) {
        doesMatch = filter.doesMatchTemplateInfo(
          templateManagerFile.templateInfo,
          emptyFilterMatchesAll
        );
      }
    }
    this.isVisible = doesMatch;
  }

  async applyTransformer(transformer: CdpEmailTemplateFileTransformer) {
    if (this.templateManagerFileVariants_.length > 0) {
      const srcTemplateManagerFile: CdpEmailTemplateManagerFile =
        this.templateManagerFileVariants_[0];

      const transformedTemplateManagerFile: CdpEmailTemplateManagerFile =
        await srcTemplateManagerFile.makeTransformed(transformer);

      if (this.templateManagerFileVariants_.length == 1) {
        this.templateManagerFileVariants_.push(transformedTemplateManagerFile);
      } else {
        this.templateManagerFileVariants_[1] = transformedTemplateManagerFile;
      }
    }

    this.setOperationInProgress(''); // TODO could anything else be in progress?
  }
}

export class CdpEmailTemplateGalleryFileSet {
  nextId: number = 0;
  files: CdpEmailTemplateGalleryFile[] = [];

  private infoSet_: CdpEmailTemplateInfoSet = new CdpEmailTemplateInfoSet();
  private categoryFilters_: CdpEmailTemplateFilterSingle[] = [];
  private areCategoryFiltersValid_: boolean = false;

  addTemplateManagerFile(
    templateManagerFile: CdpEmailTemplateManagerFile,
    transformer: CdpEmailTemplateFileTransformer | null,
    filter: CdpEmailTemplateInfoFilter | null,
    emptyFilterMatchesAll: boolean
  ) {
    const file: CdpEmailTemplateGalleryFile = new CdpEmailTemplateGalleryFile();
    file.id = this.nextId++;
    file.setTemplateManagerFile(templateManagerFile);
    if (filter) {
      file.applyFilter(filter, emptyFilterMatchesAll);
    }

    if (transformer) {
      file.applyTransformer(transformer);
    }

    this.files.push(file);

    if (templateManagerFile.templateInfo) {
      this.infoSet_.addInfo(templateManagerFile.templateInfo);

      this.areCategoryFiltersValid_ = false;
    }
  }

  getCategoryFilters(): CdpEmailTemplateFilterSingle[] {
    if (!this.areCategoryFiltersValid_) {
      this.categoryFilters_ =
        CdpEmailTemplateGalleryFileSet.buildCategoryFilters_(this.infoSet_);
      this.areCategoryFiltersValid_ = true;
    }

    return this.categoryFilters_;
  }

  removeId(id: number) {
    this.files = this.files.filter((file) => id != file.id);
  }

  applyFilter(
    filter: CdpEmailTemplateInfoFilter,
    emptyFilterMatchesAll: boolean
  ): number {
    let totalNumVisible: number = 0;

    for (const file of this.files) {
      file.applyFilter(filter, emptyFilterMatchesAll);
      if (file.isVisible) {
        ++totalNumVisible;
      }
    }

    return totalNumVisible;
  }

  setOperationInProgress(operation: string) {
    for (const file of this.files) {
      file.setOperationInProgress(operation);
    }
  }

  selectVariant(variantIndex: number) {
    for (const file of this.files) {
      file.selectVariant(variantIndex);
    }
  }

  async applyBrandingSet(brandingSet: CdpEmailTemplateBrandingSet) {
    //console.log('Setting brand set:', brandingSet);

    const transformer: CdpEmailTemplateFileTransformer =
      new CdpEmailTemplateFileTransformer();

    const transformerOperation: CdpEmailTemplateFileTransformerOperationFromBrandingSet =
      new CdpEmailTemplateFileTransformerOperationFromBrandingSet(brandingSet);

    transformer.addOperation(transformerOperation);

    // First, mark all the files as having an operation in progress, to prevent
    // the user from interacting with them.
    this.setOperationInProgress('rebrand');
    try {
      // TODO Turn the following into something that returns an observable.
      for (const file of this.files) {
        await file.applyTransformer(transformer);
      }
    } finally {
      this.setOperationInProgress('');
    }
  }

  private static buildCategoryFilters_(
    infoSet: CdpEmailTemplateInfoSet
  ): CdpEmailTemplateFilterSingle[] {
    const categoryFilters: CdpEmailTemplateFilterSingle[] = [];

    // We want to show the filter dropdowns as a simple sentence rather than just the dropdowns
    // alone.  If all 4 of the categories are present, the result will look like the following:
    //    "I'm in the [industry] and want to communicate for [holiday]."
    //    "(optional) My communication is about [type] and has this [feature]."
    //
    // Note: The labels in the data are slightly different, so we change the labels.
    const metadataKeys = infoSet.metadataKeys;
    const industryTags: CdpEmailTemplateMetadataTag[] | undefined =
      metadataKeys.get('Industry');
    const holidayTags: CdpEmailTemplateMetadataTag[] | undefined =
      metadataKeys.get('Season');
    const typeTags: CdpEmailTemplateMetadataTag[] | undefined =
      metadataKeys.get('Type');
    const featureTags: CdpEmailTemplateMetadataTag[] | undefined =
      metadataKeys.get('Feature');

    if (industryTags != undefined) {
      const industryFilter: CdpEmailTemplateFilterSingle =
        new CdpEmailTemplateFilterSingle();

      industryFilter.intro = ''; //"I'm in the following";
      industryFilter.label = 'Industry';
      industryFilter.rawLabel = 'Industry';
      industryFilter.metadataTags = industryTags;

      categoryFilters.push(industryFilter);
    }

    if (holidayTags != undefined) {
      const holidayFilter: CdpEmailTemplateFilterSingle =
        new CdpEmailTemplateFilterSingle();

      holidayFilter.intro = ''; // (hasIndustryTags ? 'and' : 'I') + ' want to communicate for';
      holidayFilter.label = 'Holiday';
      holidayFilter.rawLabel = 'Season';
      holidayFilter.metadataTags = holidayTags;

      categoryFilters.push(holidayFilter);
    }

    if (typeTags != undefined) {
      const typeFilter: CdpEmailTemplateFilterSingle =
        new CdpEmailTemplateFilterSingle();

      typeFilter.label = 'Type';
      typeFilter.rawLabel = 'Type';
      typeFilter.metadataTags = typeTags;
      typeFilter.intro = ''; //'My email is about';

      categoryFilters.push(typeFilter);
    }

    if (featureTags != undefined) {
      const featureFilter: CdpEmailTemplateFilterSingle =
        new CdpEmailTemplateFilterSingle();

      featureFilter.label = 'Feature';
      featureFilter.rawLabel = 'Feature';
      featureFilter.metadataTags = featureTags;

      featureFilter.intro = '';
      /*
        typeTags != undefined
          ? 'and has this'
          : 'My communication has this';
*/
      categoryFilters.push(featureFilter);
    }

    //console.log('Category filters:', JSON.stringify(categoryFilters));

    return categoryFilters;
  }
}

@Component({
  selector: 'app-email-template-gallery-section',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    CdpEmailTemplateFilterComponent,
    CdpEmailTemplateTileComponent,
    CdpProgressSpinnerComponent,
    CdpSelectComponent,
  ],
  templateUrl: './email-template-gallery-section.component.html',
  styleUrl: './email-template-gallery-section.component.sass',
})
export class CdpEmailTemplateGallerySectionComponent {
  @ViewChild('template_filter')
  templateFilter!: CdpEmailTemplateFilterComponent;
  @ViewChild('show_no_templates')
  showNoTemplatesDiv!: ElementRef<HTMLDivElement>;

  private useRebranded_: boolean = true;

  @Input() sectionTitle: string = 'Email Templates';
  @Input() allowVariants: boolean = true;
  @Input() showFilters: boolean = true;
  @Input() showTemplateIds: boolean = true;
  @Input() showTemplateNames: boolean = true;

  // TODO TODO It might be better to have an empty filter match
  // everything rather than nothing, but we need to improve
  // load performance first.
  @Input() emptyFilterMatchesAll: boolean = false;

  private galleryFileSet_: CdpEmailTemplateGalleryFileSet =
    new CdpEmailTemplateGalleryFileSet();

  @Input() didLoadAtLeastOnce: boolean = true;

  get galleryFileSet() {
    return this.galleryFileSet_;
  }

  onTemplatesChanged() {
    if (this.showNoTemplatesDiv) {
      this.showNoTemplatesDiv.nativeElement.hidden =
        !this.showNoTemplatesMessage;
    }
  }

  @Input() set galleryFileSet(gfs: CdpEmailTemplateGalleryFileSet) {
    if (this.templateFilter && this.templateFilter.filter) {
      gfs.applyFilter(this.templateFilter.filter, this.emptyFilterMatchesAll);
    }

    this.galleryFileSet_ = gfs;

    this.onTemplatesChanged();
  }

  @Input() ownerCategory: CdpEmailTemplateManagerOwnerCategory =
    CdpEmailTemplateManagerOwnerCategory.Builtin;

  private viewParams_: CdpEmailTemplateTileViewParams =
    new CdpEmailTemplateTileViewParams();

  get categoryFilters() {
    return this.galleryFileSet_.getCategoryFilters();
  }

  eventEmitter: EventEmitter<CdpEmailTemplateGallerySectionEvent> =
    new EventEmitter<CdpEmailTemplateGallerySectionEvent>();

  public getViewParams(): CdpEmailTemplateTileViewParams {
    return this.viewParams_;
  }

  private initialSelectedIndustry_: string = '';
  @Input() set initialSelectedIndustry(industry: string) {
    this.initialSelectedIndustry_ = industry;

    //console.log("Set industry:", industry);
  }

  get initialSelectedIndustry() {
    return this.initialSelectedIndustry_;
  }

  @Input() filterNumMatch: number = 0;

  filterText: string = '';

  isBusinessOrUser(): boolean {
    return (
      this.ownerCategory == CdpEmailTemplateManagerOwnerCategory.BusinessOrUser
    );
  }

  @Input() operationSet: CdpProgressOperationSet =
    new CdpProgressOperationSet();

  get isOperationInProgress(): boolean {
    return this.operationSet.isOperationInProgress;
  }

  get showNoTemplatesMessage(): boolean {
    return (
      !this.isOperationInProgress &&
      this.galleryFileSet.files.length == 0 &&
      this.isBusinessOrUser() &&
      this.didLoadAtLeastOnce
    );
  }

  constructor(
    public router: Router,
    public sessionManager: CdpSessionManagerService,
    public templateManager: CdpEmailTemplateManagerService
  ) {}

  viewParamsChanged() {
    const event: CdpEmailTemplateGallerySectionEvent =
      new CdpEmailTemplateGallerySectionEvent();
    event.viewParams = this.viewParams_;

    this.eventEmitter.emit(event);
  }

  set useRebranded(useRebranded: boolean) {
    this.useRebranded_ = useRebranded;

    this.galleryFileSet_.selectVariant(useRebranded ? 1 : 0);
  }

  private removeTemplateManagerFileWithId_(id: number) {
    this.galleryFileSet_.removeId(id);
  }

  removeTemplateManagerFile(galleryFile: CdpEmailTemplateGalleryFile) {
    this.removeTemplateManagerFileWithId_(galleryFile.id);
  }

  changeTargetDisplayWidthPx(val: number) {
    // Angular's change detection deals with new object detection rather than property
    // change detection, so we can simplify things a bit by using a new object rather than
    // just setting the property value on the existing params.
    const changedViewParams: CdpEmailTemplateTileViewParams =
      new CdpEmailTemplateTileViewParams();
    Object.assign(changedViewParams, this.viewParams_);
    changedViewParams.targetDisplayWidthPx = val;

    this.viewParams_ = changedViewParams;

    // Emit an event anyway in case something else cares.
    this.viewParamsChanged();
  }

  onFilterChanged(filter: CdpEmailTemplateInfoFilter) {
    this.applyFilter(filter);
  }

  applyFilter(filter: CdpEmailTemplateInfoFilter): number {
    this.filterNumMatch = this.galleryFileSet_.applyFilter(
      filter,
      this.emptyFilterMatchesAll
    );
    return this.filterNumMatch;
  }

  applyCurrentFilter(): number {
    if (this.templateFilter) {
      this.applyFilter(this.templateFilter.filter);
    } else {
      //console.log('No filter present');
      this.filterNumMatch = 0;
    }

    return this.filterNumMatch;
  }

  onClickOriginal() {
    this.useRebranded = false;
  }

  onClickRebranded() {
    this.useRebranded = true;
  }

  onClickMobile() {
    this.changeTargetDisplayWidthPx(300);
  }

  onClickDesktop() {
    this.changeTargetDisplayWidthPx(640);
  }
}
