  <div class="d-flex"></div>
    <ul
    class="nav nav-pills flex-column align-items-sm-start px-2"
    id="menu"
  >
    <li class="nav-item flex-fill">
      <a
        class="nav-link align-middle px-0  text-nowrap text-color-primary"
        (click)="onClickHome()"
      >
        <i class="fs-4 bi-house"></i>
        <span class="ms-1 d-none d-sm-inline">Home</span>
      </a>
    </li>
    @if (isUserSignedIn) {
    <hr class="hr w-75 my-2" />
    <li class="nav-item">
      <a
        class="nav-link align-left px-0  text-nowrap text-color-primary"
        (click)="onClickCalendar()"
      >
        <i class="fs-4 bi-calendar"></i>
        <span class="ms-1 d-none d-sm-inline">Calendar</span>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link align-left px-0  text-nowrap text-color-primary"
        (click)="onClickCustomerLists()"
      >
        <i class="fs-4 bi-database"></i>
        <span class="ms-1 d-none d-sm-inline">My Lists</span>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link align-left px-0  text-nowrap text-color-primary"
        (click)="onClickMyImages()"
      >
        <i class="fs-4 bi-image"></i>
        <span class="ms-1 d-none d-sm-inline">My Images</span>
      </a>
    </li>
    <li class="nav-item flex-fill">
      <a
        class="nav-link align-left px-0 text-nowrap text-color-primary"
        (click)="onClickMyTemplates()"
      >
        <i class="fs-4 bi-stack"></i>
        <span class="ms-1 d-none d-sm-inline">My Templates</span>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link align-left px-0  text-nowrap text-color-primary"
        (click)="onClickTemplateGallery()"
      >
        <i class="fs-4 bi-stack"></i>
        <span class="ms-1 d-none d-sm-inline">Template Gallery</span>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link align-left px-0  text-nowrap text-color-primary"
        (click)="onClickSenderVerification()"
      >
        <i class="fs-4 bi-send-check"></i>
        <span class="ms-1 d-none d-sm-inline">Verify Sender</span>
      </a>
    </li>
    <li class="nav-item">
      <a
        class="nav-link align-left px-0  text-nowrap text-color-primary"
        (click)="onClickSendEmail()"
      >
        <i class="fs-4 bi-send"></i>
        <span class="ms-1 d-none d-sm-inline">Send Email</span>
      </a>
    </li>

    <hr class="hr w-75 my-2" />

    <li class="nav-item">
      <a
        class="nav-link align-left px-0  text-nowrap text-color-primary"
        (click)="onClickIntegrations()"
      >
        <i class="fs-4 bi-lightning"></i>
        <span class="ms-1 d-none d-sm-inline">Integrations</span>
      </a>
    </li>
    <hr class="hr w-75 my-2" />
    <li class="nav-item">
      <a
        class="nav-link align-left px-0  text-nowrap text-color-primary"
        (click)="onClickFeedback()"
      >
        <i class="fs-4 bi-chat-dots"></i>
        <span class="ms-1 d-none d-sm-inline">Feedback</span>
      </a>
    </li>
  }
  </ul>
