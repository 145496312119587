import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpRouterService } from '../router/cdp-router.service';
import { CdpRoutes } from '../router/cdp-routes';
import { CdpSession } from '../cdp-session';

@Component({
  selector: 'app-cdp-after-sign-in',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cdp-after-sign-in.component.html',
  styleUrl: './cdp-after-sign-in.component.sass',
})
export class CdpAfterSignInComponent implements AfterViewInit {
  constructor(private sessionManager: CdpSessionManagerService, private router: CdpRouterService) {}

  async ngAfterViewInit(): Promise<void> {
    // This is the page that gets navigated to immediately after the user 
    // has signed in, including if the user has remained signed in but
    // navigated out of the app entirely and has now come back.
    //
    // Thus, the purpose of this page is to automatically send the user
    // to an appropriate page.


    const session: CdpSession | null = await this.sessionManager.getCurrentCdpSession();

    if (!session || !session.user || !(await this.sessionManager.isUserSignedIn())) {
      // Well, this is odd. Could be the result of a race condition with multiple events.
      this.router.navigate(CdpRoutes.Home);
    }

    console.log("Session user profile:", session?.user?.profile);
    console.log("Session business profile:", session?.business?.profile);
    
    // If the user's profile is missing required items, those need to be filled out
    // now.
    //
    // Note: Typically, this should happen only the first time the user logs in.
    const doesUserProfileHaveAllRequiredItems: boolean | undefined =
     session?.user?.profile.areAllRequiredFieldsCompleteAndValid();

    const doesBusinessProfileHaveAllRequiredItems: boolean | undefined =
     session?.business?.profile.areAllRequiredFieldsCompleteAndValid();
    if (!doesUserProfileHaveAllRequiredItems || !doesBusinessProfileHaveAllRequiredItems) {
      this.router.navigate(CdpRoutes.InitialRegistration);
    } else {
      // The user has finished registration, so go to the dashboard.
      // TODO Should the user's info include a preference about which page to start on?
      this.router.navigate(CdpRoutes.Dashboard);
    }
  }
}
