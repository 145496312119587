import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [CommonModule,
  HttpClientModule],
})
export class AppConfigModule {
  static CognitoConfig = {
    userPoolId: 'us-east-1_nh7bJ08E5', 
    userPoolClientId: '6v1nbjjnhpgoo6b0nt60ttt320',
    region: 'us-east-1',
  };

  static websiteHostname: string = 'inboxease.com';
}
