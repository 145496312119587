<div class="container" style="max-width: 800px">
  <br />
  <div class="m-2">
    <h3 class="text-center text-color-primary">My Lists</h3>
  </div>

  @if (!listCreateIsAllowed || !listDeleteIsAllowed || !listModifyIsAllowed ||
  !listRenameIsAllowed) {
  <div>
    <br />
    <h5>
      Alpha users are currently allowed to use only a test list consisting of
      their own email address. When this restriction is lifted, users will be
      allowed to create, modify, and delete multiple lists.
    </h5>
    <br />
  </div>
  }

  <div class="card m-2 p-2">
    @if (listCreateIsAllowed) {
    <h4 class="text-center">Upload customer data CSV file</h4>
    <p class="my-1">
      Each line should contain one customer's information, with columns
      separated by commas.
    </p>
    <p class="my-1">
      In order, the columns are <strong>email address</strong>,
      <strong>first name</strong>, then <strong>last name</strong>.
    </p>
    <p class="mx-4 my-1">Example: <em>jsmith&#64;gmail.com, John, Smith</em></p>
    <div class="vertical-break"></div>
    <div class="form-group row">
      <div class="col col-3">
        <button
          class="btn btn-primary mx-auto"
          (click)="onClickNewList()"
          [disabled]="isOperationInProgress"
        >
          + New list
        </button>
      </div>
      @if (specifyingNewListName) {
      <div class="vertical-break"></div>
      <div class="col col-auto">
        <label class="col-form-label">List name</label>
      </div>

      <div class="col col-8">
        <input
          type="text"
          class="form-control"
          placeholder="'My list' or 'Purchased recently' or 'Lapsed', etc."
          [(ngModel)]="newListName"
          name="newListName"
          (change)="onNewListChanged()"
          required
        />
      </div>
      <div class="col">
        <a (click)="onClickCancelNewList()"><i class="fs-4 bi-x-circle"></i></a>
      </div>
      }
    </div>
    }

    <div class="vertical-break"></div>
    <app-cdp-progress-spinner
      [operationSet]="operationSet"
    ></app-cdp-progress-spinner>
    <div class="vertical-break"></div>

    <div [hidden]="isOperationInProgress || specifyingNewListName">
      @if (listCreateIsAllowed) {
      <hr />
      } @if (listNames.length > 0) {
      <div class="form-group row">
        <div class="col col-auto">
          <label class="col-form-label">Selected list</label>
        </div>
        <div class="col col-6">
          <select
            class="form-select"
            [(ngModel)]="currentListName"
            (change)="onCurrentListNameChanged()"
          >
            @for (listName of listNames; track $index) {
            <option>{{ listName }}</option>
            }
          </select>
        </div>
        @if (listDeleteIsAllowed) {
        <div
          class="col"
          [hidden]="isOperationInProgress || specifyingNewListName"
        >
          <a (click)="onClickDeleteList()"><i class="fs-4 bi-trash3"></i></a>
        </div>
        }
      </div>
      }

      <div [hidden]="!hasCurrentCustomerList">
        <div class="vertical-break"></div>

        @if (listModifyIsAllowed) {
        <div>
          <div class="container mx-0" style="overflow: hidden">
            <app-cdp-drop-zone
              #dropzone
              [text]="dropzoneText"
              [allowedFileTypes]="dropzoneAllowedFileTypes"
              [allowMultipleFiles]="dropzoneAllowMultipleFiles"
            ></app-cdp-drop-zone>
          </div>
          <div #upload_progress hidden>
            <span
              #upload_progress_spinner
              class="spinner-border text-success"
              role="status"
            ></span>
            <span #upload_progress_message></span>
          </div>
        </div>
        <div class="vertical-break"></div>
        }
        <div>
          <div id="tabulator"></div>
          <app-tabulator-table
            [columnNames]="columnNames"
            [tableData]="tableData"
            [height]="tableHeight"
          ></app-tabulator-table>
          <div #customers_table id="customers_table_container"></div>
        </div>
      </div>
    </div>
  </div>
</div>
