import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { CdpRoutes } from './core/router/cdp-routes';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private routeStore = new Map<string, DetachedRouteHandle>();

  private avoidStoringPath(path: string | undefined) {
    if (!path || path.length == 0) {
      return true;
    }

    const pathsToReloadEveryTime: string[] = [
      CdpRoutes.Feedback,
      CdpRoutes.FeedbackComplete,
      CdpRoutes.CombinedProfile,
      CdpRoutes.SendEmailComplete
    ];

    if (pathsToReloadEveryTime.includes(path)) {
      return true;
    }

    return false;
  }

  private getStoredRoute(path: string | undefined): DetachedRouteHandle | null {
    if (path) {
      const drh = this.routeStore.get(path);
      return drh || null;
    } else {
      return null;
    }
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig!.path;

    //console.log("Calling should detach for path:", path);

    return !!path;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    //console.log("Store route:", route);

    if (route.routeConfig && route.routeConfig.path) {
      //console.log('Storing route:', route);
      this.routeStore.set(route.routeConfig.path, handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    //console.log("Calling should attach for path:", route.routeConfig!.path);
    let sa: boolean = !!this.getStoredRoute(route.routeConfig!.path);

    if (sa) {
      sa = !this.avoidStoringPath(route.routeConfig!.path);
    }
    //console.log(`Should attach: route=${route} sa=${sa}`);

    return sa;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = route.routeConfig!.path;
    const drh = this.getStoredRoute(route.routeConfig!.path);

    //console.log("Retrieved:", drh);
    return drh;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    const reuse: boolean = future.routeConfig === curr.routeConfig;

    //console.log(`Future: ${future} curr:${curr}  reuse=${reuse}`);
    return reuse;
  }
}
