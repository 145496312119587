import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TabulatorFull as Tabulator } from 'tabulator-tables';

@Component({
  selector: 'app-tabulator-table',
  standalone: true,
  imports: [],
  templateUrl: './tabulator-table.component.html',
  styleUrl: './tabulator-table.component.sass',
})
export class TabulatorTableComponent implements OnChanges {
  @Input() tableData: any[] = [];
  @Input() columnNames: any[] = [];
  @Input() height: string = '311px';
  // list properties you want to set per implementation here...

  tab = document.createElement('div');

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.drawTable();
  }

  private drawTable(): void {
    if (document && this.tab) {
      new Tabulator(this.tab, {
        data: this.tableData,
        reactiveData: true, //enable data reactivity
        columns: this.columnNames,
        layout: 'fitDataTable',
        height: this.height,
      });
      document.getElementById('tabulator-table')?.appendChild(this.tab);
    }
  }
}
