/* TODO
export enum CdpEventType {

}
*/

export enum CdpEventCategory {
  Auth = 'Auth',
  General = 'General',
  Session = 'Session',
}

export class CdpEventDetail {
  nmsp: string = 'ci'; // namespace "InboxEase"
  cat: CdpEventCategory = CdpEventCategory.Auth;
  categoryEventType: string = '';
  detailObj: object | null = null;

  constructor(cat: CdpEventCategory, categoryEventType: string, detailObj: object | null) {
    this.cat = cat;
    this.categoryEventType = categoryEventType;
    this.detailObj = detailObj;
  }
}

export class CdpEvent extends CustomEvent<any> {
  private detail_: CdpEventDetail;

  constructor(
    type: string,
    category: CdpEventCategory,
    categoryEventType: string,
    detailObj: object | null
  ) {
    var ctp: string = type + '.' + category.toString() + '.ci';

    const detail: CdpEventDetail = new CdpEventDetail(category, categoryEventType, detailObj);

    super(ctp, { detail: detail });
    this.detail_ = detail;
  }

  getType(): string {
    return this.type;
  }

  getDetail() {
    return this.detail_;
  }

  isCategoryAuth(): boolean {
    return this.detail_ && this.detail_.cat == CdpEventCategory.Auth;
  }

  isCategorySession(): boolean {
    return this.detail_ && this.detail_.cat == CdpEventCategory.Session;
  }

  getCategoryEventType(): string {
    return this.detail_.categoryEventType;
  }
}
