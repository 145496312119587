import { ApplicationRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { CdpEvent } from 'src/app/core/cdp-event';
import { CdpRoutes } from 'src/app/core/router/cdp-routes';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';

import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-cdp-side-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cdp-side-menu.component.html',
  styleUrl: './cdp-side-menu.component.scss',
})
export class CdpSideMenuComponent implements OnInit {
  isUserSignedIn: boolean = false;

  constructor(
    private router: Router,
    private sessionManager: CdpSessionManagerService,
    private appRef: ApplicationRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.isUserSignedIn = await this.sessionManager.isUserSignedIn();
    //console.log('Side menu OnInit: signedIn=', this.isUserSignedIn);
    const rootAppComponent: AppComponent = this.appRef.components[0]
      .instance as AppComponent;
    rootAppComponent.eventEmitter().subscribe((e: CdpEvent) => {
      //console.log('Heard event:', e);

      if (e.isCategoryAuth() || e.isCategorySession()) {
        // It's safer to just query the current signed-in state than
        // to try to infer that from the event.
        this.sessionManager.isUserSignedIn().then((val) => {
          this.isUserSignedIn = val;

          //console.log('Update: signedIn=', this.isUserSignedIn);
        });
      }
    });
  }

  onClickCalendar() {
    this.router.navigate([CdpRoutes.Calendar]);
  }

  onClickHome() {
    this.router.navigate([CdpRoutes.Home]);
  }

  onClickCustomerLists() {
    this.router.navigate([CdpRoutes.CustomerDatabase]);
  }

  onClickFeedback() {
    this.router.navigate([CdpRoutes.Feedback]);
  }
  
  onClickIntegrations() {
    this.router.navigate([CdpRoutes.Integrations]);
  }

  onClickMyImages() {
    this.router.navigate([CdpRoutes.ImageGalleryUser]);
  }
  
  onClickMyTemplates() {
    this.router.navigate([CdpRoutes.EmailTemplateGalleryUser]);
  }

  onClickSendEmail() {
    this.router.navigate([CdpRoutes.SendEmail]);
  }

  onClickSenderVerification() {
    this.router.navigate([CdpRoutes.EmailSenderVerification]);
  }

  onClickTemplateGallery() {
    this.router.navigate([CdpRoutes.EmailTemplateGallery]);
  }
}
