import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

export class CdpUsaState {
  code: string = '';
}

@Component({
  selector: 'app-cdp-usa-state',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cdp-usa-state.component.html',
  styleUrl: './cdp-usa-state.component.sass',
})
export class CdpUsaStateComponent {
  @Input() state: CdpUsaState = new CdpUsaState();
}
