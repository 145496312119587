import { CdpEmailTemplateBrandingSet } from './cdp-email-template-branding-set';
import {
  CdpEmailTemplateInfo,
  CdpEmailTemplateInfoFilter,
} from './cdp-email-template-info';
import { CdpCloneable } from './email-template-editor/email-template-editor.component';
import {
  CdpCommonFile,
  CdpCommonFileSection,
  CdpCommonFileSectionSet,
  CdpCommonFileType,
  CdpCommonMultiFile,
} from '../../core/files/cdp-file-parse';
import { CdpImage } from '../../core/images/cdp-image';

export interface CdpEmailTemplateFileTransformerOperation {
  transform(templateFile: CdpEmailTemplateFile): CdpEmailTemplateFile;
}

export class CdpEmailTemplateFileTransformer {
  private operations_: CdpEmailTemplateFileTransformerOperation[] = [];

  public clear() {
    this.operations_ = [];
  }

  public addOperation(operation: CdpEmailTemplateFileTransformerOperation) {
    this.operations_.push(operation);
  }

  public transform(templateFile: CdpEmailTemplateFile): CdpEmailTemplateFile {
    let transformedTemplateFile: CdpEmailTemplateFile = CdpCloneable.deepCopy(templateFile);
    for (const operation of this.operations_) {
      transformedTemplateFile = operation.transform(transformedTemplateFile);
    }

    return transformedTemplateFile;
  }
}

export class CdpEmailTemplateFile {
  private static emtSectionPrefix_: string = '----****----';

  info: CdpEmailTemplateInfo = new CdpEmailTemplateInfo();

  html: string = '';
  css: string = '';

  private compiledHtml_: string = '';
  private ampHtml_: string = '';

  clear() {
    this.info = new CdpEmailTemplateInfo();
    this.html = '';
    this.css = '';
    this.compiledHtml_ = '';
    this.ampHtml_ = ''
  };

  isEmpty(): boolean {
    return this.html.length == 0;
  }

  hasCompiledHtml(): boolean {
    return this.compiledHtml_.length > 0;
  }

  hasAmpHtml(): boolean {
    return this.ampHtml_.length > 0;
  }

  getCompiledHtml(): string {
    return this.compiledHtml_;
  }

  getAmpHtml(): string {
    return this.ampHtml_;
  }

  setCompiledHtml(compiledHtml: string) {
    this.compiledHtml_ = compiledHtml;
  }

  setAmpHtml(ampHtml: string) {
    this.ampHtml_ = ampHtml;
  }

  loadFromEmtCommonFile(file: CdpCommonFile): boolean {
    this.clear();

    try {
      if (file.header.fileType != CdpCommonFileType.EmailTemplate) {
        throw "Wrong file type for loading template";
      }

      let foundInfo: boolean = false;
      let foundInternalHtml: boolean = false;
      let foundInternalCss: boolean = false;

      for (const section of file.sectionSet.sections) {
        const sectionText: string = section.lines.join('\n');

        switch (section.sectionName) {
          case 'info':
            foundInfo = true;
            this.info.loadFromText(sectionText);

          break;

          case 'internalHtml':
            foundInternalHtml = true;
            this.html = sectionText;
          break;

          case 'internalCss':
            foundInternalCss = true;
            this.css = sectionText;
          break;

          case 'compiledHtml':
            this.compiledHtml_ = sectionText;
            break;

          case 'ampHtml':
            this.ampHtml_ = sectionText;
          break;

          default:
            const message: string = `Unknown template file section: ${section.sectionName}`;
            console.log(message);
            throw message;  
        }
      }

      if (!foundInfo || !foundInternalHtml || !foundInternalCss) {
        const message: string = `Template missing required section`;
        console.log(message);
        throw message;  
      }

      // As a hack, update some template file infos.
      this.updateInfoIfExcluded_();

      return true;
    } catch (error) {
      console.log("Error loading template file: ", error);
      this.clear();

      return false;
    }
  }

  loadFromEmtFormat(emtString: string) {
    this.clear();

    const lines: string[] = emtString.split('\n');

    if ((lines.length > 0) && lines[0].startsWith('@@!BeginFile')) {
      // This string is in the newer format.
      const file: CdpCommonFile = new CdpCommonFile();
      file.loadFromLines(lines);
      this.loadFromEmtCommonFile(file);
      return;
    }

    let sections: Map<string, string> = new Map<string, string>();
    let currentSectionString = '';

    const prefix: string = CdpEmailTemplateFile.emtSectionPrefix_;

    let sectionName: string = '';

    //console.log(`File: ${templateName} Length:${emtString.length} Lines:${lines.length}`);

    for (let line of lines) {
      if (line.startsWith(prefix)) {
        // Beginning of a new section.
        //console.log("Begin section:", sectionName);

        if (sectionName.length > 0) {
          // Store the previous section.
          sections.set(sectionName, currentSectionString);
        }

        sectionName = line.replace(prefix, '').trim();

        //console.log("Section name:", sectionName);
        currentSectionString = '';

        if (sectionName == 'end') {
          break;
        }
      } else {
        // This is a new line in the current section.
        currentSectionString = currentSectionString.concat(line);
      }
    }

    // End the last section.
    if (sectionName.length > 0) {
      // Store the previous section.
      sections.set(sectionName, currentSectionString);
    }

    this.html = sections.get('html') || '';
    this.css = sections.get('css') || '';
    this.compiledHtml_ = sections.get('compiledHtml') || '';
    this.ampHtml_ = sections.get('ampHtml') || '';

    const infoString: string = sections.get('info') || '';

    this.loadInfoString_(infoString);
  }

  convertToEmtFormat(): string {
    const prefix: string = CdpEmailTemplateFile.emtSectionPrefix_;

    let htmlString: string = this.html;
    let cssString: string = this.css;

    //console.log("l.86 info=", JSON.stringify(this.info));
    // TODO TODO let infoString: string = JSON.stringify(this.info);
    let saveMetadata: any = {
      id: this.info.id.id,
      name: this.info.name,
    };
    for (const entry of Object.entries(this.info.metadata)) {
      saveMetadata[entry[0]] = entry[1];
    }
    let infoString: string = JSON.stringify(saveMetadata);
    //console.log("l.96 infoString=", infoString);

    let emtString: string = `${prefix}info\n${infoString}\n${prefix}html\n${htmlString}\n${prefix}css\n${cssString}\n`;

    if (this.hasCompiledHtml()) {
      emtString =
        emtString + `${prefix}compiledHtml\n${this.getCompiledHtml()}\n`;
    }

    if (this.hasAmpHtml()) {
      emtString = emtString + `${prefix}ampHtml\n${this.getAmpHtml()}\n`;
    }

    emtString = emtString + `${prefix}end\n`;

    return emtString;
  }

  static exclusionIds: string[] = "1014572	2236903	2245280	1446062	1218998	842320	1240498	1240550	1240750	1240828	1240839	1240861	1240922	1240923	1240941	1240942	1240958	1240966	1240969	1240976	1241142	1241146	1241149	1246641	1246760	1246870	1246871	1246877	1247177	1247187	1247196	1274757	1279285	1280409	1280420	1290582	1337330	120622	1012966	2167863	885321".split('\t');

  /*
  static mapFromOldIndustryToNewIndustryString_: string = 
  `Art & Design	Art	Design								
Bakery	Beverage	Food								
Bar	Beverage	Leisure	Restaurants							
Bookstore	Books									
Clothing	Clothes									
Florist	Gifts and Flowers	Gardening								
Hair/Beauty Salon	Beauty							
Hotel	Hotels	Airline	Tourism							
Home/Furniture	Construction									
Jewelry	Jewelry									
Professional services	Consulting	Finance	Auto/Moto	Insurance	Lawyers	Software	Telecommunications	Real estate	Photography	Art
Pets	Animals									
Restaurant	Beverage	Food	Restaurants	Agriculture						
Retail	Beverage	Food	Children's products	Electronics	Gadgets	Gaming	Agriculture	Products	Construction	Health & Wellness
Spa	Beauty	Sports								
Sports bar										
Winery/Distillery	Beverage	Food	
Education / Non-for profit	Charity	Education	Government	No War						
Hobbies/Leisure	Hobbies									
Other	Transport	Webinar	Telecommunications`;
*/

static mapFromOldIndustryToNewIndustryString_: string = 
`Art & Design	Art	Design								
Bakery									
Bar	Leisure							
Bookstore	Books									
Clothing	Clothes									
Florist	Gifts and Flowers	Gardening								
Hair/Beauty Salon	Beauty						
Hotel	Hotels	Airline	Tourism							
Home/Furniture	Construction									
Jewelry	Jewelry									
Professional services	Consulting	Finance	Auto/Moto	Insurance	Lawyers	Software	Telecommunications	Real estate	Photography
Pets	Animals									
Restaurant	Beverage	Food	Restaurants						
Retail		Children's products	Electronics	Gadgets	Gaming	Agriculture	Products	Health & Wellness
Spa	Sports								
Sports bar										
Winery/Distillery
Education/Nonprofit	Charity	Education	Government	No War						
Hobbies/Leisure	Hobbies									
Generic	Transport	Webinar`;

private static mapFromOldIndustryToNewIndustryLines_: string[] = CdpEmailTemplateFile.mapFromOldIndustryToNewIndustryString_.split('\n');

private static getOldIndustryToNewIndustryMap_(): any {
    //console.log("Map lines:", CdpEmailTemplateFile.mapFromOldIndustryToNewIndustryLines_.length);
    const actualMap: any = {}
    for (const line of CdpEmailTemplateFile.mapFromOldIndustryToNewIndustryLines_) {
      let fields: string[] = line.trim().split('\t').map((f) => f.trim());
      //console.log("Row:",fields[0]);
      //console.log("  Elements:", fields.join('*'));
      const newIndustry: string = fields[0];
      fields = fields.slice(1);
      for (const field of fields) {
        if (actualMap[field]) {
          //console.log(`Duplicate mapping: ${field} -> ${actualMap[field]} and ${newIndustry}}`);
        } else {
          actualMap[field] = newIndustry;
        }
      }
    }
    
    //console.log("actualMap:", JSON.stringify(actualMap));

    return actualMap;
  }

  private updateInfoIfExcluded_() {
    //console.log("Num exclusion ids:", CdpEmailTemplateFile.exclusionIds.length);

    const exclude: boolean = CdpEmailTemplateFile.exclusionIds.indexOf(this.info.id.id) >= 0;
    if (exclude) {
      //console.log("Excluding :", this.info.id.id);
      this.info.doExclude = true;
    }
  }

  private static updateInfo_(templateFile: CdpEmailTemplateFile) {
    const info: CdpEmailTemplateInfo = templateFile.info;

    templateFile.updateInfoIfExcluded_();
    const metadata: any = info.metadata;

    const oldIndustryToNewIndustryMap: any = CdpEmailTemplateFile.getOldIndustryToNewIndustryMap_();
    for (const entry of Object.entries(metadata)) {
      if (entry[0] == 'Industry') {
        const oldIndustries: string[] = entry[1] as string[];
        const newIndustries: string[] = [];
        for (const oldIndustry of oldIndustries) {
          let newIndustry: string | undefined = oldIndustryToNewIndustryMap[oldIndustry];
          if (!newIndustry) {
            console.log("No known mapping for ", oldIndustry);
            newIndustry = 'Generic';
          }
          newIndustries.push(newIndustry);

          //console.log(`Mapped industry: ${oldIndustry} -> ${newIndustry}`);
        }

        metadata[entry[0]] = newIndustries;
      }
    }
  }

  private static makeSaveInfoString_(
    templateFile: CdpEmailTemplateFile
  ): string {
    CdpEmailTemplateFile.updateInfo_(templateFile);

    const infoString: string = JSON.stringify(templateFile.info);

    return infoString;
  }

  static makeEmtFileSectionSet_(
    templateFile: CdpEmailTemplateFile
  ): CdpCommonFileSectionSet {
    const sections: CdpCommonFileSection[] = [];

    let section: CdpCommonFileSection;
    
    section = new CdpCommonFileSection();
    const infoString: string =
      CdpEmailTemplateFile.makeSaveInfoString_(templateFile);
    section.sectionName = 'info';
    section.lines = [infoString];
    sections.push(section);

    section = new CdpCommonFileSection();
    section.sectionName = 'internalHtml';
    section.lines = [templateFile.html];
    sections.push(section);

    section = new CdpCommonFileSection();
    section.sectionName = 'internalCss';
    section.lines = [templateFile.css];
    sections.push(section);

    if (templateFile.hasCompiledHtml()) {
      section = new CdpCommonFileSection();
      section.sectionName = 'compiledHtml';
      section.lines = [templateFile.getCompiledHtml()];
      sections.push(section);
    }

    if (templateFile.hasAmpHtml()) {
      section = new CdpCommonFileSection();
      section.sectionName = 'ampHtml';
      section.lines = [templateFile.getAmpHtml()];
      sections.push(section);
    }

    const sectionSet: CdpCommonFileSectionSet = new CdpCommonFileSectionSet();
    sectionSet.sections = sections;

    return sectionSet;
  }

  makeEmtFile(): CdpCommonFile {
    const file: CdpCommonFile = new CdpCommonFile();

    file.header.fileType = CdpCommonFileType.EmailTemplate;
    file.header.formatVersion = '1.0';

    file.setFilename(`${this.info.name}__${this.info.id.id}.emt`);
    file.sectionSet = CdpEmailTemplateFile.makeEmtFileSectionSet_(this);

    return file;
  }

  static makeEmtMultiFile(
    templateFiles: CdpEmailTemplateFile[]
  ): CdpCommonMultiFile {
    const multiFile: CdpCommonMultiFile = new CdpCommonMultiFile();

    multiFile.header.fileType = CdpCommonFileType.MultiFile;
    multiFile.header.formatVersion = '1.0';

    for (const templateFile of templateFiles) {
      const file: CdpCommonFile = templateFile.makeEmtFile();
      multiFile.files.push(file);
    }

    return multiFile;
  }

  private loadInfoString_(metadataString: string) {
    const metadata: any = {};

    //console.log("metadataString:", metadataString);

    if (metadataString.length > 0) {
      // There are currently two different formats.  One is used by the builtin
      // templates, which use a simplified format that has just the name, ID,
      // and a list of tags by category.  The other is a normal JSON
      // serialization.
      //
      // TODO TODO
      if (metadataString.indexOf('metadata') >= 0) {
        // TODO TODO
      }
      const thisTemplateFile = this;

      //console.log("l.125 metadataString:", metadataString);
      const metadataFlat: any = JSON.parse(metadataString);
      //console.log("l.128 metadataFlat:", metadataFlat);
      for (const entry of Object.entries(metadataFlat)) {
        //console.log(`Flat key=${entry[0]} value=${entry[1]}`);
        const key: string = entry[0];
        const value: string = entry[1] as string;

        if (key == 'name') {
          thisTemplateFile.info.name = value;
        } else if (key == 'id') {
          thisTemplateFile.info.id.id = value;
        } else if (key.length > 0) {
          // Any other key is part of the metadata, and the value may
          // be a comma-separated list of values.
          let values: string[] = [];

          const valueStr: string = value.toString();
          if (value && valueStr.length > 0) {
            values = valueStr.split(',');
            values = values.map((v) => v.trim());
          }
          //console.log(`l.131: key=${key} values=${values}`);
          //metadata.set(key, values);
          metadata[key] = values;
          //console.log(`l.134: key=${key} values=${values} metadata:${JSON.stringify(metadata)}`);
        }
      }
      /*
      const metadataFlat: any = JSON.parse(
        metadataString,
        (key: string, value: any) => {
          //console.log(`Metadata field: key=${key} value=${value}`);

          const valueStr: string = value.toString();

          if (key == 'name') {
            thisTemplateFile.info.name = value;
          } else if (key == 'id') {
            thisTemplateFile.info.id.id = value;
            // TODO id.version

          } else if (key.length > 0) {
            // Any other key is part of the metadata, and the value may
            // be a comma-separated list of values.
            let values: string[] = []

            if (valueStr && (valueStr.length > 0)) {
              values = valueStr.split(",");
              values = values.map((v) => v.trim());
            }
            //console.log(`l.131: key=${key} values=${values}`);
            //metadata.set(key, values);
            metadata[key] = values;
            //console.log(`l.134: key=${key} values=${values} metadata:${JSON.stringify(metadata)}`);
          }
        }
      );
        //console.log("metadataflat:", JSON.stringify(metadataFlat));
        */
    } else {
      //console.log("Clear metadata");
      this.info.metadata.clear();
    }

    //console.log(`l.142: ${this.info.id}: ${metadataString}`);
    //console.log("l.148 metadata:", JSON.stringify(metadata));
    //console.log("Template info l.143:", JSON.stringify(this.info));

    this.info.metadata = metadata;

    //console.log("Template info:", JSON.stringify(this.info));
  }

  doesMatchFilter(
    filter: CdpEmailTemplateInfoFilter,
    emptyFilterMatchesAll: boolean
  ): boolean {
    return filter.doesMatchTemplateInfo(this.info, emptyFilterMatchesAll);
  }

  public static sort(
    templateFiles: CdpEmailTemplateFile[]
  ): CdpEmailTemplateFile[] {
    return templateFiles.sort(CdpEmailTemplateFile.compareName);
  }

  public static compareName(
    t1: CdpEmailTemplateFile,
    t2: CdpEmailTemplateFile
  ): number {
    // Comparison function for use by sort.  Sorts into ascending order by name.
    const name1: string = t1.info.name;
    const name2: string = t2.info.name;
    if (name1 < name2) {
      return -1;
    } else if (name1 > name2) {
      return 1;
    } else {
      return 0;
    }
  }
}

export class CdpEmailTemplateFileVariantSet {
  private set_: Map<string, CdpEmailTemplateFile> = new Map<
    string,
    CdpEmailTemplateFile
  >();
  private defaultVariantName_: string = 'original';
  private selectedVariantName_: string = this.defaultVariantName_;

  getDefaultVariantName(): string {
    return this.defaultVariantName_;
  }

  getSelectedVariantName(): string {
    return this.selectedVariantName_;
  }

  isSelectedVariantDefault(): boolean {
    return this.selectedVariantName_ == this.defaultVariantName_;
  }

  selectVariant(variantName: string): boolean {
    this.selectedVariantName_ = variantName;

    return this.getSelectedVariant() ? true : false;
  }

  selectDefaultVariant(): boolean {
    return this.selectVariant(this.defaultVariantName_);
  }

  getVariant(variantName: string): CdpEmailTemplateFile | null {
    const templateFile: CdpEmailTemplateFile | undefined =
      this.set_.get(variantName);

    //console.log(`Getting variant: ${variantName}`);

    if (templateFile) {
      //console.log("Variant has ci:", templateFile.html.indexOf("images.inboxease.com") >= 0);

      return templateFile;
    } else {
      return null;
    }
  }

  getSelectedVariant(): CdpEmailTemplateFile | null {
    return this.getVariant(this.selectedVariantName_);
  }

  getDefaultVariant(): CdpEmailTemplateFile | null {
    return this.getVariant(this.defaultVariantName_);
  }

  setVariant(
    templateFile: CdpEmailTemplateFile,
    variantName: string = this.defaultVariantName_
  ) {
    //console.log("FileSet setting variant:", variantName);
    this.set_.set(variantName, templateFile);
    //console.log("Set has variants:", this.set_.keys());
    //console.log("Variant has ci:", templateFile.html.indexOf("images.inboxease.com") >= 0);
  }

  setDefaultVariant(templateFile: CdpEmailTemplateFile) {
    this.setVariant(templateFile, this.defaultVariantName_);
  }

  remove(variantName: string) {
    this.set_.delete(variantName);
  }

  removeAll() {
    this.set_.clear();
  }

  async addTransformedVariant(
    sourceVariantName: string,
    transformer: CdpEmailTemplateFileTransformer,
    transformedVariantName: string
  ): Promise<boolean> {
    // TODO Should it be legal to have <transformedVariant> be the same as <sourceVariant>?
    let didTransform: boolean = false;

    try {
      const sourceTemplateFile: CdpEmailTemplateFile | null =
        this.getVariant(sourceVariantName);
      if (sourceTemplateFile) {
        /*console.log(
          `Transforming variant ${sourceVariantName} -> ${transformedVariantName}`
        );
        */

        const transformedTemplateFile: CdpEmailTemplateFile | null =
          transformer.transform(sourceTemplateFile);
        if (transformedTemplateFile) {
          //console.log('Did transform');
          this.setVariant(transformedTemplateFile, transformedVariantName);
          didTransform = true;
        } else {
          //console.log("Did not transform");
        }
      }
    } catch {
      //console.log("Caught exception when transforming");

      didTransform = false;
    }

    return didTransform;
  }
}

export class CdpEmailTemplateFileTransformerOperationFromBrandingSet
  implements CdpEmailTemplateFileTransformerOperation
{
  constructor(private brandingSet: CdpEmailTemplateBrandingSet) {}

  transform(templateFile: CdpEmailTemplateFile): CdpEmailTemplateFile {
    const updatedTemplateFile: CdpEmailTemplateFile =
      new CdpEmailTemplateFile();

    updatedTemplateFile.info = CdpCloneable.deepCopy(templateFile.info);
    updatedTemplateFile.css = templateFile.css;
    updatedTemplateFile.html = this.transformHtmlWithBrandingSet_(
      templateFile.html,
      this.brandingSet
    );

    if (templateFile.hasCompiledHtml()) {
      const html: string = templateFile.getCompiledHtml();
      const transformedHtml: string = this.transformHtmlWithBrandingSet_(
        html,
        this.brandingSet
      );

      updatedTemplateFile.setCompiledHtml(transformedHtml);
    }

    if (templateFile.hasAmpHtml()) {
      const html: string = templateFile.getAmpHtml();
      const transformedHtml: string = this.transformHtmlWithBrandingSet_(
        html,
        this.brandingSet
      );

      updatedTemplateFile.setAmpHtml(transformedHtml);
    }

    return updatedTemplateFile;
  }

  async isInteresting(image: HTMLImageElement): Promise<boolean> {
    return image.alt.indexOf('logo') >= 0;
    /*
  const stripoImage: string = "64951510234941531.png";
  const stripoImage2: string = "7911561025989373.png";

  if ((image.src.indexOf(stripoImage) >= 0)  || (image.src.indexOf(stripoImage2) >= 0) ) {
    return false;
  }

  try {
     const size: CdpImageSize = await CdpImageUtilities.getImageNaturalDimensions(image.src);


    if ((size.width > 400) || (size.width <= 64)) {
      return false; 
   }

   const aspect: number = size.height / size.width;
   if (aspect > 1) {
    return false;
   }
    return true;
  } catch {
    return false;
  }
  */
  }

  async appendIfInteresting(
    doc: Document,
    image: HTMLImageElement
  ): Promise<void> {
    if (await this.isInteresting(image)) {
      //console.log("Interesting:", image.src);
      const div: HTMLDivElement = doc.createElement('div');
      doc.body.appendChild(div);
      doc.body.appendChild(image);
    }
  }

  findLogoImage_(
    images: HTMLCollectionOf<HTMLImageElement>
  ): HTMLImageElement | null {
    let logoImage: HTMLImageElement | null = null;
    Array.from(images).forEach((image) => {
      const alt = image.alt.toLowerCase();
      if (alt.includes('logo')) {
        logoImage = image;
      }
    });

    return logoImage;
  }

  transformHtmlWithBrandingSet_(
    html: string,
    brandingSet: CdpEmailTemplateBrandingSet
  ): string {
    const parser = new DOMParser();

    if (brandingSet.logoImageUrl.length == 0) {
      // There aren't any other changes we currently implement.
      //console.log("No change specified");
      return html;
    }

    const logoImageUrl: string = brandingSet.logoImageUrl;

    let doc: Document = parser.parseFromString(html, 'text/html');

    let updatedHtml: string = html;

    const imagePromises: Promise<void>[] = [];

    const images: HTMLCollectionOf<HTMLImageElement> =
      doc.getElementsByTagName('img');

    const logoImage: HTMLImageElement | null = this.findLogoImage_(images);

    if (logoImage) {
      // Create a unique URL in case the browser might have cached an outdated
      // version of the image.
      const uniqueUrl = CdpImage.makeUncachedUrl(logoImageUrl);

      const origElementHtml: string = logoImage.outerHTML;

      logoImage.src = uniqueUrl;
      logoImage.alt = 'Logo'; // TODO Business name
      logoImage.title = 'Logo'; // TODO Business name

      const newElementHtml: string = logoImage.outerHTML;

      //console.log(`Replacing ${origElementHtml} with ${newElementHtml}`);

      updatedHtml = updatedHtml.replace(origElementHtml, newElementHtml);
    } else {
      //console.log("Did not find a logo image to replace");
    }

    /*
    Array.from(images).forEach(async (image: HTMLImageElement) => {
      imagePromises.push(
        this.appendIfInteresting(this.bogusAllImagesDoc, image)
      );
    });

    await Promise.all(imagePromises);
    */
    //console.log("HTML changed:", html != updatedHtml);
    //console.log("HTML includes CI image:", updatedHtml.indexOf("images.inboxease.com") >= 0);

    return updatedHtml;
  }
}
