import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CdpBusinessType } from '../cdp-business';

@Component({
  selector: 'app-cdp-business-type',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './cdp-business-type.component.html',
  styleUrl: './cdp-business-type.component.scss'
})
export class CdpBusinessTypeComponent {
  @Input() businessType: CdpBusinessType = new CdpBusinessType();

  allBusinessTypes: string[] = CdpBusinessType.knownTypes;
}
