import { EventEmitter, Injectable } from '@angular/core';
import { CdpSessionManagerService } from 'src/app/cdp-session-manager.service';
import { CdpEvent } from '../cdp-event';
import { CdpUser } from '../cdp-user';

export class CdpPermission {
  operationName: string = '';
  isAllowed: boolean = false;
}

export class CdpPermissionSet {
  private defaultIsAllowed_: boolean = true;
  
  private permissionsMap_: Map<string, boolean> = new Map<string, boolean>();

  constructor(permissions: CdpPermission[], defaultIsAllowed: boolean) {
    this.defaultIsAllowed_ = defaultIsAllowed;

    for (const permission of permissions) {
      if (permission.operationName.length > 0) {
        this.permissionsMap_.set(permission.operationName, permission.isAllowed);
      }
    }
  }

  public isAllowed(operationName: string): boolean {
    const value: boolean | undefined = this.permissionsMap_.get(operationName);
    if (value == undefined) {
      return this.defaultIsAllowed_;
    } else {
      return value;
    }
  }
}

export class CdpPermissionServiceEvent {
  currentUserPermissionSet: CdpPermissionSet;
  
  constructor(permissionSet: CdpPermissionSet) {
    this.currentUserPermissionSet = permissionSet;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CdpPermissionService {

  private currentUserPermissionSet_: CdpPermissionSet = new CdpPermissionSet([], false);

  public eventEmitter: EventEmitter<CdpPermissionServiceEvent> = new EventEmitter<CdpPermissionServiceEvent>();

  constructor(private sessionManager: CdpSessionManagerService) {
    this.refreshPermissions_();

    this.sessionManager.eventEmitter.subscribe((e) => this.processSessionManagerEvent_(e));
   }

  public getCurrentUserPermissionSet() {
    return this.currentUserPermissionSet_;

  }
  public isAllowed(operationName: string): boolean {
    return this.currentUserPermissionSet_.isAllowed(operationName);
  }

  private processSessionManagerEvent_(event: CdpEvent) {
    this.refreshPermissions_();
  }

  private async refreshPermissions_() {
    // Get the permissions for the current user and update the permissions set.
    const user: CdpUser | null = await this.sessionManager.getCurrentUser();

    // If there is no signed-in user, disallow everything.
    if (!user) {
      this.currentUserPermissionSet_ = new CdpPermissionSet([], false);
    } else {
      this.currentUserPermissionSet_ = 
        new CdpPermissionSet(user.permissions, user.defaultPermissionIsAllowed);
    }

    // TODO Emit the event only if the permissions changed.
    this.eventEmitter.emit(new CdpPermissionServiceEvent(this.currentUserPermissionSet_));
  }
}
