import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { RouteReuseStrategy, provideRouter, withDebugTracing } from '@angular/router';
import {
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';

import { APP_BASE_HREF } from '@angular/common';
import { CdpLoggerService } from './core/log/cdp-logger.service';
import { CustomRouteReuseStrategy } from './app-route-reuse';
import { CdpResourceService } from './resource/cdp-resource.service';
import { CdpEmailTemplateManagerService } from './email/template/cdp-email-template-manager.service';
import { CdpSessionManagerService } from './cdp-session-manager.service';
import { CdpCustomerDatabaseService } from './customers/cdp-customer-database.service';
import { CdpSendEmailService } from './email/send/cdp-send-email.service';

// Create an initialization function that will start the services
// that will always be needed.
function initServicesFactory(loggerService: CdpLoggerService, emailSender: CdpSendEmailService, sessionManager: CdpSessionManagerService, resourceService: CdpResourceService, databaseService: CdpCustomerDatabaseService, templateManager: CdpEmailTemplateManagerService) {}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, /* withDebugTracing() */),
    provideAnimations(),
    provideHttpClient(withFetch()),
    {
      provide: APP_BASE_HREF,
      useValue: '/', // TODO
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initServicesFactory,
      deps: [CdpCustomerDatabaseService, CdpLoggerService, CdpResourceService, CdpSendEmailService, CdpSessionManagerService, CdpEmailTemplateManagerService],
    }
  ],
};
