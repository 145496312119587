import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CdpCountryComponent } from '../../address/cdp-country/cdp-country';
import { CdpUsaStateComponent } from '../../address/cdp-usa-state/cdp-usa-state.component';
import { CdpUsaZipCodeComponent } from '../../address/cdp-usa-zip-code/cdp-usa-zip-code.component';
import { CdpBusinessProfileBasicInfo, CdpBusinessProfileDetails } from '../cdp-business';
import { CdpBusinessTypeComponent } from '../cdp-business-type/cdp-business-type.component';
import { CdpUrl } from '../../cdp-url';

@Component({
  selector: 'app-cdp-business-profile-basic-info',
  standalone: true,
  imports: [
    CommonModule,
    CdpBusinessTypeComponent,
    CdpCountryComponent,
    CdpUsaStateComponent,
    CdpUsaZipCodeComponent,
    FormsModule,
  ],
  templateUrl: './cdp-business-profile-basic-info.component.html',
  styleUrl: './cdp-business-profile-basic-info.component.scss',
})
export class CdpBusinessProfileBasicInfoComponent {
  private info_: CdpBusinessProfileBasicInfo = new CdpBusinessProfileBasicInfo();
  inputBusinessUrl: string = '';

  @Input() set info(info: CdpBusinessProfileBasicInfo) {
    //console.log("Setting basicInfo.info:", info);
    
    this.info_ = info;
    this.inputBusinessUrl = this.info_.businessUrl.hostname;
  }

  get info() {
    return this.info_;
  }

  @Input() details: CdpBusinessProfileDetails = new CdpBusinessProfileDetails();

  getInputStyle(isValid: boolean) : string {
    return (isValid ? "" : "border-color: red;");
  }

  urlStyle: string = '';

  setUrlStyle(isValid: boolean) {
    this.urlStyle = this.getInputStyle(isValid);
  }

  onBusinessUrlChange() {
    // Allow the user to type in variations of "mycompany.com", such as
    // "http://mycompany.com" or "https://mycompany.com", or "https://mycompany.com/".

    // If there is no protocol, add one, to standardize the parsing.
    const prefix: string = (this.inputBusinessUrl.includes('://') ? '' : 'https://');

    const url: CdpUrl = new CdpUrl(prefix + this.inputBusinessUrl);

    if (url.isValid()) {
      this.info.businessUrl = url;
      this.inputBusinessUrl = url.hostname;
    } else {
      // Leave the invalid display and make the business URL invalid.
      // TODO If there was already a valid business URL, it's not clear whether we
      //      should reset to that or not.
      this.info.businessUrl = new CdpUrl();
    }

    this.setUrlStyle(url.isValid());
  }
}
