import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { SquareService } from '../square.service';
import { CustomerUiService } from 'src/app/ui/customer-ui.service';

import { Tabulator } from 'tabulator-tables';
import { CdpHeaderComponent } from 'src/app/core/cdp-header/cdp-header.component';

@Component({
  selector: 'app-square-connect-complete',
  standalone: true,
  imports: [CommonModule, CdpHeaderComponent],
  templateUrl: './square-connect-complete.component.html',
  styleUrl: './square-connect-complete.component.sass',
})
export class SquareConnectCompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('square_results') square_results!: ElementRef<HTMLInputElement>;
  @ViewChild('square_customers')
  square_customers!: ElementRef<HTMLInputElement>;
  exStatsSummaryTable: any;
  exCustomersTable: any;

  elemStatsSummaryTable = document.createElement('div');
  elemCustomersTable = document.createElement('div');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private square: SquareService,
    private uiService: CustomerUiService
  ) {}

  ngOnInit(): void {}

  async ngAfterViewInit(): Promise<void> {
    const snapshot = this.route.snapshot;
    let queryParams = snapshot.queryParams;

    const queryParamsStringHttp = new HttpParams({
      fromObject: queryParams,
    }).toString();
    console.log('HTTP queryParams:', queryParamsStringHttp);
    const response = await this.square.squareSessionCompleteStart(
      '?' + queryParamsStringHttp
    );
    console.log('Square complete response:', JSON.stringify(response));

    let stats_summary_table = response.stats_summary_table;
    let customers = response.customers;
    let segmentSet = response.segments;


    this.exCustomersTable = this.uiService.makeCustomerTable(
      this.elemCustomersTable,
      400,
      customers);

    let columns = stats_summary_table.columns;
    let tableData = stats_summary_table.data;

    this.exStatsSummaryTable = new Tabulator(this.elemStatsSummaryTable, {
      height: 205,
      data: tableData,
      layout: 'fitDataTable',
      columns: columns,
    });

    document?.getElementById('square_summary_table')?.appendChild(this.elemStatsSummaryTable);
    document?.getElementById('square_customers_table')?.appendChild(this.elemCustomersTable);
  }
}
