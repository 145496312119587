import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, concatMap, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CdpJwt } from '../../core/cdp-auth/auth.service';

import {
  CdpBackendCoreService,
  CdpBackendResponse,
} from '../cdp-backend-core.service';
import { CdpEmailTemplateFile } from 'src/app/email/template/cdp-email-template-file';

@Injectable({
  providedIn: 'root',
})
export class CdpEmailTemplateAuthResponse extends CdpBackendResponse {
  static makeErrorResponse(e: any): CdpEmailTemplateAuthResponse {
    let response: CdpEmailTemplateAuthResponse =
      new CdpEmailTemplateAuthResponse();
    response.appendError(e);

    return response;
  }
}

export class CdpEmailTemplateSaveResponse extends CdpBackendResponse {
  static makeErrorResponse(e: any): CdpEmailTemplateSaveResponse {
    let response: CdpEmailTemplateSaveResponse =
      new CdpEmailTemplateSaveResponse();
    response.appendError(e);

    return response;
  }
}

export class CdpEmailTemplateLoadResponse extends CdpBackendResponse {
  static makeErrorResponse(e: any): CdpEmailTemplateLoadResponse {
    let response: CdpEmailTemplateLoadResponse =
      new CdpEmailTemplateLoadResponse();
    response.appendError(e);

    return response;
  }
}


export class CdpEmailTemplateListResponse extends CdpBackendResponse {
  data: string = "";
  
  static makeErrorResponse(e: any): CdpEmailTemplateListResponse {
    let response: CdpEmailTemplateListResponse =
      new CdpEmailTemplateListResponse();
    response.appendError(e);

    return response;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CdpBackendEmailService {
  constructor(
    private http: HttpClient,
    private backendCore: CdpBackendCoreService
  ) {}

  private getEmailTemplateEditorAuthWithJwt_(
    jwt: CdpJwt,
    makeServerUrlFunc: any,
    httpClient: HttpClient,
    handleErrorFunc: any
  ): Observable<CdpEmailTemplateAuthResponse> {
    try {
      //console.log("in withJwt this=", this);
      //const url = this.backendCore.makeServerUrlWithJwt('email_editor_get_auth_token', jwt);
      const url = makeServerUrlFunc('email_editor_get_auth_token', jwt);

      //console.log('Posting email template editor get auth to:', url);
      let observable = httpClient
        .post<CdpEmailTemplateAuthResponse>(url, {})
        .pipe(
          catchError(
            // TODO
            /*
            this.handleError<CdpEmailTemplateAuthResponse>(
              'Email template editor get authorization'
            )
            */
            handleErrorFunc('Email template editor get authorization')
          )
        );

      return observable;
    } catch (error) {
      console.log('Server error getting email template editor auth:', error);

      return of(CdpEmailTemplateAuthResponse.makeErrorResponse(error));
    }
  }

  getEmailTemplateEditorAuth(): Observable<CdpEmailTemplateAuthResponse> {
    //console.log("Start backend call: getEmailTemplateEditorAuth. this=", this);

    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    // We need to store references to member functions and pass them explicitly because
    // "this" will be something different in a callback context.
    const func = this.getEmailTemplateEditorAuthWithJwt_.bind(this);
    const makeServerUrlFunc = this.backendCore.makeServerUrlWithJwt;
    const httpClient = this.http;
    const handleErrorFunc = this.handleError<CdpEmailTemplateAuthResponse>;

    return jwtObservable.pipe(
      concatMap((jwt) =>
        func(jwt, makeServerUrlFunc, httpClient, handleErrorFunc)
      )
    );
  }

  private saveEmailTemplateWithJwt_(
    templateFile: CdpEmailTemplateFile,
    jwt: CdpJwt,
    makeServerUrlFunc: any,
    httpClient: HttpClient,
    handleErrorFunc: any
  ): Observable<CdpEmailTemplateSaveResponse> {
    try {
       const url = makeServerUrlFunc('email_templates_save_user_template', jwt);

      //console.log('Posting email template save to:', url);
      let observable = httpClient
        .post<CdpEmailTemplateSaveResponse>(url, templateFile)
        .pipe(catchError(handleErrorFunc('Email template save')));

      return observable;
    } catch (error) {
      console.log('Server error saving email template:', error);

      return of(CdpEmailTemplateSaveResponse.makeErrorResponse(error));
    }
  }

  saveEmailTemplate(templateFile: CdpEmailTemplateFile): Observable<CdpEmailTemplateSaveResponse> {
    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    // We need to store references to member functions and pass them explicitly because
    // "this" will be something different in a callback context.
    const func = this.saveEmailTemplateWithJwt_.bind(this);
    const makeServerUrlFunc = this.backendCore.makeServerUrlWithJwt;
    const httpClient = this.http;
    const handleErrorFunc = this.handleError<CdpEmailTemplateSaveResponse>;

    return jwtObservable.pipe(
      concatMap((jwt) =>
        func(templateFile, jwt, makeServerUrlFunc, httpClient, handleErrorFunc)
      )
    );
  }

  private loadEmailTemplateWithJwt_(
    templateId: string,
    jwt: CdpJwt,
    makeServerUrlFunc: any,
    httpClient: HttpClient,
    handleErrorFunc: any
  ): Observable<CdpEmailTemplateLoadResponse> {
    try {
      const url = makeServerUrlFunc('email_templates_load_user_template', jwt);

      //console.log('Posting email template save to:', url);
      let observable = httpClient
        .post<CdpEmailTemplateLoadResponse>(url, templateId)
        .pipe(catchError(handleErrorFunc('Email template load')));

      return observable;
    } catch (error) {
      console.log('Server error loading email template:', error);

      return of(CdpEmailTemplateLoadResponse.makeErrorResponse(error));
    }
  }

  loadEmailTemplate(templateId: string): Observable<CdpEmailTemplateLoadResponse> {
    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    // We need to store references to member functions and pass them explicitly because
    // "this" will be something different in a callback context.
    const func = this.loadEmailTemplateWithJwt_.bind(this);
    const makeServerUrlFunc = this.backendCore.makeServerUrlWithJwt;
    const httpClient = this.http;
    const handleErrorFunc = this.handleError<CdpEmailTemplateLoadResponse>;

    return jwtObservable.pipe(
      concatMap((jwt) =>
        func(templateId, jwt, makeServerUrlFunc, httpClient, handleErrorFunc)
      )
    );
  }

  private listEmailTemplatesWithJwt_(
    jwt: CdpJwt,
    makeServerUrlFunc: any,
    httpClient: HttpClient,
    handleErrorFunc: any
  ): Observable<CdpEmailTemplateListResponse> {
    try {
      const url = makeServerUrlFunc('email_templates_list', jwt);

      let observable = httpClient
        .post<CdpEmailTemplateListResponse>(url, {})
        .pipe(catchError(handleErrorFunc('Email template list')));

      return observable;
    } catch (error) {
      console.log('Server error listing email templates:', error);

      return of(CdpEmailTemplateListResponse.makeErrorResponse(error));
    }
  }

  listEmailTemplates(): Observable<CdpEmailTemplateListResponse> {
    const jwtObservable: Observable<CdpJwt> = from(
      CdpJwt.getCurrentSessionTokens()
    );

    // We need to store references to member functions and pass them explicitly because
    // "this" will be something different in a callback context.
    const func = this.listEmailTemplatesWithJwt_.bind(this);
    const makeServerUrlFunc = this.backendCore.makeServerUrlWithJwt;
    const httpClient = this.http;
    const handleErrorFunc = this.handleError<CdpEmailTemplateListResponse>;

    return jwtObservable.pipe(
      concatMap((jwt) =>
        func(jwt, makeServerUrlFunc, httpClient, handleErrorFunc)
      )
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.log('Error: ', error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
