import { Component, OnInit } from '@angular/core';

import {
  CdpEmailTemplateManagerEvent,
  CdpEmailTemplateManagerFile,
  CdpEmailTemplateManagerOwnerCategory,
  CdpEmailTemplateManagerService,
} from '../cdp-email-template-manager.service';
import { CdpEmailTemplateBuiltin } from '../cdp-email-template-builtin';

import { CdpEmailTemplateFile } from '../cdp-email-template-file';
import {
  CdpEmailTemplateGalleryFileSet,
  CdpEmailTemplateGalleryOperationType,
  CdpEmailTemplateGallerySectionComponent,
} from '../cdp-email-template-gallery-section/email-template-gallery-section.component';
import { NavigationEnd, Router } from '@angular/router';
import { CdpEmailTemplateBrandingSet } from '../cdp-email-template-branding-set';
import { CdpCloneable } from '../email-template-editor/email-template-editor.component';
import { CdpBackendFileService } from 'src/app/backend/files/cdp-backend-file.service';
import { Observable, catchError, firstValueFrom, of } from 'rxjs';
import {
  CdpCommonFile,
  CdpCommonFileType,
  CdpCommonMultiFile,
} from 'src/app/core/files/cdp-file-parse';
import {
  CdpProgressOperationSet,
  CdpProgressOperationTag,
} from 'src/app/ui/progress/cdp-progress';
import {
  CdpSessionManagerService,
  CdpSessionManagerEventType,
} from 'src/app/cdp-session-manager.service';
import { CdpSession } from 'src/app/core/cdp-session';
import { CdpUser } from 'src/app/core/cdp-user';
import {
  CdpBusiness,
  CdpBusinessProfile,
  CdpBusinessType,
} from 'src/app/core/business/cdp-business';
import { CdpEvent } from 'src/app/core/cdp-event';

@Component({
  selector: 'app-cdp-email-template-gallery',
  standalone: true,
  imports: [CdpEmailTemplateGallerySectionComponent],
  templateUrl: './cdp-email-template-gallery.component.html',
  styleUrl: './cdp-email-template-gallery.component.scss',
})
export class CdpEmailTemplateGalleryComponent implements OnInit {
  private thisUrl_: string = '';
  brandingSet: CdpEmailTemplateBrandingSet = new CdpEmailTemplateBrandingSet();

  galleryFileSet: CdpEmailTemplateGalleryFileSet =
    new CdpEmailTemplateGalleryFileSet();

  operationSet: CdpProgressOperationSet = new CdpProgressOperationSet();

  didLoadAtLeastOnce: boolean = false;

  sectionTitle: string = 'Template Gallery';
  ownerCategory: CdpEmailTemplateManagerOwnerCategory =
    CdpEmailTemplateManagerOwnerCategory.Builtin;
  allowVariants: boolean = true;
  showFilters: boolean = true;
  emptyFilterMatchesAll: boolean = false;
  showTemplateIds: boolean = true;
  showTemplateNames: boolean = true;

  private businessType_: CdpBusinessType | null = null;

  constructor(
    private fileService: CdpBackendFileService,
    private router: Router,
    private sessionManager: CdpSessionManagerService,
    private templateManager: CdpEmailTemplateManagerService
  ) {
    this.sessionManager.eventEmitter.subscribe((event) =>
      this.processSessionManagerEvent_(event)
    );
  }

  initialSelectedIndustry: string = '';

  private async maybeUpdateBusinessType_() {
    const businessProfile: CdpBusinessProfile | null =
      await this.sessionManager.getCurrentBusinessProfile();

    this.businessType_ = businessProfile
      ? businessProfile.details.businessType
      : null;

    if (this.businessType_ && this.businessType_.type != 'Other') {
      this.initialSelectedIndustry = this.businessType_.type;

      //console.log('Set business type:', this.businessType_);
    }
  }

  private async processSessionManagerEvent_(event: CdpEvent) {
    await this.maybeUpdateBusinessType_();
  }

  async ngOnInit() {
    this.loadBuiltinTemplates_();

    await this.maybeUpdateBusinessType_();

    this.onReload();

    this.templateManager.eventEmitter.subscribe(
      (event: CdpEmailTemplateManagerEvent) =>
        this.onTemplateManagerEvent(event)
    );

    this.thisUrl_ = this.router.url;

    const onReload = this.onReload.bind(this);
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        //console.log('Navend event:', JSON.stringify(event));
        if (event.url == this.thisUrl_) {
          // route is restored
          //console.log('Navend event match:', this.thisUrl_);

          onReload();
        }
      }
    });
  }

  onReload() {
    const brandingSet: CdpEmailTemplateBrandingSet =
      this.templateManager.getBrandingSet();
    this.setBrandingSet(brandingSet);
  }

  async applyCurrentBrandingSet() {
    await this.galleryFileSet.applyBrandingSet(this.brandingSet);
  }

  async setBrandingSet(brandingSet: CdpEmailTemplateBrandingSet) {
    this.brandingSet = brandingSet;
    await this.applyCurrentBrandingSet();
  }

  onTemplateManagerEvent(e: CdpEmailTemplateManagerEvent) {
    //console.log("Gallery got template manager event:", e);

    if (e.didBrandingChange()) {
      if (e.data) {
        const eventBrandingSet: CdpEmailTemplateBrandingSet =
          e.data.brandingSet;

        this.setBrandingSet(CdpCloneable.deepCopy(eventBrandingSet));
      }
    }
  }

  private loadBuiltinTemplatesFinish_(
    text: string,
    operationTag: CdpProgressOperationTag
  ) {
    let succeeded: boolean = false;

    try {
      const multiFile: CdpCommonMultiFile = new CdpCommonMultiFile();
      multiFile.loadFromText(text);

      const newGalleryFileSet: CdpEmailTemplateGalleryFileSet =
        new CdpEmailTemplateGalleryFileSet();

      const files: CdpCommonFile[] = multiFile.files;

      //console.log("MultiFile contains files:", files);

      for (const file of files) {
        if (file.header.fileType == CdpCommonFileType.EmailTemplate) {
          const templateFile: CdpEmailTemplateFile = new CdpEmailTemplateFile();
          templateFile.loadFromEmtCommonFile(file);

          const doExclude = templateFile.info.doExclude;
          if (doExclude) {
            //console.log("Excluding template: ", templateFile.info.id.id);
            continue;
          }

          const templateManagerFile: CdpEmailTemplateManagerFile =
            new CdpEmailTemplateManagerFile();

          templateManagerFile.templateFile = templateFile;
          templateManagerFile.templateInfo = templateFile.info;

          newGalleryFileSet.addTemplateManagerFile(
            templateManagerFile,
            null, // no need for branding transformer yet
            null, // no filter yet
            this.emptyFilterMatchesAll
          );
        } else {
          // Ignore any non-template files.
        }
      }

      this.galleryFileSet = newGalleryFileSet;
      this.applyCurrentBrandingSet();

      succeeded = true;
      //console.log('New num templates:', newGalleryFileSet.files.length);

      /* Use this to export HTML 
      console.log("Combined template file HTML:");
      console.log(allTemplateFileFullHtmlText);
      */
    } finally {
      const message: string = succeeded
        ? 'Loading succeeded.'
        : 'Loading failed.';
      this.operationSet.updateOperationFinal(operationTag, succeeded, message);
      this.didLoadAtLeastOnce = true;
    }
  }

  private loadBuiltinTemplates_() {
    const url: string = 'assets/email-templates/all_builtin_templates.emtm';
    const handleErrorFunc = this.handleError.bind(this);

    const operationTag: CdpProgressOperationTag =
      this.operationSet.addOperation(
        CdpEmailTemplateGalleryOperationType.LoadTemplates,
        'Loading templates...'
      );

    try {
      const observable: Observable<string> =
        this.fileService.loadUrlFileAsText(url);

      observable.subscribe((text) =>
        this.loadBuiltinTemplatesFinish_(text, operationTag)
      );
    } catch (error) {
      const message: string = 'Load builtin templates caught error: ' + error;
      this.operationSet.updateOperationFinal(operationTag, false, message);
      this.didLoadAtLeastOnce = true;
    }
  }

  private handleError() {
    return (error: any): Observable<string> => {
      // TODO: send the error to remote logging infrastructure
      console.log('Error: ', error); // log to console instead
      return of('');
    };
  }
}
