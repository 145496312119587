import { isArray, setRangeValue } from "@tsparticles/engine";
export class FireworkOptions {
  constructor() {
    this.background = "none";
    this.brightness = {
      min: -30,
      max: 30
    };
    this.colors = ["#ff595e", "#ffca3a", "#8ac926", "#1982c4", "#6a4c93"];
    this.gravity = 5;
    this.minHeight = {
      min: 10,
      max: 30
    };
    this.rate = 10;
    this.saturation = {
      min: -30,
      max: 30
    };
    this.sounds = true;
    this.speed = {
      min: 5,
      max: 15
    };
    this.splitCount = {
      min: 75,
      max: 150
    };
  }
  load(data) {
    if (!data) {
      return;
    }
    if (data.background !== undefined) {
      this.background = data.background;
    }
    if (data.colors !== undefined) {
      if (isArray(data.colors)) {
        this.colors = [...data.colors];
      } else {
        this.colors = data.colors;
      }
    }
    if (data.brightness !== undefined) {
      this.brightness = setRangeValue(data.brightness);
    }
    if (data.gravity !== undefined) {
      this.gravity = setRangeValue(data.gravity);
    }
    if (data.minHeight !== undefined) {
      this.minHeight = setRangeValue(data.minHeight);
    }
    if (data.rate !== undefined) {
      this.rate = setRangeValue(data.rate);
    }
    if (data.saturation !== undefined) {
      this.saturation = setRangeValue(data.saturation);
    }
    if (data.sounds !== undefined) {
      this.sounds = data.sounds;
    }
    if (data.speed !== undefined) {
      this.speed = setRangeValue(data.speed);
    }
    if (data.splitCount !== undefined) {
      this.splitCount = setRangeValue(data.splitCount);
    }
  }
}