<div class="d-flex justify-content-center m-2">
  <h3 class="text-color-primary">Integrations</h3>
</div>

<div class="vertical-break"></div>

<div class="d-flex justify-content-center m-2">
  <h3 class="text-color-primary">Connect your PoS system:</h3>
</div>

<div class="d-flex flex-column justify-content-center">
  <div class="d-flex justify-content-center my-2">
    <button class="btn btn-outline-primary" style="border-color: black;width:250px;" (click)="onClickConnectSquare()">
      <img
        src="assets/Square/Square_LogoLockup_Black_small.png"
        height="50px;"
      />
    </button>
  </div>
  <div class="d-flex justify-content-center my-2">
    <button class="btn btn-outline-primary" style="border-color: black; width:250px" disabled>
      <img src="assets/Toast/toast-logo_color.png" height="50px;" />
    </button>
  </div>
  <div class="d-flex justify-content-center my-2">
    <button class="btn btn-outline-primary" style="border-color: black; width:250px" disabled>
      <img src="assets/Clover/CloverPOS_logo-768x262.jpg" height="50px;" />
    </button>
  </div>
  
</div>
