<app-email-template-gallery-section
  [sectionTitle]="sectionTitle"
  [ownerCategory]="ownerCategory"
  [allowVariants]="allowVariants"
  [showFilters]="showFilters"
  [emptyFilterMatchesAll]="emptyFilterMatchesAll"
  [galleryFileSet]="galleryFileSet"
  [operationSet]="operationSet"
  [didLoadAtLeastOnce]="didLoadAtLeastOnce"
  [showTemplateNames]="showTemplateNames"
  [showTemplateIds]="showTemplateIds"
></app-email-template-gallery-section>
