import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { CdpProgressOperationEvent, CdpProgressOperationSet } from 'src/app/ui/progress/cdp-progress';

@Component({
  selector: 'app-cdp-progress-spinner',
  standalone: true,
  imports: [],
  templateUrl: './cdp-progress-spinner.component.html',
  styleUrl: './cdp-progress-spinner.component.sass',
})
export class CdpProgressSpinnerComponent implements AfterViewInit {
  @ViewChild('progress_div') progressDiv!: ElementRef<HTMLDivElement>;

  ngAfterViewInit() {
    this.operationSetChanged_();
  }

  /*
  get isOperationInProgress() {
    return this.operationSet_.isOperationInProgress;
  }
  */
  private isOperationInProgress_: boolean = false;
  
  get isOperationInProgress(): boolean {
    return this.isOperationInProgress_;
  }

  get message() {
    return this.operationSet_.getOperationInProgressMessage();
  }
  
  private operationSet_: CdpProgressOperationSet = new CdpProgressOperationSet();

  @Input() set operationSet(set: CdpProgressOperationSet) {
    if (set != this.operationSet_) {
      this.operationSet_.eventEmitter.unsubscribe();

      this.operationSet_ = set;
      this.operationSetChanged_();

      this.operationSet_.eventEmitter.subscribe((e) => this.operationSetChanged_());
    }
  }

  private operationSetChanged_() {
    this.isOperationInProgress_ = this.operationSet_.isOperationInProgress;

    if (this.progressDiv) {
      this.progressDiv.nativeElement.hidden = !this.isOperationInProgress_;
    }
  }
}
