import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { CdpSignInComponent } from './core/cdp-sign-in/cdp-sign-in.component';
import { CdpRegisterComponent } from './core/cdp-register/cdp-register.component';
import { SquareConnectStartComponent } from './connectors/square/square-connect-start/square-connect-start.component';
import { CdpAfterSignInComponent } from './core/cdp-after-sign-in/cdp-after-sign-in.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SquareConnectCompleteComponent } from './connectors/square/square-connect-complete/square-connect-complete.component';
import { SquareConnectPageComponent } from './pages/square-connect-page/square-connect-page.component';
import { CdpUserProfileComponent } from './core/profile/cdp-user-profile/cdp-user-profile.component';
import { CdpBusinessProfileComponent } from './core/business/cdp-business-profile/cdp-business-profile.component';
import { CdpEmailTemplateEditorComponent } from './email/template/email-template-editor/email-template-editor.component';
import { CdpEmailTemplateGalleryComponent } from './email/template/cdp-email-template-gallery/cdp-email-template-gallery.component';
import { CdpEmailTemplateGalleryUserComponent } from './email/template/cdp-email-template-gallery-user/cdp-email-template-gallery-user.component';

import { CdpRoutes } from './core/router/cdp-routes';
import { CdpProfileCombinedComponent } from './core/profile/cdp-profile-combined/cdp-profile-combined.component';
import { CdpInitialRegistrationComponent } from './core/cdp-initial-registration/cdp-initial-registration.component';
import { CdpImageGalleryUserComponent } from './images/cdp-image-gallery-user/cdp-image-gallery-user.component';
import { CdpCustomerDatabaseComponent } from './customers/cdp-customer-database/cdp-customer-database.component';
import { CdpIntegrationsComponent } from './connectors/cdp-integrations/cdp-integrations.component';
import { CdpCalendarComponent } from './calendar/cdp-calendar/cdp-calendar.component';
import { CdpEmailSenderVerificationComponent } from './email/verification/cdp-email-sender-verification/cdp-email-sender-verification.component';
import { CdpEmailSenderVerificationFailureComponent } from './email/verification/cdp-email-sender-verification-failure/cdp-email-sender-verification-failure.component';
import { CdpEmailSenderVerificationSuccessComponent } from './email/verification/cdp-email-sender-verification-success/cdp-email-sender-verification-success.component';
import { CdpTermsOfServiceComponent } from './legal/cdp-terms-of-service/cdp-terms-of-service.component';
import { CdpCookiePolicyComponent } from './legal/cdp-cookie-policy/cdp-cookie-policy.component';
import { CdpFeedbackComponent } from './core/feedback/cdp-feedback/cdp-feedback.component';
import { CdpPrivacyPolicyComponent } from './legal/cdp-privacy-policy/cdp-privacy-policy.component';
import { CdpFeedbackCompleteComponent } from './core/feedback/cdp-feedback-complete/cdp-feedback-complete.component';
import { CdpSendEmailCompleteComponent } from './email/send/cdp-send-email-complete/cdp-send-email-complete.component';
import { CdpSendEmailComponent } from './email/send/cdp-send-email/cdp-send-email.component';
import { CdpEmailCampaignCreateComponent } from './email/campaign/cdp-email-campaign-create/cdp-email-campaign-create.component';

export const routes: Routes = [
  {
    path: CdpRoutes.Home,
    component: MainComponent,
  },
  {
    path: CdpRoutes.AfterSignIn,
    component: CdpAfterSignInComponent,
  },
  {
    path: CdpRoutes.Calendar,
    component: CdpCalendarComponent,
  },
  {
    path: CdpRoutes.CookiePolicy,
    component: CdpCookiePolicyComponent,
  },
  {
    path: CdpRoutes.CustomerDatabase,
    component: CdpCustomerDatabaseComponent,
  },
  {
    path: CdpRoutes.Dashboard,
    component: DashboardComponent,
  },
  {
    path: CdpRoutes.EmailCampaignCreate,
    component: CdpEmailCampaignCreateComponent,
  },
  {
    path: CdpRoutes.EmailSenderVerification,
    component: CdpEmailSenderVerificationComponent,
  },
  {
    path: CdpRoutes.EmailSenderVerificationFailure,
    component: CdpEmailSenderVerificationFailureComponent,
  },
  {
    path: CdpRoutes.EmailSenderVerificationSuccess,
    component: CdpEmailSenderVerificationSuccessComponent,
  },
  {
    path: CdpRoutes.Feedback,
    component: CdpFeedbackComponent,
  },
  {
    path: CdpRoutes.FeedbackComplete,
    component: CdpFeedbackCompleteComponent,
  },
  {
    path: CdpRoutes.InitialRegistration,
    component: CdpInitialRegistrationComponent,
  },
  {
    path: CdpRoutes.Integrations,
    component: CdpIntegrationsComponent,  
  },
  {
    path: CdpRoutes.PrivacyPolicy,
    component: CdpPrivacyPolicyComponent,
  },
  {
    path: CdpRoutes.SignIn,
    component: CdpSignInComponent,
  },
  {
    path: CdpRoutes.TermsOfService,
    component: CdpTermsOfServiceComponent,
  },
  {
    path: CdpRoutes.Register,
    component: CdpRegisterComponent,
  },

  {
    path: CdpRoutes.BusinessProfile,
    component: CdpBusinessProfileComponent,
  },
  {
    path: CdpRoutes.CombinedProfile,
    component: CdpProfileCombinedComponent,
  },
  {
    path: CdpRoutes.SquareConnect,
    component: SquareConnectPageComponent,
  },
  {
    path: CdpRoutes.SquareConnectComplete,
    component: SquareConnectCompleteComponent,
  },
  {
    path: CdpRoutes.SquareConnectStart,
    component: SquareConnectStartComponent,
  },
  {
    path: CdpRoutes.SendEmail,
    component: CdpSendEmailComponent,
  },
  {
    path: CdpRoutes.SendEmailComplete,
    component: CdpSendEmailCompleteComponent,
  },
  {
    path: CdpRoutes.EmailTemplateEditor,
    component: CdpEmailTemplateEditorComponent,
  },
  {
    path: CdpRoutes.EmailTemplateGallery,
    component: CdpEmailTemplateGalleryComponent,
  },
  {
    path: CdpRoutes.EmailTemplateGalleryUser,
    component: CdpEmailTemplateGalleryUserComponent,
  },
  {
    path: CdpRoutes.ImageGalleryUser,
    component: CdpImageGalleryUserComponent,
  },
  {
    path: CdpRoutes.UserProfile,
    component: CdpUserProfileComponent,
  },
];
