<div class="container m-2" style="max-width: 600px">
  <div class="d-flex justify-content-center m-2">
    <h3 class="text-color-primary">Create Campaign</h3>
  </div>
  <div class="form form-control w-100">
    <div class="form-group row">
      <div class="col col-3">
        <label class="col-form-label">Sender display</label>
      </div>
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder=""
          [(ngModel)]="senderDisplayName"
        />
      </div>
    </div>

    <div class="vertical-break"></div>

    <div class="form-group row">
      <div class="col col-3">
        <label class="col-form-label">Sender</label>
      </div>
      @if (senderEmailAddressOptions.length == 1) {
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder=""
          [(ngModel)]="senderEmailAddress"
          disabled
        />
      </div>
      } @else {
      <div class="col col-9">
        <select
          class="form-select"
          [(ngModel)]="senderEmailAddress"
          (change)="onSenderEmailAddressChanged()"
        >
          @for (senderOption of senderEmailAddressOptions; track $index) {
          <option>{{ senderOption }}</option>
          }
        </select>
      </div>
      }
    </div>

    <div class="vertical-break"></div>

    <app-cdp-customer-database-list-picker></app-cdp-customer-database-list-picker>

    <div class="vertical-break"></div>

    <div class="form-group row">
      <div class="col col-3">
        <label class="col-form-label">Template</label>
      </div>
      <div class="col col-9">
        <select
          class="form-select"
          [(ngModel)]="selectedTemplateName"
          (change)="onSelectedTemplateNameChanged()"
        >
          @for (templateName of templateNames; track $index) {
          <option>{{ templateName }}</option>
          }
        </select>
      </div>
    </div>

    <div class="vertical-break"></div>

    <div class="form-group row">
      <div class="col col-3">
        <label class="col-form-label">Subject</label>
      </div>
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder=""
          [(ngModel)]="subject"
          (change)="onSubjectChanged()"
        />
      </div>
    </div>

    <div class="vertical-break"></div>

    <div class="accordion" id="accordion_subject_generator">
      <div class="accordion-item">
        <h4 class="accordion-header" id="headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Subject generator
          </button>
        </h4>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div class="card-body">
              <div class="form-group row">
                <div class="col">
                  <label class="col-form-label p-0">Subject generator</label>
                  <button
                    type="button"
                    class="btn btn-outline-primary mx-2 my-0 py-0 px-1"
                    style="
                      background-color: lightskyblue;
                      color: black;
                      border-color: rgb(99, 39, 120);
                    "
                    (click)="onClickGenerateSubjects()"
                  >
                    ⟳
                  </button>
                </div>

                <div>
                  @for (optionName of subjectSelectOptionsDisplayed; track
                  $index) {
                  <button
                    type="button"
                    class="btn btn-outline-primary m-1 text-start"
                    style="
                      width: 95%;
                      color: black;
                      border-color: rgb(99, 39, 120);
                    "
                    (click)="onClickGeneratedSubject($index)"
                  >
                    {{ optionName }}
                  </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="vertical-break"></div>

      <div class="form-group row">
        <div class="col col-3">
          <label class="col-form-label">Send date</label>
        </div>
        <div class="col">
          <ngb-datepicker #datepicker></ngb-datepicker>
        </div>
      </div>
      <div class="vertical-break"></div>
      <br>
      @if (!canCreateCampaign) {
      <div class="vertical-break"></div>
      <p style="color: red">You have not finished setting up your campaign.</p>
      }
      <div class="row justify-content-center">
      <div class="col col-auto">
        <button
          type="button"
          class="btn btn-primary"
          mdbRipple
          [disabled]="!canCreateCampaign || isOperationInProgress"
          (click)="onClickCreateCampaign()"
        >
          Create campaign
        </button>
      </div>
      <div>
        <app-cdp-progress-spinner
          [operationSet]="operationSet"
        ></app-cdp-progress-spinner>
      </div>
    </div>
      <br /><br />
      <div>
        <div class="d-flex">
          <span>
            <div>
              <p>Template</p>
              <app-cdp-email-template-tile
                #template_tile
              ></app-cdp-email-template-tile>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
