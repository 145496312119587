<nav
  class="nav navbar navbar-expand-lg justify-content-end"
  role="banner"
>
  <div class="container-fluid">
    <ul class="navbar-nav d-flex flex-row">
      <a class="navbar-brand" [routerLink]="linkHome"><img src="assets/img/wagel-logo-site-banner.png"></a>
      <a class="nav-link" [routerLink]="linkHome">Home</a>
      <!-- <a class="nav-link" href="/contact">Contact</a> -->
    </ul>
    <ul class="navbar-nav d-flex flex-row justify-content-end"> 
      @if (!isUserSignedIn) {
      <a class="nav-link px-2" [routerLink]="linkSignIn">Sign in</a>
      <a class="nav-link px-2" [routerLink]="linkRegister">Register</a>
      } @else {
      <a class="nav-link px-2" (click)="onClickSignOut()">Sign out</a>
      <a class="nav-link px-sm-2" [routerLink]="linkProfile">
        <i class="bi-person-circle"></i>
      </a>
      }
    </ul>
  </div>
</nav>
