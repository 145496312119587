export class CdpName {
  // Note that using "given name" and "family name" rather than "first name"
  // and "last name" allows for better international use.
  givenName: string = '';
  familyName: string = '';

  constructor(givenName: string = '', familyName: string = '') {
    this.givenName = givenName;
    this.familyName = familyName;
  }
  
  public isValid(): boolean {
    return this.givenName.length > 0 && this.familyName.length > 0;
  }

  public isEqual(rhs: CdpName): boolean {
    return this.givenName == rhs.givenName && this.familyName == rhs.familyName;
  }
}
