<div class="container m-2" style="max-width: 600px">
  <div class="d-flex justify-content-center m-2">
    <h3 class="text-color-primary">Send Email</h3>
  </div>
  <div class="form form-control w-100">
    <div class="form-group row">
      <div class="col col-3">
        <label class="col-form-label">Sender display</label>
      </div>
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder=""
          [(ngModel)]="senderDisplayName"
        />
      </div>
    </div>

    <div class="vertical-break"></div>

    <div class="form-group row">
      <div class="col col-3">
        <label class="col-form-label">Sender</label>
      </div>
      @if (senderEmailAddressOptions.length == 1) {
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder=""
          [(ngModel)]="senderEmailAddress"
          disabled
        />
      </div>
    } @else {
      <div class="col col-6">
        <select
          class="form-select"
          [(ngModel)]="senderEmailAddress"
          (change)="onSenderEmailAddressChanged()"
        >
          @for (senderOption of senderEmailAddressOptions; track $index) {
          <option>{{ senderOption }}</option>
          }
        </select>
      </div>
    }
    </div>

    <div class="vertical-break"></div>

    <div class="form-group row">
      <div class="col col-3">
        <label class="col-form-label">Recipients</label>
        @if (numRecipients > 0) {
        <span class="badge bg-secondary mx-2">{{ numRecipients }}</span>
        }
      </div>
      <div class="col">
        <input
          type="email"
          inputmode="text"
          class="form-control"
          placeholder=""
          [(ngModel)]="recipientEmailAddresses"
          (change)="onRecipientChanged()"
          multiple
        />
      </div>
    </div>

    <div class="vertical-break"></div>

    <div class="form-group row">
      <div class="col col-3">
        <label class="col-form-label">Subject</label>
      </div>
      <div class="col">
        <input
          type="text"
          class="form-control"
          placeholder=""
          [(ngModel)]="subject"
          (change)="onSubjectChanged()"
        />
      </div>
    </div>

    <div class="vertical-break"></div>

    <div class="card">
      <div class="card-body">
        <div class="form-group row">
          <div class="col">
            <label class="col-form-label p-0">Subject generator</label>
            <button
              type="button"
              class="btn btn-outline-primary mx-2 my-0 py-0 px-1"
              style="
                background-color: lightskyblue;
                color: black;
                border-color: rgb(99, 39, 120);
              "
              (click)="onClickGenerateSubjects()"
            >
              ⟳
            </button>
          </div>

          <div>
            @for (optionName of subjectSelectOptionsDisplayed; track $index) {
            <button
              type="button"
              class="btn btn-outline-primary m-1 text-start"
              style="width: 95%; color: black; border-color: rgb(99, 39, 120)"
              (click)="onClickGeneratedSubject($index)"
            >
              {{ optionName }}
            </button>
            }
          </div>
        </div>
      </div>
    </div>

    @if (recipientEmailAddresses.length == 0) {
    <div class="vertical-break"></div>
    <p style="color: red">Recipient email address must be specified.</p>
    }
    <div>
      <button
        type="button"
        class="btn btn-primary"
        mdbRipple
        [disabled]="(recipientEmailAddresses.length == 0) || isOperationInProgress"
        (click)="onClickSendEmail()"
      >
        Send email
      </button>
    </div>
    <div>
      <app-cdp-progress-spinner [operationSet]="operationSet"></app-cdp-progress-spinner>
    </div>
    <br /><br />
    <div>
      <div class="d-flex">
        <span>
          <div>
            <p>Template</p>
            <app-cdp-email-template-tile
              #template_tile
            ></app-cdp-email-template-tile>
          </div>
        </span>

        <span>
          <div>
            <p>Email</p>
            @if (recipientEmailAddresses.length == 0) {
            <p style="color: red">Recipient email address must be specified.</p>
            }
            <app-cdp-email-template-tile
              #email_tile
            ></app-cdp-email-template-tile>
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
