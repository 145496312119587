
@if (stillLoading) {
<div>
  <p>Connecting to Square...</p>
</div>
<div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
</div>
}
