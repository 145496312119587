<div
  class="container-fluid d-flex align-items-center m-0 p-0"
  style="
    min-height: 100%;
    background-size: 100% 100%;
    background-image: url('/assets/img/main-background.png');
    height: 100%;
  "
>
  <div class="container-fluid col-9" style="text-align: center;">
    <h2 style="color: white">
      Spend your time running your business, not figuring out how to market it.
    </h2>
    <br /><br />
    <h4 style="color: white">
      We can automatically generate smart email campaigns for your business
      using your existing PoS customer information.
      <br />
      Customize your campaigns, or relax and let us set them up for you.
    </h4>
    <br /><br />

    <h4 style="color: white">
      We're in beta phase -- new features are added weekly.
    </h4>
    <br /><br />
    <h4 style="color: white">
      <a [routerLink]="linkSignIn">Sign in</a> or <a [routerLink]="linkRegister">Register</a> to get
      started!
    </h4>
  </div>
</div>
