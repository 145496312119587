@if (visible) {
<div class="d-flex container-fluid">
  @for (select of selects; track $index) {

  <div class="container-fluid">
    <a style="display: inline !important; color: var(--color-primary)"
      ><strong>{{ selectIntros[$index] }}&nbsp;</strong></a
    ><app-cdp-select
      style="display: inline !important"
      [select]="selects[$index]"
      (selectedItemsEvent)="selectedItemsEvent($event)"
    ></app-cdp-select>
  </div>

  }
</div>
}