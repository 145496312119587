import { Component } from '@angular/core';

@Component({
  selector: 'app-cdp-email-sender-verification-success',
  standalone: true,
  imports: [],
  templateUrl: './cdp-email-sender-verification-success.component.html',
  styleUrl: './cdp-email-sender-verification-success.component.sass'
})
export class CdpEmailSenderVerificationSuccessComponent {

}
