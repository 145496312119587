<!--<span #editor_container style="width:100%;">-->
<div class="d-flex">
  <span class="d-flex m-2">
    <label class="form-label m-2" for="template_name">Name</label
    ><input
      type="text"
      id="template_name"
      class="form-control form-control-sm input-sm p-0"
      style="
        padding: 0 0;
        min-height: 0px;
        line-height: 1;
        max-height: 40px;
        width: 30em;
      "
      placeholder="My Template"
      [(ngModel)]="currentTemplateName"
    />
  </span>
  <span class="d-flex justify-content-center mx-2 w-25">
    <span class="text-center">
      <button
        #save_button
        class="btn btn-primary profile-button m-2"
        type="button"
        (click)="onClickSave()"
      >
        Save
      </button>

      <button
        hidden
        #discard_edits_button
        class="btn btn-primary profile-button m-2"
        type="button"
        (click)="onClickDiscardEdits()"
      >
        Discard edits
      </button>
    </span>

    <!--
  <input type="text" class="form-control" [(ngModel)]="operationInProgress">
  -->
  </span>
</div>


<div>
  <app-cdp-progress-spinner
    [operationSet]="this.operationSet"
  ></app-cdp-progress-spinner>
</div>

<iframe #iframe style="display: initial !important"></iframe>
