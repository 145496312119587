<div class="d-flex justify-content-center m-2">
  <h3 class="text-color-primary">My Images</h3>
</div>

<app-cdp-progress-spinner [operationSet]="operationSet">
</app-cdp-progress-spinner>


@for (sectionInfo of sectionInfos; track $index) {
<app-cdp-image-gallery-section
  [sectionInfo]="sectionInfos[$index]"
  [operationSet]="operationSet"
></app-cdp-image-gallery-section>

<div style="height: 10px"></div>
}
