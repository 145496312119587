<div
  class="container my-2 px-2 text-center"
  style="
    float:left;
    width:140px;
    height:136px;
    border: solid;
    background-color: lightgray;
    justify-content: center;
    justify-items: center;
  "
  appDnd
  [hidden]="isOperationInProgress"
  (fileDropped)="onFileDropped($event)"
>
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    [multiple]="multiple"
    (change)="fileBrowseHandler($event)"
  />
  <div>
    <div>
      <a>{{ text }}</a>
    </div>
    <div
      [innerHTML]="allowedFileTypeIcons"
    ></div>
  </div>
</div>

<!-- The following is for a general dropzone, not just a single image.
<div class="container" appDnd (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    (change)="fileBrowseHandler($event)"
  />
  <h3>Drag file(s) here</h3>
  <i class="fs-1 bi-cloud-upload"></i>
  <h4>or</h4>
  <label for="fileDropRef">Browse for file</label>
</div>
-->
