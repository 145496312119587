<div class="container-fluid">
  <div class="card px-1 ms-3">
    <h4 class="card-title mx-3 my-1">{{ getSectionInfo().name }}</h4>
    <div class="vertical-break"></div>

    <div class="card-body">
      <app-cdp-drop-zone
      #dropzone
      [isOperationInProgress]="isOperationInProgress"
      [allowMultipleFiles]="getSectionInfo().allowMultipleImages"
    ></app-cdp-drop-zone>
      <div>
        @for (image of images; track $index) {
        <app-cdp-image-tile
          [image]="images[$index]"
          [isOperationInProgress]="isOperationInProgress"
          (beingDeletedEvent)="removeImage(image)"
        ></app-cdp-image-tile>
        }
      </div>
    </div>
  </div>
</div>
