export enum CdpFileType {
  Any = 'Any',
  Csv = 'CSV',
  Txt = 'TXT',
  Image = 'Image',
}

export enum CdpFileCategory {
  CustomerData = 'CustomerData',
  CustomerList = 'CustomerList',
  EmailList = 'EmailList',
  EmailTemplate = 'EmailTemplate',
  Image = 'Image',
  Other = 'Other',
}

export enum CdpFileLocationType {
  Internal = 'Internal',
  BusinessPublic = 'BusinessPublic',
  BusinessPrivate = 'BusinessPrivate',
  UserPrivate = 'UserPrivate',
  Unspecified = 'Unspecified',
}

export const allCategories: CdpFileCategory[] = [
  CdpFileCategory.Image,
  CdpFileCategory.EmailTemplate,
  CdpFileCategory.Other,
];

// Note: "Unspecified" is intentionally omitted.
export const allLocationTypes: CdpFileLocationType[] = [
  CdpFileLocationType.BusinessPublic,
  CdpFileLocationType.BusinessPrivate,
  CdpFileLocationType.UserPrivate,
  CdpFileLocationType.Internal,
];

export class CdpFileMetadata {
  metadata: any = null;
}

export class CdpFileInfo {
  static metadataFileSuffix = '.meta.json';

  static isFilenameMetadata(filename: string): boolean {
    return filename.endsWith(CdpFileInfo.metadataFileSuffix);
  }

  isSameFile(rhs: CdpFileInfo): boolean {
    // Note: We intentionally do not compare <metadata>, <metadataFileInfo>,
    //       and/or <url>.
    return (
      this.filename == rhs.filename &&
      this.isMetadata == rhs.isMetadata &&
      this.locationType == rhs.locationType &&
      this.category == rhs.category &&
      this.isDeleted == rhs.isDeleted &&
      this.deletedVersion == rhs.deletedVersion
    );
  }

  locationType: CdpFileLocationType = CdpFileLocationType.Unspecified;
  filename: string = '';
  category: CdpFileCategory = CdpFileCategory.Other;

  // isMetadata is true if the file itself is a metadata file,
  // and false otherwise.
  isMetadata: boolean = false;

  // If <isMetadata> is true, then <metadata> represents the
  // contents of the metadata file.  If <isMetadata> is false
  // and <metadata> is not null, then <metadata> is the
  // metadata associated with the file.
  metadata: CdpFileMetadata | null = null;

  // For a file that is not itself a metadata file, the associated
  // metadata file, if any, is specified by <metadataFileInfo>.
  // In this case, <metadata> will be the contents of the
  // associated metadata file if that file has been loaded already.
  metadataFileInfo: CdpFileInfo | null = null;

  url: string = ''; // available only for public files

  // Files that are in the recycle bin are marked as deleted.
  isDeleted: boolean = false;

  // If there are multiple old deleted versions of a file,
  // each has a unique suffix.
  deletedVersion: string = '';

  isInternal(): boolean {
    // Note: It seems safest to consider an unspecified file as being
    //       internal.
    return (
      this.locationType == CdpFileLocationType.Internal ||
      this.locationType == CdpFileLocationType.Unspecified
    );
  }

  couldBeDeleted(): boolean {
    // A user could delete a file if the file isn't already
    // deleted, isn't an internal file, and has a filename.
    return !this.isDeleted && !this.isInternal() && this.filename.length > 0;
  }

  static indexOf(fileInfos: CdpFileInfo[], fileInfo: CdpFileInfo) {
    const numInfos: number = fileInfos.length;
    for (let index = 0 ; index < numInfos ; index++) {
      if (fileInfo.isSameFile(fileInfos[index])) {
        return index;
      }
    }

    return -1;
  }
}

export class CdpFile {
  fileInfo: CdpFileInfo = new CdpFileInfo();
  file: File | null = null;

  isInternal(): boolean {
    return this.fileInfo.isInternal();
  }
  
  static makeLegalFilename(filename: string): string {
    // AWS imposes restrictions on the characters that can be used as
    // object keys.
    // See https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html.

    // TODO TODO
    return filename.replaceAll(' ', '_');
  }

  couldBeDeleted(): boolean {
    return this.fileInfo.couldBeDeleted();
  }
}

export class CdpFileObjectSet {
  location_type: CdpFileLocationType = CdpFileLocationType.Unspecified;
  root_url: string = '';
  object_key_names: string[] = [];

  static makeObjectKeyNameUrl(
    objectKeyName: string,
    locationType: CdpFileLocationType,
    rootUrl: string
  ) {
    if (locationType == CdpFileLocationType.BusinessPublic) {
      // Note: <rootUrl> should already contain a trailing slash if it
      //       isn't empty.
      return rootUrl + objectKeyName;
    } else {
      // There is no public URL for this object.
      return '';
    }
  }
  getObjectKeyNameUrl(objectKeyName: string): string {
    return CdpFileObjectSet.makeObjectKeyNameUrl(
      objectKeyName,
      this.location_type,
      this.root_url
    );
  }
}

export class CdpFileHelper {
  static readFileAsText(inputFile: File): Promise<string> {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result as string);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  }
}
